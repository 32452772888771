export const fetchActivities = async (
  welletContext,
  id = null,
  includeInactive = false,
  url = 'api/Activity/all',
) => {
  const body = {
    limit: null,
    skip: 0,
    organizationId: id,
    includeInactive,
  };
  try {
    const response = await welletContext.apis.admin.post(url, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};

export const getActivity = async (welletContext, id, url = `api/Activity`) => {
  try {
    const response = await welletContext.apis.admin.get(`${url}/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};
export const postActivity = async (welletContext, organizationId, body) => {
  const url = `api/Activity/${organizationId}`;
  return await welletContext.apis.admin.post(url, body);
};

export const putActivity = async (welletContext, body, id, url = 'api/Activity') => {
  const response = await welletContext.apis.admin.put(`${url}/${id}`, body);
  return response;
};
export const getVenues = async (welletContext, organizationId, url = '/api/Venue/all') => {
  const body = {
    limit: null,
    skip: 0,
    organizationId: organizationId,
  };
  try {
    const response = await welletContext.apis.admin.post(url, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};

export const getTimeZones = async (welletContext, url = '/api/TimeZone/all') => {
  try {
    const response = await welletContext.apis.admin.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};

export const orderByPriority = async (
  welletContext,
  organizationId,
  body,
  url = '/api/Activity/priority',
) => {
  if (organizationId) {
    url += `/${organizationId}`;
  }
  return await welletContext.apis.admin.post(url, body);
};

export const getTemplates = async (welletContext, url = 'api/Activity/purchaseTemplates') => {
  return await welletContext.apis.admin.get(`${url}`);
};

export const patchActivityStatus = async (welletContext, id, status) => {
  return await welletContext.apis.admin.patch(`api/Activity/${id}?isActive=${status}`);
};
