import React from 'react';

export default function Fiserv({ size = '16px', color = '#ccc' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 666 334'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M640.41 95.9715C640.41 98.3623 638.562 100.196 636.186 100.196C633.81 100.196 631.947 98.3623 631.947 95.9715C631.947 93.5806 633.78 91.7472 636.186 91.7472C638.591 91.7472 640.41 93.5586 640.41 95.9715ZM641.224 95.9715C641.224 93.0966 639.017 90.9331 636.186 90.9331C633.355 90.9331 631.133 93.0893 631.133 95.9715C631.133 98.8537 633.304 101.01 636.186 101.01C639.068 101.01 641.224 98.839 641.224 95.9715ZM638.474 94.7834C638.474 93.6393 637.66 93.1259 636.677 93.1259H634.272V98.6337H635.21V96.5068H636.259L637.337 98.6337H638.445L637.337 96.4115C638.027 96.1915 638.481 95.6635 638.481 94.7834M637.543 94.8127C637.543 95.3481 637.169 95.5974 636.648 95.5974H635.21V94.0207H636.648C637.161 94.0207 637.543 94.2994 637.543 94.8201'
        fill='#A4A4A4'
      />
      <path d='M665.058 288.611H622.609V331.074H665.058V288.611Z' fill='#A4A4A4' />
      <path
        d='M536.468 99.5356L561.763 289.329L587.006 99.5356H626.403L589.551 331.074H533.527L497.07 99.5356H536.468Z'
        fill='#A4A4A4'
      />
      <path
        d='M21.8477 99.5355H0V130.301H21.8477V331.074H60.2479V130.301H87.2292V99.5355H60.2479V52.474C60.2479 45.6094 61.6707 40.3511 64.9856 36.7574C68.3152 33.1932 73.3976 31.4991 80.2987 31.4991H87.2146V0H73.3242C56.2582 0 39.823 3.61561 31.9978 11.0448C24.1798 18.4741 21.8477 30.0029 21.8477 46.4235V99.5429V99.5355Z'
        fill='#A4A4A4'
      />
      <path d='M99.2188 331.059H137.714V99.521H99.2188V331.059Z' fill='#A4A4A4' />
      <path
        d='M315.431 228.399H395.671V157.04C395.576 114.357 375.055 96.8511 336.171 96.8511C297.287 96.8511 276.781 114.306 276.781 157.319V274.581C276.906 313.7 300.587 333.941 336.156 333.941C371.726 333.941 395.576 317.286 395.671 274.581V256.033H358.488L358.532 278.005C358.532 293.678 352.665 304.517 336.934 304.517C321.203 304.517 315.423 293.583 315.423 277.991V228.392L315.431 228.399ZM315.431 149.362C315.431 136.3 318.826 122.769 336.831 122.769C354.836 122.769 358.466 136.095 358.466 149.347V197.193H315.438V149.362H315.431Z'
        fill='#A4A4A4'
      />
      <path
        d='M224.267 199.43C195.063 174.077 192.797 170.403 192.797 151.503C192.797 135.405 194.19 125.615 208.66 125.615C223.13 125.615 224.993 135.281 224.993 151.687V173.065H262.095V138.185C261.97 110.8 240.9 96.873 207.802 96.873C174.704 96.873 154.03 107.713 153.839 151.489C153.81 158.932 153.854 158.932 153.839 156.058C153.905 169.823 153.964 191.642 187.986 220.83L197.747 229.184C222.822 250.327 225.558 252.88 225.448 278.035C225.433 293.539 224.479 305.28 208.213 305.28C191.946 305.28 190.927 293.443 190.927 286.139V256.517H151.977V287.077C151.977 324.693 176.457 334 208.161 334C239.866 334 264.405 324.598 264.405 276.209V272.19C264.324 238.373 258.208 229.301 224.267 199.43Z'
        fill='#A4A4A4'
      />
      <path
        d='M447.21 123.363V99.5279H412.125V331.051H449.975V169.699C449.961 157.231 454.024 149.024 461.093 144.169C467.789 139.556 476.033 137.994 486.527 137.833V96.8657C466.447 97.2911 457.778 101.244 447.599 122.52L447.21 123.363Z'
        fill='#A4A4A4'
      />
    </svg>
  );
}
