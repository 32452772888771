import React from 'react';

export default function FwQrIcon({
  color = 'currentColor',
  fontSize = '16px',
  isSelected = false,
}) {
  return (
    <>
      <i className='fas fa-qrcode' style={{ color, fontSize: fontSize }}></i>
    </>
  );
}
