import React, { useContext } from 'react';
import { PageHeader, Table, TableRow, TableCell, CustomCard } from '../../components';
import { useQuery } from 'react-query';
import { fetchCheckIns, fetchChecksInfo } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { Badge, Loading, Tooltip } from '../../uiComponents/common';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { calculatePercentage } from '../../helpers/format';

const CheckIn = () => {
  /*---------- Important ----------*/
  const organization = useSelector((state) => state.app.currentOrganization);

  const welletContext = useContext(WelletContext);
  // const navigate = useNavigate();

  const checkInQuery = useQuery({
    queryKey: ['checkins', organization.id],
    queryFn: () => fetchCheckIns(welletContext, organization.id),
    refetchInterval: 10000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const checkinInfoQuery = useQuery({
    queryKey: ['checkins-info', organization.id],
    queryFn: () => fetchChecksInfo(welletContext, organization.id),
    refetchInterval: 10000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const headerAdmin = [
    'Activity Name',
    'ReferenceCode',
    'Status',
    'Device',
    'Checker Name',
    'CheckIn Date',
  ];
  const headerSuperAdmin = [
    'Organization Name',
    'Activity Name',
    'ReferenceCode',
    'Status',
    'Device',
    'Gate',
    'Checker Name',
    'CheckIn Date',
  ];

  return (
    <>
      <PageHeader title='Check In' />

      {!checkinInfoQuery.isFetched && !checkInQuery.isFetched ? (
        <Loading />
      ) : (
        <>
          <div className='d-flex justify-content-start aling-items-center mt-3 mb-3 w-100 flex-wrap'>
            <CustomCard
              title={`${checkinInfoQuery?.data?.data?.checkInRegistered} of ${checkinInfoQuery?.data?.data?.totalCheckIn}`}
              subtitle={'CHECK-INS'}
              text={`${calculatePercentage(
                checkinInfoQuery?.data?.data?.totalCheckIn,
                checkinInfoQuery?.data?.data?.checkInRegistered,
              )} % `}
            />
          </div>
          <Table
            data={checkInQuery?.data?.data?.items || []}
            totalP={checkInQuery?.data?.data?.items?.length}
            tableKey={`checkin-${organization.id}`}
            showText={false}
            columns={organization.id === 83 ? headerSuperAdmin : headerAdmin}
            renderRow={(checks, index) => (
              <TableRow key={checks.id} index={index}>
                {organization.id === 83 && (
                  <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                    {checks.organizationName || '-'}
                  </TableCell>
                )}
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.activityName || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.referenceCode || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  <Badge status={checks.status} />
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.registerDevice || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.registerUserName || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {(checks.status !== 'Pending' &&
                    format(new Date(checks.registerDate), 'MMM dd, yyyy, h:mm a')) ||
                    '-'}
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default CheckIn;
