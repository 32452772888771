import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputDescription,
  InputFile,
  InputText,
  Modal,
  Tabs,
  TextAlert,
} from '../../../uiComponents/common';
import { FwEditIcon, FwMasksIcon } from '../../../uiComponents/icons';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';

const GalleryForm = ({ images, setImages, open, setOpen }) => {
  const methods = useForm();
  const validateFields = {
    nameImage: {
      rule: (values) => {
        if (!values.nameImage && !values.descriptionImage) {
          return true;
        } else if (values.nameImage && values.nameImage.trim() !== '') {
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Title" field is required and must have a minimum of 3 characters.',
    },
    descriptionImage: {
      rule: (values) => {
        if (!values.nameImage && !values.descriptionImage) {
          return true;
        } else if (values.descriptionImage && values.descriptionImage.trim() !== '') {
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Description" field is required and must have a minimum of 3 characters.',
    },
  };

  const { languages, lngErrors, currentLng, changeLanguage } = useLanguageSwitcher(
    validateFields,
    methods,
  );

  const onSubmit = (data) => {
    let langShowImage = languages.map((lng) => {
      return {
        id: 0,
        showImageId: 0,
        language: lng.shortForm,
        title: data[`nameImage_${lng.shortForm}`],
        description: data[`descriptionImage_${lng.shortForm}`],
      };
    });
    const handleLangShowsEmptys = removeObjectsWithEmptyFields(langShowImage);
    setImages([
      ...images,
      {
        showId: 0,
        imageUrl: data.imageModal.base64,
        priority: images.length + 1,
        langShowImage: handleLangShowsEmptys,
      },
    ]);
    methods.reset();
    setOpen(false);
  };
  function removeObjectsWithEmptyFields(array) {
    return array.filter((obj) => {
      const { title, description } = obj;
      const areAllFieldsEmpty = !title && !description;
      return !areAllFieldsEmpty;
    });
  }

  return (
    <FormProvider {...methods}>
      <form key={2}>
        <Modal
          textCancel={'Cancel'}
          textSubmit={'Add'}
          idModal={'imageUploader'}
          title={'Add Image'}
          btnType='submit'
          onClose={() => {
            setOpen(false);
            methods.reset();
          }}
          handleSubmit={methods.handleSubmit(onSubmit)}
          open={open}
        >
          <InputFile
            id={'imageModal'}
            name={'imageModal'}
            className='col'
            placeholder={'Upload Image'}
            label={''}
            isRequired={true}
          />
          <div className='my-2'>
            <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
            {Object.keys(lngErrors).length !== 0 && (
              <div className='px-2'>
                <TextAlert
                  text={lngErrors.name} // Aquí estamos tomando los valores de los errores
                  type={'danger'}
                />
              </div>
            )}
          </div>
          <InputText
            name={'nameImage_' + currentLng}
            label={'Image Name'}
            className={'col'}
            placeholder={'Enter image name'}
            icon={<FwMasksIcon />}
          />
          <InputDescription
            className='col'
            name={'descriptionImage_' + currentLng}
            label={'Image Description'}
            placeholder={'Enter short description'}
            icon={<FwEditIcon />}
          />
        </Modal>
      </form>
    </FormProvider>
  );
};

export default GalleryForm;
