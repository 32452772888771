import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputDescription,
  InputFile,
  InputText,
  Modal,
  Tabs,
  TextAlert,
} from '../../../uiComponents/common';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';

const EditGalleryForm = ({ images, setImages, open, setOpen, editForm, defaultLanguage }) => {
  const validateFields = {
    nameImage: {
      rule: (values) => {
        if (!values.nameImage && !values.descriptionImage) {
          return true;
        } else if (values.nameImage && values.nameImage.trim() !== '') {
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Title" field is required and must have a minimum of 3 characters.',
    },
    descriptionImage: {
      rule: (values) => {
        if (!values.nameImage && !values.descriptionImage) {
          return true;
        } else if (values.descriptionImage && values.descriptionImage.trim() !== '') {
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Description" field is required and must have a minimum of 3 characters.',
    },
  };

  const methods = useForm();

  const { languages, lngErrors, currentLng, changeLanguage, setLanguages } = useLanguageSwitcher(
    validateFields,
    methods,
  );

  const onSubmit = (data) => {
    const updatedImages = images.map((image, idx) => {
      if (editForm.id === idx) {
        let langShowImage = languages.map((lng) => {
          return {
            id: 0,
            showImageId: 0,
            language: lng.shortForm,
            title: data[`nameImage_${lng.shortForm}`],
            description: data[`descriptionImage_${lng.shortForm}`],
          };
        });

        const handleLangShowsEmptys = removeObjectsWithEmptyFields(langShowImage, [
          'title',
          'description',
        ]);

        return {
          ...image,
          imageUrl: data.imageModal.base64,
          langShowImage: handleLangShowsEmptys,
        };
      }
      return image;
    });
    setImages(updatedImages);
    setOpen(false);
    methods.reset();
  };

  useEffect(() => {
    if (editForm) {
      const newLangs = languages.map((lang) => {
        return { ...lang, isActive: lang?.shortForm === editForm.data?.langShowImage[0]?.language };
      });

      setLanguages(newLangs);

      let formattedData = {
        imageModal: {
          base64: editForm?.data?.imageUrl ?? '',
          name: editForm.data?.langShowImage[0]?.title ?? '',
        },
      };

      editForm.data?.langShowImage.forEach((langShow) => {
        const lang = langShow.language;
        formattedData[`nameImage_${lang}`] = langShow.title;
        formattedData[`descriptionImage_${lang}`] = langShow.description;
      });
      methods.reset(formattedData);
    } else {
      methods.reset();
    }
  }, [editForm]);

  return (
    <FormProvider {...methods}>
      <form key={3}>
        <Modal
          textCancel={'Cancel'}
          textSubmit={'Save'}
          idModal={'imageUploaderEdited'}
          title={'Add Image'}
          btnType='submit'
          onClose={() => {
            setOpen(false);
            methods.reset();
          }}
          open={open}
          handleSubmit={methods.handleSubmit(onSubmit)}
        >
          <InputFile
            id={'imageModal'}
            name={'imageModal'}
            className='col'
            placeholder={'Upload Image'}
            label={''}
          />
          <div className='my-2'>
            <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
            {Object.keys(lngErrors).length !== 0 && (
              <div className='px-2'>
                <TextAlert text={lngErrors.name} type={'danger'} />
              </div>
            )}
          </div>
          <InputText
            name={'nameImage_' + currentLng}
            label={'Image Name'}
            className={'col'}
            placeholder={'Enter image name'}
            icon={<i className='fas fa-theater-masks'></i>}
          />
          <InputDescription
            className='col'
            name={'descriptionImage_' + currentLng}
            label={'Image Description'}
            placeholder={'Enter short description'}
            icon={<i className='fas fa-edit'></i>}
          />
        </Modal>
      </form>
    </FormProvider>
  );
};

export default EditGalleryForm;
