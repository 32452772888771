export const fetchOrganizations = async (
  welletContext,
  includeInactive = true,
  url = '/api/Organization/All',
) => {
  const body = {
    Limit: null,
    Skip: null,
    IncludeInactive: includeInactive,
  };
  const newUrl = `${url}`;
  const response = await welletContext.apis.admin.post(newUrl, body);
  return response;
};
export const fetchActivitiesType = async (
  welletContext,
  url = '/api/Organization/activitiesType',
) => {
  const response = await welletContext.apis.admin.get(url);
  return response;
};
export const postOrganization = async (welletContext, body, url = '/api/Organization ') => {
  const response = await welletContext.apis.admin.post(url, body);
  return response;
};
export const putOrganization = async (welletContext, body, url = '/api/Organization ') => {
  const response = await welletContext.apis.admin.put(url, body);
  return response;
};
export const getOrganizationById = async (welletContext, orgId, url = '/api/Organization') => {
  const newUrl = `${url}/${orgId}`;
  const response = await welletContext.apis.admin.get(newUrl);
  return response;
};
export const fetchTaxTypes = async (
  welletContext,
  countryId,
  url = '/api/Organization/taxtype',
) => {
  const newUrl = `${url}/${countryId}`;
  const response = welletContext.apis.admin.get(newUrl);
  return response;
};
export const fetchOwners = async (welletContext, body, url = '/api/Company/owners') => {
  const response = welletContext.apis.manager.get(url, body);
  return response;
};
export const fetchBusinessType = async (
  welletContext,
  body,
  url = '/api/Organization/businesstype',
) => {
  const response = welletContext.apis.admin.get(url, body);
  return response;
};
export const getSocialNetworks = async (welletContext) => {
  return await welletContext.apis.admin.get(`/api/Organization/socialnetworks`);
};
export const getContactInformation = async (welletContext) => {
  return await welletContext.apis.admin.get(`/api/Organization/contact`);
};
export const fetchTenants = async (welletContext) => {
  return await welletContext.apis.admin.get(`/api/Organization/tenants`);
};
