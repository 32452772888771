import React, { useContext, useState } from 'react';
import { Pagination } from '../../components';
import {
  Badge,
  Loading,
  Money,
  Subtitle,
  Modal,
  Card,
  Tooltip,
  BreadCrumbs,
  CardRowInfo,
  CardRowDetails,
} from '../../uiComponents/common';
import './paymentDetail.css';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { WelletContext } from '../../context/wellet/welletContext';
import { fetchPaymentById, refundOrder } from '../../services/paymentServices';
import usePagination from '../../hooks/usePagination';
import { getStatusMessage } from '../../helpers/format';
import { useRedirectIfSuperAdmin } from '../../hooks/useRedirectIfSuperAdmin';

const PaymentDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const welletContext = useContext(WelletContext);
  const [open, setOpen] = useState(false);
  const paymentQuery = useQuery(['payment', id], () => fetchPaymentById(welletContext, id), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const refundQuery = useQuery(
    ['refund', id],
    () => refundOrder(welletContext, id, payment.find((p) => p.type === 'CompanyId').value),
    {
      enabled: false,
      onSuccess: () => paymentQuery.refetch(),
    },
  );
  const { currentPage, goToPage, totalPages } = usePagination(
    paymentQuery?.data?.activities?.length ?? 1,
    1,
  );

  useRedirectIfSuperAdmin(!paymentQuery.isFetching, '/');

  if (paymentQuery.isLoading || refundQuery.isLoading) {
    return <Loading />;
  }

  const currentPayment = paymentQuery.data.activities[currentPage - 1].tags;
  const payment = paymentQuery.data.activities[0].tags;
  const chargeId = currentPayment.find((c) => c.type === 'ChargeId').value;
  const purchaseDate = new Date(payment.find((c) => c.type === 'PurchaseDate').value)
  const paymentDate = new Date(payment.find((c) => c.type === 'PaymentDateUTC').value)
  const lastUpdatedDate = format(
    (purchaseDate > paymentDate) ? purchaseDate : paymentDate,
    'MMM dd, yyyy, h:mm a',
  );
  const transactionDate = format(
    new Date(payment.find((c) => c.type === 'PurchaseDate').value,
    ),
    'MMM dd, yyyy, h:mm a',
  );

  const orderStatus = payment.find((c) => c.type === 'Status').value;

  // Payment details
  const price = payment.find((c) => c.type === 'Price').value;
  const gatewayFee = payment.find((c) => c.type === 'GatewayFee').value;
  const netAmount = payment.find((c) => c.type === 'NetReceivedAmount').value;
  const paymentStatus = currentPayment.find((c) => c.type === 'TransactionStatus')?.value;
  const cardHolder = currentPayment.find((c) => c.type === 'Cardholder')?.value;

  // Payment Method
  const bank = currentPayment.find((c) => c.type === 'Bank').value;
  const cardDigits = currentPayment.find((c) => c.type === 'CardLast4Digits').value;
  const paymentType = currentPayment.find((c) => c.type === 'PaymentType').value;
  const country = currentPayment.find((c) => c.type === 'Country').value;

  const bookingCode = payment.find((c) => c.type === 'BookingCode').value;
  const currencyCode = payment.find((c) => c.type === 'Currency').value;

  const handleRefund = () => {
    refundQuery.refetch();
    setOpen(!open);
  };

  const isFromOrders = location.pathname.split('/')[1] === 'orders';

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { name: isFromOrders ? 'Orders' : 'Payments', path: isFromOrders ? '/orders' : '/' },
          { name:  bookingCode },
        ]}
      />
      <Subtitle
        text={
          <div className='mt-1 payment-title d-flex align-items-center'>
            <Money value={price} currencyCode={currencyCode} />
            <Tooltip text={getStatusMessage(orderStatus)} marginT='-10px'>
              <Badge status={orderStatus} />
            </Tooltip>
          </div>
        }
        endComponent={
          orderStatus === 'APPROVED' ? (
            <button className='mt-1 btn-custom-white' onClick={() => setOpen(true)}>
              Refund
            </button>
          ) : null
        }
      />
      <div className='row mt-1 '>
        <div className='col-12 col-md-auto right-divider text-capitalize mb-1 '>
          <CardRowInfo title={'Last update'} value={lastUpdatedDate} />
        </div>
        <div className='col-12 col-md-auto right-divider text-capitalize mb-1 '>
          <CardRowInfo title={'Purchase Date'} value={transactionDate} />
        </div>
        <div className='col-12 col-md-auto right-divider mb-1 '>
          <CardRowInfo
            title={'Customer'}
            value={payment.find((c) => c.type === 'CustomerEmail').value}
          />
        </div>
        <div className='col-12 col-md-auto'>
          <CardRowInfo
            title={'Payment Method'}
            value={
              <div className='row'>
                <div className='col pr-0'>
                  {payment?.find((c) => c.type === 'PaymentType').value !== 'Others' ? (
                    <span className='card-brand'>
                      <img
                        style={{ height: '30px' }}
                        src={
                          'https://cdn1.wellet.fun/images/credit-card/' +
                          payment.find((c) => c.type === 'PaymentType').value.toLocaleLowerCase() +
                          '-logo.png'
                        }
                        className='mr-1'
                        alt='card'
                      />
                      •••• {payment.find((c) => c.type === 'CardLast4Digits').value}
                    </span>
                  ) : (
                    'NONE'
                  )}
                </div>
              </div>
            }
          />
        </div>
      </div>
      <Subtitle text={<h3>Payment details</h3>} />
      <CardRowDetails title={'Amount'} value={<Money value={price} />} />
      {chargeId ? (
        <>
          <CardRowDetails title={'Fee'} value={<Money value={gatewayFee} />} />
          <CardRowDetails title={'Net'} value={<Money value={netAmount} />} />
          <CardRowDetails title={'Status'} value={paymentStatus} />
          {orderStatus === 'ERROR' ? (
            <CardRowDetails
              title={'Description'}
              value={payment.find((c) => c.type === 'ErrorDescription').value}
            />
          ) : (
            <></>
          )}
          <Subtitle text={<h3>Payment method</h3>} />
          <CardRowDetails title={'ID'} value={chargeId} />
          <CardRowDetails title={'Card Holder'} value={cardHolder} />
          <CardRowDetails title={'Number'} value={`•••• ${cardDigits}`} />
          <CardRowDetails title={'Type'} value={paymentType} />
          <CardRowDetails title={'Issuer'} value={bank} />
          <CardRowDetails title={'Origin'} value={country} />
        </>
      ) : (
        <>
          <CardRowDetails title={'Status'} value={orderStatus} />
        </>
      )}
      {orderStatus !== 'PENDING' && (chargeId || totalPages > 1) ? (
        <div className='mt-2 payment-pagination py-1'>
          <Pagination
            text='Payment'
            className='d-flex align-items-center'
            currentPage={currentPage}
            onPageChange={goToPage}
            totalPages={totalPages}
          />
        </div>
      ) : null}
      <Subtitle text={<h3>Metadata</h3>} />
      <CardRowDetails title={'Reference Code'} value={bookingCode} />
      <Modal
        idModal={'refundModal'}
        title={'Refund Payment'}
        open={open}
        handleSubmit={handleRefund}
        onClose={() => setOpen(false)}
        textCancel={'Cancel'}
        textSubmit={'Refund'}
      >
        <div className='modal-background font-weight-bold'>
          Refunds take 5-10 days to appear on a customer's statement.
        </div>
        <div className='modal-background'>
          <div className='d-flex justify-content-center'>
            <span className='mr-2'>Refund</span>
            <Card>
              <div className='d-flex justify-content-between px-1'>
                <Money value={price} />
                <span className='ml-3'>MXN</span>
              </div>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentDetail;
