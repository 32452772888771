import { enUS, es, pt, ru, de, zhCN, fr, hi } from 'date-fns/locale';

export const getLocale = (language) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'es':
      return es;
    case 'pt':
      return pt;
    case 'ru':
      return ru;
    case 'de':
      return de;
    case 'cn':
      return zhCN;
    case 'fr':
      return fr;
    case 'hi':
      return hi;
    default:
      return enUS;
  }
};

export const alignTimezone = (date, reverse = false) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  let offset = date.getTimezoneOffset() * 60 * 1000;
  if (reverse) {
    offset *= -1;
  }
  return new Date(date.valueOf() + offset);
};

export const getWeekdays = (days) => {
  switch (days) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    default:
      return '';
  }
};

export const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};
