import { MenuHeader, MenuItem } from '../../common/menuHeader/menuHeader';

import { SidebarData, HistoryData, SetupData } from '../../../components/sideBar/sidebarData';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import useUser from '../../../hooks/useUser';
import { setSelectedOrg } from '../../../actions';
import SidebarContext from '../../../context/sidebarContext';

const SidebarSection = () => {
  const user = useSelector((state) => state.user);
  const { isSuperAdmin } = useUser();
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const location = useLocation();
  const dispacth = useDispatch();
  const { isMouseOver, isSidebarResized } = useContext(SidebarContext);
  const statusMenu = isMouseOver || !isSidebarResized;
  const isOrganizationValid = (organizationId) =>
    organizationId !== 83 && organizationId !== 371 && organizationId !== 0;
  const renderMenuItems = (menuItems) => {
    return menuItems.map((item, index) => {
      const isRoleValid = user.roles.some((r) => item.rol.includes(r));
      const isOrgValid = item.organization ? isOrganizationValid(organization.id) : true;

      if (isRoleValid && isOrgValid) {
        return (
          <MenuItem
            item={item}
            key={index}
            isCollapsable={false}
            title={item.title}
            path={item.path}
            disabled={item.disabled}
            isSelected={item.path === location.pathname || location.pathname.includes(item.subpath)}
            icon={item.icon}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (
      isSuperAdmin &&
      selectedOrganization &&
      selectedOrganization?.id !== organization.id &&
      location.pathname === '/'
    ) {
      dispacth(setSelectedOrg(organization));
    }
  }, [location.pathname]);

  return (
    <div className='sidebar-section sidebar-data-section'>
      <div className='nav nav-sidebar '>
        {statusMenu && <MenuHeader title={'Live Feeds'} />}

        {SidebarData.map((item, index) => {
          if (
            user.roles.some((r) => item.rol.includes(r)) && item.organization
              ? organization.id !== 1
              : true
          ) {
            return (
              <MenuItem
                item={item}
                key={index}
                isCollapsable={false}
                title={item.title}
                path={item.path}
                disabled={item.disabled}
                isSelected={
                  item.path === location.pathname || location.pathname.includes(item.subpath)
                }
                icon={item.icon}
              />
            );
          } else return null;
        })}
        {statusMenu && <MenuHeader title={'Operations History'} />}
        {HistoryData.map((item, index) => {
          return (
            <MenuItem
              item={item}
              key={index}
              isCollapsable={false}
              title={item.title}
              path={item.path}
              disabled={item.disabled}
              isSelected={
                item.path === location.pathname || location.pathname.includes(item.subpath)
              }
              icon={item.icon}
            />
          );
        })}

        {statusMenu && renderMenuItems(SetupData).filter(Boolean).length > 0 && (
          <MenuHeader title={'Settings & Admin'} />
        )}
        {renderMenuItems(SetupData)}
      </div>
    </div>
  );
};

export default SidebarSection;
