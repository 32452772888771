import React from 'react';
import './filterCard.css';

const FilterCard = ({ category, quantity, onClick, isSelected }) => {
  const cardClasses = `m-1 w-100  filter-card ${isSelected ? 'filter-card-selected' : ''}`;

  return (
    <div className={cardClasses} onClick={onClick}>
      <div className=''>
        <div className='d-flex'>
          <h3
            className={`ctm-text ${isSelected && 'font-weight-bold'} text-${
              isSelected ? 'primary' : 'general'
            } mb-0`}
          >
            {category}
          </h3>
        </div>

        <div>
          <p
            className={`ctm-text-medium font-weight-bold text-${
              isSelected ? 'primary' : 'general'
            } mb-0`}
          >
            {quantity ? quantity : 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FilterCard;
