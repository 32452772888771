import React from 'react';

export default function StateIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 17 15.87'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g id='Capa_1-2' data-name='Capa 1'>
        <g>
          <path
            fill={color}
            d='m0,5.9l.06-.16c.64-.32,1.27-.64,1.92-.94.07-.03,1.27.61,1.34.55,2.2-2.03,2.44-2.27,2.89-2,.49.34.97.65,1.43.99.09.07.15.19.21.3,1.1,2.34,2.82,4.63,3.2,4.63.38,0,.97-1.48,1.42-1.48s.52,1.25.75,1.37c.69.36.88.35,1.27.95.2.3.4.44.74.29.7-.3,1.29-.22,1.73.42l.04.36c-.21.54-.43,1.07-.61,1.62-.08.24-.28.43-.53.5-1.01.27-2.01.6-3.03.83-.72.16-1,.6-1.56,1.15-.48.6-1.74.6-2.32.6-1.66,0-1.54-1.29-3.56-1.29-.79,0-.64-1.39-1.19-2.17-.23-.32-.31-.71-.29-1.1.02-.32-.11-.59-.29-.85-.46-.7-.94-1.4-1.35-2.13-.3-.55-.68-.89-1.32-.9-.25,0-.37-.15-.46-.38-.15-.39-.34-.76-.5-1.15Z'
          />
          <path
            fill={color}
            d='m11.04,0c-1.46,0-2.64,1.18-2.64,2.64,0,1.77,1.88,4.23,2.47,4.95.04.05.11.08.17.08s.13-.03.17-.08c.58-.72,2.47-3.18,2.47-4.95,0-1.46-1.18-2.64-2.64-2.64Zm.26,4.05c-.95.17-1.76-.64-1.59-1.59.09-.53.52-.97,1.05-1.08.97-.2,1.82.64,1.62,1.62-.11.53-.55.96-1.08,1.05Z'
          />
        </g>
      </g>
    </svg>
  );
}
