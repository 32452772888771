export const fetchCheckIns = async (
  welletContext,
  id,
  dates = {
    from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString(),
    to: null,
  },
  status = null,
  limit = null,
  skip = null,
  url = 'api/checkIn/feed',
) => {
  const body = {
    from: dates.from,
    to: dates.to,
    organizationId: id,
    status,
    limit,
    skip
  };

  const response = await welletContext.apis.admin.post(url, body);
  return response;
};

export const fetchChecksInfo = async (
  welletContext,
  id,
  dates = {
    from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString(),
    to: null,
  },
  status = null,
  url = '/api/CheckIn/info',
) => {
  const body = {
    from: dates.from,
    to: dates.to,
    organizationId: id,
    status,
  };
  const response = await welletContext.apis.admin.post(url, body);
  return response;
};
