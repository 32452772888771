import React from 'react';

export default function VoucherIcons({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M897 4579 c-54 -13 -93 -35 -144 -82 -59 -54 -67 -73 -154 -334 l-73
   -222 -36 -6 c-102 -19 -200 -97 -246 -198 l-29 -62 -3 -251 c-4 -307 -4 -309
   91 -354 303 -143 303 -673 0 -817 -96 -46 -94 -35 -91 -458 l3 -360 29 -62
   c33 -73 103 -144 174 -176 47 -22 49 -22 942 -27 l895 -5 898 -308 c494 -169
   920 -312 948 -318 84 -18 187 12 258 77 61 55 81 96 160 335 l72 216 60 17
   c108 30 179 91 228 196 l26 55 3 360 c3 423 6 411 -93 460 -144 71 -225 220
   -225 410 0 184 87 339 227 405 95 45 95 47 91 354 l-3 251 -29 63 c-37 79
   -114 152 -191 181 -55 21 -70 21 -925 21 l-868 0 -344 119 c-1627 566 -1555
   543 -1651 520z m716 -421 l612 -213 -734 -3 c-404 -1 -736 -1 -737 1 -2 2 24
   85 57 186 70 209 90 241 151 241 26 0 252 -74 651 -212z m-443 -483 c0 -31 6
   -71 14 -90 15 -35 59 -65 96 -65 37 0 81 30 96 65 8 19 14 59 14 90 l0 55
   1616 -2 1616 -3 34 -37 34 -38 0 -208 0 -207 -35 -19 c-49 -27 -144 -125 -183
   -189 -101 -167 -126 -406 -62 -591 45 -133 134 -255 230 -317 l50 -32 0 -312
   c0 -305 0 -313 -22 -340 -46 -58 51 -55 -1683 -55 l-1595 0 0 60 c0 81 -20
   125 -64 147 -28 13 -44 14 -72 7 -56 -14 -78 -51 -82 -140 l-4 -74 -314 0
   c-342 0 -361 3 -402 55 -22 27 -22 35 -22 339 l1 311 50 34 c109 75 207 222
   246 371 22 86 22 252 -1 345 -26 108 -81 210 -158 291 -37 39 -83 79 -103 89
   l-35 20 0 207 0 206 27 32 c14 18 36 36 47 40 12 5 166 9 344 9 l322 1 0 -55z
   m3190 -2511 c0 -3 -26 -85 -57 -182 -63 -198 -85 -232 -146 -232 -24 0 -1093
   356 -1237 413 -8 3 312 6 713 6 400 1 727 -2 727 -5z'
        />
        <path
          d='M2397 3400 c-125 -32 -219 -128 -253 -259 -39 -152 37 -313 183 -387
   52 -26 68 -29 153 -29 89 0 99 2 158 34 70 38 120 90 154 160 19 39 23 62 23
   146 0 89 -3 106 -27 150 -35 67 -97 128 -162 160 -60 29 -166 41 -229 25z
   m155 -228 c15 -9 34 -30 43 -48 64 -134 -96 -254 -207 -155 -56 50 -48 169 14
   202 57 30 98 30 150 1z'
        />
        <path
          d='M1221 3344 c-40 -28 -51 -73 -51 -204 0 -168 26 -220 110 -220 84 0
   110 52 110 220 0 168 -26 220 -110 220 -20 0 -46 -7 -59 -16z'
        />
        <path
          d='M2919 2634 c-578 -578 -569 -567 -535 -633 28 -54 91 -75 144 -47 33
   18 1045 1032 1061 1063 33 65 -11 140 -87 150 l-44 6 -539 -539z'
        />
        <path
          d='M1221 2764 c-40 -28 -51 -73 -51 -204 0 -168 26 -220 110 -220 84 0
   110 52 110 220 0 168 -26 220 -110 220 -20 0 -46 -7 -59 -16z'
        />
        <path
          d='M3400 2381 c-96 -31 -177 -102 -219 -190 -22 -48 -26 -69 -26 -146 0
   -74 5 -99 24 -137 37 -75 95 -134 169 -170 59 -29 76 -33 147 -33 65 1 91 6
   136 27 79 36 133 89 171 166 29 59 33 76 33 152 0 73 -4 93 -28 142 -34 69
   -94 131 -162 166 -40 20 -67 26 -135 28 -47 2 -96 0 -110 -5z m156 -217 c44
   -21 79 -90 70 -140 -14 -73 -88 -120 -164 -103 -41 9 -71 34 -88 76 -19 43
   -18 72 4 112 34 63 110 87 178 55z'
        />
        <path
          d='M1217 2179 c-39 -30 -49 -78 -45 -221 3 -110 6 -130 24 -155 26 -34
   73 -49 115 -37 61 17 74 50 77 192 2 74 -1 142 -7 164 -15 48 -53 78 -101 78
   -22 0 -48 -8 -63 -21z'
        />
      </g>
    </svg>
  );
}
