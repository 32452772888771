import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function LoginRoute() {
  const user = useSelector((state) => state.user);
  const authenticated = user.isAuthenticated;
  const isSuperAdmin = authenticated && user.user && user.roles.length > 0;

  if (isSuperAdmin) {
    return <Navigate to={{ pathname: '/' }} replace />;
  }
  return <Outlet />;
}
