import React, { useContext, useState } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getAllGates } from '../../services';
import {
  Loading,
  Subtitle,
  BreadCrumbs,
  IconButton,
  Badge,
  Slider,
  Button,
} from '../../uiComponents/common';
import { GatesForm, Table, TableCell, TableRow } from '../../components';
import { useSelector } from 'react-redux';
import useUser from '../../hooks/useUser';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';

const Gates = () => {
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const { isSuperAdmin } = useUser();
  const [showInactives, setShowInactives] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentGate, setCurrentGate] = useState();

  const gatesQuery = useQuery({
    queryKey: ['gates', selectedOrganization.id, showInactives],
    queryFn: () => getAllGates(welletContext, showInactives, selectedOrganization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!selectedOrganization.id,
  });

  const handleOpenEdit = (gate) => {
    setCurrentGate(gate);
    setOpen(true);
  };

  return (
    <>
      {' '}
      {isSuperAdmin && organization.id === 83 ? (
        <BreadCrumbs
          crumbs={[
            {
              name: 'Gates',
              path: '/gates',
            },
          ]}
        />
      ) : (
        <></>
      )}
      <Subtitle
        text={<div className='payment-title text-truncate'>Gates</div>}
        endComponent={
          <Button
            className={'btn-custom-white'}
            onClick={() => setOpen(true)}
            size={'small'}
            text={'New Gate'}
          />
        }
      />
      {gatesQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Slider
            className={'my-2 ml-1'}
            label={'Only Actives'}
            onClick={() => setShowInactives(!showInactives)}
            defaultChecked={!showInactives}
          />
          <Table
            tableKey={`gates-${selectedOrganization.id}-${gatesQuery?.data?.data?.length}-${showInactives}`}
            data={gatesQuery?.data?.data ?? []}
            totalP={gatesQuery?.data?.data?.length}
            columns={
              selectedOrganization.id === 83
                ? ['', 'Organization', 'Name', 'Activity Name', 'Status']
                : ['', 'Name', 'Activity Name', 'Status']
            }
            renderRow={(gates) => (
              <TableRow key={gates.id}>
                <TableCell style={{ width: '60px' }} type={'primary'}>
                  <IconButton
                    onClick={() => handleOpenEdit(gates)}
                    icon={<FwEditIcon />}
                  />
                </TableCell>
                {selectedOrganization.id === 83 ? (
                  <TableCell style={{ width: '150px' }} type={'primary'}>
                    {gates.organizationName}
                  </TableCell>
                ) : null}
                <TableCell style={{ width: '150px' }}>{gates.name}</TableCell>
                <TableCell style={{ width: '150px' }}>{gates.showName}</TableCell>
                <TableCell>
                  <Badge status={gates.isActive ? 'Active' : 'Inactive'} />
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
      <GatesForm
        open={open}
        onClose={() => setOpen(false)}
        gate={currentGate}
        onSuccess={gatesQuery.refetch}
        setGate={setCurrentGate}
      />
    </>
  );
};

export default Gates;
