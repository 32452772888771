import React from 'react';

export default function DoubleArrows({ size = '16px', color = '#fff' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M818 4468 c-68 -73 -265 -286 -438 -473 -172 -187 -328 -356 -347
-375 l-33 -36 471 -509 c260 -281 476 -509 481 -507 5 1 92 75 194 163 l186
162 -44 46 c-222 233 -358 379 -358 385 0 3 713 6 1585 6 l1585 0 0 255 0 255
-1587 2 -1588 3 174 185 c96 102 188 199 203 216 l29 31 -187 161 c-103 89
-190 162 -194 162 -5 0 -64 -60 -132 -132z'
        />
        <path
          d='M3981 2394 l-192 -167 43 -46 c222 -233 358 -379 358 -385 0 -3 -713
-6 -1585 -6 l-1585 0 0 -255 0 -255 1587 -2 1587 -3 -202 -215 c-191 -203
-201 -215 -185 -231 79 -75 359 -309 367 -306 6 2 221 230 478 508 l467 504
-23 25 c-12 14 -220 239 -461 500 -241 261 -443 481 -450 488 -9 10 -51 -21
-204 -154z'
        />
      </g>
    </svg>
  );
}
