import React from 'react';

const CardRowInfo = ({ title, value }) => {
  return (
    <React.Fragment key={value + title}>
      <div className='info-description text-light'>{title}</div>
      <div className='info-value text-general'>{value}</div>
    </React.Fragment>
  );
};

export default CardRowInfo;
