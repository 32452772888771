import React, { useContext, useRef, useState } from 'react';
import './ticketForm.css';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from 'react-query';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  IconButton,
  InputDate,
  InputDescription,
  InputMedia,
  InputNumber,
  InputText,
  ModernSwitcher,
  Select,
  Subtitle,
  Switcher,
  Tabs,
  TextAlert,
  TextEditor,
  Toast,
} from '../../../uiComponents/common';
import {
  DoorsIcons,
  FwClockIcon,
  FwListIcon,
  FwMasksIcon,
  FwPersonIcon,
  FwPlusIcon,
  FwPriceIcon,
  FwTrashIcon,
  NameIcon,
} from '../../../uiComponents/icons';
import { fetchActivities } from '../../../services/activityServices';
import {
  getAgeRange,
  getProductCategories,
  getProductGroups,
  getTiersById,
  postTicket,
} from '../../../services/ticketServices';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import FwEditIcon from '../../../uiComponents/icons/font-awesome/fwEditOutline';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import UsersIcons from '../../../uiComponents/icons/usersIcon';
import Table from '../../table/table';
import TableRow from '../../table/tableRow';
import TableCell from '../../table/tableCell';
import { validateNegatives } from '../../../helpers/validations';
import { getGateByActivityId } from '../../../services';

const TicketForm = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const { control } = methods;
  const lastClickedButton = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [counter, setCounter] = useState(0);

  const [isRedirect, setIsRedirect] = useState(null);
  const validateFields = {
    name: {
      rule: (values) => {
        if (!values.name && !values.description) {
          return true;
        } else if (values.name && values.name.trim() !== '') {
          if (values.name.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Product Name" field is required and must have a minimum of 3 characters.',
    },
    description: {
      rule: (values) => {
        if (!values.name && !values.description) {
          return true;
        } else if (values.description && values.description.trim() !== '') {
          if (values.description.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Description" field is required and must have a minimum of 3 characters.',
    },
  };

  const { languages, lngErrors, currentLng, changeLanguage } = useLanguageSwitcher(
    validateFields,
    methods,
  );

  const inputMinutesBefores = useWatch({
    control,
    name: 'minutesBefore',
  });
  const inputMinutesAfters = useWatch({
    control,
    name: 'minutesAfter',
  });

  const activityValue = methods.watch('activity')?.id;

  //Functions Api Query
  const activityQuery = useQuery({
    queryKey: ['activities', organization.id],
    queryFn: () => fetchActivities(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const ageRangeQuery = useQuery({
    queryKey: ['ageRange'],
    queryFn: () => getAgeRange(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const productCategoriesQuery = useQuery({
    queryKey: ['productCategories'],
    queryFn: () => getProductCategories(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const productGroupsQuery = useQuery({
    queryKey: ['productGroups'],
    queryFn: () => getProductGroups(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const tiersQuery = useQuery({
    queryKey: ['tiers', organization.id],
    queryFn: () => getTiersById(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const gatesQuery = useQuery({
    queryKey: ['gates', activityValue],
    queryFn: () => getGateByActivityId(welletContext, activityValue),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: activityValue !== undefined,
  });
  const postTicketMutation = useMutation({
    mutationFn: (body) => {
      return postTicket(welletContext, body);
    },
    onSuccess: (data) => {
      setShowToast(true);
    },
    onError: (error) => {
      console.log('Desde on error', error);
    },
  });

  // Formated Data Query
  const activityFormated = activityQuery?.data?.map((obj) => ({
    label: obj.name,
    value: obj.name,
    id: obj.id,
  }));
  const ageRangeFormated = ageRangeQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj,
    id: obj.id,
  }));
  const productCategoriesFormated = productCategoriesQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.name,
    id: obj.id,
  }));
  const productGroupsFormated = productGroupsQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.name,
  }));
  const tiersQueryFormated = tiersQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.id,
    level: obj.level,
  }));
  const gatesQueryFormated = gatesQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.id,
  }));

  const handleGenerateTable = () => {
    methods.clearErrors('priceComposed');
    const stages = methods.watch('stages');

    const newData = Array.from({ length: stages }, (_, index) => ({
      id: counter + index,
    }));

    newData.forEach((row, i) => {
      methods.setValue(
        `nameTable_${row.id}`,
        `${methods.getValues(`name_${currentLng}`)} Release ${i + 1}`,
      );
    });

    setCounter(counter + Number(stages));
    setTableData(newData);
  };

  const handleAddRow = () => {
    methods.clearErrors('priceComposed');
    const currentStages = methods.watch('stages');

    if (currentStages > 0) {
      const newRows = Array.from({ length: 1 }, (_, index) => ({
        id: counter + index,
      }));

      newRows.forEach((row) => {
        methods.setValue(`nameTable_${row.id}`, methods.getValues(`name_${currentLng}`));
      });

      setTableData((prevTableData) => [...prevTableData, ...newRows]);
      setCounter(counter + 1);
    }
  };

  const handleDeleteRow = (id) => {
    const updatedData = tableData.filter((row) => row.id !== id);
    setTableData(updatedData);

    const inputNames = [
      `nameTable_${id}`,
      `price_${id}`,
      `extra_${id}`,
      `stock_${id}`,
      `date_${id}`,
      `status_${id}`,
    ];
    inputNames.forEach((name) => {
      methods.unregister(name);
    });
  };

  const onSubmit = (data) => {
    let hasError = false;
    tableData.forEach((rowData) => {
      const rowId = rowData.id;
      if (data[`price_${rowId}`] === '') {
        methods.setError('priceComposed', {
          message: 'You must complete the table of prices',
        });
        hasError = true;
        return;
      }
    });
    if (tableData.length === 0 && data?.price?.value !== 'simple') {
      methods.setError('priceComposed', { message: 'You must complete the prices data section.' });
      return;
    }
    const initialLangProduct = languages.map((lng) => {
      return {
        language: lng.shortForm,
        name: data[`name_${lng.shortForm}`],
        description: data[`description_${lng.shortForm}`],
        features: data[`features_${lng.shortForm}`],
      };
    });
    const handelLangProductsEmptys = removeObjectsWithEmptyFields(initialLangProduct, [
      'name',
      'description',
      'features',
    ]);

    if (Object.keys(lngErrors).length === 0) {
      const groupBody = data.group.map((g) => ({ productId: 0, groupName: g.value }));

      const body = {
        organizationId: organization.id,
        id: 0,
        name: handelLangProductsEmptys.find((l) => !!l.name).name,
        nameT: handelLangProductsEmptys.find((l) => !!l.name).name,
        nameId: data.nameId,
        image: data?.logo?.base64,
        isActive: true,
        showId: data.activity.id,
        categoryId: data.category.id,
        defaultPaxCapacity: parseInt(data.defaultPax) || 0,
        maxPaxCapacity: 0,
        minQuantityPerTransaction: data.minQty ? parseInt(data.minQty) : 0,
        maxQuantityPerTransaction: data.maxQty ? parseInt(data.maxQty) : 0,
        seatDistributionImgUrl: data?.locationImage?.base64,
        isNumberedSeat: data.seatedLocation ? data.seatedLocation : false,
        isExclusiveProduct: data.productSpecialEvent ? data.productSpecialEvent : false,
        analyticsType: data.nameId,
        sellUntilMinutesAfterShowTime: data.minutesAfter ? parseInt(data.minutesAfter) : 0,
        sellUntilMinutesBeforeShowTime: data.minutesBefore ? parseInt(data.minutesBefore) : 0,
        additionalGuest: parseInt(data.additionalGuests) || 0,
        ageRangeId: data.ageRange?.value?.id,
        tags: data.tags,
        stock: 0,
        productGroups: groupBody,
        langProducts: handelLangProductsEmptys,
        productUserTiers: data.tiers ? data?.tiers?.map((t) => t.value) : null,
        gateProduct: data.gates ? data?.gates?.map((t) => t.value) : null,
      };

      if (data.price.value === 'complex') {
        const priceComposedArray = [];

        tableData.forEach((rowData) => {
          const rowId = rowData.id;

          const row = {
            name: data[`nameTable_${rowId}`],
            price: data[`price_${rowId}`],
            stock: data[`stock_${rowId}`] || null,
            dateUntilIsValid: data[`date_${rowId}`] || null,
            priceExtraPax: data[`extra_${rowId}`] || 0,
            isActive: data[`status_${rowId}`] || false,
          };
          priceComposedArray.push(row);
        });

        body.stock = priceComposedArray[0].stock;
        body.price = priceComposedArray[0].price;
        body.dateUntilIsValid = priceComposedArray[0].dateUntilIsValid;
        body.priceComposed = priceComposedArray;
        body.priceExtraPax = priceComposedArray[0].priceExtraPax;
      } else {
        body.stock = data[`stock_simple`] || null;
        body.price = data[`price_simple`];
        body.dateUntilIsValid = data[`date_simple`] || null;
        body.priceExtraPax = data[`extra_simple`] || 0;
        body.priceComposed = [];
      }
      if (!hasError) {
        postTicketMutation.mutate(body);
      }
    }
  };

  const handleToastCallback = () => {
    if (isRedirect) {
      methods.reset();
      navigate(`/tickets`);
      setShowToast(false);
    } else {
      setShowToast(false);
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };
  const clearForm = () => {
    navigate(`/tickets`);
    methods.reset();
  };

  const isSimple = methods.watch('price')?.value === 'simple';

  return (
    <>
      {showToast && (
        <Toast
          message='Ticket created successfully.'
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      <FormProvider {...methods}>
        <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
          <Subtitle text={<h3 className='mt-3'>Main Data</h3>} />
          <div className='row mt-2'>
            <Select
              options={activityFormated}
              label={'Activity'}
              className={'col-12 col-sm-6 col-xl-4'}
              name={'activity'}
              isRequired={true}
              icon={<FwMasksIcon />}
              onManualChange={() => methods.setValue('gates', null)}
            />
            <InputText
              name={'nameId'}
              label={'Name Id'}
              className={'col-12 col-sm-6 col-xl-4'}
              placeholder={'Enter Name Id'}
              isRequired={true}
            />
          </div>
          <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
          {Object.keys(lngErrors).length !== 0 && (
            <TextAlert text={lngErrors.name} type={'danger'} />
          )}
          <div className='row mt-3'>
            <InputText
              name={'name_' + currentLng}
              label={'Name'}
              icon={<NameIcon />}
              className={'col-12 col-sm-6 col-xl-4'}
              placeholder={'Enter a product name'}
              isRequired={true}
            />
            <InputDescription
              name={'description_' + currentLng}
              label={'Description'}
              className={'col-12 col-sm-6 col-xl-4'}
              placeholder={'Description'}
              icon={<FwEditIcon />}
            />
          </div>
          <div className='row mt-3'>
            <TextEditor
              label={'Features'}
              className='col-12 col-xl-8'
              name={`features_${currentLng}`}
              customErrors={lngErrors}
            />{' '}
          </div>

          <Subtitle text={<h3>General Data</h3>} />
          <div className='row mt-3'>
            <Select
              options={productCategoriesFormated}
              label={'Category'}
              className={'col-12 col-sm-6 col-xl-4'}
              name={'category'}
              isRequired={true}
            />
            <Select
              options={ageRangeFormated}
              label={'Age range'}
              className={'col-12 col-sm-6 col-xl-4'}
              name={'ageRange'}
            />
          </div>
          <div className='row mt-2'>
            <InputText
              name={'tags'}
              label={'Tag'}
              className={'col-12 col-sm-6 col-xl-4'}
              placeholder={'Enter a tag'}
            />

            <Select
              options={productGroupsFormated}
              label={'Group'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<UsersIcons />}
              name={'group'}
              components={{
                Option: InputOption,
              }}
              isMulti={true}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              hideSelectedOptions={false}
              isRequired={true}
            />
          </div>
          <div className='row mt-2S'>
            <Select
              options={tiersQueryFormated}
              label={'Tiers'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<FwListIcon />}
              name={'tiers'}
              components={{
                Option: InputOption,
              }}
              isMulti={true}
              type='tiers'
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              hideSelectedOptions={false}
            />
            <Select
              options={gatesQueryFormated}
              label={'Gates'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<DoorsIcons />}
              name={'gates'}
              components={{
                Option: InputOption,
              }}
              isMulti={true}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              hideSelectedOptions={false}
              isRequired={true}
              isDisabled={!activityValue}
            />
          </div>
          <Subtitle text={<h3>Prices Data</h3>} />
          <div className='row col-12 col-xl-8 my-3'>
            <Select
              options={[
                { label: 'Simple', value: 'simple' },
                { label: 'Complex', value: 'complex' },
              ]}
              label={'Price Mode'}
              className={'col-12 col-xl-6 px-0 pr-2 mb-2'}
              icon={<FwPriceIcon />}
              name={'price'}
              isSearchable={false}
              isRequired={true}
            />
            {methods?.watch('price')?.value === 'complex' ? (
              <>
                {tableData.length > 0 ? (
                  <></>
                ) : (
                  <div className='col-12 col-xl-6 row align-items-end mb-2 px-0'>
                    <InputNumber
                      acceptNegative={false}
                      label={'Stages'}
                      className='col'
                      name={'stages'}
                      placeholder={'Enter Stages'}
                      isRequired={true}
                    />
                    <Button
                      type='button'
                      disabled={
                        methods.watch('stages') <= 0 || !methods?.watch(`name_${currentLng}`)
                      }
                      text={'Submit'}
                      onClick={handleGenerateTable}
                      className={'col-auto'}
                      size={'small'}
                    />
                  </div>
                )}
                {tableData.length === 0 &&
                (!methods?.watch(`name_${currentLng}`) || !methods.watch('activity')?.label) ? (
                  <div className='col-12'>
                    <TextAlert
                      type={'warning'}
                      text={'You must complete name and activity fields.'}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          {tableData.length > 0 || isSimple ? (
            <div className='mt-3 row col-12 col-xl-8 '>
              <Table
                renderRow={(price) => (
                  <TableRow key={price.id}>
                    {isSimple ? (
                      <></>
                    ) : (
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        <InputText
                          className={'mb-0 input-table-width'}
                          placeholder={'Enter ticket stage name'}
                          name={`nameTable_${price.id}`}
                          isRequired={true}
                        />
                      </TableCell>
                    )}
                    <TableCell style={{ verticalAlign: 'baseline' }}>
                      <InputNumber
                        className='input-table-width'
                        placeholder={'Enter price'}
                        name={'price_' + price.id}
                        isRequired={true}
                      />
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'baseline' }}>
                      <InputNumber
                        className='input-table-width'
                        placeholder={'Enter extra price'}
                        customValidation={{
                          validate: (value) => {
                            if (value && value < 0) {
                              return 'Value should not be negative.';
                            }
                          },
                        }}
                        name={'extra_' + price.id}
                        isRequired={false}
                      />
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'baseline' }}>
                      <InputNumber
                        className='input-table-width'
                        placeholder={'Enter stock'}
                        name={'stock_' + price.id}
                        customValidation={{
                          validate: (value) => {
                            if (value < 0) {
                              return 'Value must be positive.';
                            }
                          },
                        }}
                        isRequired={true}
                      />
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'baseline' }}>
                      <InputDate
                        placeholder={'Enter date'}
                        className={'mb-0 input-table-width'}
                        name={'date_' + price.id}
                        fromDate={
                          new Date(
                            activityQuery?.data?.find(
                              (a) => a.id === methods.watch('activity')?.id,
                            )?.startLocalDate,
                          )
                        }
                      />
                    </TableCell>
                    {isSimple ? (
                      <></>
                    ) : (
                      <>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <div style={{ marginTop: '0.5rem' }}>
                            <Switcher name={'status_' + price.id} />
                          </div>
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>
                          <IconButton
                            icon={<FwTrashIcon />}
                            onClick={() => handleDeleteRow(price.id)}
                          />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                )}
                tableKey={'tickets-table-' + tableData.length}
                totalP={isSimple ? 1 : tableData.length}
                data={isSimple ? [{ id: 'simple' }] : tableData}
                renderLastRow={(price) =>
                  !isSimple ? (
                    <TableRow key={price.id + 'add'} onClick={handleAddRow}>
                      <TableCell colSpan={12}>
                        <div className='text-center'>
                          <FwPlusIcon />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )
                }
                columns={
                  isSimple
                    ? ['Price', 'Extra', 'Stock', 'Date']
                    : ['Name', 'Price', 'Extra', 'Stock', 'Date', 'Status', '']
                }
                showPagination={tableData.length > 10}
              />
            </div>
          ) : (
            <></>
          )}

          <Subtitle text={<h3>Media Data</h3>} />

          <div className='row mt-3'>
            <InputMedia
              id={'imageFile'}
              name={'logo'}
              placeholder={'Upload Image'}
              isRequired={false}
              className='col-12 col-sm-6 col-xl-4 my-1'
              label={'Logo Image'}
              maxSize={409600}
              titleModal='Add Logo Image'
            />
            <InputMedia
              id={'locationImageFile'}
              name={'locationImage'}
              placeholder={'Upload Image'}
              label={'Location Image'}
              isRequired={false}
              className='col-12 col-sm-6 col-xl-4 my-1'
              maxSize={409600}
              titleModal='Add Location Image'
            />
          </div>

          <Subtitle text={<h3>Capacity Data</h3>} />
          <div className='row mt-3'>
            <InputNumber
              label={'Default Pax'}
              className={'col-6 col-sm-4 col-xl-2 mb-2'}
              name={'defaultPax'}
              placeholder={'0'}
              icon={<FwPersonIcon />}
              customValidation={{
                validate: validateNegatives,
              }}
            />
            <InputNumber
              label={'Additional Guests'}
              className={'col-6 col-sm-4 col-xl-2 mb-2'}
              name={'additionalGuests'}
              placeholder={'0'}
              icon={<FwPersonIcon />}
              customValidation={{
                validate: validateNegatives,
              }}
            />
          </div>

          <Subtitle text={<h3>Sales enabled</h3>} />
          <div className='row mt-3'>
            <InputNumber
              label={'Before activity time (in minutes)'}
              className={'col-6 col-sm-4 col-xl-2 mb-2'}
              name={'minutesBefore'}
              placeholder={'0'}
              customValidation={{
                validate: validateNegatives,
              }}
              icon={<FwClockIcon />}
              isDisabled={inputMinutesAfters > 0}
            />
            <InputNumber
              label={'After activity time (in minutes)'}
              className={'col-6 col-sm-4 col-xl-2 mb-2'}
              name={'minutesAfter'}
              placeholder={'0'}
              customValidation={{
                validate: validateNegatives,
              }}
              icon={<FwClockIcon />}
              isDisabled={inputMinutesBefores > 0}
            />
          </div>

          <Subtitle text={<h3>Additional Data</h3>} />
          <div className='row mt-3'>
            <InputNumber
              label={'Maximum quantity per transaction'}
              className={'col-6 col-sm-4 col-xl-2 mb-2'}
              name={'maxQty'}
              customValidation={{
                validate: validateNegatives,
              }}
              placeholder={'0'}
            />
            <InputNumber
              label={'Minimum quantity per transaction'}
              className={'col-6 col-sm-4 col-xl-2 mb-2'}
              name={'minQty'}
              customValidation={{
                validate: validateNegatives,
              }}
              placeholder={'0'}
            />
          </div>
          <div className='row mt-2'>
            <div className={'col-12'}>
              <ModernSwitcher
                name={'productSpecialEvent'}
                isRequired={false}
                text={'Product only for a special event'}
              />
            </div>
            <div className={'col-12'}>
              <ModernSwitcher
                name={'seatedLocation'}
                isRequired={false}
                text={'Numbered seated location'}
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-center justify-content-sm-start px-2 py-3'>
            <Button
              size={'small'}
              type='submit'
              text={`${
                postTicketMutation.isLoading && lastClickedButton.current === 'send'
                  ? 'Sending...'
                  : 'Send'
              }`}
              onClick={() => {
                handleRedirect(false, 'send');
              }}
            />
            <Button
              size={'small'}
              type='button'
              className={'btn-custom-white  ml-2'}
              text={`Cancel`}
              onClick={clearForm}
            />
            <Button
              size={'small'}
              className={' ml-2'}
              type='submit'
              text={`${
                postTicketMutation.isLoading && lastClickedButton.current === 'sendAndClose'
                  ? 'Sending...'
                  : 'Send and Close'
              }`}
              onClick={() => {
                handleRedirect(true, 'sendAndClose');
              }}
            />
          </div>
        </form>
      </FormProvider>

      {postTicketMutation?.error &&
        (postTicketMutation?.error?.response?.data?.message === 'INVALID_IMAGE_FORMAT' ? (
          <Alert
            title={'Oops, something went wrong'}
            text={'Error loading images. Only jpg, jpeg, and gif formats are supported.'}
            type={'error'}
          />
        ) : (
          <Alert
            title={'Oops, something went wrong'}
            text={'We are experiencing a general error at the moment.'}
            type={'error'}
          />
        ))}
      {methods.formState.errors && methods?.formState?.errors?.priceComposed ? (
        <Toast
          message={methods?.formState?.errors?.priceComposed.message}
          type={'ERROR'}
          onCallback={() => {
            methods.clearErrors('priceComposed');
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TicketForm;
