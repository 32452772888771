import React, { useContext, useState } from 'react';
import { useRef } from 'react';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { getTimezoneFormatted } from '../../../helpers/format';
import {
  Button,
  InputNumber,
  InputPhone,
  InputText,
  InputEmails,
  ModernSwitcher,
  Select,
  Subtitle,
  Toast,
  ToggleToken,
  Loading,
  TextEditor,
  InputList,
  Tabs,
  InputMedia,
} from '../../../uiComponents/common';
import {
  CityIcon,
  CountryIcon,
  FwAtSignIcon,
  FwClockIcon,
  FwGlobeIcon,
  FwLocationIcon,
  FwOrganizationIcon,
  FwPersonIcon,
  FwPhoneIcon,
  FwPriceIcon,
  FwTenantIcon,
  NameIcon,
  NumberTaskIcon,
  StateIcon,
  TaxIcon,
  TimeZoneIcon,
  WebsiteIcon,
  ZipCodeIcon,
} from '../../../uiComponents/icons';
import {
  fetchTaxTypes,
  getCities,
  getOrganizationById,
  getStates,
  postOrganization,
  putOrganization,
} from '../../../services';
import { useSelector } from 'react-redux';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';

const OrganizationForm = ({
  timezoneQuery,
  ownersQuery,
  organizationCurrentId,
  countriesQuery,
  isEditingOrganization,
  socialNetwork,
  contactInformation,
}) => {
  const navigate = useNavigate();
  const methods = useForm();
  const welletContext = useContext(WelletContext);
  const lastClickedButton = useRef(null);

  const [showToast, setShowToast] = useState(false);
  const user = useSelector((state) => state.user.user.sid);
  const [isRedirect, setIsRedirect] = useState(null);

  // Data Froms Querys

  const countryInput = methods.watch('country')?.value;
  const stateInput = methods.watch('state')?.value;
  const enabelWpp = methods.watch('isWhatsapp');

  // Api call when user is editing organization
  const { languages, currentLng, changeLanguage } = useLanguageSwitcher({}, methods);

  const organizationQuery = useQuery({
    queryKey: ['organization', organizationCurrentId],
    queryFn: () => getOrganizationById(welletContext, organizationCurrentId),
    onSuccess: (data) => {
      methods.reset(formatActivityData(data));
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
    enabled:
      isEditingOrganization && languages.length > 0 && countriesQuery.isSuccess ? true : false,
  });

  // Api calls that depends on others inputs
  const taxTypeQuery = useQuery({
    queryKey: ['timezones', countryInput],
    queryFn: () => fetchTaxTypes(welletContext, countryInput),
    onSuccess: (data) =>
      methods.setValue(
        'taxType',
        data.data.map((tax) => ({ value: tax.shortForm, label: tax.shortForm })),
      ),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: countryInput ? true : false,
  });
  const statesQuery = useQuery({
    queryKey: ['states', countryInput],
    queryFn: () => getStates(welletContext, countryInput),
    onSuccess: () => {
      if (!isEditingOrganization) {
        methods.setValue('state', '');
        methods.setValue('city', '');
      }
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: countryInput ? true : false,
  });
  const citiesQuery = useQuery({
    queryKey: ['cities', stateInput],
    queryFn: () => getCities(welletContext, stateInput),
    onSuccess: () => {
      if (!isEditingOrganization) {
        methods.setValue('city', '');
      }
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: stateInput ? true : false,
  });
  const putOrganizationMutation = useMutation({
    mutationFn: (body) => {
      return putOrganization(welletContext, body);
    },
    onSuccess: () => {
      setShowToast(true);
    },
    onError: (error) => {},
  });
  const postOrganizationMutation = useMutation({
    mutationFn: (body) => {
      return postOrganization(welletContext, body);
    },
    onSuccess: () => {
      setShowToast(true);
    },
    onError: (error) => {},
  });

  // Formatted Data
  const owners = ownersQuery?.data?.data?.map((ow) => ({ label: ow.email, value: ow.id }));
  const timezone = timezoneQuery.data;
  const taxTypes = taxTypeQuery?.data?.data?.map((ty) => ({
    label: ty.shortForm,
    value: ty.shortForm,
  }));
  const socialNetworkFormatted = socialNetwork?.data?.data?.map((ow) => ({
    label: ow,
    value: ow,
  }));
  const contactInformationFormatted = contactInformation?.data?.data?.map((ow) => ({
    label: ow,
    value: ow,
  }));
  const countries = countriesQuery?.data?.data?.map((c) => ({
    label: c.name,
    value: c.id,
    countryCode: c.countryCode,
  }));
  const state = statesQuery?.data?.data?.map((s) => ({ label: s.name, value: s.id }));
  const city = citiesQuery?.data?.data?.map((c) => ({ label: c.name, value: c.id }));

  const queryClient = useQueryClient();
  const editorOptions = ['inline', 'blockType', 'fontSize', 'list', 'textAlign'];
  const socialInitialState = methods.getValues('socialNetworks') ?? [];
  const organizationContact = methods.getValues('organizationContact') ?? [];

  // Functions
  const clearForm = () => {
    if (isEditingOrganization) {
      navigate(`/organizations`);
    } else {
      methods.reset();
      navigate(`/organizations`);
    }
  };
  const onSubmit = (data) => {
    const langOrganizations = languages.map((lng) => {
      return {
        organizationId: data.organizationId ?? 0,
        language: lng.shortForm,
        termsAndConditions: data[`terms_${lng.shortForm}`],
        faq: data[`faq_${lng.shortForm}`],
        privacyPolicy: data[`privacy_${lng.shortForm}`],
      };
    });

    let socialNetworks = [];

    for (const key in data) {
      if (key.startsWith('nameSocialNetwork_')) {
        const socialNetworkName = key.substring('nameSocialNetwork_'.length);
        const socialNetworkValue = data[key];

        socialNetworks.push({
          socialNetwork: socialNetworkName,
          value: socialNetworkValue,
        });
      }
    }
    let organizationContacts = [];

    for (const key in data) {
      if (key.startsWith('nameContactInfo_')) {
        const organizationContact = key.substring('nameContactInfo_'.length);
        const value = data[key];

        organizationContacts.push({
          organizationContact: organizationContact,
          value: value,
        });
      }
    }

    const body = {
      organizationId: data.organizationId ?? 0,
      businessType: 'ShowProducer',
      name: data.name,
      address: data.address || null,
      city: data.city.label || null,
      state: data.state.label || null,
      countryCode: data.country.countryCode || null,
      zipCode: data.zip || null,
      legalName: data.legalName || null,
      timezone: data.timezone.value,
      telephone: data.phone || null,
      currency: data.currency.value,
      email: data.email || null,
      isActive: !isEditingOrganization ? true : data.isActive,
      tenantId: data?.tenantId?.value,
      logo: data?.logo?.base64 ?? data?.logo,
      ownerUserId: data.owner.value || null,
      whatsAppConfirmation: data.isWhatsapp ? data.whatsapp || null : null,
      website: data.website || null,
      taxIdentificationNumber: data.tax || null,
      taxIdentificationType: data?.taxType[0]?.value || null,
      isAutomaticConfirmation: data.isAutomatic ? data.isAutomatic : false,
      isWhatsAppEnable: data.isWhatsapp ? data.isWhatsapp : false,
      purchaseConfirmationEmails: data?.emails?.map((email) => email.value.trim()) ?? [],
      biometricRequired: false, // sacar
      biometricAutomatic: false, // sacar
      marketCommission: parseInt(data.marketCommission) || 0,
      utcOffSet: parseInt(data.utcOffSet),
      faq: data.faq,
      termsAndConditions: data.terms,
      privacyPolicy: data.privacy,
      defaultLanguage: data.defaultLanguage.value,
      socialNetworks: socialNetworks,
      organizationContact: organizationContacts,
      langOrganizations: removeObjectsWithEmptyFields(langOrganizations, [
        'termsAndConditions',
        'faq',
        'privacyPolicy',
      ]),
      appImageURL: data?.appImageURL?.base64 ?? data?.appImageURL,
    };
    if (isEditingOrganization) {
      putOrganizationMutation.mutate(body);
    } else {
      postOrganizationMutation.mutate(body);
    }
  };

  const formatActivityData = (d) => {
    const data = d.data.organization;
    const formattedData = {
      organizationId: data.organizationId,
      name: data.name,
      address: data.address || null,
      country: countries?.find((cty) => cty.countryCode === data?.countryCode),
      state: { label: data?.state, value: data?.cityDTO?.state?.id },
      city: { label: data?.city, value: data?.cityDTO?.id },
      zip: data.zipCode || null,
      legalName: data.legalName || null,
      timezone: getTimezoneFormatted(timezone)?.find((tz) => tz.value === data?.timezone),
      phone: data.telephone || null,
      email: data.email || null,
      isActive: data.isActive ? data.isActive : false,
      tenantId: { label: data.tenantId, value: data.tenantId },
      currency: { label: data.currency, value: data.currency },
      logo: data.logo ? { base64: data.logo, name: 'logo' } : null,
      owner: owners?.find((own) => own.value === data?.ownerUserId),
      website: data.website || null,
      description: data.description || null,
      tax: data.taxIdentificationNumber || null,
      taxType: data?.taxIdentificationType,
      legals: '',
      isAutomatic: data.isAutomaticConfirmation ? data.isAutomaticConfirmation : false,
      isWhatsapp: data.isWhatsAppEnable ? data.isWhatsAppEnable : false,
      whatsapp: data.whatsAppConfirmation,
      emails: data.purchaseConfirmationEmails.map((d) => ({ label: d, value: d })),
      socialNetworks: data?.socialNetworks,
      organizationContact: data?.organizationContact,
      marketCommission: parseInt(data.marketCommission) || 0,
      utcOffSet: data.utcOffSet || null,
      defaultLanguage: data?.defaultLanguage
        ? {
            value: data?.defaultLanguage,
            label: languages.find((l) => l.shortForm === data?.defaultLanguage)?.title,
          }
        : null,
      appImageURL: data.appImageURL ? { base64: data.appImageURL, name: 'appImageURL' } : null,
    };

    data.langOrganizations.forEach((langOrg) => {
      const lang = langOrg.language;
      formattedData[`terms_${lang}`] = langOrg.termsAndConditions;
      formattedData[`faq_${lang}`] = langOrg.faq;
      formattedData[`privacy_${lang}`] = langOrg.privacyPolicy;
    });
    return formattedData;
  };
  const handleToastCallback = () => {
    queryClient.refetchQueries({ queryKey: ['organizations', user], exact: true });
    if (isRedirect) {
      methods.reset();
      navigate(`/organizations`);
      setShowToast(false);
    } else {
      setShowToast(false);
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  return (
    <>
      {showToast && (
        <Toast
          message={`Organization ${isEditingOrganization ? 'updated' : 'created'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {putOrganizationMutation?.error || postOrganizationMutation.error ? (
        <Toast
          message={`Error while attempting to ${
            isEditingOrganization ? 'edit' : 'create'
          } the organization.`}
          type='ERROR'
          onCallback={() => {
            putOrganizationMutation.reset();
            postOrganizationMutation.reset();
          }}
        />
      ) : (
        <></>
      )}
      {organizationQuery.isLoading || countriesQuery.isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='row mt-3 mt-sm-2 align-items-end '>
              <Select
                options={languages.map((language) => ({
                  label: language.title,
                  value: language.shortForm,
                }))}
                label={'Select a Default Language'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwGlobeIcon />}
                name={'defaultLanguage'}
                isRequired={true}
              />
            </div>
            <Subtitle text={<h3>Main Data</h3>} />
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'name'}
                label={'Name'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter organization name'}
                icon={<NameIcon />}
                isRequired={true}
              />
              <div className={'col-12 col-sm-6 col-xl-4'}>
                {isEditingOrganization && (
                  <ToggleToken
                    label={'Status'}
                    activeText={'Active'}
                    inactiveText={'Inactive'}
                    name={'isActive'}
                  />
                )}
              </div>
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'legalName'}
                label={'Legal Name'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter organization legal name'}
                icon={<FwOrganizationIcon />}
                isRequired={true}
              />
              <Select
                options={countries}
                label={'Country'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<CountryIcon />}
                name={'country'}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <Select
                options={state}
                label={'State'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<StateIcon />}
                name={'state'}
                isDisabled={countryInput === undefined}
                isRequired={true}
              />
              <Select
                options={city}
                label={'City'}
                c
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<CityIcon />}
                name={'city'}
                isDisabled={stateInput === undefined}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'zip'}
                label={'Zip Code'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter zip code'}
                icon={<ZipCodeIcon />}
              />
              <Select
                options={getTimezoneFormatted(timezone)}
                label={'Time zone'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<TimeZoneIcon />}
                name={'timezone'}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'address'}
                label={'Address'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter address'}
                icon={<FwLocationIcon />}
              />
              <Select
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'MXN', value: 'MXN' },
                ]}
                label={'Currency'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwPriceIcon />}
                name={'currency'}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              {isEditingOrganization && (
                <Select
                  options={[]}
                  label={'Tenant'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwTenantIcon />}
                  name={'tenantId'}
                  isDisabled={true}
                />
              )}
            </div>
            <Subtitle text={<h3>General Data</h3>} />
            <div className='row mt-4'>
              <div className='col-auto mb-1'>
                <ModernSwitcher
                  text={'Enable WhatsApp'}
                  name={'isWhatsapp'}
                  onClick={() => {
                    methods.clearErrors('whatsapp');
                  }}
                />
              </div>
              <div className='col-auto mb-1'>
                <ModernSwitcher text={'Automatic Confirmation'} name={'isAutomatic'} />
              </div>
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputPhone
                name='phone'
                placeholder='Phone'
                className={'col-12 col-sm-6 col-xl-4 my-2 my-sm-0'}
                label={'Phone'}
                icon={<FwPhoneIcon />}
                defaultCountry='MX'
                initialValue={methods.watch('phone')}
              />
              <InputPhone
                name='whatsapp'
                className={'col-12 col-sm-6 col-xl-4 my-2 my-sm-0'}
                placeholder='Whatsapp'
                label={'Whatsapp'}
                icon={<FwPhoneIcon />}
                defaultCountry='MX'
                isDisabled={enabelWpp ? false : true}
                isRequired={false}
                initialValue={methods.watch('whatsapp')}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputText
                name={'website'}
                label={'Website'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter website'}
                icon={<WebsiteIcon />}
              />
              <InputText
                name={'email'}
                label={'Email'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter email'}
                icon={<FwAtSignIcon />}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <Select
                options={taxTypes}
                label={'Tax Identification Type'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<TaxIcon size='18px' />}
                name={'taxType'}
                isDisabled={true}
              />
              <InputText
                name={'tax'}
                label={'Tax Identification Number'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Tax Identification Number'}
                icon={<NumberTaskIcon size='18px' />}
                isRequired={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputEmails
                name={'emails'}
                label={'Purchase Confirmation Emails'}
                className={'col-xl-8 col'}
                placeholder={'Enter email(s) and press Enter to add'}
                icon={<FwAtSignIcon />}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputNumber
                label={'Timezone Change'}
                className={'col-xl-4 col'}
                name={'utcOffSet'}
                icon={<FwClockIcon />}
                placeholder={'0'}
                acceptNegative={true}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputNumber
                name={'marketCommission'}
                label={'Market Commission'}
                className={'col-xl-4 col'}
                placeholder={'Enter a market commission'}
                isRequired={true}
              />
            </div>
            <div className='row mt-4'>
              <InputList
                className={'col-xl-8 col-12'}
                {...methods}
                data={socialNetworkFormatted}
                initalStae={socialInitialState}
                labelSelect={'Social Network'}
                nameSelect={'currentSocialNetwork'}
                nameInput={'currentSocialNetworkName'}
                baseNameInput={'nameSocialNetwork_'}
                placeholder={'Enter url'}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <InputList
                className={'col-xl-8 col-12'}
                {...methods}
                data={contactInformationFormatted}
                initalStae={organizationContact}
                labelSelect={'Contacts'}
                nameSelect={'currentContactInfo'}
                nameInput={'currentContactInfoName'}
                baseNameInput={'nameContactInfo_'}
                placeholder={'Enter data contact'}
                isPhone={true}
              />
            </div>

            <Subtitle text={<h3>Text Editors</h3>} />
            <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
            <div className='row mt-3 mt-sm-2'>
              <TextEditor
                label='FAQ'
                className='col col-xl-8'
                name={`faq_${currentLng}`}
                options={editorOptions}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <TextEditor
                label='Terms & Conditions'
                className='col col-xl-8'
                name={`terms_${currentLng}`}
                options={editorOptions}
              />
            </div>
            <div className='row mt-3 mt-sm-2'>
              <TextEditor
                label='Privacy Policy'
                className='col col-xl-8'
                name={`privacy_${currentLng}`}
                options={editorOptions}
              />
            </div>
            <Subtitle text={<h3>Structure</h3>} />
            <div className='row mt-3 mt-sm-2'>
              <Select
                options={owners}
                label={'Owner user'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwPersonIcon />}
                name={'owner'}
                isRequired={true}
              />
            </div>

            <Subtitle text={<h3>Multimedia</h3>} />

            <div className='row mt-3 mt-sm-2'>
              <InputMedia
                id={'logoFile'}
                name={'logo'}
                className='col-12 col-sm-6 col-xl-3 mt-3'
                placeholder={'Upload Logo'}
                label={'Logo'}
                isRequired={true}
                maxSize={409600}
                titleModal='Add logo'
              />
            </div>
            <Subtitle text={<h3>App</h3>} />

            <div className='row mt-3 mt-sm-2'>
              <InputMedia
                id={'appImage'}
                name={'appImageURL'}
                className='col-12 col-sm-6 col-xl-3 mt-3'
                placeholder={'Upload App Images'}
                label={'App Image'}
                isRequired={true}
                maxSize={409600}
                titleModal='Add App Image'
              />
            </div>

            <Button
              size={'small'}
              type='submit'
              text={`${
                (postOrganizationMutation.isLoading || putOrganizationMutation.isLoading) &&
                lastClickedButton.current === 'send'
                  ? 'Sending...'
                  : 'Send'
              }`}
              onClick={() => {
                handleRedirect(false, 'send');
              }}
            />
            <Button
              size={'small'}
              type='button'
              className={'btn-custom-white mt-3 ml-2'}
              text={`Cancel`}
              onClick={clearForm}
            />
            <Button
              size={'small'}
              className={'mt-3 ml-2'}
              type='submit'
              text={`${
                (postOrganizationMutation.isLoading || putOrganizationMutation.isLoading) &&
                lastClickedButton.current === 'sendAndClose'
                  ? 'Sending...'
                  : 'Send and Close'
              }`}
              onClick={() => {
                handleRedirect(true, 'sendAndClose');
              }}
            />
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default OrganizationForm;
