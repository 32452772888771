import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  Alert,
  Button,
  InputText,
  Loading,
  Modal,
  Select,
  Toast,
  ToggleToken,
} from '../../../uiComponents/common';
import { FormProvider, useForm } from 'react-hook-form';
import { DoorsIcons, FwMasksIcon, FwUserIcon } from '../../../uiComponents/icons';
import {
  fetchActivities,
  getDoormansUsers,
  postGate,
  putGate,
} from '../../../services';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';

const GatesForm = ({ open, onClose, gate, setGate, onSuccess }) => {
  const [isRedirect, setIsRedirect] = useState(null);
  const lastClickedButton = useRef(null);
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);

  const defaultForm = {
    id: gate?.id,
    isActive: gate?.isActive,
    name: gate?.name,
    activity: gate?.showId ? { label: gate?.showName, value: gate?.showId } : null,
    doormans: gate?.gateUsers
      ? gate?.gateUsers.map((g) => ({
          label: g.applicationUser.fullName,
          value: g.applicationUser.id,
        }))
      : null,
  };

  const methods = useForm();

  useEffect(() => {
    methods.reset(defaultForm);
  }, [open]);

  const activitiesQuery = useQuery({
    queryKey: ['activities', organization.id],
    queryFn: () => fetchActivities(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const doormansQuery = useQuery({
    queryKey: ['doormans', organization.id],
    queryFn: () => getDoormansUsers(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const activities = activitiesQuery?.data?.map((v) => ({ label: v.name, value: v.id }));
  const doormans = doormansQuery?.data?.data?.map((v) => ({ label: v.name, value: v.sid }));

  const gateMutation = useMutation({
    mutationFn: (body) => {
      if (gate?.id) {
        return putGate(welletContext, body);
      }
      return postGate(welletContext, body);
    },
  });

  const onSubmit = (data) => {
    const body = {
      id: !isEditing ? 0 : data.id,
      showId: data?.activity?.value,
      name: data?.name,
      isActive: !isEditing ? true : data.isActive,
      gateUsers: data?.doormans
        ? data?.doormans?.map((c) => ({ gateId: !isEditing ? 0 : data.id, userId: c.value }))
        : null,
    };
    gateMutation.mutate(body);
  };

  const clearForm = () => {
    if (gate?.id) {
      navigate('/gates');
    } else {
      gateMutation.reset();
      navigate('/gates');
    }
    methods.reset({
      id: null,
      activity: null,
      isActive: null,
      name: null,
    });
    setGate();
    onClose();
  };

  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  const handleToastCallback = () => {
    if (isRedirect) {
      clearForm();
      navigate('/gates');
    } else {
      gateMutation.reset();
    }
    onSuccess();
  };

  const isEditing = !!gate?.id;

  return (
    <div>
      {activitiesQuery.isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <Modal
            title={isEditing ? 'Edit Gate' : 'New Gate'}
            open={open}
            nested={true}
            customButtons={
              <div className='row align-items-center justify-content-center justify-content-sm-start px-2'>
                <Button
                  size={'small'}
                  type='submit'
                  text={`${
                    gateMutation.isLoading && lastClickedButton.current === 'send'
                      ? 'Sending...'
                      : 'Send'
                  }`}
                  onClick={() => {
                    methods.handleSubmit(onSubmit)();
                    handleRedirect(false, 'send');
                  }}
                />
                <Button
                  size={'small'}
                  type='button'
                  className={'btn-custom-white  ml-2'}
                  text={`Cancel`}
                  onClick={clearForm}
                />
                <Button
                  size={'small'}
                  className={' ml-2'}
                  type='submit'
                  text={`${
                    gateMutation.isLoading && lastClickedButton.current === 'sendAndClose'
                      ? 'Sending...'
                      : 'Send & Close'
                  }`}
                  onClick={() => {
                    methods.handleSubmit(onSubmit)();
                    handleRedirect(true, 'sendAndClose');
                  }}
                />
              </div>
            }
          >
            <form key={1} {...methods}>
              {isEditing ? (
                <div className='row mt-2 mx-0'>
                  <div className='col-auto'>
                    <ToggleToken
                      label={'Status'}
                      activeText={'Active'}
                      inactiveText={'Inactive'}
                      name={'isActive'}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className='row mt-2 mx-0'>
                <Select
                  options={activities}
                  label={'Activity'}
                  className={'col-12 col-sm-6 m-0 mb-2'}
                  icon={<FwMasksIcon />}
                  name={'activity'}
                  isRequired={true}
                  placeholder={'Select Activity'}
                />
                <InputText
                  name={'name'}
                  label={'Name'}
                  className={'col-12 col-sm-6'}
                  placeholder={'Enter gate name'}
                  icon={<DoorsIcons />}
                  isRequired={true}
                />
              </div>
              <div className='row mx-0'>
                <Select
                  options={doormans}
                  label={'Doormans'}
                  className={'col-12 col-sm-6 m-0 mb-2'}
                  icon={<FwUserIcon />}
                  name={'doormans'}
                  components={{
                    Option: InputOption,
                  }}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  hideSelectedOptions={false}
                  placeholder={'Select Doorman'}
                />
              </div>
            </form>
          </Modal>
        </FormProvider>
      )}
      {gateMutation.isSuccess && (
        <Toast
          message={`Gate ${isEditing ? 'updated' : 'created'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {gateMutation?.error &&
        (gateMutation?.error?.response?.data?.message ? (
          <Alert
            title={'Oops, something went wrong'}
            text={gateMutation?.error?.response?.data?.message}
            type={'error'}
          />
        ) : (
          <></>
        ))}
    </div>
  );
};

export default GatesForm;
