import React from 'react';
import './subtitle.css';

const Subtitle = ({ border = true, text, endComponent }) => {
  return (
    <div
      className={`${
        border ? 'subtitle-border' : ''
      } flex-wrap text-subtitle py-2 subtitle d-flex justify-content-between align-items-center`}
    >
      <div>{text}</div>
      <div>{endComponent}</div>
    </div>
  );
};

export default Subtitle;
