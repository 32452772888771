import React from 'react';

const PageHeader = ({ button, title }) => {
  return (
    <div className='d-flex w-100 justify-content-between aling-items-center mb-3  border-table-bottom text-subtitle'>
      <h1 className='ctm-title font-weight-bold'>{title}</h1>
      {button}
    </div>
  );
};

export default PageHeader;
