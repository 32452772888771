export const setTenantId = (tenantId) => ({
  type: 'SET_TENANT_ID',
  payload: tenantId,
});

export const setCurrentOrg = (currentOrg) => {
  const currentOrgJSON = JSON.stringify(currentOrg);
  localStorage.setItem('currentOrg', currentOrgJSON);
  return {
    type: 'SET_CURRENT_ORG',
    payload: currentOrg,
  };
};

export const setSelectedOrg = (selectedOrg) => {
  const selectedOrgJSON = JSON.stringify(selectedOrg);
  localStorage.setItem('selectedOrg', selectedOrgJSON);
  return {
    type: 'SET_SELECTED_ORG',
    payload: selectedOrg,
  };
};
export const setSelectedUser = (selectedUser) => ({
  type: 'SET_SELECTED_USER',
  payload: selectedUser,
});

export const setSelectedDate = (selectedDate) => ({
  type: 'SET_SELECTED_DATE',
  payload: selectedDate,
});

export const clearSelectedDate = (selectedDate) => ({
  type: 'CLEAR_SELECTED_DATE',
  payload: selectedDate,
});
