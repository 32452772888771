export default function ExclamationIcon({ size = '16px', color = '#ccc' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M2375 4900 c-446 -38 -864 -196 -1223 -464 -137 -102 -366 -331 -468 -468 -693 -930 -613 -2206 191 -3037 451 -466 1038 -714 1685 -714 270 0 493 35 730 113 360 119 652 301 926 574 311 312 511 661 618 1081 93 361 93 788 0 1150 -198 779 -778 1401 -1542 1654 -282 93 -630 135 -917 111z m375 -430 c581 -61 1098 -374 1416 -861 576 -878 334 -2059 -541 -2644 -568 -380 -1290 -429 -1902 -130 -1127 551 -1436 2002 -631 2960 178 212 374 364 633 491 318 155 678 220 1025 184z' />
        <path d='M2350 2985 l0 -855 210 0 210 0 0 855 0 855 -210 0 -210 0 0 -855z' />
        <path d='M2510 1701 c-105 -34 -160 -106 -160 -211 0 -61 25 -115 74 -158 103 -90 263 -56 327 69 35 70 20 180 -34 237 -47 51 -149 82 -207 63z' />
      </g>
    </svg>
  );
}
