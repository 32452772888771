export const getEnglishLangs = (languageId) => {
  const translations = {
    es: 'Spanish',
    en: 'English',
    fr: 'French',
    de: 'German',
    pt: 'Portuguese',
    ru: 'Russian',
    cn: 'Chinese',
    hi: 'Hindi',
  };

  return translations[languageId] || languageId;
};
