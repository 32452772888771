import React from 'react';
import './alert.css';
const Alert = ({ title, text, icon, type, list }) => {
  const alertClass = `alert alert-${type} my-3 d-flex align-items-start`;

  return (
    <div className={alertClass}>
      <div className='alert-content'>
        <div className='d-flex align-items-center'>
          {icon && <div className='alert-icon mr-1'>{icon}</div>}
          {title && (
            <h4 className='alert-title font-weight-semibold m-0' style={{ fontSize: '20px' }}>
              {title}
            </h4>
          )}
        </div>
        {list ? (
          <ul className='alert-list'>
            {list.map((item, index) => (
              <li key={index} className='alert-list-item'>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          text && <p className='alert-text'>{text}</p>
        )}
      </div>
    </div>
  );
};

export default Alert;
