import { Routes, Route } from 'react-router-dom';
import {
  Login,
  Payments,
  PaymentDetail,
  Wallets,
  Sellers,
  OrganizationWallets,
  OperationDetails,
  Activity,
  SellerDetail,
  NewActivity,
  EditActivity,
  Orders,
  Profile,
  ChangePassword,
  Ticket,
  NewTicket,
  EditTicket,
  SoldOut,
  Organizations,
  OrganizationParentForm,
  Venues,
  Users,
  UserProfile,
  Promotions,
  Gates,
  CheckIn,
  CheckInHistory,
} from './screens';

import './assets/bootstrap.min.css';
import './assets/bootstrap_limitless.css';
import './assets/colors.css';
import './assets/components.css';
import './assets/layout.css';
import './assets/global-styles.css';
// Custom Global Styles
import './assets/css/fonts.css';
import './assets/css/custom-colors.css';
import './assets/icons/icomoon/styles.min.css';
import './assets/icons/fontawesome/styles.min.css';

import { initializeApp } from 'firebase/app';
import AuthRoute from './routes/authRoute';
import Auth from './components/auth/Auth';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import LoginRoute from './routes/loginRoute';
import { Loading } from './uiComponents/common';
import ForgotPassword from './screens/forgotPassword/forgotPassword';
import { PromotionsForm, VenuesForm } from './components';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE);

const renderRoutes = (organization) => {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        {/* Live Feed */}
        <Route path='/' element={<Payments />} />
        <Route path='/paymentDetails/:id' element={<PaymentDetail />} />
        <Route path='/check-in'>
          <Route index element={<CheckIn />} />
          {/* <Route path='/check-in/:id' element={<OperationDetails />} /> */}
        </Route>

        {/* Operation History */}
        <Route
          path='/wallets'
          element={organization === 83 ? <Wallets /> : <OrganizationWallets />}
        />
        <Route path='/operationDetails/:orgId/:id' element={<OperationDetails />} />
        <Route path='/orders'>
          <Route index element={<Orders />} />
          <Route path='/orders/:orgId/:id' element={<OperationDetails />} />
        </Route>
        <Route path='/checkin-history'>
          <Route index element={<CheckInHistory />} />
        </Route>
        <Route path='/sellers'>
          <Route index element={<Sellers />} />
          <Route path='/sellers/sellersDetails/:orgId/:id' element={<SellerDetail />} />
          <Route
            path='/sellers/sellersOperationDetails/:orgId/:id'
            element={<OperationDetails />}
          />
          <Route path='/sellers/sellersDetails/:orgId/:id/profile' element={<UserProfile />} />
        </Route>
        <Route path='/users'>
          <Route index element={<Users />} />
          <Route path='/users/edit/:id' element={<UserProfile />} />
          <Route path='/users/new-user' element={<UserProfile />} />
        </Route>

        {/* Settings & Admin */}
        <Route path='/venues'>
          <Route index element={<Venues />} />
          <Route path='/venues/new-venue' element={<VenuesForm />} />
          <Route path='/venues/edit-venue/:id' element={<VenuesForm />} />
        </Route>
        <Route path='/activities'>
          <Route index element={<Activity />} />
          <Route path='/activities/new-activity' element={<NewActivity />} />
          <Route path='/activities/edit-activity/:id' element={<EditActivity />} />
          <Route path='/activities/clone/:id' element={<EditActivity isDuplicate={true} />} />
        </Route>
        <Route path='/tickets'>
          <Route index element={<Ticket />} />
          <Route path='/tickets/new-tickets' element={<NewTicket />} />
          <Route path='/tickets/clone/:id' element={<EditTicket isDuplicate={true} />} />
          <Route path='/tickets/edit-tickets/:id' element={<EditTicket />} />
        </Route>
        <Route path='/sold-out' element={<SoldOut />} />
        <Route path='/organizations'>
          <Route index element={<Organizations />} />
          <Route
            path='/organizations/edit-organization/:orgId'
            element={<OrganizationParentForm />}
          />
          <Route path='/organizations/new-organization' element={<OrganizationParentForm />} />
        </Route>
        <Route path='/gates'>
          <Route index element={<Gates />} />
        </Route>
        <Route path='/promotions'>
          <Route index element={<Promotions />} />
          <Route path='/promotions/new-promotion' element={<PromotionsForm />} />
          <Route path='/promotions/edit-promotion/:id' element={<PromotionsForm />} />
        </Route>
        {/* User Settings */}
        <Route path='/profile' element={<Profile />} />
      </Route>

      <Route element={<LoginRoute />}>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
      </Route>
      <Route path='/change-password' element={<ChangePassword />} />
    </Routes>
  );
};

function App() {
  const user = useSelector((state) => state.user);
  const firebaseApp = initializeApp(firebaseConfig);
  const organization = useSelector((state) => state.app.selectedOrganization?.id);
  const authenticated = user.isAuthenticated;
  const authChecked = user.authChecked;
  return (
    <div className='App'>
      <Suspense fallback={<Loading />}>
        {(!authenticated || (authenticated && user.user !== null)) && authChecked ? (
          renderRoutes(organization)
        ) : (
          <Loading />
        )}
        <Auth key='auth' />
      </Suspense>
    </div>
  );
}

export default App;
