import React, { useContext, useState } from 'react';
import {
  BreadCrumbs,
  Loading,
  Money,
  Subtitle,
  ButtonFilter,
  Badge,
} from '../../uiComponents/common';
import './wallets.css';
import { WelletContext } from '../../context/wellet/welletContext';
import { CustomCard, Table, TableCell, TableRow } from '../../components';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { fetchWalletByOrganization, fetchWalletTotals } from '../../services';
import useUser from '../../hooks/useUser';
import { setSelectedOrg } from '../../actions';
import DateFilter from '../../components/dateFilter/dateFilter';
import { formatTitleNumber } from '../../helpers/format';

const OrganizationWallets = () => {
  const welletContext = useContext(WelletContext);
  const { isSuperAdmin, isAdmin, user } = useUser();
  const [filter, setFilter] = useState([
    { label: 'Sale', value: 'SALE', isActive: false },
    { label: 'Deposit', value: 'DEPOSIT', isActive: false },
    { label: 'Refund Sale', value: 'REFUND_SALE', isActive: false },
    { label: 'Pending withdrawal', value: 'PENDING_WITHDRAWAL', isActive: false },
    { label: 'Withdrawal', value: 'WITHDRAWAL', isActive: false },
  ]);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrg = useSelector((state) => state.app.selectedOrganization);

  const navigate = useNavigate();

  // To Do: Logica paginado, consultar con EVA.
  const organizationQuery = useQuery({
    queryKey: ['organization-wallets', filter, organization.id, selectedDates],
    queryFn: () =>
      fetchWalletByOrganization(welletContext, organization.id, null, null, filter, selectedDates),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: isAdmin,
  });

  const organizationId = isSuperAdmin
    ? selectedOrg?.id
      ? selectedOrg.id
      : organization.id
    : organization.id;

  const superAdminQuery = useQuery({
    queryKey: ['organization-super-wallets', filter, organizationId, selectedDates],
    queryFn: () =>
      fetchWalletByOrganization(welletContext, organizationId, null, null, filter, selectedDates),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: isSuperAdmin,
    onError: () => navigate('/wallets'),
  });

  const organizationData = isSuperAdmin ? superAdminQuery : organizationQuery;

  const totalsQuery = useQuery({
    queryKey: ['organizations-totals', isSuperAdmin ? organizationId : null, filter, selectedDates],
    queryFn: () =>
      fetchWalletTotals(welletContext, isSuperAdmin ? organizationId : null, filter, selectedDates),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onError: () => navigate('/wallets'),
  });

  const navigateToOperationDetail = (id) => {
    if (id) navigate(`/operationDetails/${selectedOrg.id}/${id}`);
  };

  if (!selectedOrg?.id) {
    return navigate('/wallets');
  }

  return (
    <>
      {superAdminQuery.isLoading || organizationQuery.isLoading || totalsQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          {isSuperAdmin && organization.id === 83 ? (
            <BreadCrumbs
              crumbs={[
                {
                  name: 'Wellet',
                  path: '/wallets',
                  onClick: () =>
                    dispatch(setSelectedOrg({ name: user.companyUser.name, id: user.companyId })),
                },
                { name: 'Wallet' },
              ]}
            />
          ) : (
            <></>
          )}
          <Subtitle
            text={
              <div className='mt-1 payment-title text-truncate'>
                <div>{isSuperAdmin ? selectedOrg?.name : organization.name}</div>
                <Money value={totalsQuery.data.data.totalAmount} currencyCode={'MXN'} />
              </div>
            }
            endComponent={
              <div className='mt-1'>
                <DateFilter filterName={'Date'} />
              </div>
            }
          />
          {/* formatTitleNumber */}
          <div className='d-flex justify-content-start aling-items-center mt-3 mb-3 w-100 flex-wrap'>
            <CustomCard
              title={formatTitleNumber(totalsQuery.data.data.revenue) + ' MXN'}
              subtitle={
                <Money
                  value={totalsQuery.data.data.revenue}
                  currencyCode={'MXN'}
                  currencyClass={'text-white'}
                />
              }
              text={'Revenue'}
            />
            <CustomCard
              title={formatTitleNumber(totalsQuery.data.data.totalSalesAmount) + ' MXN'}
              subtitle={
                <Money
                  value={totalsQuery.data.data.totalSalesAmount}
                  currencyCode={'MXN'}
                  currencyClass={'text-white'}
                />
              }
              text={'Total Sales'}
            />
            <CustomCard
              title={formatTitleNumber(totalsQuery.data.data.totalProcessingFeeAmount) + ' MXN'}
              subtitle={
                <Money
                  value={totalsQuery.data.data.totalProcessingFeeAmount}
                  currencyCode={'MXN'}
                  currencyClass={'text-white'}
                />
              }
              text={'Total Processing Fee'}
            />
            <CustomCard
              title={formatTitleNumber(totalsQuery.data.data.totalCommissionsAmount) + ' MXN'}
              subtitle={
                <Money
                  value={totalsQuery.data.data.totalCommissionsAmount}
                  currencyCode={'MXN'}
                  currencyClass={'text-white'}
                />
              }
              text={'Total Commissions'}
            />

            <CustomCard
              title={formatTitleNumber(totalsQuery.data.data.totalRefundAmount) + ' MXN'}
              subtitle={
                <Money
                  value={totalsQuery.data.data.totalRefundAmount}
                  currencyCode={'MXN'}
                  currencyClass={'text-white'}
                />
              }
              text={'Total Refunds'}
            />
          </div>
          <div className='w-100 d-flex align-items-center justify-content-start my-3'>
            <ButtonFilter
              type={'checkbox'}
              filterName={'Type'}
              filterOptions={filter}
              callback={setFilter}
            />
          </div>
          <Table
            tableKey={`walletsOrganization-${selectedOrg.id}-${JSON.stringify(
              filter,
            )}-${JSON.stringify(selectedDates)}`}
            data={organizationData.data.data.summaryOperations}
            totalP={organizationData.data.data.summaryOperations.length}
            columns={['Operation', 'Amount', 'Type', 'Date', '']}
            renderRow={(operation, index) => (
              <TableRow
                key={operation.orderId + operation.operationDate}
                index={index}
                onClick={() => navigateToOperationDetail(operation.orderId)}
              >
                <TableCell>{operation.operationId}</TableCell>
                <TableCell className='ctm-text font-weight-bold' type={'primary'}>
                  <Money value={operation.amount} currencyCode={operation.currency} />
                </TableCell>
                <TableCell>
                  <Badge status={operation.operationType} />
                </TableCell>
                <TableCell>
                  {format(new Date(operation.operationDate), 'MMM dd, yyyy, h:mm a')}
                </TableCell>
                <TableCell className={'w-sm-50 w-25'}></TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default OrganizationWallets;
