import React, { useContext, useState } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { fetchSellers } from '../../services/liveFeedServices';
import { Badge, Loading, Money, Subtitle, BreadCrumbs } from '../../uiComponents/common';
import { Table, TableCell, TableRow } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedUser } from '../../actions';
import useUser from '../../hooks/useUser';

const Sellers = () => {
  const [showButtons, setShowButtons] = useState(false);
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const { isSuperAdmin } = useUser();
  const navigate = useNavigate();
  const selletrsQuery = useQuery({
    queryKey: ['sellers', selectedOrganization.id],
    queryFn: () => fetchSellers(welletContext, selectedOrganization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!selectedOrganization.id,
  });
  const navigateToSellerDetail = (seller) => {
    dispatch(
      setSelectedUser({
        name: seller.sellerName,
        email: seller.sellerEmail,
        id: seller.userId,
        salesCategory: seller?.tier?.style,
      }),
    );
    navigate(`/sellers/sellersDetails/${selectedOrganization.id}/${seller.userId}`);
  };
  return (
    <>
      {' '}
      {isSuperAdmin && organization.id === 83 ? (
        <BreadCrumbs
          crumbs={[
            {
              name: 'Sellers',
              path: '/sellers',
            }
          ]}
        />
      ) : (
        <></>
      )}
      <Subtitle text={<div className='payment-title text-truncate'>Sellers</div>} />
      {selletrsQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            tableKey={`sellers-${selectedOrganization.id}`}
            data={selletrsQuery?.data?.data}
            totalP={selletrsQuery?.data?.data.length}
            columns={['Total Amount', 'Name', 'Email', 'Is Enabled', 'Sales Category']}
            renderRow={(seller, index) => (
              <TableRow
                key={index}
                onMouseEnter={() => setShowButtons(index)}
                onMouseLeave={() => setShowButtons(null)}
                onClick={() => navigateToSellerDetail(seller)}
              >
                <TableCell
                  className='ctm-text font-weight-bold'
                  style={{ width: '120px' }}
                  type={'primary'}
                >
                  <Money value={seller.totalAmount} currencyCode={seller.currency} />
                </TableCell>
                <TableCell style={{ width: '150px' }}>{seller.sellerName}</TableCell>
                <TableCell style={{ width: '150px' }}>{seller.sellerEmail}</TableCell>
                <TableCell style={{ width: '150px' }}>
                  <Badge status={seller.isEnabled ? 'ENABLED' : 'DISABLED'} />
                </TableCell>
                <TableCell style={{ width: '150px' }}>
                  <Badge status={seller?.tier?.style} />
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Sellers;
