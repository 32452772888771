import React, { useContext, useState } from 'react';
import { Badge, Button, IconButton, Loading, Slider, Subtitle } from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchOrganizations } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { Table, TableCell, TableRow } from '../../components';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';
import { useDispatch } from 'react-redux';
import { setCurrentOrg, setSelectedOrg } from '../../actions';

const Organizations = () => {
  const navigate = useNavigate();
  const [showInactives, setShowInactives] = useState(false);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);

  // Api Querys
  const organizationQuery = useQuery({
    queryKey: ['organizations', organization.id, showInactives],
    queryFn: () => fetchOrganizations(welletContext, showInactives),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const organizationData = organizationQuery?.data?.data ?? [];
  const columns = [
    ' ',
    'Logo',
    'Name',
    'Address',
    'City',
    'State',
    'Country',
    'Email',
    'Phone',
    'Is Active',
  ];

  return (
    <>
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Organizations</h1>}
        endComponent={
          <Button
            className={'btn-custom-white'}
            onClick={() => navigate('/organizations/new-organization')}
            size={'small'}
            text={'New Organization'}
          />
        }
      />
      {organizationQuery.isLoading || organizationData.lenght === 0 ? (
        <Loading />
      ) : (
        <>
          <div className='my-2 row align-items-center'>
            <Slider
              className={'col-auto'}
              label={'Only Actives'}
              onClick={() => setShowInactives(!showInactives)}
              defaultChecked={!showInactives}
            />
          </div>
          <Table
            tableKey={`organizations-${organization.id}-${organizationQuery?.data?.data?.length}`}
            data={organizationData}
            columns={columns}
            renderRow={(org, index) => (
              <TableRow
                key={index}
                onClick={() => {
                  const organizationBody = {
                    name: org.organization.name,
                    id: org.organization.organizationId,
                    marketId: org.organization.marketSetupId ?? null,
                    isMarket: org.organization.marketSetupId === org.organization.organizationId,
                    tenantId: org.organization.tenantId,
                  };
                  dispatch(setCurrentOrg(organizationBody));
                  dispatch(setSelectedOrg(organizationBody));
                }}
              >
                <TableCell style={{ width: '60px' }} type={'primary'}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/organizations/edit-organization/${org.organization.organizationId}`,
                      );
                    }}
                    icon={<FwEditIcon />}
                  />
                </TableCell>
                <TableCell type={'primary'}>
                  <img
                    className='mr-2'
                    style={{
                      height: '35px',
                      width: '35px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    src={org.organization.logo}
                    alt={''}
                  />
                </TableCell>
                <TableCell type={'primary'}>{org.organization.name}</TableCell>
                <TableCell type={'primary'}>{org.organization.address}</TableCell>
                <TableCell type={'primary'}>{org.organization.city}</TableCell>
                <TableCell type={'primary'}>{org.organization.state}</TableCell>
                <TableCell type={'primary'}>{org.organization.countryCode}</TableCell>
                <TableCell type={'primary'}>{org.organization.email}</TableCell>
                <TableCell type={'primary'}>{org.organization.phone}</TableCell>
                <TableCell type={'primary'}>
                  <Badge status={org.organization.isActive ? 'ACTIVE' : 'INACTIVE'} />
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Organizations;
