import React, { createContext, useContext, useReducer, useState } from 'react';

// Define un contexto de paginación
const PaginationContext = createContext();

// Definir un reducer para gestionar el estado de paginación
function paginationReducer(state, action) {
  switch (action.type) {
    case 'SET_PAGINATION':
      return {
        ...state,
        [action.tableKey]: action.paginationData,
      };
    case 'CLEAR_PAGINATION':
      const newState = { ...state };
      delete newState[action.tableKey];
      return newState;
    default:
      return state;
  }
}

// Componente proveedor del contexto
function PaginationProvider({ children }) {
  const [paginationState, dispatch] = useReducer(paginationReducer, {});
  const [currentTable, setCurrentTable] = useState(null);

  const setPagination = (tableKey, paginationData) => {
    if (tableKey !== currentTable) {
      // Cambiar de tabla, limpiar la paginación de la tabla anterior
      if (currentTable) {
        dispatch({ type: 'CLEAR_PAGINATION', tableKey: currentTable });
      }
      setCurrentTable(tableKey);
    }
    dispatch({ type: 'SET_PAGINATION', tableKey, paginationData });
  };

  return (
    <PaginationContext.Provider value={{ paginationState, setPagination }}>
      {children}
    </PaginationContext.Provider>
  );
}
// Función personalizada para consumir el contexto
function usePagination() {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error('usePagination debe utilizarse dentro de un PaginationProvider');
  }
  return context;
}

export { PaginationProvider, usePagination };
