import React from 'react';

export default function TaxIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20.75 14.23'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path
            fill={color}
            d='m19.05,0H1.7C.76,0,0,.76,0,1.7v10.83c0,.94.76,1.7,1.7,1.7h17.35c.94,0,1.7-.76,1.7-1.7V1.7c0-.94-.76-1.7-1.7-1.7Zm.7,12.53c0,.39-.31.7-.7.7H1.7c-.39,0-.7-.31-.7-.7V1.7c0-.39.31-.7.7-.7h17.35c.39,0,.7.31.7.7v10.83Z'
          />
          <path
            fill={color}
            d='m2.69,4.69v1.26h.99v3.16h-.41v.66h7.03v-.66h-.41v-3.16h.99v-1.26l-4.1-1.63-4.1,1.63Zm3.79,4.42h-1.95v-3.16h1.95v3.16Zm.84-3.16h1.74v3.16h-1.74v-3.16Z'
          />
          <rect fill={color} x='3.02' y='10.51' width='7.53' height='.66' />
          <path
            fill={color}
            d='m17.64,10.05h-4.89c-.32,0-.57.25-.57.55,0,.32.26.56.56.57h4.89c.14,0,.29-.06.38-.15.11-.1.18-.26.19-.41,0-.31-.26-.56-.57-.56Z'
          />
          <path
            fill={color}
            d='m12.86,8.39l2.61.03c.16,0,.29-.06.4-.15.11-.1.17-.24.18-.41,0-.3-.25-.56-.56-.57l-2.61-.03c-.31,0-.56.24-.57.56,0,.3.24.56.55.56Z'
          />
        </g>
      </g>
    </svg>
  );
}
