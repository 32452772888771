import React from 'react';

const Slider = ({ onClick, defaultChecked, label, className }) => {
  return (
    <div className={className}>
      { label ? <span className='mr-2' style={{ color: 'var(--color-subtitle)'}}>{label}</span> : <></>}
      <label className='switch'>
        <input
          type='checkbox'
          onClick={onClick}
          defaultChecked={defaultChecked}
        />
        <span className='slider round'></span>
      </label>
    </div>
  );
};

export default Slider;
