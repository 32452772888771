import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  ButtonGallery,
  IconButton,
  InputClock,
  InputDate,
  InputDescription,
  InputMedia,
  InputNumber,
  InputText,
  Loading,
  Select,
  Subtitle,
  Tabs,
  TextAlert,
  TextEditor,
  Toast,
  ToggleToken,
} from '../../../uiComponents/common';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  getActivity,
  getTimeZones,
  getVenues,
  postActivity,
  putActivity,
} from '../../../services/activityServices';
import { WelletContext } from '../../../context/wellet/welletContext';
import useUser from '../../../hooks/useUser';
import { useSelector } from 'react-redux';
import { EditGalleryForm, GalleryForm } from '../gallery';
import {
  CirclePlus,
  FwCalendarIcon,
  FwEdit,
  FwClockIcon,
  FwEditIcon,
  FwGlobeIcon,
  FwMasksIcon,
  FwPersonIcon,
  FwTrashIcon,
  FwYoutubeIcon,
  TimeZoneIcon,
  FwLocationIcon,
} from '../../../uiComponents/icons';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import { usePatchActivityStatusMutation } from '../../../hooks/queries/useActivityStatus';
import { formatTimeToMinutes } from '../../../helpers/format';

const EditActivityForm = ({ isDuplicating = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const { isSuperAdmin } = useUser();
  const methods = useForm();
  const lastClickedButton = useRef(null);

  const [showToast, setShowToast] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isRedirect, setIsRedirect] = useState(null);

  const validateFields = {
    name: {
      rule: (values) => {
        if (!values.name && !values.shortDescription) {
          return true;
        } else if (values.name && values.name.trim() !== '') {
          if (values.name.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Name" field is required and must have a minimum of 3 characters.',
    },
    shortDescription: {
      rule: (values) => {
        if (!values.name && !values.shortDescription) {
          return true;
        } else if (values.shortDescription && values.shortDescription.trim() !== '') {
          if (values.shortDescription.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg:
        'the "Short Description" field is required and must have a minimum of 3 characters.',
    },
  };

  const { languages, lngErrors, currentLng, changeLanguage } = useLanguageSwitcher(
    validateFields,
    methods,
  );

  // Functions Api Query
  const timezoneQuery = useQuery({
    queryKey: ['timezones'],
    queryFn: () => getTimeZones(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const timeZoneFormated = timezoneQuery?.data?.map((obj) => ({
    label: `${obj.name} (UTC ${obj.shift})`,
    value: obj.id,
  }));
  const activityMutation = useMutation({
    mutationFn: async (body) => {
      if (!isDuplicating) await putActivity(welletContext, body, organization.id);
      else await postActivity(welletContext, organization.id, body);
    },
    onSuccess: async () => {
      setShowToast(true);
    },
    onError: async (error) => {
      console.log('error', error);
    },
  });
  const venuesQuery = useQuery({
    queryKey: ['venues'],
    queryFn: () => getVenues(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const activityQuery = useQuery({
    queryKey: ['activity', id],
    queryFn: () => getActivity(welletContext, id),
    onSuccess: (data) => methods.reset(formatActivityData(data)),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
    enabled: !!venuesQuery?.data,
  });

  const { mutate } = usePatchActivityStatusMutation(id);
  // useRedirectIfSuperAdmin(activityQuery.data || organization.id === 83, '/activities');

  const venues = venuesQuery?.data?.venues?.map((v) => ({ label: v.name, value: v.id }));
  const activityName = methods.formState.defaultValues?.name_es;

  const onSubmit = async (data) => {
    const initialLangShows = languages.map((lng) => {
      return {
        language: lng.shortForm,
        name: data[`name_${lng.shortForm}`],
        description: data[`description_${lng.shortForm}`],
        shortDescription: data[`shortDescription_${lng.shortForm}`],
        showId: data.showId,
      };
    });
    const handelLangShowsEmptys = removeObjectsWithEmptyFields(initialLangShows, [
      'name',
      'description',
      'shortDescription',
      // 'shortName',
    ]);

    if (Object.keys(lngErrors).length === 0) {
      const body = {
        id: isDuplicating ? 0 : data.id,
        name: handelLangShowsEmptys.some((s) => s.language === data.defaultLanguage.value)
          ? handelLangShowsEmptys.find((s) => s.language === data.defaultLanguage.value)?.name
          : handelLangShowsEmptys.find((l) => !!l.name).name,
        organizationId: organization.id,
        zoneTime: data.timezone.value,
        priority: data.priority,
        imageUrl: data.image?.base64,
        isActive: isDuplicating ? true : data.isActive ? data.isActive : false,
        capacity: data.capacity,
        shortName: data.shortName,
        venueId: data.venue.value,
        defaultLanguage: data.defaultLanguage.value,
        bannerImageUrl: data.banner?.base64,
        videoUrl: data.video,
        location: data.location,
        showImages: images,
        isCopy: isDuplicating,
        langShows: handelLangShowsEmptys, //
        saleStartLocalDate: data.startSaleDate
          ? `${data.startSaleDate}T${data.saleStartTime}`
          : null,
        startLocalDate: data.activityDate ? `${data.activityDate}T${data.activityTime}` : null,

        endLocalDate: data.activityEndDate
          ? `${data.activityEndDate}T${data.activityEndTime}`
          : null,
        publishLocalDate: data.publishDate ? `${data.publishDate}T${data.publishTime}` : null,
      };

      if (isDuplicating) {
        body['idCopy'] = data.id;
      }

      if (isSuperAdmin) {
        body['utcOffset'] = data.timeZoneChange ? data.timeZoneChange : null;
      }
      activityMutation.mutateAsync(body);
    }
  };
  const formatActivityData = (data) => {
    const formattedData = {
      id: data.id,
      timezone: {
        label: timeZoneFormated?.find((t) => t.value === data?.zoneTime)?.label,
        value: data?.zoneTime,
      },
      defaultLanguage: {
        value: data?.defaultLanguage,
        label: languages.find((l) => l.shortForm === data?.defaultLanguage)?.title,
      },
      capacity: String(data?.capacity),
      priority: String(data?.priority),
      shortName: data?.shortName,
      location: data?.location,
      venue: {
        value: data?.venueId,
        label: venues?.find((v) => v.value === data?.venueId)?.label ?? '',
      },
      image: { base64: data?.imageUrl, name: data?.imageUrl?.split('/')[3] ?? '' },
      banner: { base64: data?.bannerImageUrl, name: data?.bannerImageUrl?.split('/')[3] ?? '' },
      video: data?.videoUrl || '',
      isActive: data?.isActive,
      timeZoneChange: data?.utcOffset,
      startSaleDate: data?.saleStartLocalDate?.split('T')[0],
      activityDate: data?.startLocalDate?.split('T')[0],
      activityEndDate: data?.endLocalDate?.split('T')[0],
      publishDate: data?.publishLocalDate?.split('T')[0],
      activityTime: data?.startLocalDate
        ? data?.startLocalDate?.split('T')[1]?.split(':')[0] +
          ':' +
          data?.startLocalDate?.split('T')[1]?.split(':')[1]
        : null,
      activityEndTime: data?.endLocalDate
        ? data?.endLocalDate?.split('T')[1]?.split(':')[0] +
          ':' +
          data?.endLocalDate?.split('T')[1]?.split(':')[1]
        : null,
      saleStartTime: data?.saleStartLocalDate
        ? data?.saleStartLocalDate?.split('T')[1]?.split(':')[0] +
          ':' +
          data?.saleStartLocalDate?.split('T')[1]?.split(':')[1]
        : null,
      publishTime: data?.publishLocalDate
        ? data?.publishLocalDate?.split('T')[1]?.split(':')[0] +
          ':' +
          data?.publishLocalDate?.split('T')[1]?.split(':')[1]
        : null,
    };

    data.langShows.forEach((langShow) => {
      const lang = langShow.language;
      formattedData[`name_${lang}`] = langShow.name;
      // formattedData[`shortName_${lang}`] = langShow.shortName;
      formattedData[`shortDescription_${lang}`] = langShow.shortDescription;
      formattedData[`description_${lang}`] = langShow.description;
      formattedData[`showId`] = langShow.showId;
    });

    setImages(
      data?.showImages?.map((s) => ({
        ...s,
        showId: 0,
        langShowImage: s?.langShowImage?.map((langImage) => ({
          ...langImage,
          id: 0,
          showImageId: 0,
        })),
      })),
    );
    return formattedData;
  };
  const handleRemoveImg = (idx) => {
    const imagesCopy = images.filter((i, index) => index !== idx);
    setImages(imagesCopy);
  };
  const handleEditImg = (i, idx) => {
    setSelectedImage({
      data: i,
      id: idx,
    });
    setEditOpen(true);
  };
  const clearForm = () => {
    navigate(`/activities`);
  };
  const handleToastCallback = () => {
    if (isRedirect) {
      navigate(`/activities`);
      setShowToast(false);
    } else {
      setShowToast(false);
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  const publishDate = methods.watch('publishDate');
  const saleStartDate = methods.watch('startSaleDate');

  return (
    <>
      {showToast && (
        <Toast
          message={`Activity ${isDuplicating ? 'created' : 'updated'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}

      <FormProvider {...methods}>
        <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
          {activityQuery.isLoading || venuesQuery.isLoading ? (
            <Loading />
          ) : (
            <>
              <Subtitle
                text={<h3>{isDuplicating ? 'New Activity' : `Edit Activity - ${activityName}`}</h3>}
              />
              <div className='row mt-3 align-items-end '>
                <Select
                  options={languages.map((language) => ({
                    label: language.title,
                    value: language.shortForm,
                  }))}
                  label={'Select a Default Language'}
                  className={'col-12 col-sm-6 col-xl-4 m-0'}
                  icon={<FwGlobeIcon />}
                  name={'defaultLanguage'}
                  isRequired={true}
                />
                {!isDuplicating ? (
                  <div className='col-auto mt-2'>
                    <ToggleToken
                      label={'Status'}
                      activeText={'Active'}
                      onClick={(status) => mutate(status)}
                      inactiveText={'Inactive'}
                      name={'isActive'}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Subtitle text={<h3>Main Data</h3>} />
              <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
              {Object.keys(lngErrors).length !== 0 && (
                <TextAlert text={lngErrors.name} type={'danger'} />
              )}
              <div className='row mt-3'>
                <InputText
                  name={'name_' + currentLng}
                  label={'Name'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  placeholder={'Enter activity name'}
                  icon={<FwMasksIcon />}
                  isRequired={true}
                />
                <InputDescription
                  className={'col-12 col-sm-6 col-xl-4'}
                  name={'shortDescription_' + currentLng}
                  label={'Short Description'}
                  placeholder={'Enter short description'}
                  icon={<FwEditIcon />}
                  isRequired={true}
                />
              </div>

              <div className='row mt-3'>
                <TextEditor
                  className='col-12 col-xl-8'
                  label={'Description'}
                  name={`description_${currentLng}`}
                  customErrors={lngErrors}
                />
              </div>
              <Subtitle text={<h3>General Data</h3>} />
              <div className='row mt-3'>
                <InputDate
                  placeholder={'Enter Publish Date'}
                  label={'Publish Date'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwCalendarIcon />}
                  name={'publishDate'}
                  fromDate={new Date()}
                  onDaySelect={() => {
                    methods.setValue('startSaleDate', '');
                    methods.setValue('saleStartTime', '');
                    methods.setValue('activityDate', '');
                    methods.setValue('activityTime', '');
                    methods.setValue('activityEndTime', '');
                    methods.setValue('activityEndDate', '');
                  }}
                  isRequired={true}
                />
                <InputClock
                  label={'Publish Start Time'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-4'}
                  name={`publishTime`}
                  required
                />
              </div>
              <div className='row mt-3'>
                <InputDate
                  placeholder={'Enter Sale Start Date'}
                  label={'Sale Start Date'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwCalendarIcon />}
                  name={'startSaleDate'}
                  fromDate={new Date(publishDate + 'T00:00:00')}
                  onDaySelect={() => {
                    methods.setValue('activityDate', '');
                    methods.setValue('activityTime', '');
                    methods.setValue('activityEndDate', '');
                    methods.setValue('activityEndTime', '');
                  }}
                  isRequired={true}
                  isDisabled={!publishDate}
                />
                <InputClock
                  label={'Sale Start Time'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-4'}
                  name={`saleStartTime`}
                  validateFunction={(value) => {
                    const publishTime = methods.watch('publishTime');
                    const publishDate = methods.watch('publishDate');
                    const saleTime = formatTimeToMinutes(value);
                    const publishTimeMinutes = formatTimeToMinutes(publishTime);
                    if (publishDate === saleStartDate && saleTime < publishTimeMinutes) {
                      return 'It must be greater than or equal to the publish time.';
                    }
                    return true;
                  }}
                  required
                />
              </div>
              <div className='row mt-3'>
                <InputDate
                  placeholder={'Enter Activity Date'}
                  label={'Activity Date'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwCalendarIcon />}
                  name={'activityDate'}
                  onDaySelect={() => {
                    methods.setValue('activityEndDate', '');
                    methods.setValue('activityEndTime', '');
                  }}
                  fromDate={new Date(saleStartDate + 'T00:00:00')}
                  isDisabled={!saleStartDate}
                  isRequired={true}
                />
                <InputClock
                  label={'Activity Time'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-4'}
                  name={`activityTime`}
                  validateFunction={(value) => {
                    const saleTime = methods.watch('saleStartTime');
                    const activityTime = formatTimeToMinutes(value);
                    const activityDate = methods.watch('activityDate');
                    const saleTimeMinutes = formatTimeToMinutes(saleTime);

                    if (saleStartDate === activityDate && activityTime <= saleTimeMinutes) {
                      return 'It must be greater than the sale time.';
                    }
                    return true;
                  }}
                  required
                />
              </div>
              <div className='row mt-3'>
                <InputDate
                  placeholder={'Enter Activity End Date'}
                  label={'Activity End Date'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwCalendarIcon />}
                  name={'activityEndDate'}
                  fromDate={new Date(methods.watch('activityDate') + 'T00:00:00')}
                  isDisabled={!methods.watch('activityDate')}
                  isRequired={true}
                />
                <InputClock
                  label={'Activity End Time'}
                  icon={<FwClockIcon />}
                  className={'col-12 col-sm-6 col-xl-4'}
                  name={`activityEndTime`}
                  validateFunction={(value) => {
                    const activityTime = methods.watch('activityTime');
                    const time = formatTimeToMinutes(value);
                    const activityDate = methods.watch('activityDate');
                    const activityEndDate = methods.watch('activityEndDate');
                    const activityTimeMinutes = formatTimeToMinutes(activityTime);

                    if (activityDate === activityEndDate && time <= activityTimeMinutes) {
                      return 'It must be greater than the activity time.';
                    }
                    return true;
                  }}
                  required
                />
              </div>
              <div className='row mt-3'>
                <Select
                  options={timeZoneFormated}
                  label={'Timezone'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<TimeZoneIcon />}
                  name={'timezone'}
                  isRequired={true}
                />
                <InputNumber
                  label={'Capacity'}
                  name={'capacity'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwPersonIcon />}
                  placeholder={'0'}
                  isRequired={true}
                />
              </div>
              <div className='row mt-3'>
                <InputText
                  name={'location'}
                  label={'Location'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  placeholder={'Enter a location'}
                  icon={<FwLocationIcon />}
                  isRequired={true}
                />

                <Select
                  options={venues}
                  label={'Venue'}
                  className={'col-12 col-sm-6 col-xl-4'}
                  icon={<FwGlobeIcon />}
                  name={'venue'}
                  isRequired={true}
                />
              </div>
              <div className='row mt-3'>
                <InputText
                  name={'shortName'}
                  label={'Short Name'}
                  className={'mb-2 col-12 col-sm-6 col-xl-4'}
                  placeholder={'Enter activity short name'}
                  icon={<FwMasksIcon />}
                  isRequired={true}
                />
                {isSuperAdmin && (
                  <InputNumber
                    label={'Timezone Change'}
                    className={'mb-2 col-12 col-sm-6 col-xl-4'}
                    name={'timeZoneChange'}
                    icon={<FwClockIcon />}
                    placeholder={'0'}
                    customValidation={{}}
                  />
                )}
              </div>
              <Subtitle text={<h3>Multimedia</h3>} />
              <div className='row mt-3'>
                <InputMedia
                  id={'mainImage'}
                  name={'image'}
                  className='col-12 col-sm-6 col-xl-4 my-1'
                  placeholder={'Upload Image'}
                  label={'Main Image'}
                  isRequired={true}
                  maxSize={409600}
                  titleModal='Add Main Image'
                />
                <InputMedia
                  id={'bannerImage'}
                  name={'banner'}
                  className='col-12 col-sm-6 col-xl-4 my-1'
                  placeholder={'Upload Banner'}
                  label={'Banner Image'}
                  isRequired={true}
                  maxSize={409600}
                  titleModal='Add Banner Image'
                />
              </div>
              <div className='row mt-3'>
                <InputText
                  name={'video'}
                  className={'mb-2 col-12 col-sm-6 col-xl-4'}
                  label={'Video'}
                  placeholder={'Video URL'}
                  icon={<FwYoutubeIcon />}
                  isRequired={false}
                />
              </div>

              <Subtitle text={<h3>Gallery</h3>} />
              <div className='row mt-3'>
                <div className='row p-0 m-0 col-12 col-xl-8 d-flex justify-content-center justify-content-sm-start'>
                  {images.length > 0
                    ? images.map((i, idx) => (
                        <div className='col-12 col-sm-6 col-xl-4 my-1' key={i.src}>
                          <div className='card-gallery'>
                            <div className='card-img-actions p-1'>
                              <div
                                className='image-container'
                                style={
                                  i.langShowImage[0]?.title
                                    ? { height: '150px' }
                                    : { height: '100%' }
                                }
                              >
                                <img
                                  src={i.imageUrl}
                                  alt={i.langShowImage[0]?.title}
                                  className='card-img img-fluid'
                                />
                              </div>
                              <h6 className='m-0 mt-2 font-weight-bold text-truncate'>
                                {i.langShowImage[0]?.title}
                              </h6>
                            </div>
                            <div className='image-overlay d-flex justify-content-end'>
                              <IconButton
                                onClick={() => handleEditImg(i, idx)}
                                classNameIcon={'hoverIconConatiner'}
                                icon={
                                  <FwEdit
                                    color='var(--hover)'
                                    size={20}
                                    classNameIcon={'hoverIcon'}
                                  />
                                }
                              />
                              <IconButton
                                onClick={() => handleRemoveImg(idx)}
                                classNameIcon={'hoverIconConatiner'}
                                icon={
                                  <FwTrashIcon
                                    color='var(--hover)'
                                    size={20}
                                    classNameIcon={'hoverIcon'}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                  {/* Aca van las imagnes */}
                  <div className='col-12 col-sm-6 col-xl-4 my-1'>
                    <ButtonGallery
                      icon={<CirclePlus size='2rem' color='var(--color-text-light)' />}
                      text={'Add Image'}
                      onClick={() => {
                        setOpen(true);
                        setSelectedImage(null);
                      }}
                      width='100%'
                      msg='Max file upload 1MB'
                    />
                  </div>
                </div>
              </div>

              <div className='row align-items-center justify-content-center justify-content-sm-start px-2 py-3'>
                <Button
                  size={'small'}
                  type='submit'
                  text={`${
                    activityMutation.isLoading && lastClickedButton.current === 'send'
                      ? 'Sending...'
                      : 'Send'
                  }`}
                  onClick={() => {
                    handleRedirect(false, 'send');
                  }}
                />
                <Button
                  size={'small'}
                  type='button'
                  className={'btn-custom-white  ml-2'}
                  text={`Cancel`}
                  onClick={clearForm}
                />
                <Button
                  size={'small'}
                  className={' ml-2'}
                  type='submit'
                  text={`${
                    activityMutation.isLoading && lastClickedButton.current === 'sendAndClose'
                      ? 'Sending...'
                      : 'Send and Close'
                  }`}
                  onClick={() => {
                    handleRedirect(true, 'sendAndClose');
                  }}
                />
              </div>
            </>
          )}
        </form>
      </FormProvider>
      <EditGalleryForm
        open={editOpen}
        setOpen={setEditOpen}
        images={images}
        setImages={setImages}
        editForm={selectedImage}
        defaultLanguage={activityQuery.data?.defaultLanguage}
      />
      <GalleryForm
        open={open}
        setOpen={setOpen}
        images={images}
        setImages={setImages}
        editForm={selectedImage}
      />
      {activityMutation?.error &&
        (activityMutation?.error?.response?.data?.message === 'INVALID_IMAGE_FORMAT' ? (
          <Alert
            title={'Oops, something went wrong'}
            text={'Error loading images. Allowed formats: jpg, jpeg, gif'}
            type={'error'}
          />
        ) : (
          <Alert
            title={'Oops, something went wrong'}
            text={activityMutation?.error?.response?.data?.message}
            type={'error'}
          />
        ))}
    </>
  );
};

export default EditActivityForm;
