import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ crumbs }) => {
  const currentOrganization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);

  if (crumbs.length <= 1 && currentOrganization.id === selectedOrganization.id) {
    return null;
  }

  return (
    <div className='breadcrumb'>
      {crumbs.map(({ name, path, onClick, disabled = false }, key) =>
        key + 1 === crumbs.length ? (
          <span className='breadcrumb-item active' href='/' key={key}>
            {name}
          </span>
        ) : (
          <Link
            className={`breadcrumb-item ${disabled ? 'active cursor-normal' : ''}`}
            key={key}
            to={path}
            onClick={onClick}
          >
            {name}
          </Link>
        ),
      )}
      {currentOrganization.id !== selectedOrganization.id ? (
        <span className='breadcrumb-item active cursor-normal' href='/'>
          {selectedOrganization.name}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BreadCrumbs;
