import React from 'react';
import Select from 'react-select';
import './selects.css';
import DoubleChevronIcon from '../../icons/doubleChevron';
import { useFormContext, Controller } from 'react-hook-form';
import { AllSelect, SelectTiers } from './index';

const SelectWellet = ({
  options,
  label,
  icon,
  name,
  className,
  isRequired = false,
  onManualChange = () => {},
  useError = true,
  type = 'select',
  ...others
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validationRules = {};

  if (isRequired) {
    validationRules.required = 'This field is required';
  }

  const componentMap = {
    'all': AllSelect,
    'tiers': SelectTiers,
    'select': Select,
  };

  const SelectComponent = componentMap[type] || Select;

  return (
    <div className={`form-group ${className}`}>
      <label style={{ color: 'var(--color-text)' }}>{label}</label>
      <div className='d-flex'>
        {icon ? (
          <span
            className='input-group-text px-2 input-icon'
            style={{ color: 'var(--color-subtitle)' }}
          >
            {icon}
          </span>
        ) : (
          <></>
        )}
        <Controller
          name={name}
          control={control}
          rules={validationRules}
          render={({ field }) => (
            <SelectComponent
              {...field}
              styles={{
                control: (baseStyles, props) => ({
                  ...baseStyles,
                  border: 'none',
                  borderRadius: 'none',
                  outline: 0,
                  boxShadow: 'none',
                  padding: 0,
                  minHeight: '34px',
                  backgroundColor: props.isDisabled ? 'var(--color-background-disabled)' : 'none',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  padding: '0 8px',
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  padding: 0,
                  margin: 0,
                  color: 'var(--text-color)',
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  padding: 0,
                  backgroundColor: 'var(--color-background-app)',
                  borderRadius: '5px',
                  zIndex: 99999,
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  padding: 0,
                  borderRadius: '5px',
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: 'var(--text-color)',
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: 'var(--hover)',
                  color: 'var(--text-color)',
                }),
                multiValueGeneric: (baseStyles) => ({
                  ...baseStyles,
                  color: 'var(--color-title)',
                }),
                multiValueRemove: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: 'var(--hover)',
                  color: 'var(--text-color)',
                  ':hover': {
                    color: 'var(--color-title)',
                    backgroundColor: '#cacaca',
                  },
                }),
                menuPortal: (baseStyles) => ({
                  ...baseStyles,
                  zIndex: 9999,
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: 'var(--text-color)',
                  backgroundColor: state.isSelected
                    ? 'rgba(0,0,0,.10)'
                    : 'var(--color-background-app)',
                  ':hover': {
                    backgroundColor: state.isSelected ? 'rgba(0,0,0,.10)' : 'rgba(0,0,0,.03)',
                  },
                }),
              }}
              {...others}
              onChange={(e) => {
                field.onChange(e);
                onManualChange(e);
              }}
              menuPosition='fixed'
              components={{
                ...others.components,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => (
                  <div className='pr-2'>
                    {others.customIcon ? (
                      others.customIcon
                    ) : (
                      <DoubleChevronIcon size='12px' color='var(--color-subtitle)' />
                    )}
                  </div>
                ),
              }}
              options={options}
              className={`select-style ${errors[name] ? 'input-error' : ''}`}
            />
          )}
        />
      </div>
      {useError && errors[name] && (
        <div className='d-flex align-items-center error-content'>
          <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
          <p className='text-danger p-0 m-0'>{errors[name].message}</p>
        </div>
      )}
    </div>
  );
};

export default SelectWellet;
