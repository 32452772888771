import React from 'react';
import './badge.css';

const statusStyles = {
  // ---------- RED BADGES ----------
  ERROR: { className: 'badge-red border-red', text: 'Error' },
  TICKETSALE: { className: 'badge-red border-red', text: 'Cash sale' },
  CANCELLED: { className: 'badge-red border-red', text: 'Cancelled' },
  WITHDRAWAL: { className: 'badge-red border-red', text: 'Withdrawal' },
  REJECTED: { className: 'badge-red border-red', text: 'Rejected' },

  // ---------- GREEN BADGES ----------
  APPROVED: { className: 'badge-green border-green', text: 'Approved' },
  ENABLED: { className: 'badge-green border-green', text: 'Enabled' },
  COMMISSION: { className: 'badge-green border-green', text: 'Commission' },
  PUBLIC: { className: 'badge-green border-green', text: 'Public' },
  ACTIVE: { className: 'badge-green border-green', text: 'Active' },
  SALE: { className: 'badge-green border-green', text: 'Sale' },
  DEPOSIT: { className: 'badge-green border-green', text: 'Deposit' },
  SEND: { className: 'badge-green border-green', text: 'Send' },
  VALID: { className: 'badge-green border-green', text: 'Valid' },
  CHECKIN: { className: 'badge-green border-green', text: 'Check In' },
  YES: { className: 'badge-green border-green', text: 'Yes' },

  // ---------- BLUE BADGES ----------
  REFUND: { className: 'badge-blue border-blue', text: 'Refund' },
  REFUNDED: { className: 'badge-blue border-blue', text: 'Refund' },
  REFUND_SALE: { className: 'badge-blue border-blue', text: 'Refund Sale' },
  CONFIRMED: { className: 'badge-blue border-blue', text: 'Confirmed' },

  // ---------- ORANGE BADGES ----------
  PENDING: { className: 'badge-orange border-orange', text: 'Pending' },
  RESERVED: { className: 'badge-orange border-orange', text: 'Reserved' },
  PENDING_WITHDRAWAL: {
    className: 'badge-orange border-orange',
    text: 'Pending Withdrawal',
  },

  // ---------- GRAY BADGES ----------
  EXPIRED: { className: 'badge-gray border-gray', text: 'Expired' },
  DISABLED: { className: 'badge-gray border-gray', text: 'Disabled' },
  PRIVATE: { className: 'badge-gray border-gray', text: 'Private' },
  INACTIVE: { className: 'badge-gray border-gray', text: 'Inactive' },
  NO_SHOW: { className: 'badge-gray border-gray', text: 'No Show' },
  INVALID: { className: 'badge-gray border-gray', text: 'Invalid' },
  NO: { className: 'badge-gray border-gray', text: 'No' },

  // ---------- LEVEL AFFILIATION BADGE ----------
  SILVER: { className: 'badge-silver', text: 'Silver' },
  DIAMOND: { className: 'badge-diamond', text: 'Diamond' },
  'PLATINUM-PRO': { className: 'badge-platinum-pro', text: 'Platinum Pro' },
  PLATINUM: { className: 'badge-platinum', text: 'Platinum' },
  GOLD: { className: 'badge-gold', text: 'Gold' },

  //  ---------- BY DEFAULT ----------
  DEFAULT: { className: 'badge-default', text: 'Default' },
};

const Badge = ({ status = 'DEFAULT' }) => {
  const statusInfo = statusStyles[status.toUpperCase()] || statusStyles.DEFAULT;
  const { className, icon, text } = statusInfo;

  return (
    <div className={`badge  text-capitalize p-1 ${className}`}>
      {text}
      {icon ? <i className={`fas fa-${icon} ml-1 ctm-text-small`}></i> : <></>}
    </div>
  );
};

export default Badge;
