import React, { useRef } from 'react';
import Select from 'react-select';

export const SelectTiers = (props) => {
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const isFirstSelection = () => {
    return (!valueRef.current || valueRef.current.length === 0)
  };

  const isOptionSelected = (option) => {
    if (option) {
      return valueRef?.current?.some(({ value }) => value === option.value);
    }
  };

  const getOptions = () => (!!props.options?.length ? props.options : []);

  const getValue = () => {
    if (props.isFetching) {
      return [];
    } else if (!props?.options?.length) {
      return [];
    } else {
      return props.value;
    }
  };

  const getHigherLevelOptions = (selectedOption) => {
    const { level } = selectedOption;
    return props.options.filter((option) => option.level > level);
  };

  const onChange = (newValue, actionMeta) => {
    const { action, option } = actionMeta;

    if (action === 'select-option' && isFirstSelection()) {
      const higherLevelOptions = getHigherLevelOptions(option);
      const allValues = [option, ...higherLevelOptions];
      props.onChange(allValues, actionMeta);
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <Select
      isOptionSelected={isOptionSelected}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      isMulti
      {...props}
      value={getValue()}
      onChange={onChange}
      options={getOptions()}
    />
  );
};
