import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const Switcher = ({ onClick, defaultChecked, text, name, isRequired }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validationRules = {};

  if (isRequired) {
    validationRules.required = 'This field is required';
  }
  return (
    <Controller
      name={name}
      key={name}
      control={control}
      rules={validationRules}
      defaultValue=''
      render={({ field }) => (
        <>
          <div className='d-flex align-items-center'>
            <span className='text-sidebar-link mr-2 font-weight-bold'>{text}</span>
            <label className='switch'>
              <input type='checkbox' onClick={onClick} defaultChecked={field.value} {...field} />
              <span className='slider round'></span>
            </label>
          </div>
          {errors[name] && (
            <div className='d-flex align-items-center error-content'>
              <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
              <p className='text-danger p-0 m-0'>{errors[name].message}</p>
            </div>
          )}
        </>
      )}
    />
  );
};

export default Switcher;
