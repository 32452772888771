import React, { useContext } from 'react';
import { Badge, BreadCrumbs, Loading, Money, Subtitle } from '../../uiComponents/common';
import './sellerDetail.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { WelletContext } from '../../context/wellet/welletContext';
import { fetchsOperationsSeller } from '../../services/sellerServices';
import { useSelector } from 'react-redux';
import { DateFilter, Table, TableCell, TableRow } from '../../components';
import { useRedirectIfSuperAdmin } from '../../hooks/useRedirectIfSuperAdmin';

const SellerDetail = () => {
  const { id, orgId } = useParams();
  const user = useSelector((state) => state.app.selectedUser);
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrg = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const selectedDates = useSelector((state) => state.app.selectedDates);

  const sellerDetailQuery = useQuery(
    ['sellerOperations', orgId, id, selectedDates],
    () => fetchsOperationsSeller(welletContext, orgId, id, [], selectedDates),
    { refetchInterval: false, refetchOnWindowFocus: false },
  );

  useRedirectIfSuperAdmin(!sellerDetailQuery.isFetching, '/sellers');

  if (!user?.name) {
    navigate('/sellers');
  }

  const breadcrumb =
    organization.id === 83
      ? [
          {
            name: 'Sellers',
            path: '/sellers',
          },
          { name: selectedOrg.name, disabled: true },

          { name: user?.name ?? `Seller` },
        ]
      : [
          {
            name: 'Sellers',
            path: '/sellers',
          },

          { name: user?.name ?? 'Seller' },
        ];

  return (
    <>
      {sellerDetailQuery.isLoading || !user ? (
        <Loading />
      ) : (
        <>
          <BreadCrumbs crumbs={breadcrumb} />
          <Subtitle
            text={
              <div className='w-100 mt-1 d-flex justify-content-start align-items-end'>
                <div className='payment-title text-truncate mr-2'>{user?.name}</div>
                <div className='mt-1'>
                  <Badge status={sellerDetailQuery?.data?.data?.tier?.style} />
                </div>

                <Link
                  className=' ml-2 btn-custom-white p-1'
                  to={`/sellers/sellersDetails/${orgId}/${id}/profile`}
                >
                  View Profile
                </Link>
              </div>
            }
            endComponent={
              <div className='mt-1'>
                <DateFilter filterName={'Date'} />
              </div>
            }
          />
          <Table
            tableKey={`sellerDetail-${selectedOrg.id}-${selectedDates.from}-${selectedDates.to}`}
            data={sellerDetailQuery?.data?.data.summaryOperations || []}
            totalP={(sellerDetailQuery?.data?.data.summaryOperations || []).length}
            columns={['Operation', 'Amount', 'Type', 'Date', '']}
            renderRow={(seller, index) => (
              <TableRow
                key={index}
                onClick={() =>
                  navigate(`/sellers/sellersOperationDetails/${orgId}/${seller.orderId}?sellerId=${id}`)
                }
              >
                <TableCell>{seller.operationId}</TableCell>
                <TableCell className='ctm-text font-weight-bold' type={'primary'}>
                  <Money value={seller.amount} currencyCode={seller.currency} />
                </TableCell>
                <TableCell>
                  {' '}
                  <Badge status={seller.operationType} />
                </TableCell>
                <TableCell>
                  {format(new Date(seller.operationDate), 'MMM dd, yyyy, h:mm a')}
                </TableCell>
                <TableCell className={'w-sm-50 w-25'}></TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default SellerDetail;
