import React from 'react';

export default function SettingIcon({ size = '16px', color = '#fff', rotation = '0' }) {
  return (
    <svg
      id='Capa_2'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 135.67 132.45'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <circle
            cx='67.83'
            cy='66.23'
            r='24.65'
            transform='translate(-23.96 42.55) rotate(-29.85)'
            fill={color}
          />
          <path
            fill={color}
            d='m130.53,47.04l-11.4-1.93c-1.82-4.44-4.23-8.66-7.18-12.53l4.08-10.8c1.06-2.82-.07-5.99-2.67-7.51L90.41.93c-2.61-1.51-5.92-.92-7.84,1.4l-7.36,8.9c-4.83-.64-9.68-.64-14.45-.03l-7.33-8.95c-1.91-2.33-5.22-2.94-7.84-1.44l-23.02,13.21c-2.61,1.5-3.76,4.67-2.71,7.49l4.03,10.83c-1.45,1.89-2.8,3.89-4.03,6.01-1.23,2.12-2.3,4.28-3.22,6.48l-11.41,1.87c-2.97.49-5.16,3.05-5.17,6.07L0,79.32c0,3.01,2.16,5.59,5.13,6.09l11.4,1.93c1.82,4.44,4.23,8.66,7.18,12.53l-4.08,10.8c-1.06,2.82.07,5.99,2.67,7.51l22.95,13.33c2.61,1.51,5.92.92,7.84-1.4l7.36-8.9c4.83.64,9.68.64,14.45.03l7.33,8.95c1.91,2.33,5.22,2.94,7.84,1.44l23.02-13.21c2.61-1.5,3.76-4.67,2.71-7.49l-4.03-10.83c1.45-1.89,2.8-3.89,4.03-6.01,1.23-2.12,2.3-4.28,3.22-6.48l11.41-1.87c2.97-.49,5.16-3.05,5.17-6.07l.07-26.54c0-3.01-2.16-5.59-5.13-6.09Zm-7.25,27.38l-12.99,2.13-2.64,6.29c-.72,1.72-1.56,3.41-2.51,5.05s-2,3.21-3.14,4.69l-4.16,5.41,4.59,12.34-13.96,8.01-8.35-10.2-6.78.88c-3.73.48-7.51.48-11.23-.02l-6.77-.9-8.39,10.14-13.92-8.08,4.65-12.31-4.14-5.43c-2.28-2.99-4.15-6.27-5.58-9.75l-2.6-6.33-13-2.2.04-16.1,12.99-2.13,2.64-6.29c.72-1.72,1.56-3.41,2.51-5.05.95-1.64,2-3.21,3.14-4.69l4.16-5.41-4.59-12.34,13.96-8.01,8.35,10.2,6.78-.88c3.73-.48,7.51-.48,11.23.02l6.77.9,8.39-10.14,13.92,8.08-4.65,12.31,4.14,5.43c2.28,2.99,4.15,6.27,5.58,9.75l2.6,6.33,13,2.2-.04,16.1Z'
          />
        </g>
      </g>
    </svg>
  );
}
