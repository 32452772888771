import { NoPhotoIcon } from '../../uiComponents/icons';

const ProfileMainInfo = ({ image, name }) => {
  let screenWith = window.innerWidth;
  let borderType = screenWith > 575 ? 'border-table-right' : 'border-table-bottom';
  return (
    <div
      style={{ width: '170px' }}
      className={`col-12 col-sm-auto justify-content-sm-start  d-md-flex flex-column align-items-center  main-section py-3 ${borderType}`}
    >
      {image ? (
        <div className='text-center'>
        <img
          src={image}
          alt='Profile'
          style={{ height: '80px', width: '80px', objectFit: 'cover' }}
          className='rounded-circle img-fluid '
        />
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <NoPhotoIcon  />
        </div>
      )}
      <div className='d-flex flex-column justify-content-center align-items-center text-center mt-2'>
        <h1 style={{ color: 'var(--color-subtitle)' }}>{name}</h1>
      </div>
    </div>
  );
};

export default ProfileMainInfo;
