import { useContext } from 'react';
import SidebarContext from '../../context/sidebarContext';
import styles from './compnaySelector.module.css';
import { Dropdown } from '../../uiComponents/common';
import { WelletContext } from '../../context/wellet/welletContext';
import { fetchsOrganizations } from '../../services/appServices';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentOrg, setSelectedOrg } from '../../actions/appActions';
import { useQuery } from 'react-query';
import { FwPlusIcon } from '../../uiComponents/icons';
import { useNavigate } from 'react-router-dom';

const CompanySelector = ({
  icon,
  isCollapse,
  isCollapsable = true,
  isSelected = false,
  path,
  callback,
}) => {
  const { isMouseOver, isSidebarResized } = useContext(SidebarContext);
  const navigate = useNavigate();
  const welletContext = useContext(WelletContext);
  const dispacth = useDispatch();
  const currentOrganization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const roles = useSelector((state) => state.user.roles);
  const user = useSelector((state) => state.user.user.sid);

  const organizationsQuery = useQuery({
    queryKey: ['organizations', user],
    queryFn: () => fetchsOrganizations(welletContext),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const formattOrganizations = (organizations) => {
    if (organizations?.length > 0) {
      const organizationsFormatted = organizations?.map((org) => ({
        name: org.name,
        id: org.id,
      }));
      return organizationsFormatted;
    }
  };
  const selectOrganization = (organization) => {
    dispacth(setCurrentOrg(organization));
    if (organization.id !== selectedOrganization?.id) {
      dispacth(setSelectedOrg(organization));
    }
  };
  const createOrganization = () => {
    navigate('/organizations/new-organization');
  };
  const handleClick = (org) => {
    selectOrganization(org);
    callback && callback();
  };
  const selectorDropdown = (
    <div
      className={`${styles.selector} nav-item  font-weight-bold justify-content-${
        isMouseOver || !isSidebarResized ? 'between' : 'center'
      } `}
    >
      <div className=' w-100 d-flex align-items-center'>
        <div className={`btn-custom-white ${styles.btnCompanySelector}`}>{icon}</div>
        <div className='px-2 company-selector-dropdown text-sidebar-link'>
          {currentOrganization ? currentOrganization?.name : 'Seleccionar organización'}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`${styles.menuItemContainer} nav-item ${
        isSelected ? styles.menuSelected : ''
      } font-weight-bold justify-content-${
        isMouseOver || !isSidebarResized ? 'between' : 'center'
      } `}
    >
      <Dropdown
        id={'companySelector'}
        btn={selectorDropdown}
        isHover={false}
        customDropdwon={'max-height left-center'}
        isMouseOver={isSidebarResized ? isMouseOver : undefined}
        list={
          <>
            {organizationsQuery.isLoading ? (
              <div>Loading</div>
            ) : (
              <>
                {roles.includes('superadministrator') && (
                  <div className='dropdown-item text-general' onClick={createOrganization}>
                    <FwPlusIcon /> <span className='ml-1'>Create new organization</span>
                  </div>
                )}
                {organizationsQuery.data &&
                  formattOrganizations(organizationsQuery.data)?.map((org) => (
                    <div
                      className='dropdown-item text-general'
                      onClick={() => handleClick(org)}
                      key={org.id}
                    >
                      {org.name}
                    </div>
                  ))}
              </>
            )}
          </>
        }
      />
    </div>
  );
};

export default CompanySelector;
