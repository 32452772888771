import React from 'react';

const TextAlert = ({ text, icon, type }) => {
  const textAlertClass = `text-${type}`;
  return (
    <div className='d-flex align-items-center  error-content'>
      <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
      <p className={`${textAlertClass} p-0 m-0`}>{text}</p>
    </div>
  );
};

export default TextAlert;
