export const getAllSoldOut = async (
  welletContext,
  orgId = null,
  limit = null,
  skip = null,
  includeInactive = false,
) => {
  let url = 'api/SoldOut/all';
  if (orgId && orgId !== 1) {
    url += `/${orgId}`;
  }
  return await welletContext.apis.admin.post(url, {
    limit,
    skip,
    includeInactive,
  });
};
export const postSoldOut = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/SoldOut`, body);
};
export const putSoldOut = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/SoldOut`, body);
};
export const deleteSoldOut = async (welletContext, id) => {
  return await welletContext.apis.admin.delete(`api/SoldOut/${id}`);
};
