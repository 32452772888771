import axios from 'axios';

let _api = null;
let _hubUrl = null;

const api = () => _api;
export const hubUrl = () => _hubUrl;

export default api;

export const configureApi = (endpoint) => {
  _api = axios.create({
    baseURL: endpoint,
  });

  configureHubs(endpoint);
};

const configureHubs = (endpoint) => {
  _hubUrl = endpoint + '/signalr';
};
