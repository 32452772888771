import React, { useContext, useRef, useState } from 'react';
import { FwClockIcon } from '../../icons';
import Popup from 'reactjs-popup';
import { useMutation } from 'react-query';
import { postStatusBooking } from '../../../services/orderServices';
import './actionButton.css';

import { WelletContext } from '../../../context/wellet/welletContext';
import Toast from '../toast/toast';
import { usePagination } from '../../../context/paginationContext';

// Objeto de condiciones

const conditionMap = {
  isRequiresAction: (status) => status === 'APPROVED' || status === 'REFUNDED', // Primer filtro
  isBookingStatusNull: (bookingStatus) => bookingStatus === null, // Guion
  isConfirmationReservation: (bookingStatus) => bookingStatus === 'CONFIRMATION_RESERVATION', // Badge confirmed
  isCancelReservation: (bookingStatus) => bookingStatus === 'CANCELED', // Badge canceled
  isPendingReservationAndApprovedAndNotAutomatic: (
    bookingStatus,
    isAutomaticConfirmation,
    status,
  ) =>
    bookingStatus === 'PENDING_CONFIRMATION' && !isAutomaticConfirmation && status === 'APPROVED', //Boton de accionar
  isPendingReservationAndApprovedAndIsAutomatic: (bookingStatus, isAutomaticConfirmation, status) =>
    bookingStatus === 'PENDING_CONFIRMATION' && isAutomaticConfirmation && status === 'APPROVED', // Badge pending
  isBookingStatusPendingAndRefunded: (bookingStatus, status) =>
    bookingStatus === 'PENDING_CONFIRMATION' && status === 'REFUNDED', // Badge pending
};

const ActionButton = ({
  status,
  bookingStatus,
  callBack,
  notificationCallback,
  id,
  tableKey,
  isAutomaticConfirmation,
}) => {
  const [option, setOption] = useState(null);
  const [showToast, setShowToast] = useState({ show: false, type: '' });
  const { setPagination } = usePagination();
  const submenuRef = useRef(null);
  const welletContext = useContext(WelletContext);

  const handleCallBack = () => {
    callBack.refetch();
    // Fetch para recargar las notificaciones
    setPagination(tableKey, null);
    notificationCallback.refetch();
  };

  const postStatusBookingQuery = useMutation({
    mutationFn: (body) => {
      return postStatusBooking(welletContext, id, body);
    },
    onSuccess: () => {
      setShowToast({ show: true, type: 'SUCCESS' });
    },
    onError: (error) => {
      setShowToast({ show: true, type: 'ERROR' });
      console.log(error);
    },
  });
  const postDataStatus = () => {
    return postStatusBookingQuery.mutate(option);
  };
  const closeSubmenu = () => {
    submenuRef.current.close();
  };
  const renderActionButton = () => {
    if (conditionMap.isRequiresAction(status)) {
      switch (true) {
        case conditionMap.isPendingReservationAndApprovedAndNotAutomatic(
          bookingStatus,
          isAutomaticConfirmation,
          status,
        ):
          return (
            <div className='contentActionButton'>
              <span>Requires action</span>
              <Popup
                trigger={
                  <div className={`actionButton`}>
                    <FwClockIcon />
                  </div>
                }
                position='bottom right'
                closeOnDocumentClick
                mouseLeaveDelay={0}
                mouseEnterDelay={0}
                nested
                arrow={false}
                on={'hover'}
                ref={submenuRef}
              >
                {(close) => (
                  <Popup
                    trigger={
                      <div className='submenu-action-btn'>
                        <div
                          className='submenu-action-item text-light'
                          onClick={() => {
                            setOption(true);
                          }}
                        >
                          Confirm
                        </div>
                        <div
                          className='submenu-action-item text-light'
                          onClick={() => {
                            setOption(false);
                          }}
                        >
                          Cancel
                        </div>
                      </div>
                    }
                    closeOnDocumentClick
                    modal
                  >
                    {(close) => (
                      <div className={`d-block`}>
                        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable '>
                          <div className='modal-content text-light'>
                            <div className='modal-header'>
                              <h5 className='modal-title'>Confirmation Action</h5>
                            </div>
                            <div className='modal-body'>
                              Are you sure you want to {option ? 'confirm' : 'cancel'} this sale?
                            </div>
                            <div className='modal-footer'>
                              <button
                                type='button'
                                onClick={() => {
                                  closeSubmenu();
                                  close();
                                }}
                                className='btn-custom-white font-weight-bold'
                                data-dismiss='modal'
                              >
                                NO
                              </button>
                              <button
                                type={'button'}
                                onClick={() => {
                                  closeSubmenu();
                                  postDataStatus();
                                  close();
                                }}
                                className='btn-custom-primary ml-2 '
                              >
                                YES
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                )}
              </Popup>
            </div>
          );
        case conditionMap.isConfirmationReservation(bookingStatus):
          return <div className='confirmBadge'>Confirmed</div>;
        case conditionMap.isCancelReservation(bookingStatus):
          return <div className='cancelBadge'>Cancelled</div>;
        case conditionMap.isBookingStatusPendingAndRefunded(bookingStatus, status):
          return <div className='confirmBadge'>Pending</div>;
        case conditionMap.isBookingStatusNull(bookingStatus):
          return <span>-</span>;
        case conditionMap.isPendingReservationAndApprovedAndIsAutomatic(
          bookingStatus,
          isAutomaticConfirmation,
          status,
        ):
          return <div className='confirmBadge'>Pending</div>;
        default:
          return <span>No contemplado</span>;
      }
    } else {
      return <span>-</span>;
    }
  };

  return (
    <>
      {showToast.show && (
        <Toast
          message={` ${
            showToast.type === 'SUCCESS'
              ? `Reservation  ${option ? 'confirmed' : 'canceled'} successfully `
              : `There was an error when   ${option ? 'confirming ' : 'canceling '} the reservation`
          }`}
          type={showToast.type}
          onCallback={handleCallBack}
        />
      )}
      <div
        className='text-light d-flex align-items-center justify-content-around'
        style={{ maxWidth: '140px' }}
        onClick={(e) => e.stopPropagation()}
      >
        {renderActionButton()}
      </div>
    </>
  );
};

export default ActionButton;