import React from 'react';

function TableRow({ children, onMouseEnter, onMouseLeave, onClick, index, className }) {
  return (
    <tr
      className={`table-row ${index % 2 === 0 ? 'even' : 'odd'} ${className ?? ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </tr>
  );
}

export default TableRow;
