import React from 'react';

export default function CheckInIcon({ size = '18px', color = 'currentColor', rotation = 0 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0  16.8 17.1'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <path
          fill={color}
          d='M14,12.9c-0.4-0.1-0.7,0.1-0.8,0.5c-0.3,1.5-1.7,2.5-3.2,2.4c-1.7-0.1-2.9-1.6-2.7-3.3c0.1-1.7,1.6-2.9,3.3-2.7
		c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.7,0,0.9-0.3c0.2-0.3,0-0.7-0.3-0.9c-0.5-0.3-1-0.4-1.6-0.5C8.3,8.2,6.2,10,6,12.4
		c-0.2,2.4,1.6,4.5,3.9,4.7c0.1,0,0.3,0,0.4,0c2,0,3.8-1.4,4.2-3.4C14.6,13.3,14.4,13,14,12.9z'
        />
        <path
          fill={color}
          d='M4.8,11.8c0.2-1.5,1.1-2.7,2.3-3.6c1.4-1,3.2-1.3,4.8-0.8c0.2,0.1,0.4,0,0.6-0.1c0.2-0.1,0.3-0.3,0.3-0.5V1.9
		c0-0.4-0.3-0.7-0.7-0.7h-1.2v1.1c0,0.5-0.4,0.9-0.9,0.9H3.8c-0.5,0-0.9-0.4-0.9-0.9V1.2H1.6c-0.4,0-0.7,0.3-0.7,0.7v12.2
		c0,0.4,0.3,0.7,0.7,0.7h2.6c0.2,0,0.4-0.1,0.5-0.2c0.1-0.2,0.2-0.4,0.1-0.6C4.7,13.2,4.7,12.5,4.8,11.8z M4.1,8.3H3.3
		C3,8.3,2.8,8.1,2.8,7.8c0-0.3,0.2-0.5,0.5-0.5h0.8c0.3,0,0.5,0.2,0.5,0.5C4.6,8.1,4.4,8.3,4.1,8.3z M3.3,5.8C3,5.8,2.8,5.6,2.8,5.3
		C2.8,5,3,4.8,3.3,4.8h3.2C6.8,4.8,7,5,7,5.3c0,0.3-0.2,0.5-0.5,0.5H3.3z'
        />
        <path
          fill={color}
          d='M15.7,8.9c-0.3-0.3-0.7-0.2-0.9,0l-4.1,4.3l-1.3-1.5c-0.2-0.3-0.7-0.3-0.9-0.1c-0.3,0.2-0.3,0.7-0.1,0.9l1.8,2.1
		c0.1,0.1,0.3,0.2,0.5,0.2c0,0,0,0,0,0c0.2,0,0.4-0.1,0.5-0.2l4.7-4.9C16,9.5,16,9.1,15.7,8.9z'
        />
        <path
          fill={color}
          d='M4.3,2.6h5.1c0.4,0,0.7-0.3,0.7-0.7V0.7C10,0.3,9.7,0,9.4,0H4.3C4,0,3.7,0.3,3.7,0.7v1.2C3.7,2.3,4,2.6,4.3,2.6z'
        />
      </g>
    </svg>
  );
}
