import React from 'react';
import Button from '../button/button';

const FormButton = ({ handleRedirect, clearForm, mutation, reff }) => {
  return (
    <div className='row align-items-center justify-content-center justify-content-sm-start px-2 py-3'>
      <Button
        size={'small'}
        type='submit'
        text={`${mutation.isLoading && reff.current === 'send' ? 'Sending...' : 'Send'}`}
        onClick={() => {
          handleRedirect(false, 'send');
        }}
        className={'btn-custom-primary'}
      />
      <Button
        size={'small'}
        type='button'
        className={'btn-custom-white  ml-2'}
        text={`Cancel`}
        onClick={clearForm}
      />
      <Button
        size={'small'}
        className={'btn-custom-primary ml-2'}
        type='submit'
        text={`${
          mutation.isLoading && reff.current === 'sendAndClose' ? 'Sending...' : 'Send and Close'
        }`}
        onClick={() => {
          handleRedirect(true, 'sendAndClose');
        }}
      />
    </div>
  );
};

export default FormButton;
