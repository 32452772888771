import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import TimePicker from 'react-time-picker';
import { CloseIcon } from '../../icons';

const InputClock = ({
  name,
  className,
  label,
  icon,
  maxTime,
  usecMaxTime = false,
  required = true,
  validateFunction,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isSpecificMaxTimeProvided = usecMaxTime && maxTime === undefined;
  const parseMaxTime = maxTime === '00:00' ? '23:59' : maxTime;

  return (
    <div className={className}>
      {label ? <label style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
      <Controller
        name={name}
        control={control}
        defaultValue=''
        rules={
           {
            required: required,
            validate: (value) => {
              if (validateFunction) {
                return validateFunction(value);
              }
              return true;
            },
          }
        }
        render={({ field }) => (
          <>
            <div className='d-flex'>
              {icon ? (
                <span
                  className='input-group-text px-2 input-icon'
                  style={{ color: 'var(--color-subtitle)' }}
                >
                  {icon}
                </span>
              ) : (
                <></>
              )}
              <TimePicker
                className={'input-style'}
                disableClock={true}
                value={field.value}
                clearIcon={<CloseIcon color='var(--color-subtitle)' />}
                maxTime={parseMaxTime}
                disabled={isSpecificMaxTimeProvided}
                {...field}
                style={{ visibility: 'visible', position: 'static', zIndex: 'auto' }}
              />
            </div>
            {errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InputClock;
