import React, { useRef, useState } from 'react';
import InputBase from './inputBase';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../button/button';
import { CirclePlus, FwEdit, FwSearchIcon, FwTrashIcon, FwUploadIcon } from '../../icons';
import ButtonGallery from '../buttonGallery/buttonGallery';
import Modal from '../modal/modal';
import IconButton from '../iconButton/iconButton';

const InputMedia = ({
  id,
  name,
  placeholder,
  label,
  showDrag = true,
  className = '',
  initialValue = null,
  isRequired = false,
  maxSize = 1048576,
  idModal = null,
  titleModal = null,
}) => {
  const validationRules = {};
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useFormContext();
  const [modal, setModal] = useState(false);
  const [logo, setLogo] = useState(watch(name)?.base64);
  const fileInputRef = useRef(null);

  const isMobile = window.screen.width < 764;

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleRemoveFile = () => {
    fileInputRef.current.value = '';
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      clearErrors(name);
      previewFile(file)
        .then((base64String) => {
          setValue(name, { base64: base64String, name: file.name });
        })
        .catch((error) => {
          console.error('Error al convertir la imagen:', error);
        });
    }
  };
  const previewFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = /\.(gif|png|jpe?g|webp)$/i;
      if (allowedExtensions.test(file.name)) {
        if (file.size <= maxSize) {
          handleFileInputChange(e);
        } else {
          setError(name, {
            type: 'custom',
            message: 'The file is too large. Please select a file smaller.',
          });
        }
      } else {
        setError(name, {
          type: 'custom',
          message: 'Invalid file type. Please select a .gif, .png, .jpg, .jpeg, or .webp file.',
        });
      }
    }
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  const deleteImage = () => {
    setValue(name, '');
    setLogo(null);
  };

  if (isRequired) {
    validationRules.required = 'This field is required.';
  }

  return (
    <div className={`input-file-container ${className}`}>
      <label style={{ color: 'var(--color-text)' }}>{label}</label>
      <Controller
        control={control}
        name={name}
        rules={validationRules}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <Modal
                textCancel={'Cancel'}
                textSubmit={'Save'}
                idModal={idModal}
                title={titleModal}
                onClose={toggleModal}
                open={modal}
                handleSubmit={() => {
                  watch(name)?.base64 ? setLogo(watch(name).base64) : setLogo(null);
                  toggleModal();
                }}
              >
                <div className='p-2'>
                  {showDrag ? (
                    <div
                      className='file-dropzone mb-2'
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        e.preventDefault();
                        const droppedFile = e.dataTransfer.files[0];
                        if (droppedFile) {
                          clearErrors(name);
                          previewFile(droppedFile)
                            .then((base64String) => {
                              setValue(name, { base64: base64String, name: droppedFile.name });
                            })
                            .catch((error) => {
                              console.error('Error al convertir la imagen:', error);
                            });
                        }
                      }}
                    >
                      {value && value?.base64 ? (
                        <img src={value.base64} alt={value.name} className='preview-image' />
                      ) : (
                        <p>{isMobile ? 'Add image' : 'Drag & Drop File'}</p>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <input
                    type='file'
                    id={id}
                    name={name}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelection}
                  />
                  <InputBase
                    errors={errors}
                    icon={<FwUploadIcon />}
                    type='text'
                    name={name}
                    value={value?.name || ''}
                    label={''}
                    readOnly={true}
                    placeholder={placeholder}
                    disabled
                    endComponent={
                      <div className='file-btn d-flex'>
                        {value ? (
                          <>
                            <Button
                              type='button'
                              size={'small'}
                              className={'remove-file'}
                              text={<FwTrashIcon />}
                              onClick={() => {
                                handleRemoveFile();
                                deleteImage();
                                onChange(null);
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        <Button
                          type='button'
                          size={'small'}
                          className={'send-file'}
                          text={<FwSearchIcon color='' />}
                          onClick={handleFileInputClick}
                        />
                      </div>
                    }
                  />
                  {errors[name] && (
                    <div className='d-flex align-items-center error-content'>
                      <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                      <p className='text-danger p-0 m-0'>{errors[name].message}</p>
                    </div>
                  )}
                </div>
              </Modal>
              {logo ? (
                <div className='w-100 p-0 m-1 gallery-item'>
                  <div className='card-gallery'>
                    <div className='card-img-actions p-1'>
                      <div className='image-container' style={{ height: '100%' }}>
                        <img src={logo} alt='banner' className='card-img img-fluid' />
                      </div>
                    </div>
                    <div className='image-overlay d-flex justify-content-end'>
                      <IconButton
                        onClick={toggleModal}
                        classNameIcon={'hoverIconConatiner'}
                        icon={<FwEdit color='var(--hover)' size={20} classNameIcon={'hoverIcon'} />}
                      />
                      <IconButton
                        onClick={deleteImage}
                        classNameIcon={'hoverIconConatiner'}
                        icon={
                          <FwTrashIcon color='var(--hover)' size={20} classNameIcon={'hoverIcon'} />
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <ButtonGallery
                  icon={<CirclePlus size='2rem' color='var(--color-text-light)' />}
                  text={'Add Image'}
                  onClick={toggleModal}
                  width='100%'
                  msg='Max file upload 1MB'
                  stylesError={errors[name]}
                />
              )}
              {errors[name] && (
                <div className='d-flex align-items-center error-content'>
                  <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
                  <p className='text-danger p-0 m-0'>{errors[name].message}</p>
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
};
export default InputMedia;
