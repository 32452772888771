import React from 'react';
import './modal.css';
import Popup from 'reactjs-popup';

const Modal = ({
  title,
  children,
  open = false,
  onClose,
  handleSubmit,
  handleClose,
  idModal,
  textCancel,
  textSubmit,
  btnType = 'button',
  customButtons = null,
  ...others
}) => {
  return (
    <Popup className='custom-popup' modal open={open} {...others} closeOnEscape={false}>
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{title}</h5>
          </div>
          <div className='modal-body p-0'>{children}</div>
          <div className='modal-footer'>
            {!customButtons && (
              <>
                <button
                  type='button'
                  onClick={onClose}
                  className='btn-general btn-custom-white'
                  data-dismiss='modal'
                >
                  {textCancel}
                </button>
                <button
                  type={btnType}
                  onClick={handleSubmit}
                  className='btn-general btn-custom-primary ml-2'
                >
                  {textSubmit}
                </button>
              </>
            )}
            {customButtons && customButtons}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default Modal;
