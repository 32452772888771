import React from 'react';

export default function ErrorCircleIcon({
  color = 'currentColor',
  fontSize = '16px',
  isSelected = false,
}) {
  return (
    <>
      <i
        className={`fas fa-times-circle ml-1 ctm-text-small`}
        style={{ color, fontSize: fontSize }}
      ></i>
    </>
  );
}
