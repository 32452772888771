import { createContext, useReducer, useState } from 'react';

export const SidebarContext = createContext();

const SidebarContextProvider = ({ children }) => {
  const [isSidebarOpen, toggleIsSidebarOpen] = useReducer((isOpen) => !isOpen, false);
  const [isSidebarResized, toggleIsSidebarResized] = useReducer((isResized) => !isResized, false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleToggleSidebarResized = () => {
    setIsMouseOver(false); // Establecer el estado del mouse en false al activar toggleSidebarResized
    toggleIsSidebarResized();
  };

  return (
    <SidebarContext.Provider
      value={{
        toggleIsSidebarOpen,
        isSidebarOpen,
        isSidebarResized,
        toggleIsSidebarResized,
        isMouseOver,
        setIsMouseOver,
        handleToggleSidebarResized,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export { SidebarContextProvider };
export default SidebarContext;
