import React, { useContext } from 'react';
import { BreadCrumbs, Subtitle } from '../../uiComponents/common';
import { OrganizationForm } from '../../components';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  getTimeZones,
  fetchOwners,
  getSocialNetworks,
  getContactInformation,
  getCountries,
  fetchTenants,
} from '../../services';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const OrganizationParentForm = () => {
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.currentOrganization);
  const location = useLocation();
  const isEditingOrganization = location.pathname.split('/')[2] === 'edit-organization';
  const organizationCurrentId = location.pathname.split('/')[3];
  const newOrgBreadCumbs = [
    { name: 'Organization', path: '/organizations' },
    { name: 'New Organization' },
  ];
  const editOrgBreadCumbs = [
    { name: 'Organization', path: '/organizations' },
    { name: 'Edit Organization' },
  ];

  //fetchTenants

  const timezoneQuery = useQuery({
    queryKey: ['timezones'],
    queryFn: () => getTimeZones(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const ownersQuery = useQuery({
    queryKey: ['owners'],
    queryFn: () => fetchOwners(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const countriesQuery = useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountries(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const socailNetworkQuery = useQuery({
    queryKey: ['social'],
    queryFn: () => getSocialNetworks(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const contactInformationQuery = useQuery({
    queryKey: ['contact'],
    queryFn: () => getContactInformation(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <BreadCrumbs crumbs={isEditingOrganization ? editOrgBreadCumbs : newOrgBreadCumbs} />
      <Subtitle
        text={
          <h1 className='m-0 ctm-title font-weight-bold'>
            {isEditingOrganization ? 'Edit Organization' : 'New Organization'}
          </h1>
        }
      />

      <OrganizationForm
        timezoneQuery={timezoneQuery}
        organization={organization}
        ownersQuery={ownersQuery}
        countriesQuery={countriesQuery}
        organizationCurrentId={organizationCurrentId}
        isEditingOrganization={isEditingOrganization}
        socialNetwork={socailNetworkQuery}
        contactInformation={contactInformationQuery}
      />
    </>
  );
};

export default OrganizationParentForm;
