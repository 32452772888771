const ProfileSecondary = ({ name, email, role, phone }) => {
  const rowProfile = (title, text) => (
    <div className={`row height-input-content p-0 px-2`}>
      <div
        className={`col-auto p-0 pr-2 font-weight-semibold`}
        style={{ color: 'var(--color-text)' }}
      >
        {title}
      </div>
      <div className='col p-0' style={{ color: 'var(--color-text)' }}>
        {text}
      </div>
    </div>
  );
  return (
    <div className='col p-0'>
      {rowProfile('Name:', name)}
      {rowProfile('Email:', email)}
      {rowProfile('Roles:', role)}
      {rowProfile('Phone:', phone ?? '-')}
    </div>
  );
};

export default ProfileSecondary;
