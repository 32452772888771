import React from 'react';

export default function FilledArrowDown({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M452 3814 c-84 -42 -132 -121 -132 -216 1 -75 27 -125 117 -220 44
-46 477 -506 962 -1023 486 -517 902 -957 924 -979 23 -21 65 -50 92 -65 44
-23 62 -26 145 -26 83 0 101 3 145 26 28 15 69 44 92 65 22 22 438 462 924
979 485 517 918 977 962 1023 90 95 116 145 117 220 0 96 -51 180 -134 218
l-51 24 -2056 0 -2055 0 -52 -26z'
        />
      </g>
    </svg>
  );
}
