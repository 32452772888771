import React from 'react';

const CustomCard = ({ title, subtitle, text }) => {
  return (
    <div
      className='card  text-white w-100 mr-2'
      style={{ maxWidth: '200px', width: '100%', backgroundColor: 'var(--bg-primary)' }}
    >
      <div className='card-body'>
        <div className='d-flex'>
          <h3 className='font-weight-semibold mb-0 text-white'>{title} </h3>
          <div className='list-icons ml-auto'></div>
        </div>

        <div className='text-white'>
          {/* <Money value={amount} currencyCode={'MXN'} currencyClass={'text-white'} /> */}
          {subtitle}
          <div className='font-size-sm opacity-75'>{text}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
