import React, { useEffect, useState } from 'react';
import { Select } from '../selects';
import InputText from './inputText';
import Button from '../button/button';
import IconButton from '../iconButton/iconButton';
import { CloseIcon, FwAddIcon, FwCheckIcon, FwEdit, FwTrashIcon } from '../../icons';
import './inputs.css';
import InputPhone from './inputPhone';
import InputEmail from './inputEmail';

const InputList = ({
  className,
  watch,
  setValue,
  unregister,
  resetField,
  formState: { errors },
  setError,
  clearErrors,
  initalStae = [],
  data = [],
  labelSelect = '',
  labelInput = '',
  nameSelect = null,
  nameInput = null,
  baseNameInput = null,
  placeholder = null,
  isPhone = false,
}) => {
  const [options, setOptions] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (initalStae.length !== 0) {
      // To Do: hacer mas generico el componente para que no dependamos de una key en especifico (ver de usar el data)
      const newState = initalStae.map((i) => ({
        value: i.value,
        label: i.socialNetwork || i.organizationContact,
        info: i.socialNetwork || i.organizationContact,
      }));
      newState.forEach((i) => setValue(baseNameInput + i.label, i.value));
      setOptions(newState);
    } else {
      setOptions([]);
    }
  }, []);

  const currentInputText = watch(nameInput);
  const currentOptionSelected = watch(nameSelect);

  const formatLabel = (label) => {
    const words = label.split('_');
    const formattedLabel = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    return formattedLabel;
  };

  const handleSeleccionChange = (selectedOption) => {
    setValue(nameSelect, selectedOption);
    clearErrors(nameSelect);
  };
  const addOption = (newOption) => {
    const existingSocialNetwork = options.find((op) => op?.info === newOption?.value);

    if (existingSocialNetwork) {
      setError(nameSelect, {
        type: 'string',
        message: `You already added  ${newOption.label}`,
      });
      return;
    }

    const selfCurrent = { ...newOption, info: newOption.value };

    if (currentInputText) {
      selfCurrent.value = currentInputText;
      setOptions([...options, selfCurrent]);
      resetField(nameInput);
      setValue(nameSelect, null);
      setValue(baseNameInput + selfCurrent.info, currentInputText);
      clearErrors(nameSelect);
    }
  };
  const startEdit = (idx) => {
    setEditingIndex(idx);
  };
  const saveEdit = (social) => {
    const currentInput = watch(baseNameInput + social);

    const newOptions = [...options];
    newOptions[editingIndex] = {
      ...newOptions[editingIndex],
      social: currentInput,
    };
    if (currentInput === options[editingIndex].social) {
      setEditingIndex(null);
    } else {
      setOptions(newOptions);
      setEditingIndex(null);
    }
  };
  const cancelEdit = () => {
    if (editingIndex !== null) {
      const editedOption = options[editingIndex];

      // Revertir cualquier cambio realizado durante la edición
      setValue(baseNameInput + editedOption.info, editedOption.value);

      // Limpiar errores asociados al input que estaba en edición
      clearErrors(baseNameInput + editedOption.info);

      // Finalizar la edición
      setEditingIndex(null);
    }
  };
  const deleteOption = (index) => {
    const currentState = options;
    const currentInput = currentState[index];
    unregister(baseNameInput + currentInput.info);
    const newOptions = currentState.filter((_, i) => i !== index);
    setOptions(newOptions);
  };
  const dataFormatted = data.map((d) => ({ ...d, label: formatLabel(d.label) }));

  return (
    <div className={`${className} row p-0 m-0`}>
      <div className='row col-12 align-items-sm-end my-3'>
        <Select
          isSearchable={false}
          options={dataFormatted}
          label={labelSelect}
          className={'col-12 col-sm-4 m-sm-0'}
          name={nameSelect}
          useError={false}
          onManualChange={(option) => {
            handleSeleccionChange(option);
            clearErrors(nameSelect);
          }}
        />
        {isPhone && currentOptionSelected?.value?.includes('PHONE') ? (
          <InputPhone
            name={nameInput}
            label={labelInput}
            className={'col-12 col-sm-6 m-sm-0'}
            placeholder={placeholder}
            isDisabled={!currentOptionSelected}
            defaultCountry={currentOptionSelected.value.split('_').pop()}
            isRequired={false}
          />
        ) : isPhone && currentOptionSelected?.value?.includes('EMAIL') ? (
          <InputEmail
            name={nameInput}
            label={labelInput}
            className={'col-12 col-sm-6 m-sm-0'}
            placeholder={'Enter email'}
            isDisabled={!currentOptionSelected}
          />
        ) : (
          <InputText
            name={nameInput}
            label={labelInput}
            className={'col-12 col-sm-6 m-sm-0'}
            placeholder={placeholder}
            isDisabled={!currentOptionSelected}
          />
        )}
        <div className='col-12 col-sm-2 p-sm-0 d-flex justify-content-end'>
          <Button
            className={'d-flex justify-content-center align-items-center '}
            size={'small'}
            type='button'
            text={'Add'}
            onClick={() => addOption(currentOptionSelected)}
            disabled={!currentInputText}
            icon
            typeIcon={<FwAddIcon size='20pxpx' />}
          />
        </div>
      </div>

      {errors[nameSelect] && (
        <div className='d-flex align-items-center error-content'>
          <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
          <p className='text-danger p-0 m-0'>{errors[nameSelect].message}</p>
        </div>
      )}
      {/* <div className='col-12 align-items-end row'> */}
      {options && options.length !== 0
        ? options.map((op, idx) => {
            return (
              <div className='col-12 align-items-start align-items-sm-end row mt-2'>
                <div className='form-group col-12 col-sm-4 m-sm-0' key={idx}>
                  <div className='input-style d-flex justify-content-start align-items-center'>
                    {formatLabel(op?.label)}
                  </div>
                </div>

                {isPhone && op.info.includes('PHONE') ? (
                  <InputPhone
                    name={baseNameInput + op.info}
                    initialValue={op.value}
                    className={'col-8 col-sm-6 m-sm-0'}
                    isDisabled={editingIndex !== idx}
                    defaultCountry={op.info.split('_').pop()}
                    isRequired={false}
                  />
                ) : isPhone && op.info.includes('EMAIL') ? (
                  <InputEmail
                    name={baseNameInput + op.info}
                    className={'col-8 col-sm-6 m-sm-0'}
                    isDisabled={editingIndex !== idx}
                  />
                ) : (
                  <InputText
                    name={baseNameInput + op.info}
                    className={'col-8 col-sm-6 m-sm-0'}
                    isDisabled={editingIndex !== idx}
                  />
                )}

                {editingIndex === idx ? (
                  <div className='col-4 col-sm-2 d-flex justify-content-between align-items-start'>
                    <IconButton onClick={() => saveEdit(op.info)} icon={<FwCheckIcon />} />
                    <IconButton onClick={cancelEdit} icon={<CloseIcon size='18px' />} />
                  </div>
                ) : (
                  <div className='col-4 col-sm-2 d-flex justify-content-between align-items-start'>
                    <IconButton onClick={() => startEdit(idx)} icon={<FwEdit />} />
                    <IconButton onClick={() => deleteOption(idx)} icon={<FwTrashIcon />} />
                  </div>
                )}
              </div>
            );
          })
        : null}
      {/* </div> */}
    </div>
  );
};

export default InputList;
