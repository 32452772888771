import React from 'react';
import { CardRowInfo } from '../../uiComponents/common';
import { format } from 'date-fns';

const ProfileExtraInfo = ({ user, registered = false }) => {
  return (
    <>
      <div className='col-auto mb-2 px-0 pr-2'>
        <CardRowInfo
          title={'Last Login'}
          value={format(new Date(user?.data?.data?.lastLogin), 'MMM dd, yyyy, h:mm a')}
        />
      </div>
      <div className='col-12 col-md-auto mb-2 px-0 pr-2'>
        <CardRowInfo
          title={'Registration Date'}
          value={format(new Date(user?.data?.data?.registrationDate), 'MMM dd, yyyy, h:mm a')}
        />
      </div>
      {user?.data?.data?.role.some((r) => r === 'salesteamlead' || r === 'associate') ? (
        <>
          <div className='col-12 col-md-auto mb-2 px-0 pr-2'>
            <CardRowInfo
              title={'Channel'}
              value={user?.data?.data?.associateDetails?.channel ?? '-'}
            />
          </div>
          <div className='col-12 col-md-auto mb-2 px-0 pr-2'>
            <CardRowInfo
              title={'Mercado Pago'}
              value={user?.data?.data?.associateDetails?.mercadoPago ?? '-'}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {registered ? (
        <div className='col-12 col-md-auto mb-2 px-0 pr-2'>
          <CardRowInfo
            title={'Registered By'}
            value={
              registered.data?.data
                ? `${registered?.data?.data.firstName} ${registered?.data?.data.lastName}`
                : '-'
            }
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileExtraInfo;
