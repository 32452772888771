import React from 'react';

const CardRowDetails = ({ title, value }) => {
  return (
    <div className={`row m-0 mt-1 `} key={value + title}>
      <div className={`info-description px-0 col-4 text-light`}>{title}</div>
      <div className='col info-value px-0 text-general'>{value}</div>
    </div>
  );
};

export default CardRowDetails;
