import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  InputDescription,
  InputNumber,
  InputText,
  Select,
  Subtitle,
  Tabs,
  TextEditor,
  TextAlert,
  Toast,
  ButtonGallery,
  IconButton,
  InputDate,
  InputClock,
  InputMedia,
  FormButton,
} from '../../../uiComponents/common';
import './activityForm.css';
import GalleryForm from '../gallery/galleryForm';
import { WelletContext } from '../../../context/wellet/welletContext';
import { postActivity, getVenues, getTimeZones } from '../../../services/activityServices';
import { useSelector } from 'react-redux';
import useUser from '../../../hooks/useUser';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  FwCalendarIcon,
  FwEdit,
  FwClockIcon,
  FwEditIcon,
  FwGlobeIcon,
  FwPersonIcon,
  FwTrashIcon,
  FwYoutubeIcon,
  CirclePlus,
  TimeZoneIcon,
  FwLocationIcon,
} from '../../../uiComponents/icons';
import FwMasksIcon from '../../../uiComponents/icons/font-awesome/fwMasksIcon';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';

import EditGalleryForm from '../gallery/editGalleryForm';
import { formatTimeToMinutes } from '../../../helpers/format';

const ActivityForm = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const { isSuperAdmin } = useUser();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const btnReff = useRef(null);

  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [isRedirect, setIsRedirect] = useState(null);

  // Functions Api Query
  const timezoneQuery = useQuery({
    queryKey: ['timezones'],
    queryFn: () => getTimeZones(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const venuesQuery = useQuery({
    queryKey: ['venues', organization.id],
    queryFn: () => getVenues(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const postActivityMutation = useMutation({
    mutationFn: (body) => {
      return postActivity(welletContext, organization.id, body);
    },
    onSuccess: (data) => {
      setShowToast(true);
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const venues = venuesQuery?.data?.venues?.map((v) => ({ label: v.name, value: v.id }));
  const timeZoneFormated = timezoneQuery?.data?.map((obj) => ({
    label: `${obj.name} (UTC ${obj.shift})`,
    value: obj.id,
  }));

  const validateFields = {
    name: {
      rule: (values) => {
        if (!values.name && !values.shortDescription) {
          return true;
        } else if (values.name && values.name.trim() !== '') {
          if (values.name.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Name" field is required and must have a minimum of 3 characters.',
    },
    shortDescription: {
      rule: (values) => {
        if (!values.name && !values.shortDescription) {
          return true;
        } else if (values.shortDescription && values.shortDescription.trim() !== '') {
          if (values.shortDescription.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg:
        'the "Short Description" field is required and must have a minimum of 3 characters.',
    },
  };

  const { languages, lngErrors, currentLng, changeLanguage } = useLanguageSwitcher(
    validateFields,
    methods,
  );

  // Functions
  const onSubmit = (data) => {
    const initialLangShows = languages.map((lng) => {
      return {
        language: lng.shortForm,
        name: data[`name_${lng.shortForm}`],
        description: data[`description_${lng.shortForm}`],
        shortDescription: data[`shortDescription_${lng.shortForm}`],
      };
    });
    const handelLangShowsEmptys = removeObjectsWithEmptyFields(initialLangShows, [
      'name',
      'description',
      'shortDescription',
    ]);

    if (Object.keys(lngErrors).length === 0 || requiredErros) {
      const body = {
        id: 0,
        name: handelLangShowsEmptys.some((s) => s.language === data.defaultLanguage.value)
          ? handelLangShowsEmptys.find((s) => s.language === data.defaultLanguage.value)?.name
          : handelLangShowsEmptys.find((l) => !!l.name).name,
        organizationId: organization.id,
        zoneTime: data.timezone.value,
        priority: data.priority,
        imageUrl: data.image.base64,
        isActive: true,
        capacity: data.capacity,
        shortName: data.shortName,
        venueId: data.venue.value,
        defaultLanguage: data.defaultLanguage.value,
        bannerImageUrl: data.banner?.base64,
        videoUrl: data.video,
        location: data.location,
        showImages: images,
        langShows: handelLangShowsEmptys,
        saleStartLocalDate: data.startSaleDate
          ? `${data.startSaleDate}T${data.saleStartTime}`
          : null,
        startLocalDate: data.activityDate ? `${data.activityDate}T${data.activityTime}` : null,
        endLocalDate: data.activityEndDate
          ? `${data.activityEndDate}T${data.activityEndTime}`
          : null,
        publishLocalDate: data.publishDate ? `${data.publishDate}T${data.publishTime}` : null,
      };
      if (isSuperAdmin) {
        body['utcOffset'] = data.timeZoneChange ? data.timeZoneChange : null;
      }
      postActivityMutation.mutate(body);
    }
  };
  const handleRemoveImg = (idx) => {
    const imagesCopy = images.filter((i, index) => index !== idx);
    setImages(imagesCopy);
  };
  const handleEditImg = (i, idx) => {
    setOpen(false);
    setSelectedImage({ data: i, id: idx });
    setEditOpen(true);
  };
  const clearForm = () => {
    navigate(`/activities`);
    methods.reset();
  };
  const handleToastCallback = () => {
    if (isRedirect) {
      methods.reset();
      navigate(`/activities`, { replace: true });
      setShowToast(false);
    } else {
      setShowToast(false);
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    btnReff.current = string;
  };

  // Constants
  const bannerImage = methods.watch('banner');
  const mainImage = methods.watch('image');
  const logoImage = methods.watch('logo');
  const requiredErros = !bannerImage || !mainImage || !logoImage;

  const publishDate = methods.watch('publishDate');
  const saleStartDate = methods.watch('startSaleDate');

  return (
    <>
      {showToast && (
        <Toast
          message='Activity created successfully.'
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      <FormProvider {...methods}>
        <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='row mt-3'>
            <Select
              options={languages.map((language) => ({
                label: language.title,
                value: language.shortForm,
              }))}
              label={'Select a Default Language'}
              className={'col-12 col-sm-6 col-xl-4 m-0'}
              icon={<FwGlobeIcon />}
              name={'defaultLanguage'}
              isRequired={true}
            />
          </div>

          <Subtitle text={<h3>Main Data</h3>} />
          <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
          {Object.keys(lngErrors).length !== 0 && (
            <TextAlert text={lngErrors.name} type={'danger'} />
          )}
          <div className='row mt-3'>
            <InputText
              name={'name_' + currentLng}
              label={'Name'}
              className={'col-12 col-sm-6 col-xl-4'}
              placeholder={'Enter activity name'}
              icon={<FwMasksIcon />}
              isRequired={true}
            />
            <InputDescription
              className='col-12 col-sm-6 col-xl-4'
              name={'shortDescription_' + currentLng}
              label={'Short Description'}
              placeholder={'Enter short description'}
              icon={<FwEditIcon />}
              isRequired={true}
            />
          </div>

          <div className='row mt-3'>
            <TextEditor
              label='Description'
              className='col-12 col-xl-8'
              name={`description_${currentLng}`}
              customErrors={lngErrors}
            />
          </div>

          <Subtitle text={<h3>General Data</h3>} />
          <div className='row mt-3'>
            <InputDate
              placeholder={'Enter Publish Date'}
              label={'Publish Date'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<FwCalendarIcon />}
              name={'publishDate'}
              fromDate={new Date()}
              onDaySelect={() => {
                methods.setValue('startSaleDate', '');
                methods.setValue('saleStartTime', '');
                methods.setValue('activityDate', '');
                methods.setValue('activityTime', '');
              }}
              isRequired={true}
            />
            <InputClock
              label={'Publish Start Time'}
              icon={<FwClockIcon />}
              className={'col-12 col-sm-6 col-xl-4'}
              name={`publishTime`}
              required
            />
          </div>
          <div className='row mt-3'>
            <InputDate
              placeholder={'Enter Sale Start Date'}
              label={'Sale Start Date'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<FwCalendarIcon />}
              name={'startSaleDate'}
              fromDate={new Date(publishDate + 'T00:00:00')}
              onDaySelect={() => {
                methods.setValue('activityDate', '');
                methods.setValue('activityTime', '');
                methods.setValue('activityEndDate', '');
                methods.setValue('activityEndTime', '');
              }}
              isRequired={true}
              isDisabled={!publishDate}
            />
            <InputClock
              label={'Sale Start Time'}
              icon={<FwClockIcon />}
              className={'col-12 col-sm-6 col-xl-4'}
              name={`saleStartTime`}
              validateFunction={(value) => {
                const publishTime = methods.watch('publishTime');
                const publishDate = methods.watch('publishDate');
                const saleTime = formatTimeToMinutes(value);
                const publishTimeMinutes = formatTimeToMinutes(publishTime);
                if (publishDate === saleStartDate && saleTime < publishTimeMinutes) {
                  return 'It must be greater than or equal to the publish time.';
                }
                return true;
              }}
              required
            />
          </div>
          <div className='row mt-3'>
            <InputDate
              placeholder={'Enter Activity Date'}
              label={'Activity Date'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<FwCalendarIcon />}
              name={'activityDate'}
              onDaySelect={() => {
                methods.setValue('activityEndDate', '');
                methods.setValue('activityEndTime', '');
              }}
              fromDate={new Date(saleStartDate + 'T00:00:00')}
              isDisabled={!saleStartDate}
              isRequired={true}
            />
            <InputClock
              label={'Activity Time'}
              icon={<FwClockIcon />}
              className={'col-12 col-sm-6 col-xl-4'}
              name={`activityTime`}
              validateFunction={(value) => {
                const saleTime = methods.watch('saleStartTime');
                const activityTime = formatTimeToMinutes(value);
                const activityDate = methods.watch('activityDate');
                const saleTimeMinutes = formatTimeToMinutes(saleTime);

                if (saleStartDate === activityDate && activityTime <= saleTimeMinutes) {
                  return 'It must be greater than the sale time.';
                }
                return true;
              }}
              required
            />
          </div>
          <div className='row mt-3'>
            <InputDate
              placeholder={'Enter Activity End Date'}
              label={'Activity End Date'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<FwCalendarIcon />}
              name={'activityEndDate'}
              fromDate={new Date(methods.watch('activityDate') + 'T00:00:00')}
              isDisabled={!methods.watch('activityDate')}
              isRequired={true}
            />
            <InputClock
              label={'Activity End Time'}
              icon={<FwClockIcon />}
              className={'col-12 col-sm-6 col-xl-4'}
              name={`activityEndTime`}
              validateFunction={(value) => {
                const activityTime = methods.watch('activityTime');
                const time = formatTimeToMinutes(value);
                const activityDate = methods.watch('activityDate');
                const activityEndDate = methods.watch('activityEndDate');
                const activityTimeMinutes = formatTimeToMinutes(activityTime);

                if (activityDate === activityEndDate && time <= activityTimeMinutes) {
                  return 'It must be greater than the activity time.';
                }
                return true;
              }}
              required
            />
          </div>
          <div className='row mt-3'>
            <Select
              options={timeZoneFormated}
              label={'Timezone'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<TimeZoneIcon />}
              name={'timezone'}
              isRequired={true}
            />
            <InputNumber
              label={'Capacity'}
              name={'capacity'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<FwPersonIcon />}
              placeholder={'0'}
              isRequired={true}
            />
          </div>
          <div className='row mt-3'>
            <InputText
              name={'location'}
              label={'Location'}
              className={'col-12 col-sm-6 col-xl-4'}
              placeholder={'Enter a location'}
              icon={<FwLocationIcon />}
              isRequired={true}
            />
            <Select
              options={venues}
              label={'Venue'}
              className={'col-12 col-sm-6 col-xl-4'}
              icon={<FwGlobeIcon />}
              name={'venue'}
              isRequired={true}
            />
          </div>

          <div className='row mt-3'>
            <InputText
              name={'shortName'}
              label={'Short Name'}
              className={'col-12 col-sm-6 col-xl-4'}
              placeholder={'Enter activity short name'}
              icon={<FwMasksIcon />}
              isRequired={true}
            />
            {isSuperAdmin && (
              <InputNumber
                label={'Timezone Change'}
                className={'col-12 col-sm-6 col-xl-4'}
                name={'timeZoneChange'}
                icon={<FwClockIcon />}
                placeholder={'0'}
                customValidation={{}}
              />
            )}
          </div>
          <Subtitle text={<h3>Multimedia</h3>} />
          <div className='row mt-3'>
            <InputMedia
              id={'mainImage'}
              name={'image'}
              className='col-12 col-sm-6 col-xl-4 my-1'
              placeholder={'Upload Image'}
              label={'Main Image'}
              isRequired={true}
              maxSize={409600}
              titleModal='Add Main Image'
            />
            <InputMedia
              id={'bannerImage'}
              name={'banner'}
              className='col-12 col-sm-6 col-xl-4 my-1'
              placeholder={'Upload Banner'}
              label={'Banner Image'}
              isRequired={true}
              maxSize={409600}
              titleModal='Add Banner Image'
            />
          </div>

          <div className='row mt-3'>
            <InputText
              name={'video'}
              className={'mb-2 col-12 col-sm-6 col-xl-4'}
              label={'Video'}
              placeholder={'Video URL'}
              icon={<FwYoutubeIcon />}
              isRequired={false}
            />
          </div>

          <Subtitle text={<h3>Gallery</h3>} />
          <div className='row mt-3'>
            <div className='row p-0 m-0 col-12 col-xl-8 d-flex justify-content-center justify-content-sm-start'>
              {images.length > 0
                ? images.map((i, idx) => (
                    <div className='col-12 col-sm-6 col-xl-4 my-1' key={i.src}>
                      <div className='card-gallery'>
                        <div className='card-img-actions p-1'>
                          <div
                            className='image-container'
                            style={
                              i.langShowImage[0]?.title ? { height: '150px' } : { height: '100%' }
                            }
                          >
                            <img
                              src={i.imageUrl}
                              alt={i.langShowImage[0]?.title}
                              className='card-img img-fluid'
                            />
                          </div>
                          <h6 className='m-0 mt-2 font-weight-bold text-truncate'>
                            {i.langShowImage[0]?.title}
                          </h6>
                        </div>
                        <div className='image-overlay d-flex justify-content-end'>
                          <IconButton
                            onClick={() => handleEditImg(i, idx)}
                            classNameIcon={'hoverIconConatiner'}
                            icon={
                              <FwEdit color='var(--hover)' size={20} classNameIcon={'hoverIcon'} />
                            }
                          />
                          <IconButton
                            onClick={() => handleRemoveImg(idx)}
                            classNameIcon={'hoverIconConatiner'}
                            icon={
                              <FwTrashIcon
                                color='var(--hover)'
                                size={20}
                                classNameIcon={'hoverIcon'}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))
                : null}
              {/* Aca van las imagnes */}
              <div className='col-12 col-sm-6 col-xl-4 my-1'>
                <ButtonGallery
                  icon={<CirclePlus size='2rem' color='var(--color-text-light)' />}
                  text={'Add Image'}
                  onClick={() => {
                    setOpen(true);
                    setSelectedImage(null);
                  }}
                  width='100%'
                  msg='Max file upload 1MB'
                />
              </div>
            </div>
          </div>
          <FormButton
            mutation={postActivityMutation}
            clearForm={clearForm}
            handleRedirect={handleRedirect}
            reff={btnReff}
          />
        </form>
      </FormProvider>
      <GalleryForm open={open} setOpen={setOpen} images={images} setImages={setImages} />
      <EditGalleryForm
        open={editOpen}
        setOpen={setEditOpen}
        images={images}
        setImages={setImages}
        editForm={selectedImage}
      />

      {postActivityMutation?.error &&
        (postActivityMutation?.error?.response?.data?.message === 'INVALID_IMAGE_FORMAT' ? (
          <Alert
            title={'Oops, something went wrong'}
            text={'Error loading images. Allowed formats: jpg, jpeg, gif'}
            type={'error'}
          />
        ) : (
          <Alert
            title={'Oops, something went wrong'}
            text={postActivityMutation?.error?.response?.data?.message}
            type={'error'}
          />
        ))}
    </>
  );
};

export default ActivityForm;
