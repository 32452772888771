import React from 'react';

import { BreadCrumbs } from '../../uiComponents/common';
import { EditTicketForm } from '../../components/forms';

const EditTicket = ({ isDuplicate = false }) => {
  return (
    <>
      <BreadCrumbs
        crumbs={[
          { name: 'Tickets', path: '/tickets' },
          { name: isDuplicate ? 'New Ticket' : 'Edit Ticket' },
        ]}
      />

      <EditTicketForm />
    </>
  );
};

export default EditTicket;
