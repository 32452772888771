import React from 'react';
import './loading.css';
const Loading = ({ text }) => {
  return (
    <div className='loaderDiv text-center'>
      <div className='spinner-border text-spinner' role='status'>
        <span className='sr-only' />
      </div>
      <span className='mt-1 d-block'>{text}</span>
    </div>
  );
};

export default Loading;
