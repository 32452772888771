export default function CirclePlus({ size = '16px', color = '#ccc' }) {
  return (
    <svg
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 162.24 162.24'
      width={size}
      height={size}
    >
      <g data-name='Capa 1'>
        <g>
          <path
            fill={color}
            d='m81.12,0C36.39,0,0,36.39,0,81.12s36.39,81.12,81.12,81.12,81.12-36.39,81.12-81.12S125.85,0,81.12,0Zm0,156.24c-41.42,0-75.12-33.7-75.12-75.12S39.7,6,81.12,6s75.12,33.7,75.12,75.12-33.7,75.12-75.12,75.12Z'
          />
          <path
            fill={color}
            d='m125.41,78.12h-41.29v-41.29c0-1.66-1.34-3-3-3s-3,1.34-3,3v41.29h-41.29c-1.66,0-3,1.34-3,3s1.34,3,3,3h41.29v41.29c0,1.66,1.34,3,3,3s3-1.34,3-3v-41.29h41.29c1.66,0,3-1.34,3-3s-1.34-3-3-3Z'
          />
        </g>
      </g>
    </svg>
  );
}
