import {
  FwMasksIcon,
  PaymentIcon,
  SaleIcon,
  WalletIcon,
  UserIcon,
  SoldsOutIcon,
  CompanysIcon,
  FwLocationIcon,
  UsersIcon,
  FwPromotionIcon,
  DoorsIcons,
  CheckInIcon,
} from '../../uiComponents/icons';

export const SidebarData = [
  {
    title: 'Payments',
    path: '/',
    subpath: '/paymentDetails',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <PaymentIcon fontSize={'15px'} />,
    disabled: false,
  },
  {
    title: 'Check In',
    path: '/check-in',
    subpath: '/check-in-details',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <CheckInIcon fontSize={'15px'} />,
    disabled: false,
  },
];

export const HistoryData = [
  {
    title: 'Wallets',
    path: '/wallets',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    subpath: '/operationDetails',
    icon: <WalletIcon fontSize={'15px'} />,
    disabled: false,
  },
  {
    title: 'Orders',
    path: '/orders',
    subpath: '/orders/',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <SaleIcon fontSize={'15px'} />,
    disabled: false,
  },
  {
    title: 'All Check Ins',
    path: '/checkin-history',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <CheckInIcon fontSize={'15px'} />,
    disabled: false,
  },
  {
    title: 'Sellers',
    path: '/sellers',
    subpath: '/sellers',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <UserIcon fontSize={'15px'} />,
    disabled: false,
  },
];

export const SetupData = [
  {
    title: 'Venues',
    path: '/venues',
    subpath: '/venues',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <FwLocationIcon fontSize={'15px'} />,
    disabled: false,
  },
  {
    title: 'Activity',
    path: '/activities',
    subpath: '/activities',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <FwMasksIcon fontSize={'15px'} />,
    disabled: false,
  },
  {
    title: 'Gates',
    path: '/gates',
    subpath: '/gates',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <DoorsIcons size={'15px'} />,
    disabled: false,
  },
  {
    title: 'Tickets',
    path: '/tickets',
    subpath: '/tickets/',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <SaleIcon fontSize={'15px'} />,
    disabled: false,
  },
  {
    title: 'Sold Out',
    path: '/sold-out',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <SoldsOutIcon size={'15px'} />,
    disabled: false,
  },
  {
    title: 'Promotions',
    path: '/promotions',
    subpath: 'promotion',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <FwPromotionIcon size={'15px'} />,
    disabled: false,
  },
  {
    title: 'Users',
    path: '/users',
    subpath: '/users/',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <UsersIcon size='20px' />,
    disabled: false,
  },
  {
    title: 'Organizations',
    path: '/organizations',
    subpath: 'organization',
    rol: ['superadministrator', 'admin', 'associate'],
    organization: false,
    icon: <CompanysIcon fontSize={'15px'} />,
    disabled: false,
  },
];
