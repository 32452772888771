import React from 'react';
import { BreadCrumbs, Subtitle } from '../../uiComponents/common';
import TicketForm from '../../components/forms/tickets/ticketsForm';
import { AdminAlert } from '../../components';

const NewTicket = () => {
  return (
    <>
      <BreadCrumbs crumbs={[{ name: 'Tickets', path: '/tickets' }, { name: 'New Ticket' }]} />
      <Subtitle text={<h1 className='m-0 ctm-title font-weight-bold'>New Ticket</h1>} />
      <AdminAlert text={'Please select a company to create a ticket.'}>
        <TicketForm />
      </AdminAlert>
    </>
  );
};

export default NewTicket;
