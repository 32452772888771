import React from 'react';

export default function FwListIcon({
  color = 'currentColor',
  fontSize = '16px',
}) {
  return (
    <>
      <i className='fas fa-list' style={{ color, fontSize: fontSize }}></i>
    </>
  );
}
