import React from 'react';

export default function ZipCodeIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19.52 18.82'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path fill={color} d='m16.45,14.23h-1v.95h1c.26,0,.46-.21.46-.47s-.2-.48-.46-.48Z' />
          <path
            fill={color}
            d='m17.93,12.09H6.88c-.23,0-.46.06-.65.18-.42.22-.7.66-.7,1.17v4.03c0,.74.61,1.35,1.35,1.35h11.28c.75,0,1.36-.61,1.36-1.35v-4.03c0-.74-.84-1.35-1.59-1.35Zm-6.98,4.68v.98h-3.84l.57-.85.65-.95,1.1-1.65.03-.04h-2.29v-.98h4.29l-.53.79-1.16,1.74-.65.96h1.83Zm2.51.99h-1.16v-4.49h1.16v4.49Zm2.99-1.6h-1v1.62h-.99v-4.52h1.99c.8,0,1.45.65,1.45,1.45s-.65,1.45-1.45,1.45Z'
          />
          <path
            fill={color}
            d='m5.65,2.46c-.07.05-.14.11-.2.19-.39.43-.78.85-1.16,1.28-.09.11-.17.2-.26.3-.17.19-.25.39-.11.63.16.27.38.56.71.5.85-.16,1.64.11,2.44.29.37.08.72.23,1.05.43.67.38.92,1.01.79,1.79-.1.58-.36,1.03-.92,1.27-.14.07-.26.15-.4.23-.54.29-.85.74-1,1.35-.05.21-.09.41-.12.61.18-.05.38-.07.58-.07h7.35c.19-.74.22-1.49.11-2.26-.06-.41-.23-.82-.39-1.2-.15-.35-.45-.52-.83-.53-1.02-.03-1.89-1.03-1.84-2.05.02-.32.17-.6.38-.83.21-.25.43-.46.65-.71.25-.3.28-.52.13-.79-.1-.16-.23-.2-.4-.14-.19.08-.38.16-.58.23-.43.13-.72-.01-.87-.44-.11-.34-.2-.68.06-1.01.1-.12.17-.14.31-.09,1.16.43,2.15,1.09,2.98,1.99,2,2.16,2.52,5.19,1.5,7.83h1.05c.29-.85.44-1.76.44-2.71C17.1,3.83,13.27,0,8.55,0S0,3.83,0,8.55c0,3.34,1.91,6.23,4.7,7.63v-2.57c0-.31-.02-.74.09-1.02-.22-.38-.27-.67-.3-1.15-.03-.4-.11-.79-.14-1.19-.02-.24-.13-.43-.31-.56-.18-.14-.37-.27-.57-.4-.48-.33-.63-.8-.48-1.35.1-.31.25-.62.4-.9.3-.6.2-.86-.45-1.01-.39-.08-.8-.11-1.21-.17-.07-.01-.15-.01-.25-.02.4-1.06,1-1.95,1.8-2.72,1.17-1.12,2.55-1.8,4.15-2.05.02.03.03.05.04.07-.13.11-.26.23-.41.32-.47.34-.94.66-1.41,1.01Z'
          />
        </g>
      </g>
    </svg>
  );
}
