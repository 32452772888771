import React from 'react';
import './toggleToken.css';
import { useFormContext, Controller } from 'react-hook-form';
const ToggleToken = ({ activeText, inactiveText, name, label, isRequired, onClick = () => {} }) => {
  const { control } = useFormContext();
  const validationRules = {};

  if (isRequired) {
    validationRules.required = 'This field is required';
  }
  return (
    <Controller
      name={name}
      key={name}
      control={control}
      rules={validationRules}
      defaultValue=''
      render={({ field }) => (
        <div>
          {label ? <label style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
          <div className='d-flex status-container'>
            <div className={`status-active ${field.value ? 'active' : 'inactive'}`} onClick={() => { field.onChange(true); onClick(true); } }>
              {activeText}
            </div>
            <div className={`status-inactive ${field.value ? 'inactive' : 'active'}`} onClick={() => { field.onChange(false); onClick(false); }}>
              {inactiveText}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default ToggleToken;
