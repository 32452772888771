import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ErrorAlert, InputText, Modal, Select, Toast } from '../../../uiComponents/common';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { SaleIcon, FwMasksIcon, FwEditIcon } from '../../../uiComponents/icons';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import { getAllTickets, postSoldOut, fetchActivities } from '../../../services';

const SoldOutForm = ({ open, onClose, onSuccess }) => {
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const methods = useForm();
  const lastClickedButton = useRef(null);

  const [isRedirect, setIsRedirect] = useState(null);

  const currentActivity = methods.watch('activity');

  const activitiesQuery = useQuery({
    queryKey: ['activities', organization.id],
    queryFn: () => fetchActivities(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const ticketsQuery = useQuery({
    queryKey: ['tickets', currentActivity?.value, organization.id],
    queryFn: () =>
      getAllTickets(
        welletContext,
        [1, 2, 3, 4, 5, 6],
        null,
        null,
        false,
        currentActivity?.value,
        organization.id,
      ),
    onSuccess: () => methods.setValue('tickets', null),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!currentActivity?.value,
  });
  const postSoldOutMutation = useMutation({
    mutationFn: (body) => {
      return postSoldOut(welletContext, body);
    },
    onError: (error) => {
      console.log('Desde on error', error);
    },
  });

  const activities = activitiesQuery?.data?.map((v) => ({ label: v.name, value: v.id }));
  const tickets = ticketsQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));

  const onSubmit = (data) => {
    const body = {
      productIds: data.tickets.map((t) => t.value),

      description: data.description,
    };

    postSoldOutMutation.mutate(body);
  };
  const handleCloseForm = () => {
    methods.reset();
    postSoldOutMutation.reset();
    onClose();
  };
  const handleToastCallback = () => {
    if (isRedirect) {
      onSuccess();
      handleCloseForm();
    } else {
      onSuccess();
      postSoldOutMutation.reset();
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  const customButtons = (
    <div className='row align-items-center justify-content-center justify-content-sm-start py-1'>
      <Button
        size={'small'}
        type='button'
        className={'mt-1'}
        text={`${
          postSoldOutMutation.isLoading && lastClickedButton.current === 'send'
            ? 'Sending...'
            : 'Send'
        }`}
        onClick={() => {
          methods.handleSubmit(onSubmit)();
          handleRedirect(false, 'send');
        }}
      />
      <Button
        size={'small'}
        type='button'
        className={'btn-custom-white mt-1 ml-1'}
        text={`Cancel`}
        onClick={handleCloseForm}
      />
      <Button
        size={'small'}
        className={'mt-1 ml-1'}
        type='button'
        text={`${
          postSoldOutMutation.isLoading && lastClickedButton.current === 'sendAndClose'
            ? 'Sending...'
            : 'Send and Close'
        }`}
        onClick={() => {
          methods.handleSubmit(onSubmit)();
          handleRedirect(true, 'sendAndClose');
        }}
      />
    </div>
  );

  return (
    <>
      {postSoldOutMutation.isSuccess && (
        <Toast
          message='Sold Out created successfully.'
          type='SUCCESS'
          onCallback={() => {
            handleToastCallback();
          }}
        />
      )}

      <FormProvider {...methods}>
        <Modal title={'Sold Out'} open={open} nested={true} customButtons={customButtons}>
          <form key={1}>
            <div className='mt-2 row mx-0'>
              <Select
                options={activities}
                label={'Activity'}
                className={'col-12 col-md-6'}
                icon={<FwMasksIcon />}
                name={'activity'}
                isRequired={true}
              />
              <Select
                options={tickets}
                label={'Tickets'}
                className={'col-12 col-md-6'}
                icon={<SaleIcon />}
                name={'tickets'}
                type='all'
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
                isRequired={true}
                isFetching={ticketsQuery.isFetching}
              />
            </div>

            <div className='row m-0'>
              <InputText
                label={'Description'}
                className={'col-12'}
                icon={<FwEditIcon />}
                placeholder={'Enter Description'}
                name={'description'}
                isRequired={true}
              />
            </div>
            {postSoldOutMutation?.error &&
            !(postSoldOutMutation?.error?.response?.data instanceof Object) ? (
              <ErrorAlert text={postSoldOutMutation?.error?.response?.data} />
            ) : (
              <></>
            )}
          </form>
        </Modal>
      </FormProvider>
    </>
  );
};

export default SoldOutForm;
