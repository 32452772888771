import { useContext } from 'react';
import { patchActivityStatus } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';

export const usePatchActivityStatusMutation= (id) => {
  const welletContext = useContext(WelletContext);

  return useMutation(['activity-status'], (status) => patchActivityStatus(welletContext, id, status));
};
