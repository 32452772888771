import React from 'react';

export default function CountryIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 17.1 17.1'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path
            fill={color}
            d='m8.55,0C3.83,0,0,3.83,0,8.55s3.83,8.55,8.55,8.55,8.55-3.83,8.55-8.55S13.27,0,8.55,0Zm5.95,9c-.05-.42-.22-.82-.38-1.21-.15-.34-.45-.51-.83-.52-1.01-.03-1.89-1.04-1.84-2.05.02-.32.17-.6.37-.83.21-.25.44-.46.65-.7.26-.3.28-.52.13-.79-.1-.16-.22-.2-.39-.14-.19.07-.38.16-.58.22-.44.13-.73-.01-.88-.44-.11-.34-.2-.68.06-1.01.1-.12.17-.14.31-.09,1.15.43,2.15,1.09,2.98,1.99,2.42,2.63,2.68,6.54.63,9.46-.05.09-.12.17-.18.25-.36-.27-.51-.82-.34-1.29.35-.92.43-1.87.29-2.84ZM2.94,6.02c-.4-.09-.8-.12-1.21-.17-.08-.01-.15-.01-.26-.02.41-1.06,1-1.95,1.8-2.72,1.16-1.12,2.55-1.79,4.14-2.05.02.03.03.05.04.07-.13.11-.26.22-.41.32-.47.34-.94.66-1.41,1-.07.05-.14.12-.2.19-.4.43-.78.85-1.16,1.28-.09.11-.17.2-.26.3-.17.19-.25.4-.11.63.16.27.37.57.71.5.85-.16,1.64.11,2.45.29.36.09.72.23,1.05.43.67.38.92,1.01.79,1.79-.1.58-.36,1.03-.92,1.27-.14.06-.27.15-.41.22-.53.3-.84.75-.99,1.36-.19.78-.26,1.55-.14,2.35.03.27.01.54-.02.81-.02.22-.15.27-.35.16-.05-.03-.11-.06-.15-.1-.1-.09-.2-.17-.3-.27-.65-.61-1.08-1.33-1.14-2.25-.03-.4-.11-.79-.14-1.19-.02-.25-.13-.43-.31-.57s-.37-.27-.57-.4c-.48-.33-.63-.8-.48-1.35.1-.31.25-.62.4-.91.3-.6.2-.85-.45-1Zm3.76,9.3c.09-.06.2-.12.3-.13.83-.11,1.62-.33,2.39-.65.8-.34,1.63-.41,2.48-.2.42.11.66.26.82.51-1.21,1.17-4.84,1.68-6.47.89.16-.14.31-.29.48-.42Z'
          />
        </g>
      </g>
    </svg>
  );
}
