import './header.css';
import SearchBar from '../searchBar/searchBar';
import {
  MenuBurger,
  FwUserIcon,
  SettingIcon,
  ChangePasswordIcon,
  LogOutIcon,
} from '../../uiComponents/icons';
import { useContext, useState } from 'react';
import SidebarContext from '../../context/sidebarContext';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions';
import { Dropdown } from '../../uiComponents/common';
import SwitcherTheme from '../switcherTheme/switcherTheme';
import { IconButton } from '../../uiComponents/common';
import { Link } from 'react-router-dom';

// To do: usar componentes Icon buttons para la botonera

export default function Header() {
  const [showInput, setShowInput] = useState(false);
  const { toggleIsSidebarOpen } = useContext(SidebarContext);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(logout());
  };
  return (
    <div className='navbar header-container py-2 '>
      <div className='d-flex flex-1 d-lg-none align-items-center  text-sidebar-link'>
        <div className='cursor-pointer mr-3' onClick={toggleIsSidebarOpen}>
          <MenuBurger color='currentColor' size='18px' />
        </div>
        {/* <div className='cursor-pointer'>
          <IconButton
            icon={<i className='fas fa-search text-general'></i>}
            onClick={() => setShowInput(!showInput)}
          />
        </div> */}
      </div>

      <div className='d-flex flex-row  justify-content-end align-items-center flex-1 flex-lg-0 order-1 order-lg-2 text-sidebar-link'>
        <SwitcherTheme />
        <div onClick={() => {}} className='mx-1 d-sm-block d-none'>
          <IconButton icon={<i className='fas fa-bell  ctm-subtitle'></i>} />
        </div>
        <div className='mx-1 d-sm-block'>
          <Dropdown
            id={'logoutDropdown'}
            isHover={false}
            customDropdwon={''}
            btn={<IconButton icon={<SettingIcon color='currentColor' size='20px' />} />}
            list={
              <div>
                <Link className='dropdown-item justify-content-start' to={'/profile'}>
                  <FwUserIcon color='currentColor' size='20px' />
                  <span className='ml-2'>Profile</span>
                </Link>
                <Link className='dropdown-item justify-content-start' to={'/change-password'}>
                  <ChangePasswordIcon color='currentColor' size='20px' />
                  <span className='ml-2'>Change password</span>
                </Link>
                <div className='dropdown-item justify-content-start' onClick={handleSignOut}>
                  <LogOutIcon color='currentColor' size='20px' />
                  <span className='ml-2'>Logout</span>
                </div>
              </div>
            }
          />
        </div>
      </div>

      <div
        className={`flex-lg-1 pt-2 pt-lg-0 navbar-collapse mx-lg-3 order-2 order-lg-1 collapse ${
          showInput ? 'show' : ''
        }`}
      >
        {/* <SearchBar /> */}
      </div>
    </div>
  );
}
