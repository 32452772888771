import React from 'react';

export default function ChannelIcon({ size = '18px', color = 'currentColor', rotation = 0 }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M662 4945 c-35 -8 -102 -33 -150 -56 -210 -101 -352 -331 -352 -569
0 -362 313 -659 671 -637 87 6 215 41 265 74 l26 16 156 -156 c120 -120 155
-160 147 -171 -150 -209 -227 -377 -277 -604 -31 -140 -31 -424 0 -564 50
-227 127 -395 277 -604 8 -11 -27 -51 -147 -171 l-156 -156 -26 16 c-14 9 -60
28 -102 43 -461 154 -921 -261 -819 -740 72 -341 416 -564 759 -491 302 64
521 350 503 656 -6 87 -41 215 -74 265 l-16 26 156 156 c121 121 160 155 171
147 214 -152 402 -236 631 -281 123 -25 376 -25 500 -1 206 40 409 123 568
233 43 29 82 54 85 54 4 0 76 -69 161 -154 151 -151 153 -154 138 -177 -32
-49 -68 -182 -74 -268 -7 -113 14 -212 69 -321 84 -167 251 -296 434 -335 450
-95 854 309 759 759 -64 303 -349 521 -656 503 -86 -6 -219 -42 -268 -74 -23
-15 -26 -13 -180 141 -119 120 -154 159 -146 170 214 298 300 552 300 886 0
334 -86 588 -300 886 -8 11 27 51 147 171 l156 156 26 -16 c50 -33 178 -68
265 -74 247 -15 493 128 600 349 94 194 94 382 0 576 -101 210 -331 352 -569
352 -362 0 -659 -313 -637 -671 6 -87 41 -215 74 -265 l16 -26 -156 -156
c-120 -120 -160 -155 -171 -147 -209 150 -377 227 -604 277 -140 31 -424 31
-564 0 -227 -50 -395 -127 -604 -277 -11 -8 -51 27 -171 147 l-156 156 16 26
c33 50 68 178 74 265 7 113 -14 212 -69 321 -128 256 -423 396 -706 335z m269
-334 c69 -33 112 -74 149 -143 31 -56 34 -71 34 -143 1 -99 -25 -162 -92 -228
-69 -68 -129 -92 -227 -92 -72 1 -87 4 -143 35 -69 36 -109 79 -144 150 -32
66 -31 196 2 265 42 86 123 152 215 176 49 13 158 2 206 -20z m3520 0 c69 -33
112 -74 150 -143 31 -58 34 -70 34 -148 0 -78 -3 -90 -34 -148 -38 -70 -70
-101 -145 -140 -70 -36 -187 -38 -262 -4 -64 29 -136 102 -166 167 -33 71 -32
190 2 260 42 85 123 152 215 176 49 13 158 2 206 -20z m-1716 -946 c491 -79
864 -462 935 -960 29 -210 -7 -431 -105 -630 -50 -104 -121 -214 -137 -215 -4
0 -17 19 -29 43 -70 138 -195 266 -367 373 -2 2 17 32 42 67 146 205 165 441
55 667 -33 68 -58 101 -123 165 -123 122 -244 175 -416 183 -127 5 -197 -10
-320 -70 -166 -80 -297 -251 -336 -438 -37 -176 12 -382 126 -525 16 -21 30
-41 30 -45 0 -4 -29 -27 -65 -50 -115 -75 -218 -189 -312 -348 l-20 -34 -38
54 c-132 182 -215 435 -215 652 0 568 424 1049 985 1119 75 10 228 6 310 -8z
m-40 -656 c237 -112 246 -441 15 -568 -60 -33 -71 -36 -150 -36 -78 0 -91 3
-148 34 -249 135 -220 488 48 585 63 23 170 16 235 -15z m-7 -945 c203 -41
383 -188 458 -374 30 -75 35 -68 -88 -131 -310 -157 -674 -157 -993 0 -131 64
-126 54 -79 152 90 186 246 311 442 353 89 19 167 19 260 0z m-1762 -973 c64
-29 137 -101 166 -165 17 -40 23 -69 22 -131 0 -72 -3 -87 -33 -143 -38 -70
-70 -100 -145 -140 -43 -22 -64 -26 -136 -26 -78 -1 -90 2 -148 33 -69 37
-109 80 -144 151 -32 66 -31 196 2 265 42 86 123 152 215 176 49 13 148 3 201
-20z m3525 0 c69 -33 112 -74 150 -143 31 -58 34 -70 34 -148 0 -78 -3 -90
-34 -148 -38 -70 -70 -100 -145 -140 -43 -22 -64 -26 -136 -26 -78 -1 -90 2
-148 33 -113 61 -165 149 -166 276 -1 96 23 157 87 223 72 74 148 104 248 98
38 -3 85 -14 110 -25z'
        />
      </g>
    </svg>
  );
}
