export const getOrderCommission = async (welletContext, order) => {
  try {
    const response = await welletContext.apis.tickets.get(
      '/wallet/orderCommissionsByUser?orderId=',
      order,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
export const refundOrder = async (welletContext, order, companyId) => {
  try {
    const response = await welletContext.apis.tickets.post(
      `superadmin/refundOrder?orderId=${order}`, {},{
        "headers": {
          'x-wellet-scope': companyId
        }
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const fetchPaymentById = async (
  welletContext,
  id,
  url = 'api/Dashboard/ApprovalActivityFeed',
) => {
  const body = {
    filter: { states: [], channels: [], limit: 20, orderId: id },
  };
  try {
    const response = await welletContext.apis.manager.post(url, body);
    return response.data;
  } catch (error) {
    return error;
  }
};