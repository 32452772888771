export const fetchWallets = async (welletContext) => {
  try {
    const response = await welletContext.apis.admin.get(`api/Wallet`);
    return response;
  } catch (e) {
    throw Error(e);
  }
};
export const fetchWalletByOrganization = async (
  welletContext,
  orgId = null,
  limit = null,
  skip = null,
  filter = [],
  dates = {},
) => {
  const filterFormatted = filter.filter((item) => item.isActive).map((item) => item.value);
  const body = {
    OperationTypes: filterFormatted,
    From: dates?.from ? dates?.from : null,
    To: dates?.to ? dates?.to : null,
  };
  try {
    let url = '';
    if (orgId !== null) url += `/${orgId}`;
    if (limit !== null) url += `/${limit}`;
    if (skip !== null) url += `/${skip}`;
    const response = await welletContext.apis.admin.post(
      `api/Wallet/summary/operations${url}`,
      body,
    );
    return response;
  } catch (e) {
    throw Error(e);
  }
};

export const fetchWalletTotals = async (welletContext, orgId = null, filter = [], dates = {}) => {
  try {
    let url = '';
    if (orgId !== null) url += `/${orgId}`;
    const filterFormatted = filter.filter((item) => item.isActive).map((item) => item.value);
    const response = await welletContext.apis.admin.post(`api/Wallet/summary${url}`, {
      OperationTypes: filterFormatted,
      From: dates?.from ? dates?.from : null,
      To: dates?.to ? dates?.to : null,
    });
    return response;
  } catch (e) {
    throw Error(e);
  }
};
export const fetchOperationById = async (
  welletContext,
  orgId = null,
  orderId = null,
  sellerId = null,
) => {
  try {
    let url = '';
    if (orgId !== null) url += `/${orgId}`;
    if (orderId !== null) url += `/${orderId}`;
    if (sellerId !== null) url += `/${sellerId}`;
    const response = await welletContext.apis.admin.get(`api/Wallet/admin/operation/detail${url}`);
    return response;
  } catch (e) {
    throw Error(e);
  }
};
export const fetchOperationByIdSuperAdmin = async (
  welletContext,
  orgId = null,
  orderId = null,
  userId = null,
) => {
  try {
    let url = '';
    if (orgId !== null) url += `/${orgId}`;
    if (orderId !== null) url += `/${orderId}`;
    if (userId !== null) url += `/${userId}`;
    const response = await welletContext.apis.admin.get(`api/Wallet/operation/detail${url}`);
    return response;
  } catch (e) {
    throw Error(e);
  }
};
