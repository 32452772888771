const initialState = {
  tenantId: '',
  currentOrganization: JSON.parse(localStorage.getItem('currentOrg'))
    ? JSON.parse(localStorage.getItem('currentOrg'))
    : null,
  selectedOrganization: JSON.parse(localStorage.getItem('selectedOrg'))
    ? JSON.parse(localStorage.getItem('selectedOrg'))
    : null,
  selectedUser: null,
  selectedDates: {
    from: null,
    to: null,
  },
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TENANT_ID':
      return {
        ...state,
        tenantId: action.payload,
      };
    case 'SET_CURRENT_ORG':
      return {
        ...state,
        currentOrganization: action.payload,
      };
    case 'SET_SELECTED_ORG':
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    case 'SET_SELECTED_USER':
      return {
        ...state,
        selectedUser: action.payload,
      };
    case 'SET_SELECTED_DATE':
      return {
        ...state,
        selectedDates: action.payload,
      };
    case 'CLEAR_SELECTED_DATE':
      return {
        ...state,
        selectedDates: action.payload,
      };
    case 'ASSOCIATES_LOGOUT':
      return {
        ...state,
        tenantId: '',
        currentOrganization: null,
        selectedOrganization: null,
      };
    default:
      return state;
  }
};

export default appReducer;
