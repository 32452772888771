import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const isDark = localStorage?.getItem('darkMode')
    ? JSON.parse(localStorage?.getItem('darkMode'))
    : false;
  const [isDarkMode, setIsDarkMode] = useState(isDark);

  useEffect(() => {
    document.body.setAttribute('data-theme', isDark ? 'dark' : 'light');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDarkMode = () => {
    localStorage.setItem('darkMode', !isDarkMode);
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>{children}</ThemeContext.Provider>
  );
};
