import { useSelector } from 'react-redux';

const useUser = () => {
  const organization = useSelector((state) => state.user.user);
  const jwt = useSelector((state) => state.user.jwt);
  const isSuperAdmin = organization?.roles?.findIndex((r) => r === 'superadministrator') > -1;
  return {
    isSuperAdmin,
    isAdmin: !isSuperAdmin,
    user: organization,
    jwt
  };
};

export default useUser;
