import './iconButton.css';
const IconButton = ({ icon, onClick, classNameIcon }) => {
  return (
    <button
      type='button'
      className={`btn btn-outline-light border-transparent btn-icon btn-sm rounded-pill ${classNameIcon}`}
      onClick={onClick}
    >
      {icon}
    </button>
  );
};
export default IconButton;
