import React from 'react';

export default function LogOutIcon({ size = '16px', color = '#fff', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 143.8 143.61'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <rect width='74.2' height='143.61' rx='6.5' ry='6.5' fill={color} />
          <path
            fill={color}
            d='m142.32,75.93c.07-.09.14-.18.2-.27.06-.08.12-.16.18-.24.07-.1.13-.21.19-.31.05-.08.1-.15.14-.23.06-.1.1-.21.15-.32.04-.09.08-.17.12-.26.04-.1.08-.21.11-.31.03-.1.07-.19.1-.29.03-.11.06-.21.08-.32.02-.1.05-.2.07-.3.02-.12.04-.25.06-.37.01-.09.03-.17.04-.26.02-.21.03-.43.03-.64s-.01-.43-.03-.64c0-.09-.03-.18-.04-.27-.02-.12-.03-.24-.06-.36-.02-.1-.05-.2-.07-.3-.03-.1-.05-.21-.08-.31-.03-.1-.07-.19-.1-.29-.04-.1-.07-.21-.11-.31-.04-.09-.08-.17-.12-.26-.05-.11-.1-.21-.15-.31-.04-.08-.09-.16-.14-.24-.06-.1-.12-.21-.19-.31-.06-.08-.12-.16-.18-.24-.07-.09-.13-.18-.2-.27-.11-.13-.22-.26-.34-.38-.03-.03-.06-.07-.09-.1l-24.53-24.53c-2.54-2.54-6.65-2.54-9.19,0-2.54,2.54-2.54,6.65,0,9.19l13.44,13.44h-30.39c-3.59,0-6.5,2.91-6.5,6.5s2.91,6.5,6.5,6.5h30.39l-13.44,13.44c-2.54,2.54-2.54,6.65,0,9.19,1.27,1.27,2.93,1.9,4.6,1.9s3.33-.63,4.6-1.9l24.53-24.53s.06-.07.1-.11c.11-.12.22-.24.33-.36Z'
          />
        </g>
      </g>
    </svg>
  );
}
