import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import InputBase from './inputBase';

const InputNumber = ({
  name,
  placeholder,
  icon,
  label,
  className = '',
  isRequired = false,
  customValidation,
  acceptNegative = false,
  isDisabled = false,
  maxNumber = null,
  minNumber = null,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const defaultValidationRule = {
    validate: (value) => {
      if (maxNumber !== null && minNumber !== null) {
        if (value > maxNumber || value < minNumber)
        return `Value should be between ${minNumber} and ${maxNumber}`
      } else if (value <= 0) {
        return 'The value must be greater than 0.';
      }
    },
  };

  const validationRules = customValidation || defaultValidationRule;

  if (isRequired) {
    validationRules.required = 'This field is required';
  }
  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        defaultValue=''
        rules={!acceptNegative && validationRules}
        render={({ field }) => (
          <div>
            <InputBase
              type='number'
              field={field}
              errors={errors}
              name={name}
              {...field}
              onInput={(e) => {
                field.onChange(e);
              }}
              placeholder={placeholder}
              icon={icon}
              label={label}
              isDisabled={isDisabled}
            />
            {errors[name] && (
              <div className='d-flex align-items-center error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default InputNumber;
