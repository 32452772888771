import React, { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import Popup from 'reactjs-popup';
import { Button, InputBase } from '../../uiComponents/common';
import { clearSelectedDate, setSelectedDate } from '../../actions';

const DateFilter = ({ filterName }) => {
  const dispatch = useDispatch();
  const [isApplying, setIsApplying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const initialState = [
    {
      value: selectedDates.from ?? '',
      isActive: !!selectedDates.from,
      label: selectedDates.from,
      date: selectedDates.from ? new Date(`${selectedDates.from}T00:00:00`) : '',
    },
    {
      value: selectedDates.to ?? '',
      isActive: !!selectedDates.to,
      label: selectedDates.to,
      date: selectedDates.to ? new Date(`${selectedDates.to}T00:00:00`) : '',
    },
  ];
  const [localFilterOptions, setLocalFilterOptions] = useState(initialState);

  const renderSelectedOptions = () => {
    const selectedLabels = localFilterOptions
      .filter((option) => option.isActive)
      .map((option) => option.label);

    if (selectedLabels.length <= 3) {
      return selectedLabels.join(', ');
    } else {
      const lastLabel = selectedLabels[selectedLabels.length - 1];
      return `${lastLabel}, y ${selectedLabels.length - 1} más`;
    }
  };

  const handleApplyClick = (close) => {
    setIsApplying(true);
    dispatch(
      setSelectedDate({
        from: localFilterOptions[0].value,
        to: localFilterOptions[1].value,
      }),
    );
    close();
  };

  const clear = () => {
    const resetedDate = [
      {
        value: '',
        isActive: false,
        label: '',
        date: '',
      },
      {
        value: '',
        isActive: false,
        label: '',
        date: '',
      },
    ];
    dispatch(
      clearSelectedDate({
        from: '',
        to: '',
      }),
    );
    setLocalFilterOptions(resetedDate);
  };

  useEffect(() => {
    if (!isApplying && !isOpen) {
      setLocalFilterOptions(initialState);
    }
  }, [isApplying, isOpen]);

  return (
    <div>
      <Popup
        onOpen={() => {
          setIsApplying(false);
          setIsOpen(true);
        }}
        keepTooltipInside={'.App'}
        trigger={
          <div className='btnFilter'>
            {' '}
            <i className='fas fa-plus-circle mr-2'></i>
            <p className='p-0 m-0'>{filterName}</p>
            {localFilterOptions.some((option) => option.isActive) && (
              <span className='mx-1'>|</span>
            )}
            <p className='p-0 m-0 option-selected'>{renderSelectedOptions()}</p>
            {localFilterOptions.some((option) => option.isActive) && (
              <i className='fas fa-angle-down ml-2'></i>
            )}
          </div>
        }
        nested
        arrow={false}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {(close) => (
          <div className={`filter-modal isOpen`}>
            <h4 className='ctm-text-medium'>Select options:</h4>
            <ul className='d-flex flex-column m-0 p-0'>
              <Popup
                keepTooltipInside={'.App'}
                arrow={false}
                nested
                repositionOnResize={true}
                trigger={
                  <div className='mb-2'>
                    <InputBase
                      type='text'
                      errors={{}}
                      name={'from'}
                      placeholder={'From Date'}
                      icon={<i className='fas fa-calendar'></i>}
                      label={'From'}
                      value={localFilterOptions[0].value}
                      autoComplete={'off'}
                      readOnly={true}
                    />
                  </div>
                }
              >
                <div className='card-popup py-2' onClick={(e) => e.stopPropagation()}>
                  <DayPicker
                    mode='range'
                    initialFocus={true}
                    fromYear={2020}
                    captionLayout='dropdown-buttons'
                    defaultMonth={localFilterOptions[0].date}
                    selected={{ from: localFilterOptions[0].date, to: localFilterOptions[1].date }}
                    onSelect={(date) => {
                      setLocalFilterOptions([
                        {
                          ...localFilterOptions[0],
                          date: date?.from ? date.from : '',
                          value: date?.from ? format(date.from, 'y-MM-dd') : '',
                          isActive: !!date?.from,
                          label: date?.from ? format(date.from, 'y-MM-dd') : '',
                        },
                        {
                          isActive: date?.to
                            ? true
                            : localFilterOptions[1].isActive && !!date?.from,
                          date: date?.to ? date.to : '',
                          value: date?.to ? format(date?.to, 'y-MM-dd') : '',
                          label: date?.to ? format(date?.to, 'y-MM-dd') : '',
                        },
                      ]);
                    }}
                    toDate={new Date()}
                  />
                </div>
              </Popup>
              {localFilterOptions[0].value ? (
                <Popup
                  nested
                  arrow={false}
                  keepTooltipInside={'.App'}
                  trigger={
                    <div className='mb-2'>
                      <InputBase
                        type='text'
                        errors={{}}
                        name={'to'}
                        placeholder={'To'}
                        icon={<i className='fas fa-calendar'></i>}
                        label={'To'}
                        value={localFilterOptions[1].value}
                        autoComplete={'off'}
                        readOnly={true}
                      />
                    </div>
                  }
                >
                  <div className='card-popup py-2'>
                    <DayPicker
                      mode='range'
                      initialFocus={true}
                      fromYear={2020}
                      captionLayout='dropdown-buttons'
                      defaultMonth={localFilterOptions[1].date}
                      selected={{
                        from: localFilterOptions[0].date,
                        to: localFilterOptions[1].date,
                      }}
                      onSelect={(date) => {
                        setLocalFilterOptions([
                          {
                            date: date?.from ? date.from : '',
                            value: date?.from ? format(date?.from, 'y-MM-dd') : '',
                            isActive: !!date?.from,
                            label: date?.from ? format(date?.from, 'y-MM-dd') : '',
                          },
                          {
                            ...localFilterOptions[1],
                            date: date?.to ? date.to : '',
                            value: date?.to ? format(date.to, 'y-MM-dd') : '',
                            isActive: !!date?.to,
                            label: date?.to ? format(date.to, 'y-MM-dd') : '',
                          },
                        ]);
                      }}
                      toDate={new Date()}
                    />
                  </div>
                </Popup>
              ) : (
                <></>
              )}
            </ul>
            <Button
              size={'small'}
              onClick={() => handleApplyClick(close)}
              className='btn-custom-white'
              text={'Apply'}
            />
            <Button size={'small'} onClick={clear} className='ml-2 btn-custom-red' text={'Clear'} />
          </div>
        )}
      </Popup>
    </div>
  );
};

export default DateFilter;
