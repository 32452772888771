import React from 'react';

export default function TimeZoneIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 17.92 19.11'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path
            fill={color}
            d='m5.63,13.68c-.66-.61-1.08-1.33-1.15-2.25-.03-.4-.1-.79-.14-1.19-.02-.24-.13-.43-.31-.56-.18-.14-.37-.27-.56-.4-.48-.33-.63-.8-.48-1.35.09-.31.24-.62.39-.9.3-.6.21-.86-.45-1.01-.39-.08-.8-.11-1.2-.17-.08-.01-.15-.01-.26-.02.41-1.06,1-1.95,1.81-2.72,1.16-1.12,2.55-1.8,4.14-2.05.02.03.03.05.04.07-.13.11-.25.23-.4.32-.47.34-.94.66-1.41,1.01-.08.05-.14.11-.21.19-.39.43-.78.85-1.16,1.28-.09.11-.17.2-.26.3-.17.19-.24.39-.1.63.16.27.37.56.7.5.86-.16,1.65.11,2.45.29.36.08.71.23,1.04.43.67.37.91.99.79,1.75.98-.6,2.13-.94,3.35-.95-.5-.39-.84-1.02-.8-1.66.02-.32.17-.6.37-.83.21-.25.44-.46.65-.71.26-.3.28-.52.13-.79-.1-.16-.23-.2-.4-.14-.19.08-.38.16-.57.23-.44.13-.73-.01-.88-.44-.11-.34-.2-.68.07-1.01.09-.12.17-.14.31-.09,1.15.43,2.14,1.09,2.97,1.99,1.23,1.32,1.9,2.97,2,4.65.36.26.69.54.99.86,0-.13,0-.26,0-.39C17.1,3.83,13.27,0,8.55,0S0,3.83,0,8.55c0,4.06,2.83,7.46,6.63,8.33-.56-.92-.89-1.98-.93-3.13-.02-.02-.05-.04-.07-.07Z'
          />
          <path
            fill={color}
            d='m16.92,10.31c-.24-.36-.53-.69-.85-.97-.51-.47-1.11-.84-1.77-1.09-.62-.24-1.29-.37-1.99-.37-3.11,0-5.62,2.52-5.62,5.62,0,.6.09,1.17.26,1.71.1.29.21.57.36.84.18.37.41.71.67,1.03,1.03,1.24,2.59,2.03,4.33,2.03,3.1,0,5.61-2.51,5.61-5.61,0-1.19-.37-2.28-1-3.19Zm-1.17,5.26c-.1.2-.3.32-.5.32-.08,0-.15-.01-.22-.04l-2.98-1.34c-.2-.09-.32-.27-.32-.49v-4.29c0-.3.24-.55.55-.55s.55.25.55.55v3.93l2.64,1.19c.28.12.41.45.28.72Z'
          />
        </g>
      </g>
    </svg>
  );
}
