import React from 'react';
import InputBase from './inputBase';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { useFormContext, Controller } from 'react-hook-form';
import Popup from 'reactjs-popup';

const InputDate = ({
  name,
  placeholder,
  className,
  icon,
  label,
  isRequired = false,
  isDisabled = false,
  popUpProps,
  onDaySelect = () => {},
  customValidation = {},
  ...others
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  const handleDaySelect = (date, close) => {
    if (date) {
      setValue(name, format(date, 'y-MM-dd'));
    } else {
      setValue(name, '');
    }
    close();
    onDaySelect();
    clearErrors(name);
  };
  const validationRules = customValidation;

  if (isRequired) {
    validationRules.required = 'This field is required';
  }

  return (
    <div className={`form-group ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        rules={validationRules}
        render={({ field }) => (
          <div>
            <Popup
              nested
              trigger={
                <InputBase
                  type='text'
                  errors={errors}
                  name={name}
                  placeholder={placeholder}
                  icon={icon}
                  label={label}
                  value={field.value}
                  isDisabled={isDisabled}
                  isRequired={isRequired}
                  autoComplete={'off'}
                  readOnly={true}
                />
              }
              modal
            >
              {(close) => (
                <div className='card-popup py-2' onClick={(e) => e.stopPropagation()}>
                  <DayPicker
                    initialFocus={true}
                    mode='single'
                    defaultMonth={field.value ? new Date(field.value + 'T00:00:00') : ''}
                    selected={field.value ? new Date(field.value + 'T00:00:00') : ''}
                    onSelect={(date) => handleDaySelect(date, close)}
                    captionLayout='dropdown-buttons'
                    {...others}
                  />
                </div>
              )}
            </Popup>
            {errors[name] && (
              <div className='d-flex align-items-center error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      ></Controller>
    </div>
  );
};

export default InputDate;
