import { useContext } from 'react';
import styles from './menuItem.module.css';
import { Link } from 'react-router-dom';
import SidebarContext from '../../../context/sidebarContext';

export const MenuHeader = ({ title }) => (
  <div className={styles.menuHeaderContainer + ' nav-item-header text-12'}>
    <div className='icon-menu d-none'></div>
    <span className='sidebar-main-title text-sidebar-link'>{title}</span>
  </div>
);

export const MenuItem = ({
  icon,
  title,
  isSelected = false,
  path,
  disabled,
}) => {
  const { isMouseOver, isSidebarResized, toggleIsSidebarOpen } = useContext(SidebarContext);

  return (
    <Link to={disabled ? window.location.pathname : path} onClick={toggleIsSidebarOpen}>
      <div
        className={`${styles.menuItemContainer} nav-item ${
          isSelected ? styles.menuSelected : ''
        } font-weight-bold justify-content-${
          isMouseOver || !isSidebarResized ? 'between' : 'center'
        } text-sidebar-link`}
      >
        <div className='d-flex align-items-center'>
          {icon}
          <span className='ml-3 '>{title}</span>
        </div>
      </div>
    </Link>
  );
};
