import React from 'react';
import './table.css';

const TableCell = ({ children, className, align, type, style, ...others }) => {
  return (
    <td
      className={`${type === 'primary' ? 'text-sidebar-link' : 'text-light'} ${className ?? ''}`}
      align={align}
      style={style}
      {...others}
    >
      {children}
    </td>
  );
};

export default TableCell;
