import { components } from 'react-select';
import './selects.css';
import EditIcon from '../../icons/editIcon';
import { useState } from 'react';
import Modal from '../modal/modal';

export const InputOption = (props) => {
  return (
    <components.Option {...props} className='d-flex align-items-center custom-option'>
      <div className='d-flex align-items-center'>
        <input type='checkbox' checked={props.isSelected} style={{}} onChange={() => null} />
      </div>
      {props.children}
    </components.Option>
  );
};

export const MultiValueContainer = (props) => {
  const handleEditClick = () => {
    props.onEdit();
  };

  return (
    <components.MultiValueContainer {...props}>
      {props.children}
      {props.hasEdit ? (
        <div className='multiValueEdit' onClick={handleEditClick}>
          <EditIcon size='14px' />
        </div>
      ) : null}
    </components.MultiValueContainer>
  );
};
export const RemoveWithModalContainer = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <components.MultiValueRemove
        {...props}
        innerProps={{ ...props.innerProps, onClick: () => setOpen(true) }}
      >
        {props.children}
      </components.MultiValueRemove>
      <Modal
        idModal={'categoryModal'}
        title={'Delete Category'}
        open={open}
        handleSubmit={() => {
          props.innerProps.onClick();
        }}
        onClose={() => {
          setOpen(false);
        }}
        btnType='button'
        textCancel={'Cancel'}
        textSubmit={'Delete'}
      >
        <div className='modal-background font-weight-bold text-center'>{props.text}</div>
      </Modal>
    </>
  );
};
