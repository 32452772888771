import React from 'react';
import { CardRowDetails, Subtitle } from '../../uiComponents/common';
import usePagination from '../../hooks/usePagination';
import { Pagination } from '../../components';
import { format } from 'date-fns';

const CheckinData = ({ checkin }) => {
  const { currentPage, goToPage, totalPages } = usePagination(
    checkin.length,
    1,
  );
  const currentCheckin = checkin[currentPage - 1];
  return (
    <>
      <Subtitle text={<h3>Checkin Info</h3>} />
      <CardRowDetails title={'Reference Code'} value={currentCheckin.individualReferenceCode} />
      <CardRowDetails title={'Ticket'} value={currentCheckin.ticketName} />
      <CardRowDetails title={'User'} value={currentCheckin.registerUserName ? currentCheckin.registerUserName : '-'} />
      <CardRowDetails title={'Date Time'} value={currentCheckin.registerDateTime ? format(new Date(currentCheckin.registerDateTime), 'MMM dd, yyyy, h:mm a') : '-'} />
      {totalPages > 1 ? (
        <div className='mt-2 payment-pagination py-1'>
          <Pagination
            text='Checkin'
            className='d-flex align-items-center'
            currentPage={currentPage}
            onPageChange={goToPage}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </>
  );
};

export default CheckinData;
