import React from 'react';
const Content = ({ children }) => {
  return (
    <div
      className='content-wrapper'
      style={{
        margin: '10px',
        overflowY: 'auto',
        padding: '10px',
      }}
    >
      <div style={{ maxWidth: '1920px' }}>{children}</div>
    </div>
  );
};

export default Content;
