const Money = ({
  value,
  currency = null,
  symbol = null,
  currencyCode = null,
  convert = true,
  currencyClass,
}) => {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (value === null || isNaN(value)) return;

  const valueInSelectedCurrency = convert ? value * 1 : value;

  if (symbol === null) {
    symbol = '$';
  }

  const integerPart = valueInSelectedCurrency;

  return (
    <div className='d-inline-flex align-items-center'>
      <div className='integer'>
        {symbol +
          ' ' +
          integerPart.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
      </div>
      &nbsp;
      <span
        className={`${currencyClass ? currencyClass : 'text-light'}`}
        style={{ fontWeight: 400 }}
      >
        {currencyCode !== null ? currencyCode : null}
      </span>
    </div>
  );
};

export default Money;
