import React, { useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import './switcherTheme.css';

const SwitcherTheme = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();

  const handleToggle = () => {
    toggleDarkMode();
  };
  useEffect(() => {
    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);
  return (
    <div className='d-flex align-items-center'>
      <span className='text-sidebar-link mr-2 font-weight-bold'>
        {isDarkMode ? 'Dark' : 'Light'}
      </span>
      <label className='switch'>
        <input type='checkbox' onClick={handleToggle} defaultChecked={isDarkMode} />
        <span className='slider round'></span>
      </label>
    </div>
  );
};

export default SwitcherTheme;
