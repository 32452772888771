import './checkbox.css'
export function CheckBox({ handleCheck, checked, label }) {
  return (
    <label className='checkbox-container mb-0'>
      <input type='checkbox' onChange={handleCheck} checked={checked} />
      <span className='checkmark'></span>
      <span style={{ color: 'var(--color-subtitle)'}}>{label}</span>
    </label>
  );
}
