import React, { useContext, useState } from 'react';
import { BreadCrumbs, Subtitle, IconButton, Loading } from '../../uiComponents/common';
import { useLocation, useParams } from 'react-router-dom';
import { FwEditIcon } from '../../uiComponents/icons';
import { useQuery } from 'react-query';
import {
  getAllBoss,
  getAllChannels,
  getAllRoles,
  getImgsKyc,
  getProfilePic,
  getUserById,
} from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  ProfileExtraInfo,
  ProfileMainInfo,
  ProfileSecondary,
  UserKyc,
  UsersForm,
} from '../../components';
import { useSelector } from 'react-redux';

const UserProfile = () => {
  const { id } = useParams();
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const location = useLocation();
  const isFromSeller = location.pathname.split('/')[1] === 'sellersDetails';

  const [edit, setEdit] = useState(false);
  //  Note: lo uso para editar o crear un usuario, deberia hacer solo una llamada por ciclo de vida
  const rolesQuery = useQuery({
    queryKey: ['roles-dragaret'],
    queryFn: () => getAllRoles(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const channelsQuery = useQuery({
    queryKey: ['channels-dragaret'],
    queryFn: () => getAllChannels(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const bossQuery = useQuery({
    queryKey: ['boss-dragaret'],
    queryFn: () => getAllBoss(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  // Note: lo uso  unicamente cuando edito un user
  const userQuery = useQuery({
    queryKey: ['user', id],
    queryFn: () => getUserById(welletContext, id),
    enabled: !!id && rolesQuery.isSuccess,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const registeredByQuery = useQuery({
    queryKey: ['registeredby', userQuery.data?.data?.registerBy],
    queryFn: () => getUserById(welletContext, userQuery.data?.data?.registerBy),
    enabled: !!id && !!userQuery.data?.data?.registerBy,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const roles = rolesQuery?.data?.data.map((r) => ({ label: r.prettyName, value: r.id }));

  //  Note: lo uso para editar o crear un usuario, deberia hacer solo una llamada por ciclo de vida
  const role = userQuery?.data?.data?.role?.join(', ');
  const image = userQuery?.data?.data?.profilePic;
  const phone = userQuery?.data?.data?.phone;
  const email = userQuery.data?.data?.email;
  const name = `${userQuery?.data?.data?.firstName} ${userQuery?.data?.data?.lastName}`;
  const kycStatus = userQuery.data?.data?.kycStatus;

  const profileQuery = useQuery({
    queryKey: ['profile-pic', id],
    queryFn: () => getProfilePic(welletContext, image.slice(image.indexOf('/api'))),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!image,
  });
  const imageUrl = profileQuery.isSuccess
    ? URL.createObjectURL(new Blob([profileQuery?.data?.data], { type: 'image/jpeg' }))
    : null;

  const isNecessaryKyc = userQuery.isSuccess && kycStatus !== 'PENDING' && !isFromSeller && !!id;

  const kycQuery = useQuery({
    queryKey: ['kyc', id],
    queryFn: () => getImgsKyc(welletContext, id),
    enabled: isNecessaryKyc,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  // useRedirectIfSuperAdmin(
  //   !userQuery.isFetching &&
  //     userQuery.status !== 'idle' &&
  //     window.location.pathname !== '/users/new-user',
  //   '/users',
  // );

  const dataFromUser = [
    {
      name: 'Users',
      path: '/users',
    },
    {
      name: id ? 'Edit User' : 'New User',
      path: id ? `/users/edit-users/${id}` : '/new-user',
    },
  ];
  const dataFromSeller = [
    { name: 'Sellers', path: `/sellers` },
    { name: name, path: `/sellers/sellersDetails/${organization.id}/${id}` },
    { name: 'Profile' },
  ];

  return (
    <>
      {id ? (
        rolesQuery.isLoading ||
        userQuery.isFetching ||
        profileQuery.isLoading ||
        registeredByQuery.isLoading ||
        kycQuery.isLoading ? (
          <Loading />
        ) : (
          <>
            <BreadCrumbs crumbs={isFromSeller ? dataFromSeller : dataFromUser} />
            <Subtitle
              text={
                <div className='d-flex '>
                  <h3 className='mr-1'>{name}</h3>
                  {!isFromSeller && (
                    <IconButton
                      icon={<FwEditIcon color='var(--color-subtitle)' />}
                      onClick={() => setEdit(!edit)}
                    />
                  )}
                </div>
              }
            />
            <div className='profile-section'>
              <div className='row d-flex'>
                <ProfileMainInfo image={imageUrl} name={name} />
                <div className='row col mx-2 profile-container px-0' style={{ height: '50%' }}>
                  <div className='row col-12 border-table-bottom data-section '>
                    <ProfileSecondary name={name} role={role} email={email} phone={phone} />
                  </div>
                  <div className='row pt-3 px-2'>
                    <ProfileExtraInfo user={userQuery} registered={registeredByQuery} />

                    <UsersForm
                      id={id}
                      roles={roles}
                      edit={edit}
                      userData={userQuery?.data?.data}
                      channelsData={channelsQuery}
                      bossData={bossQuery}
                      organizationId={organization.id}
                    />
                    {!edit && !isFromSeller ? (
                      <UserKyc status={kycStatus} data={kycQuery.data?.data} id={id} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <UsersForm
          roles={roles}
          organizationId={organization.id}
          channelsData={channelsQuery}
          bossData={bossQuery}
        />
      )}
    </>
  );
};

export default UserProfile;
