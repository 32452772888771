import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const InputDescription = ({
  name,
  className = '',
  placeholder,
  label,
  icon,
  isRequired = false,
  minLength = 3,
  maxLength = 500,
  height = '34px',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validationRules = {
    minLength: {
      value: minLength,
      message: 'The field is too short',
    },
    maxLength: {
      value: maxLength,
      message: 'The field is too long',
    },
  };

  if (isRequired) {
    validationRules.required = 'This field is required';
  }
  return (
    <div className={`form-group ${className}`}>
      <label style={{ color: 'var(--color-text)' }}>{label}</label>
      <Controller
        name={name}
        control={control}
        key={name}
        defaultValue=''
        rules={validationRules}
        render={({ field }) => (
          <>
            <div className='d-flex'>
              {icon ? (
                <span
                  className='input-group-text px-2 input-icon'
                  style={{ color: 'var(--color-subtitle)' }}
                >
                  {icon}
                </span>
              ) : (
                <></>
              )}
              <textarea
                style={{ height: height }}
                placeholder={placeholder}
                {...field}
                className={`input-style textarea-style ${errors[name] ? 'input-error' : ''}`}
              />
            </div>
            {errors[name] && (
              <div className='d-flex align-items-center error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default InputDescription;
