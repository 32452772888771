export const getAllUsers = async (
  welletContext,
  limit = 20,
  skip = 0,
  ids,
  includeInactive = false,
) => {
  return await welletContext.apis.manager.post(`api/User/list`, {
    limit,
    skip,
    companyId: ids !== 1 ? ids : null,
    includeInactive,
  });
};
export const getUserById = async (welletContext, id) => {
  return await welletContext.apis.manager.get(`api/User/${id}`);
};
export const postUser = async (welletContext, body) => {
  return await welletContext.apis.manager.post(`api/User`, body);
};

export const putUser = async (welletContext, body) => {
  return await welletContext.apis.manager.put(`api/User`, body);
};

export const getAllRoles = async (welletContext) => {
  return await welletContext.apis.manager.get(`api/Role/list`);
};
export const getAllChannels = async (welletContext) => {
  return await welletContext.apis.manager.get(`api/Channel/list/admin`);
};
export const getAllBoss = async (welletContext, orgId, channel) => {
  return await welletContext.apis.manager.post(`api/Impersonate/list`, {
    companyIds: orgId !== 1 ? [orgId] : null,
    onlyActives: true,
    channel,
    roles: ['associate', 'referral', 'salesteamlead'],
  });
};

export const getProfilePic = async (welletContext, url) => {
  return await welletContext.apis.manager.get(url, {
    responseType: 'arraybuffer',
  });
};

export const getImgsKyc = async (welletContext, id = null) => {
  let url = 'api/User/profile/kyc';
  const newUrl = `${url}/${id}`;
  const response = welletContext.apis.manager.get(newUrl);
  return response;
};
export const putKycStatus = async (welletContext, body) => {
  let url = 'api/User/kyc/approve';
  const response = welletContext.apis.manager.put(url, body);
  return response;
};
