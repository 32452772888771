import React, { useContext, useState } from 'react';
import {
  Badge,
  BreadCrumbs,
  Button,
  IconButton,
  Loading,
  Money,
  Slider,
  Subtitle,
} from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import { DragDropTable, TableCell } from '../../components';
import useUser from '../../hooks/useUser';
import Popup from 'reactjs-popup';
import { FwCloneIcon, FwEditIcon, MenuBurger } from '../../uiComponents/icons';
import { getAllTickets, orderTicketByPriority } from '../../services';

const Tickets = () => {
  const navigate = useNavigate();
  const { isSuperAdmin } = useUser();
  const [showInactives, setShowInactives] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const currentOrg = useSelector((state) => state.app.currentOrganization);
  const welletContext = useContext(WelletContext);
  const [currentDragTable, setCurrentDragTable] = useState([]);
  const ticketsPriorityMutation = useMutation({
    mutationFn: (body) => {
      return orderTicketByPriority(welletContext, body);
    },
    onSuccess: () => {
      ticketQuery.refetch();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
  const ticketQuery = useQuery({
    queryKey: ['tickets', organization.id, showInactives],
    queryFn: () =>
      getAllTickets(
        welletContext,
        // PREGUNTAR ESTO
        [1, 2, 6],
        null,
        null,
        showInactives,
        null,
        organization.id,
      ),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const queryClient = useQueryClient();

  const handleDragEnd = (result) => {
    const currentData = queryClient.getQueryData(['tickets', organization.id, showInactives]);

    if (!currentData || !result.destination) {
      return;
    }
    const items = [...currentData.data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCurrentDragTable(items);
    ticketsPriorityMutation.mutate(items.map((item) => item.id));
    queryClient.setQueryData(['tickets', organization.id, showInactives], items);
  };

  const columns =
    organization.id === 1
      ? [
          '',
          'Organization',
          'Product',
          'Activity',
          'Category',
          'Priority',
          'Pax',
          'MAX Pax / Transaction',
          'Price',
          'Stock',
          'Date Until is Valid',
          'Seated Location',
          'Status',
        ]
      : [
          '',
          'Product',
          'Activity',
          'Category',
          'Priority',
          'Pax',
          'MAX Pax / Transaction',
          'Price',
          'Stock',
          'Date Until is Valid',
          'Seated Location',
          'Status',
        ];

  return (
    <>
      {isSuperAdmin && currentOrg.id === 83 ? (
        <BreadCrumbs
          crumbs={[
            {
              name: 'Tickets',
              path: '/tickets',
            },
          ]}
        />
      ) : (
        <></>
      )}
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Tickets</h1>}
        endComponent={
          <Button
            className={'btn-custom-white'}
            onClick={() => navigate('/tickets/new-tickets')}
            size={'small'}
            text={'New Ticket'}
          />
        }
      />
      {ticketQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='my-2 row align-items-center'>
            <Popup
              on={'hover'}
              trigger={
                <div>
                  <Slider
                    className={'col-auto'}
                    defaultChecked={showAll}
                    onClick={() => setShowAll(!showAll)}
                    label={'Set Priority'}
                  />
                </div>
              }
            >
              <div className='tooltipCard'>
                Activate the slider and drag-and-drop a table row to change priority
              </div>
            </Popup>
            <Slider
              className={'col-auto'}
              label={'Only Actives'}
              onClick={() => setShowInactives(!showInactives)}
              defaultChecked={!showInactives}
            />
          </div>
          <DragDropTable
            tableKey={`ticket-${organization.id}-${showAll}-${showInactives}-${ticketQuery?.data?.data?.length}`}
            data={ticketQuery?.data?.data ?? currentDragTable}
            columns={columns}
            perPage={
              showAll
                ? currentDragTable.length > 0
                  ? currentDragTable?.length ?? 0
                  : ticketQuery?.data?.data?.length ?? 0
                : 10
            }
            disableDrag={!showAll}
            handleDragEnd={(results) => handleDragEnd(results)}
            renderRow={(ticket, index, provided) => (
              <tr
                key={String(index)}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableCell
                  className='ctm-text font-weight-bold text-center'
                  style={{ width: '100px' }}
                  type={'primary'}
                >
                  {showAll ? (
                    <div className='mr-1'>
                      <MenuBurger size='15px' color='currentColor' />
                    </div>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => navigate(`/tickets/clone/${ticket.id}`)}
                        icon={<FwCloneIcon />}
                      />
                      <IconButton
                        onClick={() => navigate(`/tickets/edit-tickets/${ticket.id}`)}
                        icon={<FwEditIcon />}
                      />
                    </>
                  )}
                </TableCell>
                {organization.id === 1 ? (
                  <TableCell>{ticket.show.organization.name}</TableCell>
                ) : null}
                <TableCell>{ticket.name}</TableCell>
                <TableCell>{ticket.show.name}</TableCell>
                <TableCell>{ticket.category?.name}</TableCell>
                <TableCell>{ticket.priority}</TableCell>
                <TableCell>{ticket.defaultPaxCapacity}</TableCell>
                <TableCell>{ticket.maxQuantityPerTransaction}</TableCell>
                <TableCell><Money value={ticket.price} /></TableCell>
                <TableCell>{ticket.stock ?? '-'}</TableCell>
                <TableCell>{ticket?.dateUntilIsValid?.split('T')[0] ?? '-'}</TableCell>
                <TableCell>
                  <Badge status={ticket.isNumberedSeat ? 'yes' : 'no'} />
                </TableCell>
                <TableCell>
                  <Badge status={ticket.isActive ? 'ACTIVE' : 'INACTIVE'} />
                </TableCell>
                <TableCell></TableCell>
              </tr>
            )}
          />
        </>
      )}
    </>
  );
};

export default Tickets;
