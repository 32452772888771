import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { exchangeToken, authChecked, setTenantId as setAppTenant } from '../../actions';

const TENANT_ID = process.env.REACT_APP_TENANT_ID;

const Auth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppTenant(TENANT_ID));
  }, [dispatch]);

  return <AuthWithTenant tenantId={TENANT_ID} />;
};

const AuthWithTenant = ({ tenantId }) => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);
  const auth = getAuth();
  auth.tenantId = tenantId;

  const [user] = useAuthState(auth);

  useEffect(() => {
    if (auth._isInitialized && !user) {
      dispatch(authChecked());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth._isInitialized]);

  useEffect(() => {
    if (user && !isLogin) {
      exchangeToken(dispatch, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};

export default Auth;
