export const getAllGates = async (welletContext, includeInactive = true, orgId) => {
  let url = 'api/gate/all?';

  if (orgId !== 83) {
    url += `organizationId=${orgId}`
  }

  return await welletContext.apis.admin.get(
    `${url}&includeInactive=${includeInactive}`,
  );
};
export const getGateById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/gate/${id}`);
};
export const getGateByActivityId = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/gate/activity/${id}`);
};

export const postGate = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/gate`, body);
};
export const putGate = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/gate`, body);
};

export const getDoormansUsers = async (welletContext, organization) => {
  return await welletContext.apis.admin.get(`api/user/role/doorman/${organization}`);
}
