import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './zoomImg.css';
import { FwSearchIcon } from '../../icons';

const ZoomImg = ({ width = 'auto', className = '', img = '', alt = 'Zoom' }) => (
  <div className={`card-zoom ${className}`}>
    <Zoom>
      <img alt={alt} src={img} width={width} style={{ maxWidth: width, width: '100%' }} />
    </Zoom>
    <div className='card-zoom-icon'>
      <FwSearchIcon color='#fff' fontSize='18px' />
    </div>
  </div>
);

export default ZoomImg;
