export const getStatusMessage = (status) => {
  switch (status) {
    case 'APPROVED':
      return 'This payment is complete.';
    case 'PENDING':
      return 'The customer has not entered their payment method.';
    case 'ERROR':
      return 'This payment failed.';
    case 'EXPIRED':
      return 'This payment expired.';
    case 'REFUNDED':
      return 'This refund has been initiated';
    default:
      return '';
  }
};

export const getCurrentFilter = (filter, data, totalCount = false) => {
  let quantityToShow = 20;
  if (totalCount) {
    quantityToShow = data?.totalCount;
  } else if (data) {
    if (filter === 'approved') {
      quantityToShow = data.approvedQuantity;
    } else if (filter === 'error') {
      quantityToShow = data.errorQuantity;
    } else if (filter === 'refunded') {
      quantityToShow = data.refundedQuantity;
    } else if (filter === 'expired') {
      quantityToShow = data.expiredQuantity;
    } else if (filter === 'pending') {
      quantityToShow = data.pendingQuantity;
    } else {
      quantityToShow = data.allQuantity;
    }
  }
  return quantityToShow;
};

export const getTimezoneFormatted = (data) =>
  data?.map((obj) => ({
    label: `${obj.name} (UTC ${obj.shift})`,
    value: obj.id,
  }));

export const formatTimeFromMinutes = (closeAfter) => {
  const hours = Math.floor(closeAfter / 60);
  const minutes = closeAfter % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const formatTimeToMinutes = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const formatTitleNumber = (value) => {
  if (value === '' || value === undefined) {
    return 0;
  }
  const absValue = Math.abs(value);
  const sign = value < 0 ? '-' : '';
  console.log(value);
  if (absValue >= 1000000) {
    const formatted = absValue / 1000000;
    const formattedString =
      formatted % 1 === 0 ? formatted.toFixed(0) + 'M' : formatted.toFixed(1) + 'M';
    return sign + formattedString.replace(/\.0M$/, 'M');
  } else if (absValue >= 1000) {
    const formatted = absValue / 1000;
    const formattedString =
      formatted % 1 === 0 ? formatted.toFixed(0) + 'K' : formatted.toFixed(1) + 'K';
    return sign + formattedString.replace(/\.0K$/, 'K');
  } else {
    return sign + (absValue % 1 === 0 ? absValue.toFixed(0) : absValue.toFixed(1));
  }
};
export function calculatePercentage(total, number) {
  if (total > 0) {
    const percentage = (number / total) * 100;
    return parseFloat(percentage.toFixed(2));
  } else {
    return 0;
  }
}
