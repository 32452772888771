import React from 'react';
import { EditActivities } from '../../components';
import { BreadCrumbs } from '../../uiComponents/common';

const EditActivity = ({ isDuplicate = false}) => {
  return (
    <>
      <BreadCrumbs crumbs={[{ name: 'Activities', path: '/activities' }, { name: isDuplicate ?  'New Activity' : 'Edit Activity' }]} />
      
      <EditActivities isDuplicating={isDuplicate} />
    </>
  );
};

export default EditActivity;
