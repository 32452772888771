import React from 'react';

export default function NameIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16.83 17.1'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path
            fill={color}
            d='m15.79,2.3c0-.12-.08-.39-.26-.55-1.18.29-1.64,1.26-1.83,1.84.24.29.38.65.38,1.05,0,.92-.75,1.66-1.67,1.66s-1.66-.74-1.66-1.66c0-.71.44-1.32,1.07-1.56.21-1.1.72-1.69.86-1.83.04-.02.04-.03.04-.03l-3.35-.15c-.12,0-.23.01-.34.06-.16.03-.32.13-.44.26L.3,9.67c-.2.21-.3.48-.3.75s.1.53.3.73l5.63,5.65c.26.26.62.35.95.28.2-.04.39-.13.54-.28l8.29-8.29c.1-.1.18-.22.24-.35.04-.14.07-.28.06-.44l-.22-5.42ZM7.51,13.73c-.11.11-.27.17-.42.17-.13,0-.28-.06-.38-.16l-3.53-3.39c-.21-.21-.23-.57-.01-.8.21-.22.57-.23.8-.01l3.52,3.39c.22.21.23.57.02.8Zm.34-3.48c-.12.11-.26.17-.41.17-.14-.01-.28-.06-.39-.17l-1.86-1.83c-.22-.22-.22-.58,0-.79.23-.22.57-.22.8-.01l1.86,1.83c.22.22.22.58.01.8Z'
          />

          <path
            fill={color}
            d='m16.83.47c.01.28-.2.51-.47.53-.15.01-.29.02-.42.05-.38.04-.7.14-.99.26-1.12.49-1.56,1.44-1.72,2.18-.03.11-.05.21-.07.31-.06.37-.05.64-.04.68.02.27-.2.51-.46.52h-.03c-.21,0-.39-.14-.46-.32-.02-.04-.03-.09-.03-.14-.02-.32,0-.71.07-1.12.15-.73.49-1.53,1.12-2.16.12-.13.26-.25.41-.35C14.34.4,15.18.07,16.3,0c.3-.03.51.2.53.46Z'
          />
        </g>
      </g>
    </svg>
  );
}
