import React from 'react';

export default function DiscountIcon({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M4905 5104 c-16 -9 -132 -117 -256 -241 -207 -206 -228 -224 -248
   -214 -31 17 -1426 361 -1491 367 -72 8 -159 -10 -240 -49 -60 -29 -167 -134
   -1313 -1276 -686 -684 -1261 -1264 -1277 -1289 -66 -100 -94 -234 -71 -344 28
   -134 -1 -102 978 -1080 618 -617 921 -913 950 -927 134 -67 281 -66 418 1 53
   26 204 173 1305 1276 685 686 1261 1269 1279 1295 54 80 75 149 74 252 0 82
   -15 150 -177 798 -97 390 -181 718 -187 729 -10 19 11 43 214 247 124 124 232
   240 241 258 61 123 -78 260 -199 197z m-1388 -539 c345 -85 636 -158 646 -162
   14 -7 -15 -40 -160 -185 l-178 -178 -63 20 c-260 81 -528 -89 -574 -365 -48
   -287 221 -555 509 -507 273 45 444 315 363 574 l-20 63 178 178 c145 145 178
   174 185 160 4 -10 77 -301 162 -648 124 -503 155 -638 149 -670 -5 -37 -93
   -127 -1247 -1283 -995 -995 -1249 -1245 -1279 -1254 -89 -27 -32 -78 -991 880
   -937 937 -908 904 -893 989 7 34 128 159 1244 1275 680 681 1250 1244 1267
   1253 16 8 40 15 52 15 12 0 304 -70 650 -155z m201 -812 c63 -46 79 -147 33
   -211 -27 -39 -97 -67 -146 -58 -46 9 -101 57 -115 102 -22 66 11 147 71 177
   43 22 120 17 157 -10z'
        />
        <path
          d='M2080 3438 c-76 -9 -168 -51 -232 -108 -226 -198 -187 -572 74 -724
   118 -68 289 -76 411 -19 213 100 314 345 234 563 -73 198 -266 313 -487 288z
   m155 -322 c80 -53 76 -195 -8 -247 -46 -29 -136 -23 -173 12 -55 51 -69 120
   -38 184 36 77 142 101 219 51z'
        />
        <path
          d='M2210 2400 c-526 -59 -964 -112 -975 -118 -11 -5 -33 -24 -49 -41
   -39 -41 -47 -120 -17 -169 28 -44 77 -72 126 -72 44 0 1915 206 1952 215 65
   16 122 109 108 176 -14 65 -88 120 -157 118 -18 -1 -463 -50 -988 -109z'
        />
        <path
          d='M2240 1944 c-189 -51 -330 -236 -330 -434 0 -274 252 -488 521 -442
   195 34 341 184 372 382 14 91 -1 171 -48 267 -92 186 -309 282 -515 227z m191
   -305 c43 -21 78 -80 79 -128 0 -48 -38 -108 -84 -130 -54 -27 -91 -26 -140 2
   -52 30 -78 79 -73 138 10 112 117 170 218 118z'
        />
      </g>
    </svg>
  );
}
