import React from 'react';

export default function FwCheckIcon({
  color = 'currentColor',
  fontSize = '16px',
  isSelected = false,
}) {
  return (
    <>
      <i className='fas fa-check' style={{ color, fontSize: fontSize }}></i>
    </>
  );
}
