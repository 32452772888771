import React, { useContext, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  Alert,
  Button,
  IconButton,
  InputDate,
  InputDescription,
  InputMedia,
  InputNumber,
  InputText,
  Loading,
  ModernSwitcher,
  Select,
  Subtitle,
  Switcher,
  Tabs,
  TextAlert,
  TextEditor,
  Toast,
  ToggleToken,
} from '../../../uiComponents/common';
import './ticketForm.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { fetchActivities } from '../../../services/activityServices';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  DoorsIcons,
  FwClockIcon,
  FwEditIcon,
  FwListIcon,
  FwMasksIcon,
  FwPersonIcon,
  FwPlusIcon,
  FwPriceIcon,
  FwTrashIcon,
  NameIcon,
} from '../../../uiComponents/icons';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import {
  getAgeRange,
  getProductCategories,
  getProductGroups,
  putTicket,
  getTicketById,
  postTicket,
  getTiersById,
  getGateByActivityId,
} from '../../../services';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import UsersIcons from '../../../uiComponents/icons/usersIcon';
import Table from '../../table/table';
import TableRow from '../../table/tableRow';
import TableCell from '../../table/tableCell';
import { validateNegatives } from '../../../helpers/validations';

const EditTicketForm = () => {
  const { id } = useParams();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);
  const methods = useForm();
  const navigate = useNavigate();
  const { control } = methods;
  const lastClickedButton = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const isDuplicating = window.location.pathname.includes('/clone');

  const validateFields = {
    name: {
      rule: (values) => {
        if (!values.name && !values.description) {
          return true;
        } else if (values.name && values.name.trim() !== '') {
          if (values.name.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Name" field is required and must have a minimum of 3 characters.',
    },
    description: {
      rule: (values) => {
        if (!values.name && !values.description) {
          return true;
        } else if (values.description && values.description.trim() !== '') {
          if (values.description.length < 3) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      },
      errorMsg: 'the "Description" field is required and must have a minimum of 3 characters.',
    },
  };

  const { languages, lngErrors, currentLng, changeLanguage } = useLanguageSwitcher(
    validateFields,
    methods,
  );
  const [multimediaPopups, setMultimediaPopups] = useState({
    logo: { open: false, selectedImage: null },
    locationImage: { open: false, selectedImage: null },
    error: null,
  });
  const [isRedirect, setIsRedirect] = useState(null);
  const inputMinutesBefores = useWatch({
    control,
    name: 'minutesBefore',
  });
  const inputMinutesAfters = useWatch({
    control,
    name: 'minutesAfter',
  });

  const activityValue = methods.watch('activity')?.id;

  const activityQuery = useQuery({
    queryKey: ['activities', organization.id],
    queryFn: () => fetchActivities(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const ageRangeQuery = useQuery({
    queryKey: ['ageRange'],
    queryFn: () => getAgeRange(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const productCategoriesQuery = useQuery({
    queryKey: ['productCategories'],
    queryFn: () => getProductCategories(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const productGroupsQuery = useQuery({
    queryKey: ['productGroups'],
    queryFn: () => getProductGroups(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const gatesQuery = useQuery({
    queryKey: ['gates', activityValue],
    queryFn: () => getGateByActivityId(welletContext, activityValue),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: activityValue !== undefined,
  });
  const ticketMutation = useMutation({
    mutationFn: (body) => {
      if (!isDuplicating) return putTicket(welletContext, body);
      else return postTicket(welletContext, body);
    },
    onSuccess: () => {
      setShowToast(true);
    },
  });
  const tciketByIdQuery = useQuery({
    queryKey: [`ticket`, id],
    queryFn: () => getTicketById(welletContext, id),
    onSuccess: (data) => {
      methods.reset(formatActivityData(data));
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
    enabled: productCategoriesQuery.isSuccess,
  });

  const tiersQuery = useQuery({
    queryKey: ['tiers', organization.id],
    queryFn: () => getTiersById(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  // Formated Data Query
  const activityFormated = activityQuery?.data?.map((obj) => ({
    label: obj.name,
    value: obj.name,
    id: obj.id,
  }));
  const ageRangeFormated = ageRangeQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj,
    id: obj.id,
  }));
  const productCategoriesFormated = productCategoriesQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.name,
    id: obj.id,
  }));
  const productGroupsFormated = productGroupsQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.name,
  }));
  const tiersQueryFormated = tiersQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.id,
    level: obj.level,
  }));

  const gatesQueryFormated = gatesQuery?.data?.data?.map((obj) => ({
    label: obj.name,
    value: obj.id,
  }));

  const formatActivityData = (d) => {
    const data = d?.data;
    const formattedData = {
      id: data?.id,
      name: data?.name,
      logo: data?.image ? { base64: data?.image, name: data?.image?.split('/')[3] ?? '' } : null,
      isActive: data?.isActive,
      activity: { label: data?.show.name, value: data?.show.name, id: data?.show.id },
      category: productCategoriesFormated.find((c) => c.id === data?.categoryId),
      defaultPax: String(data?.defaultPaxCapacity),
      maxPaxCapacity: String(data?.maxPaxCapacity),
      minQty: String(data?.minQuantityPerTransaction),
      maxQty: String(data?.maxQuantityPerTransaction),
      locationImage: data?.seatDistributionImgUrl
        ? {
            base64: data?.seatDistributionImgUrl,
            name: data?.seatDistributionImgUrl?.split('/')[3] ?? '',
          }
        : null,
      additionalGuests: String(data?.maxPaxCapacity - data?.defaultPaxCapacity),
      seatedLocation: data?.isNumberedSeat,
      tag: data?.tags,
      productSpecialEvent: data?.isExclusiveProduct,
      nameId: data?.nameId,
      minutesAfter: data?.sellUntilMinutesAfterShowTime
        ? String(data?.sellUntilMinutesAfterShowTime)
        : null,
      minutesBefore: data?.sellUntilMinutesBeforeShowTime
        ? String(data?.sellUntilMinutesBeforeShowTime)
        : null,
      stockPerDay: String(data?.stockPerDay),
      ageRange: {
        label: data.ageRange.name,
        value: data.ageRange,
        id: data.ageRange.id,
      },
      group: data?.productGroups?.map((g) => ({
        value: g.groupName,
        label: g.groupName,
        id: g.productId,
      })),
      tiers: data?.tiers?.map((t) => ({ value: t.id, label: t.name, level: t.level })),
      gates: data?.gateProducts?.map((t) => ({ value: t.gateId, label: t.gate?.name })),
    };
    data?.langProducts?.forEach((langShow) => {
      const lang = langShow.language;
      formattedData[`name_${lang}`] = langShow.name;
      formattedData[`description_${lang}`] = langShow.description;
      formattedData[`features_${lang}`] = langShow.features;
    });

    if (data.priceComposed.length > 0) {
      formattedData['price'] = { label: 'Complejo', value: 'complex' };
      data.priceComposed.forEach((price) => {
        const id = price.id;
        formattedData[`nameTable_${id}`] = price.name;
        formattedData[`price_${id}`] = price.price;
        formattedData[`extra_${id}`] = price.priceExtraPax || '';
        formattedData[`stock_${id}`] = price.stock;
        formattedData[`date_${id}`] = price.dateUntilIsValid?.split('T')[0] || '';
        formattedData[`status_${id}`] = price.isActive || '';
      });
      formattedData['stages'] = 1;
      setTableData(data.priceComposed);
    } else {
      formattedData['price'] = { label: 'Simple', value: 'simple' };
      formattedData[`extra_simple`] = data.priceExtraPax;
      formattedData[`price_simple`] = data.price;
      formattedData[`date_simple`] = data?.dateUntilIsValid?.split('T')[0] || '';
      formattedData[`stock_simple`] = data.stock;
    }

    setMultimediaPopups((prevState) => ({
      ...prevState,
      logo: { ...prevState.logo, selectedImage: formattedData.logo },
      locationImage: { ...prevState.locationImage, selectedImage: formattedData.locationImage },
    }));

    return formattedData;
  };

  const onSubmit = (data) => {
    let hasError = false;
    tableData.forEach((rowData) => {
      const rowId = rowData.id;
      if (data[`price_${rowId}`] === '') {
        methods.setError('priceComposed', {
          message: 'You must complete the table of prices',
        });
        hasError = true;
        return;
      }
    });

    const initialLangProduct = languages.map((lng) => {
      return {
        language: lng.shortForm,
        name: data[`name_${lng.shortForm}`],
        description: data[`description_${lng.shortForm}`],
        features: data[`features_${lng.shortForm}`],
      };
    });
    const handelLangProductsEmptys = removeObjectsWithEmptyFields(initialLangProduct, [
      'name',
      'description',
      'features',
    ]);

    if (Object.keys(lngErrors).length === 0) {
      const groupBody = data.group.map((g) => ({ productId: 0, groupName: g.value }));

      const body = {
        organizationId: organization.id,
        id: isDuplicating ? 0 : data?.id,
        name: handelLangProductsEmptys.find((l) => !!l.name).name,
        nameT: handelLangProductsEmptys.find((l) => !!l.name).name,
        nameId: data.nameId,
        image: data?.logo?.base64 ?? data?.logo,
        isActive: data.isActive ? data.isActive : false,
        showId: data.activity.id,
        categoryId: data.category.id,
        defaultPaxCapacity: parseInt(data.defaultPax) || 0,
        maxPaxCapacity: 0,
        minQuantityPerTransaction: data.minQty ? parseInt(data.minQty) : 0,
        maxQuantityPerTransaction: data.maxQty ? parseInt(data.maxQty) : 0,
        seatDistributionImgUrl: data?.locationImage?.base64 ?? data?.locationImage,
        isNumberedSeat: data.seatedLocation ? data.seatedLocation : false,
        isExclusiveProduct: data.productSpecialEvent ? data.productSpecialEvent : false,
        analyticsType: data.nameId,
        sellUntilMinutesAfterShowTime: data.minutesAfter ? parseInt(data.minutesAfter) : 0,
        sellUntilMinutesBeforeShowTime: data.minutesBefore ? parseInt(data.minutesBefore) : 0,
        additionalGuest: parseInt(data.additionalGuests) || 0,
        ageRangeId: data.ageRange.value.id,
        tags: data.tag,
        stock: 0,
        productGroups: groupBody,
        langProducts: handelLangProductsEmptys,
        productUserTiers: data.tiers ? data?.tiers?.map((t) => t.value) : null,
        gateProduct: data.gates ? data?.gates?.map((t) => t.value) : null,
      };

      if (data.price.value === 'complex') {
        const priceComposedArray = [];

        tableData.forEach((rowData) => {
          const rowId = rowData.id;

          const row = {
            id: rowData.isNew ? 0 : rowData.id,
            name: data[`nameTable_${rowId}`],
            price: data[`price_${rowId}`],
            stock: data[`stock_${rowId}`],
            dateUntilIsValid: data[`date_${rowId}`] || null,
            priceExtraPax: data[`extra_${rowId}`] || 0,
            isActive: data[`status_${rowId}`] || false,
          };
          priceComposedArray.push(row);
        });
        body.dateUntilIsValid = priceComposedArray[0].dateUntilIsValid;
        body.price = priceComposedArray[0].price;
        body.stock = priceComposedArray[0].stock;
        body.priceExtraPax = priceComposedArray[0].priceExtraPax;
        body.priceComposed = priceComposedArray;
      } else {
        body.stock = data[`stock_simple`];
        body.price = data[`price_simple`];
        body.dateUntilIsValid = data[`date_simple`] || null;
        body.priceExtraPax = data[`extra_simple`] || 0;
        body.priceComposed = [];
      }
      if (!hasError) {
        ticketMutation.mutate(body);
      }
    }
  };

  const handleAddRow = () => {
    const currentStages = methods.watch('stages');

    if (currentStages > 0) {
      const newRows = Array.from({ length: currentStages }, (_, index) => ({
        id: counter + index,
        isNew: true,
      }));

      newRows.forEach((row) => {
        methods.setValue(`nameTable_${row.id}`, methods.getValues(`name_${currentLng}`));
      });

      setTableData((prevTableData) => [...prevTableData, ...newRows]);
      setCounter(counter + Number(currentStages));
    }
  };

  const handleDeleteRow = (id) => {
    const updatedData = tableData.filter((row) => row.id !== id);
    setTableData(updatedData);

    const inputNames = [
      `nameTable_${id}`,
      `price_${id}`,
      `extra_${id}`,
      `stock_${id}`,
      `date_${id}`,
      `status_${id}`,
    ];
    inputNames.forEach((name) => {
      methods.unregister(name);
    });
  };

  const handleToastCallback = () => {
    if (isRedirect) {
      methods.reset();
      navigate(`/tickets`);
      setShowToast(false);
    } else {
      setShowToast(false);
    }
  };
  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };
  const clearForm = () => {
    navigate(`/tickets`);
  };

  const ticketName = methods.formState?.defaultValues?.activity?.label;
  const isSimple = methods.watch('price')?.value === 'simple';

  return (
    <>
      {showToast && (
        <Toast
          message={`Ticket ${isDuplicating ? 'created' : 'edited'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {tciketByIdQuery.isFetching ||
      productGroupsQuery.isLoading ||
      productCategoriesQuery.isLoading ||
      ageRangeQuery.isLoading ||
      gatesQuery.isLoading ||
      activityQuery.isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
            <Subtitle
              text={<h3>{isDuplicating ? 'New Ticket' : `Edit Ticket  - ${ticketName}`}</h3>}
            />
            <div className='row mt-3'>
              <Select
                options={activityFormated}
                label={'Activity'}
                className={'col-12 col-sm-6 col-xl-4'}
                name={'activity'}
                isRequired={true}
                icon={<FwMasksIcon />}
                onManualChange={() => methods.setValue('gates', null)}
              />
              <InputText
                name={'nameId'}
                label={'Name Id'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter Name Id'}
                isRequired={true}
              />
            </div>
            <div className='row my-2'>
              <div className='col-auto'>
                <ToggleToken
                  label={'Status'}
                  activeText={'Active'}
                  inactiveText={'Inactive'}
                  name={'isActive'}
                />
              </div>
            </div>
            <Subtitle text={<h3>Main Data</h3>} />
            <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
            {Object.keys(lngErrors).length !== 0 && (
              <TextAlert text={lngErrors.name} type={'danger'} />
            )}
            <div className='row mt-3'>
              <InputText
                name={'name_' + currentLng}
                label={'Name'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter a product name'}
                isRequired={true}
                icon={<NameIcon />}
              />
              <InputDescription
                name={'description_' + currentLng}
                label={'Description'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Description'}
                icon={<FwEditIcon />}
              />
            </div>
            <div className='row mt-3'>
              <TextEditor
                name={`features_${currentLng}`}
                label={'Features'}
                className='col-12 col-xl-8'
                customErrors={lngErrors}
              />{' '}
            </div>

            <Subtitle text={<h3>General Data</h3>} />
            <div className='row mt-3'>
              <Select
                options={productCategoriesFormated}
                label={'Category'}
                className={'col-12 col-sm-6 col-xl-4'}
                name={'category'}
                isRequired={true}
              />
              <Select
                options={ageRangeFormated}
                label={'Age range'}
                className={'col-12 col-sm-6 col-xl-4'}
                name={'ageRange'}
                isRequired={true}
              />
            </div>
            <div className='row mt-3'>
              <InputText
                name={'tag'}
                label={'Tag'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter a tag'}
              />

              <Select
                options={productGroupsFormated}
                label={'Group'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<UsersIcons />}
                name={'group'}
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
                isRequired={true}
              />
            </div>
            <div className='row mt-2S'>
              <Select
                options={tiersQueryFormated}
                label={'Tiers'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwListIcon />}
                name={'tiers'}
                type='tiers'
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
              />
              <Select
                options={gatesQueryFormated}
                label={'Gates'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<DoorsIcons />}
                name={'gates'}
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                isRequired={true}
                hideSelectedOptions={false}
                isDisabled={!activityValue}
              />
            </div>

            <Subtitle text={<h3>Prices Data</h3>} />
            <div className='row col-12 col-xl-8 my-2'>
              <Select
                label={'Price Mode'}
                className={'col-12 col-xl-6 mt-2 mb-0'}
                icon={<FwPriceIcon />}
                name={'price'}
                openMenuOnClick={false}
                isSearchable={false}
                isRequired={true}
              />
              {methods?.watch('price')?.value === 'complex' ? (
                <>
                  {tableData.length === 0 &&
                  (!methods?.watch(`name_${currentLng}`) || !methods.watch('activity')?.label) ? (
                    <div className='col-12'>
                      <TextAlert
                        type={'warning'}
                        text={'You must complete name and activity fields.'}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {tableData.length > 0 || isSimple ? (
              <div className='mt-3 row col-12 col-xl-8 '>
                <Table
                  renderRow={(price) => (
                    <TableRow key={price.id}>
                      {isSimple ? (
                        <></>
                      ) : (
                        <TableCell style={{ verticalAlign: 'baseline' }}>
                          <InputText
                            className={'mb-0 input-table-width'}
                            name={`nameTable_${price.id}`}
                            placeholder={'Enter ticket stage name'}
                            isRequired={true}
                          />
                        </TableCell>
                      )}
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        <InputNumber
                          placeholder={'Enter price'}
                          className='input-table-width'
                          name={'price_' + price.id}
                          isRequired={true}
                        />
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        <InputNumber
                          customValidation={{
                            validate: (value) => {
                              if (value && value < 0) {
                                return 'Value should not be negative.';
                              }
                            },
                          }}
                          className='input-table-width'
                          placeholder={'Enter extra price'}
                          name={'extra_' + price.id}
                          isRequired={false}
                        />
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        <InputNumber
                          className='input-table-width'
                          placeholder={'Enter stock'}
                          customValidation={{
                            validate: (value) => {
                              if (value < 0) {
                                return 'Value must be positive.';
                              }
                            },
                          }}
                          name={'stock_' + price.id}
                          isRequired={true}
                        />
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        <InputDate
                          placeholder={'Enter date'}
                          className={'mb-0 input-table-width'}
                          name={'date_' + price.id}
                          fromDate={
                            new Date(
                              activityQuery?.data?.find(
                                (a) => a.id === methods.watch('activity')?.id,
                              )?.startLocalDate,
                            )
                          }
                        />
                      </TableCell>
                      {isSimple ? (
                        <></>
                      ) : (
                        <>
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <div style={{ marginTop: '0.5rem' }}>
                              <Switcher className={'mb-0'} name={'status_' + price.id} />
                            </div>
                          </TableCell>
                          {price.isNew ? (
                            <TableCell style={{ verticalAlign: 'top' }}>
                              <IconButton
                                icon={<FwTrashIcon />}
                                onClick={() => handleDeleteRow(price.id)}
                              />
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </TableRow>
                  )}
                  renderLastRow={(price) =>
                    !isSimple ? (
                      <TableRow key={price.id + 'add'} onClick={handleAddRow}>
                        <TableCell colSpan={12}>
                          <div className='text-center'>
                            <FwPlusIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  }
                  tableKey={`tickets-table-${tableData.length}`}
                  totalP={isSimple ? 1 : tableData.length}
                  data={isSimple ? [{ id: 'simple' }] : tableData}
                  columns={
                    isSimple
                      ? ['Price', 'Extra', 'Stock', 'Date']
                      : ['Name', 'Price', 'Extra', 'Stock', 'Date', 'Status', '']
                  }
                  showPagination={tableData.length > 10}
                />
              </div>
            ) : (
              <></>
            )}

            <Subtitle text={<h3>Media Data</h3>} />
            <div className='row mt-3'>
              <InputMedia
                id={'imageFile'}
                name={'logo'}
                placeholder={'Upload Image'}
                isRequired={false}
                className='col-12 col-sm-6 col-xl-4 my-1'
                label={'Logo Image'}
                maxSize={409600}
                titleModal='Add Logo Image'
              />
              <InputMedia
                id={'locationImageFile'}
                name={'locationImage'}
                placeholder={'Upload Image'}
                label={'Location Image'}
                isRequired={false}
                className='col-12 col-sm-6 col-xl-4 my-1'
                maxSize={409600}
                titleModal='Add Location Image'
              />
            </div>
            <Subtitle text={<h3>Capacity Data</h3>} />
            <div className='row mt-3'>
              <InputNumber
                label={'Default Pax'}
                className={'col-6 col-sm-4 col-xl-2 mb-2'}
                name={'defaultPax'}
                placeholder={'0'}
                icon={<FwPersonIcon />}
                customValidation={{
                  validate: validateNegatives,
                }}
              />
              <InputNumber
                label={'Additional Guests'}
                className={'col-6 col-sm-4 col-xl-2 mb-2'}
                name={'additionalGuests'}
                placeholder={'0'}
                icon={<FwPersonIcon />}
                customValidation={{
                  validate: validateNegatives,
                }}
              />
            </div>

            <Subtitle text={<h3>Sales enabled</h3>} />
            <div className='row mt-3'>
              <InputNumber
                label={'Before activity time (in minutes)'}
                className={'col-6 col-sm-4 col-xl-2 mb-2'}
                name={'minutesBefore'}
                placeholder={'0'}
                customValidation={{
                  validate: validateNegatives,
                }}
                icon={<FwClockIcon />}
                isDisabled={inputMinutesAfters}
              />
              <InputNumber
                label={'After activity time (in minutes)'}
                className={'col-6 col-sm-4 col-xl-2 mb-2'}
                name={'minutesAfter'}
                placeholder={'0'}
                customValidation={{
                  validate: validateNegatives,
                }}
                icon={<FwClockIcon />}
                isDisabled={inputMinutesBefores > 0}
              />
            </div>
            <Subtitle text={<h3>Additional Data</h3>} />
            <div className='row mt-3'>
              <InputNumber
                label={'Maximum quantity per transaction'}
                className={'col-6 col-sm-4 col-xl-2 mb-2'}
                name={'maxQty'}
                placeholder={'0'}
                customValidation={{
                  validate: validateNegatives,
                }}
              />
              <InputNumber
                label={'Minimum quantity per transaction'}
                className={'col-6 col-sm-4 col-xl-2 mb-2'}
                name={'minQty'}
                placeholder={'0'}
                customValidation={{
                  validate: validateNegatives,
                }}
              />
            </div>

            <div className='row mt-3'>
              <div className={'col-12'}>
                <ModernSwitcher
                  name={'productSpecialEvent'}
                  isRequired={false}
                  text={'Product only for a special event'}
                />
              </div>
              <div className={'col-12'}>
                <ModernSwitcher
                  name={'seatedLocation'}
                  isRequired={false}
                  text={'Numbered seated location'}
                />
              </div>
            </div>
            <div className='row align-items-center justify-content-center justify-content-sm-start px-2 py-3'>
              <Button
                size={'small'}
                type='submit'
                text={`${
                  ticketMutation.isLoading && lastClickedButton.current === 'send'
                    ? 'Sending...'
                    : 'Send'
                }`}
                onClick={() => {
                  handleRedirect(false, 'send');
                }}
              />
              <Button
                size={'small'}
                type='button'
                className={'btn-custom-white  ml-2'}
                text={`Cancel`}
                onClick={clearForm}
              />
              <Button
                size={'small'}
                className={' ml-2'}
                type='submit'
                text={`${
                  ticketMutation.isLoading && lastClickedButton.current === 'sendAndClose'
                    ? 'Sending...'
                    : 'Send and Close'
                }`}
                onClick={() => {
                  handleRedirect(true, 'sendAndClose');
                }}
              />
            </div>
          </form>
        </FormProvider>
      )}
      {ticketMutation?.error &&
        (ticketMutation?.error?.response?.data?.message === 'INVALID_IMAGE_FORMAT' ? (
          <Alert
            title={'Oops, something went wrong'}
            text={'Error loading images. Only jpg, jpeg, and gif formats are supported.'}
            type={'error'}
          />
        ) : (
          <Alert
            title={'Oops, something went wrong'}
            text={'We are experiencing a general error at the moment.'}
            type={'error'}
          />
        ))}
      {methods.formState.errors && methods?.formState?.errors?.priceComposed ? (
        <Toast
          message={methods?.formState?.errors?.priceComposed.message}
          type={'ERROR'}
          onCallback={() => {
            methods.clearErrors('priceComposed');
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default EditTicketForm;
