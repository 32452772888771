import React, { useContext } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';
import { getAllVenues } from '../../services';
import { BreadCrumbs, Button, IconButton, Loading, Subtitle } from '../../uiComponents/common';
import { Table, TableCell, TableRow } from '../../components';
import { useSelector } from 'react-redux';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';

const Venues = () => {
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const { isSuperAdmin } = useUser();
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedOrganization = useSelector((state) => state.app.selectedOrganization);
  const venuesQuery = useQuery({
    queryKey: ['venues-all', selectedOrganization.id],
    queryFn: () => getAllVenues(welletContext, null, null, selectedOrganization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {isSuperAdmin && organization.id === 83 ? (
        <BreadCrumbs
          crumbs={[
            {
              name: 'Venues',
              path: '/venues',
            },
          ]}
        />
      ) : (
        <></>
      )}
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Venues</h1>}
        endComponent={
          <Button
            className={'btn-custom-white'}
            onClick={() => navigate('new-venue')}
            size={'small'}
            text={'New Venue'}
          />
        }
      />
      {venuesQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            tableKey={`venues-${selectedOrganization.id}`}
            data={venuesQuery?.data?.data?.venues}
            totalP={venuesQuery?.data?.data?.venues?.length}
            columns={[
              '',
              'Name',
              'Alias',
              'Address',
              'City',
              'State',
              'Country',
              'ZIP Code',
              'Phone',
              'Latitude',
              'Longitude',
            ]}
            renderRow={(venue, index) => (
              <TableRow
                key={index}
                // onClick={() => navigate(venue)}
              >
                <TableCell>
                  <IconButton
                    onClick={() => navigate(`edit-venue/${venue.id}`)}
                    icon={<FwEditIcon />}
                  />
                </TableCell>
                <TableCell>{venue.name}</TableCell>
                <TableCell>{venue.shortName}</TableCell>
                <TableCell>{venue.address.street}</TableCell>
                <TableCell>{venue.address.city}</TableCell>
                <TableCell>{venue.address.state}</TableCell>
                <TableCell>{venue.address.country}</TableCell>
                <TableCell>{venue.address.postalCode}</TableCell>
                <TableCell>{venue.phone}</TableCell>
                <TableCell>{venue.location.lat}</TableCell>
                <TableCell>{venue.location.lng}</TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Venues;
