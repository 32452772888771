import React from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import './inputs.css';

const InputPhone = ({
  name,
  placeholder,
  defaultCountry,
  label = null,
  className,
  isRequired = true,
  isDisabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // Función para validar el número de teléfono
  const isValidPhone = (value) => {
    if (isRequired) {
      return isValidPhoneNumber(value);
    }
    return true;
  };

  return (
    <div className={className}>
      {label && <label style={{ color: 'var(--color-subtitle)' }}>{label}</label>}

      <Controller
        name={name}
        control={control}
        defaultValue=''
        rules={{
          required: isRequired ? 'This field is required' : '',
          validate: (value) => {
            if (!isValidPhone(value)) {
              return 'Invalid Phone';
            }
            return true;
          },
        }}
        render={({ field }) => (
          <div>
            <PhoneInput
              defaultCountry={defaultCountry}
              {...field}
              disabled={isDisabled}
              placeholder={placeholder}
              className={`${errors[name] ? 'input-error' : ''} ${isDisabled ? 'disabled' : ''}`}
            />
            {errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default InputPhone;
