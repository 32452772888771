export const fetchsOrganizations = async (welletContext, url = 'api/Company/list/report') => {
  const body = {
    businessType: 'ShowProducer',
    includeInactive: true,
  };
  try {
    const response = await welletContext.apis.manager.post(url, body);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
};

export const changePassword = async (welletContext, values) => {
  return await welletContext.apis.manager.put(`/api/User/password/`, {
    new: values.new_password,
    confirmation: values.confirm_password,
  })
}

export const resetPassword = async (welletContext, email, tenantId) => {
  return await welletContext.apis.manager.post(`/api/User/v2/password/forgot`, {
    email: email,
    tenantId: tenantId,
  })
}

export const validateCode = async (welletContext, email, tenantId, code) => {
  return await welletContext.apis.manager.post(`/api/User/password/validate`, {
    email,
    tenantId,
    code
  })
}

export const getAllLanguages = async (welletContext) => {
  return await welletContext.apis.admin.get(`api/Language/all`)
}