import React, { useContext, useState } from 'react';
import {
  Badge,
  BreadCrumbs,
  Button,
  IconButton,
  Loading,
  Slider,
  Subtitle,
} from '../../uiComponents/common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchActivities, orderByPriority } from '../../services/activityServices';
import { WelletContext } from '../../context/wellet/welletContext';
import { DragDropTable, TableCell } from '../../components';
import useUser from '../../hooks/useUser';
import Popup from 'reactjs-popup';
import { FwCloneIcon, MenuBurger } from '../../uiComponents/icons';

const Activity = () => {
  const navigate = useNavigate();
  const { isSuperAdmin } = useUser();
  const [showInactives, setShowInactives] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const organization = useSelector((state) => state.app.selectedOrganization);
  const currentOrg = useSelector((state) => state.app.currentOrganization);
  const welletContext = useContext(WelletContext);
  const activityQuery = useQuery({
    queryKey: ['activities', organization.id, showInactives],
    queryFn: () => fetchActivities(welletContext, organization.id, showInactives),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const activityPriorityMutation = useMutation({
    mutationFn: (body) => {
      return orderByPriority(welletContext, organization.id, body);
    },
    onSuccess: () => {
      activityQuery.refetch();
    },
  });

  const queryClient = useQueryClient();
  const handleDragEnd = (result) => {
    const currentData = queryClient.getQueryData(['activities', organization.id, showInactives]);

    if (!currentData || !result.destination) {
      return;
    }

    const items = [...currentData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    activityPriorityMutation.mutate(items.map((item) => item.id));
    queryClient.setQueryData(['activities', organization.id, showInactives], items);
  };

  const columns = ['', 'Activity Name', 'Languages', 'Priority', 'Location', 'Capacity', 'Status'];

  return (
    <>
      {isSuperAdmin && currentOrg.id === 83 ? (
        <BreadCrumbs
          crumbs={[
            {
              name: 'Activities',
              path: '/activities',
            }
          ]}
        />
      ) : (
        <></>
      )}
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Activities</h1>}
        endComponent={
          <Button
            className={'btn-custom-white'}
            onClick={() => navigate('/activities/new-activity')}
            size={'small'}
            text={'New Activity'}
          />
        }
      />
      {activityQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='my-2 row align-items-center'>
            <Popup
              on={'hover'}
              trigger={
                <div>
                  <Slider
                    className={'col-auto'}
                    defaultChecked={showAll}
                    onClick={() => setShowAll(!showAll)}
                    label={'Set Priority'}
                  />
                </div>
              }
            >
              <div className='tooltipCard'>
                Activate the slider and drag-and-drop a table row to change priority
              </div>
            </Popup>
            <Slider
              className={'col-auto'}
              label={'Only Actives'}
              onClick={() => setShowInactives(!showInactives)}
              defaultChecked={!showInactives}
            />
          </div>

          <DragDropTable
            tableKey={`activity-${organization.id}-${showAll}`}
            data={activityQuery?.data ?? []}
            columns={columns}
            perPage={
              showAll ? (activityQuery.data?.length > 0 ? activityQuery.data?.length : 10) : 10
            }
            disableDrag={!showAll}
            handleDragEnd={(results) => handleDragEnd(results)}
            renderRow={(activity, index, provided) => (
              <tr
                key={String(index)}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableCell
                  className='ctm-text font-weight-bold text-center'
                  style={{ width: showAll ? '80px' : '60px' }}
                  type={'primary'}
                >
                  <div className='d-flex align-items-center justify-content-center'>
                    {showAll ? (
                      <div className='mr-1'>
                        <MenuBurger size='15px' color='currentColor' />
                      </div>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => navigate(`/activities/clone/${activity.id}`)}
                          icon={<FwCloneIcon />}
                        />
                        <IconButton
                          onClick={() => navigate(`/activities/edit-activity/${activity.id}`)}
                          icon={<i className='far fa-edit'></i>}
                        />
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell style={{ width: '220px' }}>
                  {activity.thumbnail ? (
                    <img
                      className='mr-2'
                      style={{
                        height: '35px',
                        width: '35px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      src={activity.thumbnail}
                      alt={''}
                    />
                  ) : (
                    <></>
                  )}
                  {activity.name}
                </TableCell>
                <TableCell style={{ width: '120px' }}>
                  {activity.langShows.map((s, i, shows) =>
                    i === shows.length - 1 ? s.language : `${s.language} | `,
                  )}
                </TableCell>
                <TableCell style={{ width: '120px' }}>{activity.priority}</TableCell>
                <TableCell style={{ width: '200px' }}>{activity.location}</TableCell>
                <TableCell style={{ width: '120px' }}>{activity.capacity}</TableCell>
                <TableCell style={{ width: '120px' }}>
                  <Badge status={activity.isActive ? 'ACTIVE' : 'INACTIVE'} />
                </TableCell>
                <TableCell></TableCell>
              </tr>
            )}
          />
        </>
      )}
    </>
  );
};

export default Activity;
