import React from 'react';

export default function DoubleChevronIcon({ size = '16px', color = '#000', rotation = 90 }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M1620 4034 c-44 -19 -1340 -1310 -1377 -1372 -35 -59 -35 -145 0
-204 39 -65 1334 -1354 1379 -1373 50 -20 124 -19 174 4 68 31 124 117 124
192 0 84 7 75 -608 691 l-587 588 586 587 c615 617 609 611 609 694 0 77 -53
158 -125 190 -48 22 -127 24 -175 3z'
        />
        <path
          d='M3325 4031 c-72 -32 -125 -113 -125 -191 0 -85 -8 -75 608 -692 l587
-588 -587 -587 c-616 -618 -608 -608 -608 -693 0 -78 53 -159 125 -191 49 -23
123 -24 173 -4 45 19 1340 1308 1379 1373 19 32 25 56 25 102 0 46 -6 70 -25
102 -39 65 -1334 1354 -1379 1373 -50 20 -124 19 -173 -4z'
        />
      </g>
    </svg>
  );
}
