import React, { useEffect, useState } from 'react';
import './toast.css';
import { FwCheckIcon, ErrorCircleIcon } from '../../icons';

function Toast({
  message,
  type,
  delay = 1,
  zIndex = 9999,
  onCallback,
  top = true,
  initDelay = 1000,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setIsVisible(true);

      const hideTimeout = setTimeout(() => {
        setIsVisible(false);
        onCallbackHandle();
      }, delay * 3000);

      return () => clearTimeout(hideTimeout);
    }, initDelay);

    return () => clearTimeout(initialTimeout);
  }, []);

  const onCallbackHandle = () => {
    if (onCallback) onCallback();
  };

  const getPositionClass = () => {
    // Posición configurable
    return top ? 'top-center' : 'bottom-center';
  };

  const getIcon = () => {
    switch (type) {
      case 'SUCCESS':
        return <FwCheckIcon />;
      case 'ERROR':
        return <ErrorCircleIcon />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`toast-container ${getPositionClass()} ${!isVisible && 'd-none'}`}
      style={{ zIndex }}
    >
      <div className={`toasts font-small toast-${type.toLowerCase()} ${isVisible && 'toast-show'}`}>
        <div className='toasts-body'>
          <div className='toasts-icon'>{getIcon()}</div>
          <div>{message}</div>
        </div>
      </div>
    </div>
  );
}

export default Toast;
