export const fetchsOperationsSeller = async (
  welletContext,
  organizationId,
  userId,
  filter = [],
  dates = {},
) => {
  try {
    const response = await welletContext.apis.admin.post(
      `/api/Wallet/summary/operations/${organizationId}/${userId}`,
      {
        OperationTypes: filter,
        From: dates?.from ? dates?.from : null,
        To: dates?.to ? dates?.to : null,
      },
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

//https://admin-api.wellet.dev/api/Wallet/summary/operations/{organizationId}/{userId}
// https://admin-api.wellet.dev/api/Wallet/summary/operations/{organizationId}/{userId}/{limit:int}/{skip:int}
