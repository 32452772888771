import React from 'react';
import { ActivityForm } from '../../components';
import { BreadCrumbs, Subtitle } from '../../uiComponents/common';

const NewActivity = () => {
  return (
    <>
      <BreadCrumbs
        crumbs={[{ name: 'Activities', path: '/activities' }, { name: 'New Activity' }]}
      />
      <Subtitle text={<h1 className='m-0 ctm-title font-weight-bold'>New Activity</h1>} />
      <ActivityForm />
    </>
  );
};

export default NewActivity;
