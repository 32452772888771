import React, { useContext, useState } from 'react';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  Badge,
  BreadCrumbs,
  Button,
  IconButton,
  Loading,
  PerformanceWeekdays,
  Slider,
  Subtitle,
} from '../../uiComponents/common';
import { useNavigate } from 'react-router-dom';
import { Table, TableCell, TableRow } from '../../components';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';
import { useSelector } from 'react-redux';
import { getWeekdays } from '../../helpers/dates';
import { useQuery } from 'react-query';
import useUser from '../../hooks/useUser';
import { getAllPromotions } from '../../services';

const Promotions = () => {
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const [showInactives, setShowInactives] = useState(false);
  const { isSuperAdmin } = useUser();
  const currentOrganization = useSelector((state) => state.app.currentOrganization);

  const promotionsQuery = useQuery({
    queryKey: ['promotions', organization.id, showInactives],
    queryFn: () => getAllPromotions(welletContext, null, null, showInactives, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {isSuperAdmin && currentOrganization.id === 83 ? (
        <BreadCrumbs
          crumbs={[
            {
              name: 'Promotions',
              path: '/promotions',
            },
          ]}
        />
      ) : (
        <></>
      )}
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Promotions</h1>}
        endComponent={
          <Button
            size={'small'}
            className={'btn-custom-white'}
            text={'New Promotion'}
            onClick={() => navigate('/promotions/new-promotion')}
          />
        }
      />
      {promotionsQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='my-2 row align-items-center'>
            <Slider
              className={'col-auto'}
              label={'Only Actives'}
              onClick={() => setShowInactives(!showInactives)}
              defaultChecked={!showInactives}
            />
          </div>
          <Table
            tableKey={`promotion-${organization.id}-${showInactives}-${promotionsQuery?.data?.data?.length}`}
            data={promotionsQuery?.data?.data ?? []}
            totalP={promotionsQuery?.data?.data?.length}
            columns={[
              '',
              'Promotion',
              'Channels',
              'Tickets',
              'Discount Type',
              'Discount Value',
              'Purchase Weekdays',
              'Is Active',
            ]}
            renderRow={(promotion, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: '60px' }} type={'primary'}>
                  <IconButton
                    onClick={() => navigate(`/promotions/edit-promotion/${promotion.id}`)}
                    icon={<FwEditIcon />}
                  />
                </TableCell>
                <TableCell type={'primary'}>{promotion.name}</TableCell>
                <TableCell type={'primary'}>{promotion.promotionChannel?.map((c) => c?.channel?.name)?.join(', ')}</TableCell>
                <TableCell type={'primary'}>{promotion.productPromotions?.map((p) => p?.ticketProduct?.name)?.join(', ')}</TableCell>
                <TableCell type={'primary'}>{promotion.discountType}</TableCell>
                <TableCell type={'primary'}>{promotion.discountValue}</TableCell>
                <TableCell type={'primary'}>
                  <PerformanceWeekdays
                    performance={promotion?.purchaseWeekdays?.map((w) => getWeekdays(w))}
                  />
                </TableCell>
                <TableCell type={'primary'}>
                  <Badge status={promotion.isActive ? 'Active' : 'Inactive'} />
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Promotions;
