export const getAllVenues = async (welletContext, limit = 20, skip = 0, organizationId = null) => {
  return await welletContext.apis.admin.post(`api/Venue/all`, {
    limit,
    skip,
    organizationId,
  });
};
export const getVenueById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`api/Venue/${id}`);
};
export const postVenue = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`api/Venue`, body);
};
export const putVenue = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`api/Venue`, body);
};
