import React, { useContext, useState } from 'react';
import { BreadCrumbs, CardRowInfo, InputText, Loading } from '../../uiComponents/common';
import { PageHeader, ProfileExtraInfo, ProfileMainInfo, ProfileSecondary } from '../../components';
import { useLocation, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import './profile.css';
import { getProfilePic, getUserById } from '../../services';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import useUser from '../../hooks/useUser';
import { UserIcon } from '../../uiComponents/icons';
import { format } from 'date-fns';

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();
  const { user } = useUser();
  const location = useLocation();
  const welletContext = useContext(WelletContext);
  const methods = useForm();
  const isFromSeller = location.pathname.split('/')[1] === 'sellersDetails';

  const userQuery = useQuery({
    queryKey: ['user', id],
    queryFn: () => getUserById(welletContext, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: isFromSeller,
  });

  // Functions
  const onSubmit = (data) => {
    console.log(data);
  };

  const name = isFromSeller
    ? `${userQuery?.data?.data?.firstName} ${userQuery?.data?.data?.lastName}`
    : user.name;
  const role = isFromSeller ? userQuery?.data?.data?.role?.join(', ') : user?.roles?.join(', ');
  const image = isFromSeller ? userQuery?.data?.data?.profilePic : user?.profilePic;
  const phone = isFromSeller ? userQuery?.data?.data?.phone : user?.phone;
  const email = isFromSeller ? userQuery.data?.data?.email : user?.email;

  const profileQuery = useQuery({
    queryKey: ['profile-pic', id],
    queryFn: () => getProfilePic(welletContext, image.slice(image.indexOf('/api'))),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!image,
  });

  const imageUrl = profileQuery.isSuccess
    ? URL.createObjectURL(new Blob([profileQuery?.data?.data], { type: 'image/jpeg' }))
    : null;

  const rowProfile = (title, text) => (
    <div className={`row height-input-content`}>
      <div className={`col-auto pr-2 font-weight-semibold`} style={{ color: 'var(--color-text)' }}>
        {title}
      </div>
      <div className='col' style={{ color: 'var(--color-text)' }}>
        {text}
      </div>
    </div>
  );

  // const userProfile = (
  //   <div className='col'>
  //     {/* {isEditing ? (
  //       <FormProvider {...methods}>
  //         <form key={1} onSubmit={methods.handleSubmit(onSubmit)} className='w-100'>
  //           <div className={`row height-input-content`}>
  //             <div className={`col-auto font-weight-semibold`}>Name:</div>
  //             <div className='col-4'>
  //               <InputText name='user-name' />
  //             </div>
  //           </div>
  //         </form>
  //       </FormProvider>
  //     ) : ( */}
  //       {rowProfile('Name:', name)}
  //     {rowProfile('Email:', email)}
  //     {rowProfile('Roles:', role)}
  //   </div>
  // );

  return (
    <>
      {userQuery.isLoading || profileQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          {isFromSeller ? (
            <BreadCrumbs
              crumbs={[
                { name: 'Sellers', path: `/sellers` },
                { name: name, path: `/sellers/sellersDetails/2/${id}` },
                { name: 'Profile' },
              ]}
            />
          ) : (
            <></>
          )}
          <PageHeader title={'Profile'} />
          <div className='profile-section'>
            <div className='row'>
              <div className='row col-12 d-flex'>
                <ProfileMainInfo image={imageUrl} name={name} phone={phone} />
                <div className='row col pl-3' style={{ height: '50%' }}>
                  <div className='row col pl-3 pt-3 border-bottom data-section'>
                    {<ProfileSecondary email={email} name={name} role={role} />}
                  </div>
                  <div className='row col-12 p-3'>
                    {isFromSeller ? <ProfileExtraInfo user={userQuery} /> : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
