import React, { useContext } from 'react';
import { Alert, Select, Subtitle, ZoomImg } from '../../../uiComponents/common';
import { ExclamationIcon } from '../../../uiComponents/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { putKycStatus } from '../../../services';
import { WelletContext } from '../../../context/wellet/welletContext';
import FilledArrowDown from '../../../uiComponents/icons/filledArrowDown';

const UserKyc = ({ status, data, id }) => {
  const methods = useForm({
    defaultValues: {
      status: { label: status[0]?.toUpperCase() + status.slice(1).toLowerCase(), value: status },
    },
  });
  const welletContext = useContext(WelletContext);
  const kycStatusMutation = useMutation({
    mutationFn: (body) => {
      return putKycStatus(welletContext, body);
    },
  });

  return (
    <div className='col-12 p-0 m-0'>
      <Subtitle text={<h3>Know Your Client</h3>} />
      {status === 'PENDING' ? (
        <div className='row'>
          <div className='col-12 col-sm-6'>
            <Alert
              icon={<ExclamationIcon color='currentColor' />}
              title={'Pending'}
              type={'warning'}
              text={'No documentation loaded. Please contact the user.'}
            />
          </div>
        </div>
      ) : (
        <>
          <FormProvider {...methods}>
            <div className='row mt-2'>
              {data?.images &&
                data.images.map((i, ind) => (
                  <div className='col-auto mb-3' key={i + ind}>
                    <ZoomImg width={200} img={i} />
                  </div>
                ))}
            </div>
            <div className='row align-items-center'>
              <label className='col-auto mt-2' style={{ color: 'var(--color-subtitle)' }}>
                Status:
              </label>
              <Select
                name={'status'}
                className={'col'}
                onManualChange={(d) =>
                  kycStatusMutation.mutate({
                    userId: id,
                    isApproved: d.value === 'APPROVED' ? true : false,
                  })
                }
                isSearchable={false}
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: '12px',
                    textAlign: 'center',
                    maxWidth: '120px',
                    backgroundColor:
                      methods.watch('status.value') === 'APPROVED'
                        ? 'var(--color-background-approved)'
                        : methods.watch('status.value') === 'REJECTED'
                        ? 'var(--color-background-error)'
                        : '',
                    borderColor:
                      methods.watch('status.value') === 'APPROVED'
                        ? 'var(--color-border-approved)'
                        : methods.watch('status.value') === 'REJECTED'
                        ? 'var(--color-border-error)'
                        : '',
                  }),
                  control: (baseStyles, props) => ({
                    ...baseStyles,
                    border: 'none',
                    outline: 0,
                    boxShadow: 'none',
                    paddingLeft: '8px',
                    minHeight: '34px',
                    color:
                      methods.watch('status.value') === 'APPROVED'
                        ? 'var(--color-text-approved)'
                        : methods.watch('status.value') === 'REJECTED'
                        ? 'var(--color-text-error)'
                        : '',
                    borderRadius: '12px',
                    background: props.isDisabled ? '#e1e1e1' : 'none',
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: '0',
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    padding: 0,
                    margin: 0,
                    color: 'var(--text-color)',
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: 0,
                    marginTop: '6px',
                    backgroundColor: 'var(--color-background-app)',
                    borderRadius: '12px',
                    zIndex: 99999,
                  }),
                  menuList: (baseStyles) => ({
                    ...baseStyles,
                    padding: 0,
                    borderRadius: '12px',
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: 'var(--text-color)',
                    fontWeight: 'bold',
                  }),
                  menuPortal: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: '12px',
                    zIndex: 9999,
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    border: 'none',
                    fontWeight: 'bold',
                    color: 'var(--text-color)',
                    backgroundColor: state.isSelected
                      ? 'rgba(0,0,0,.10)'
                      : 'var(--color-background-app)',
                    ':hover': {
                      backgroundColor: state.isSelected ? 'rgba(0,0,0,.10)' : 'rgba(0,0,0,.03)',
                    },
                  }),
                }}
                customIcon={
                  <div className='p-0 m-0'>
                    <FilledArrowDown size='12px' />
                  </div>
                }
                options={[
                  { label: 'Approved', value: 'APPROVED' },
                  { label: 'Rejected', value: 'REJECTED' },
                ].filter((o) => o.value !== methods.watch('status.value'))}
              />
            </div>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default UserKyc;
