import React, { useEffect } from 'react';
import './table.css';
import { usePagination } from '../../context/paginationContext';
import Pagination from '../pagination/pagination';

const Table = ({
  data,
  columns,
  renderRow,
  tableKey,
  totalP,
  showText = true,
  hasPagination = true,
  showPagination = true,
  renderLastRow,
}) => {
  const itemsPerPage = 10;
  const totalItems = totalP;
  const { paginationState, setPagination } = usePagination();
  // Verifica si hay un estado de paginación para esta tabla en el contexto
  const tablePagination = paginationState[tableKey] || {
    currentPage: 1,
    totalPages: Math.ceil(totalItems / itemsPerPage),
  };
  const { currentPage, totalPages } = tablePagination;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const dataToDisplay = hasPagination ? data.slice(startIndex, endIndex) : data;

  const handlePageChange = (newPage) => {
    setPagination(tableKey, { currentPage: newPage, totalPages: totalPages });
  };
  useEffect(() => {
    const currentTableKey = Object.keys(paginationState);
    if (tableKey !== currentTableKey[0]) {
      setPagination(tableKey, { currentPage: 1, totalPages: totalPages });
    }
  }, [tableKey]);

  return (
    <div className='w-100'>
      <div className='datatable-scroll '>
        <table
          className='table datatable-basic dataTable no-footer'
          role='grid'
          aria-describedby='DataTables_Table_0_info'
        >
          <thead className=''>
            <tr className=''>
              {columns.map((h, index) => {
                return index === columns.length - 1 ? (
                  <th
                    className='sorting text-left text-sidebar-link ctm-text-small font-weight-bold'
                    tabIndex='0'
                    aria-controls='DataTables_Table_0'
                    rowSpan='1'
                    colSpan='1'
                    aria-label={h}
                    key={index}
                  >
                    {h}
                  </th>
                ) : (
                  <th
                    className='sorting text-left text-sidebar-link font-weight-bold ctm-text-small'
                    tabIndex='0'
                    aria-controls='DataTables_Table_0'
                    rowSpan='1'
                    colSpan='1'
                    aria-label={h}
                    key={index}
                  >
                    {h}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dataToDisplay && dataToDisplay?.length > 0 ? (
              dataToDisplay?.map((rowData, index) => (
                <React.Fragment key={index}>
                  {renderRow(rowData, index)}
                  {index === dataToDisplay.length - 1 &&
                    renderLastRow &&
                    currentPage === totalPages &&
                    renderLastRow(rowData, index)}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className='text-center'
                  style={{ color: 'var(--color-subtitle)' }}
                >
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showPagination ? (
        <div className='datatable-footer '>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            showText={showText}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Table;
