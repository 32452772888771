import React from 'react';
import './buttonGallery.css';
const ButtonGallery = ({
  onClick,
  text,
  icon,
  type = 'button',
  width = '200px',
  height = '200px',
  msg,
  stylesError = null,
}) => {
  const stylesButtonGallery = {
    width: width,
    height: height,
  };
  return (
    <button
      onClick={onClick}
      type={type}
      className={`d-flex flex-column align-items-center justify-content-center p-4 buttonGallery ${
        stylesError && 'error'
      }`}
      style={stylesButtonGallery}
    >
      <span className='mb-1'>{icon}</span>
      {text}
      <span>{msg}</span>
    </button>
  );
};

export default ButtonGallery;
