import { Link } from 'react-router-dom';
import './signInHeader.css';
import welletLargeLogo from '../../assets/images/wellet.png';

export default function SignInHeader() {
  return (
    <div className='navbar sign-header-container justify-content-center py-3'>
      <Link to={'/'}>
        <img alt='Wellet' src={welletLargeLogo} height={20} />
      </Link>
    </div>
  );
}
