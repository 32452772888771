import React, { useContext, useEffect, useState } from 'react';
import { Table, FilterCard, TableRow, TableCell, DateFilter } from '../../components';
import { useQuery } from 'react-query';
import { fetchPayments } from '../../services/liveFeedServices';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  Badge,
  BreadCrumbs,
  Button,
  ButtonFilter,
  Loading,
  Money,
  Subtitle,
  Tooltip,
} from '../../uiComponents/common';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { getCurrentFilter, getStatusMessage } from '../../helpers/format';
import MercadoPago from '../../uiComponents/icons/mercadoPago';
import Fiserv from '../../uiComponents/icons/fiserv';
import { usePagination } from '../../context/paginationContext';
import { fetchsOrganizations } from '../../services';
import { ClearFilterIcon } from '../../uiComponents/icons';

const Orders = () => {
  const organization = useSelector((state) => state.app.selectedOrganization);
  const user = useSelector((state) => state.user.user.sid);
  const welletContext = useContext(WelletContext);
  const { paginationState } = usePagination();
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const isMainCompany = organization.id === 83;
  const navigate = useNavigate();

  const [filter, setFilter] = useState(null);
  const [filterOrganization, setFilterOrganization] = useState([]);

  useEffect(() => {
    if (filter === null || !!(filter[0] ?? '')) {
      setFilter([]);
    }
  }, [organization.id]);

  const selectedFormattedDates = {
    from: selectedDates.from ? new Date(selectedDates.from).toISOString() : null,
    to: selectedDates.to ? new Date(selectedDates.to).toISOString() : null,
  };

  const tableKey = `orders-${organization.id}-${filter ? filter[0] ?? '' : ''}-${JSON.stringify(
    selectedFormattedDates,
  )}-${JSON.stringify(filterOrganization)}`;

  let limit = 10;
  let skip =
    10 * paginationState[tableKey]?.currentPage - 10
      ? 10 * paginationState[tableKey]?.currentPage - 10
      : 0;

  const quantitiesQuery = useQuery({
    queryKey: [
      'orders-quantities',
      '',
      organization.id,
      selectedFormattedDates,
      10,
      0,
      filterOrganization,
    ],
    queryFn: () =>
      fetchPayments(
        welletContext,
        [],
        organization.id,
        selectedFormattedDates,
        limit,
        skip,
        filterOrganization,
      ),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: filter !== null,
  });
  const paymentsQuery = useQuery({
    queryKey: [
      'orders',
      filter ? filter[0] ?? '' : '',
      organization.id,
      selectedFormattedDates,
      limit,
      skip,
      filterOrganization,
    ],
    queryFn: () =>
      fetchPayments(
        welletContext,
        filter,
        organization.id,
        selectedFormattedDates,
        limit,
        skip,
        filterOrganization,
      ),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: filter !== null,
  });
  const organizationsQuery = useQuery({
    queryKey: ['listOrg', user],
    queryFn: () => fetchsOrganizations(welletContext),
    onSuccess: (data) => {
      let response = data?.map((org) => ({
        label: org.name,
        value: org.id,
        isActive: false,
      }));
      setFilterOrganization(response);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: isMainCompany,
  });
  const paymentFormatted = paymentsQuery.data?.activities?.map((payment) => {
    // Tags que necesito para mis th y mis td
    const allowedKeys = [
      'GatewayName',
      'Price',
      'Currency',
      'Company',
      'BookingCode',
      'CustomerEmail',
      'PurchaseDate',
      'Channel',
      'Status',
      'BookingStatus',
      'IsAutomaticConfirmation',
    ];
    // Filtrar las tags para incluir solo las claves que necesitas
    const tagsFiltered = payment?.tags
      .filter((tag) => allowedKeys.includes(tag.type))
      .reduce((obj, tag) => {
        // Convierte la primera letra de la clave a minúscula
        const key = tag.type.charAt(0).toLowerCase() + tag.type.slice(1);
        // Asigna el valor al objeto con la clave en minúscula
        obj[key] = tag.value;
        return obj;
      }, {});

    return {
      id: payment.id,
      tags: tagsFiltered,
    };
  });
  const uniquePayments = paymentFormatted?.filter(
    (payment, index, self) =>
      index === self.findIndex((p) => p.tags.bookingCode === payment.tags.bookingCode),
  );

  const navigateToOrdersDetail = (id) => {
    navigate(`/orders/${organization.id}/${id}`);
  };
  const handleClick = (filter) => {
    setFilter(filter);
  };

  const clear = () => {
    // To Do: Ver como seria si hay mas filtros
    const resetedFilter = organizationsQuery?.data?.map((org) => ({
      label: org.name,
      value: org.id,
      isActive: false,
    }));
    setFilterOrganization(resetedFilter);
  };

  return (
    <>
      <BreadCrumbs
        crumbs={[
          {
            name: 'Orders',
            path: '/orders',
          },
        ]}
      />
      <Subtitle
        text={
          <div className='w-100 mt-1'>
            <div className='payment-title text-truncate mr-2'>Orders</div>
          </div>
        }
        endComponent={
          <div className='mt-1'>
            <DateFilter filterName={'Date'} />
          </div>
        }
      />
      {paymentsQuery.isFetching && quantitiesQuery.isFetching ? (
        <Loading />
      ) : (
        <>
          <div className='row my-3'>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'All'}
                quantity={quantitiesQuery.data?.totalCount}
                onClick={() => handleClick([])}
                isSelected={filter && filter.length === 0}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Approved'}
                quantity={quantitiesQuery.data?.totalApprovedCount}
                onClick={() => handleClick(['approved'])}
                isSelected={filter && filter[0] === 'approved'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Refunded'}
                quantity={quantitiesQuery.data?.totalRefundedCount}
                onClick={() => handleClick(['refunded'])}
                isSelected={filter && filter[0] === 'refunded'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Pending'}
                quantity={quantitiesQuery.data?.totalPendingCount}
                onClick={() => handleClick(['pending'])}
                isSelected={filter && filter[0] === 'pending'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Expired'}
                quantity={quantitiesQuery.data?.totalExpiredCount}
                onClick={() => handleClick(['expired'])}
                isSelected={filter && filter[0] === 'expired'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Error'}
                quantity={quantitiesQuery.data?.totalErrorCount}
                onClick={() => handleClick(['error'])}
                isSelected={filter && filter[0] === 'error'}
              />
            </div>
          </div>

          {isMainCompany && (
            <div className='row my-3'>
              <div className='col-12 col-md-auto p-0'>
                <ButtonFilter
                  type={'checkbox'}
                  filterName={'Organization'}
                  filterOptions={filterOrganization}
                  callback={setFilterOrganization}
                  isSearchable={true}
                />
              </div>

              <div className='col-12 mt-2 col-md-auto p-0 ml-md-2 mt-md-0'>
                <Button
                  className={
                    'd-flex justify-content-center align-items-center btn-custom-red clean-filter-btn'
                  }
                  size={'small'}
                  type='button'
                  text={`Clear All`}
                  onClick={clear}
                  icon
                  typeIcon={<ClearFilterIcon />}
                  startIcon
                />
              </div>
            </div>
          )}
          {!uniquePayments ? (
            <Loading />
          ) : (
            <Table
              data={uniquePayments}
              totalP={getCurrentFilter(filter ? filter[0] ?? '' : '', paymentsQuery.data, true)}
              tableKey={tableKey}
              hasPagination={false}
              columns={['Amount', '', '', 'Description', 'Customer', 'Date', 'Gateway']}
              renderRow={(payment, index) => (
                <TableRow
                  key={payment.id}
                  index={index}
                  onClick={() => navigateToOrdersDetail(payment.id)}
                >
                  <TableCell
                    className='ctm-text font-weight-bold'
                    style={{ width: '120px' }}
                    type={'primary'}
                  >
                    <Money value={payment.tags.price} currencyCode={payment.tags.currency} />
                  </TableCell>
                  <TableCell style={{ width: '120px' }}>
                    <Tooltip text={getStatusMessage(payment.tags.status)}>
                      <Badge status={payment.tags.status} />
                    </Tooltip>
                  </TableCell>
                  {organization.id === 83 ? (
                    <TableCell>{payment.tags.company}</TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                  <TableCell>{payment.tags.bookingCode}</TableCell>
                  <TableCell>{payment.tags.customerEmail}</TableCell>
                  <TableCell>
                    {format(new Date(payment.tags.purchaseDate), 'MMM dd, yyyy, h:mm a')}
                  </TableCell>
                  <TableCell>
                    {payment.tags.gatewayName === 'mercadopago' ? (
                      <div className='img-table d-flex align-items-center rounded justify-content-center overflow-hidden'>
                        <MercadoPago size={'30px'} />
                      </div>
                    ) : payment.tags.gatewayName === 'fiserv' ? (
                      <div className='img-table d-flex align-items-center rounded justify-content-center overflow-hidden'>
                        <Fiserv size={'30px'} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

export default Orders;
