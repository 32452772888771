export const getAllPromotions = async (
  welletContext,
  limit = 20,
  skip = 0,
  includeInactive = false,
  orgId,
) => {
  let url = 'api/Promotion/all';

  return await welletContext.apis.admin.post(url, {
    limit,
    skip,
    includeInactive,
    organizationId: orgId,
  });
};

export const postPromotion = async (welletContext, body) => {
  return await welletContext.apis.admin.post(`/api/Promotion`, body);
};

export const getPromotionById = async (welletContext, id) => {
  return await welletContext.apis.admin.get(`/api/Promotion/${id}`);
};
export const putPromotion = async (welletContext, body) => {
  return await welletContext.apis.admin.put(`/api/Promotion`, body);
};
