import React, { useContext, useState } from 'react';
import { SoldOutForm, Table, TableCell, TableRow } from '../../components';
import {
  BreadCrumbs,
  Button,
  ErrorAlert,
  IconButton,
  Loading,
  Modal,
  Subtitle,
  Toast,
  Tooltip,
} from '../../uiComponents/common';
import { FwTrashIcon } from '../../uiComponents/icons';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation, useQuery } from 'react-query';
import { deleteSoldOut, getAllSoldOut } from '../../services';
import { useSelector } from 'react-redux';

const SoldOut = () => {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const organization = useSelector((state) => state.app.selectedOrganization);
  const welletContext = useContext(WelletContext);

  const soldOutQuery = useQuery({
    queryKey: ['soldout', organization.id],
    queryFn: () => getAllSoldOut(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const deleteQuery = useMutation({
    mutationKey: ['delete-soldout'],
    mutationFn: (id) => deleteSoldOut(welletContext, id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {deleteQuery.isSuccess && (
        <Toast
          message='Sold Out deleted successfully.'
          type='SUCCESS'
          onCallback={() => {
            deleteQuery.reset();
            setDeleteId('');
            soldOutQuery.refetch();
          }}
        />
      )}
      <BreadCrumbs crumbs={[{ name: 'Sold Out', path: '/sold-out' }]} />
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Sold Out</h1>}
        endComponent={
          organization.id === 1 ? (
            <Tooltip
              marginL='-80px'
              marginT='45px'
              text={'Please select a company to create a sold out.'}
            >
              <Button
                size={'small'}
                disabled
                className={'btn-custom-white'}
                text={'New Sold Out'}
                onClick={() => setOpen(true)}
              />
            </Tooltip>
          ) : (
            <Button
              size={'small'}
              className={'btn-custom-white'}
              text={'New Sold Out'}
              onClick={() => setOpen(true)}
            />
          )
        }
      />
      {soldOutQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            tableKey={`sold-out-${soldOutQuery?.data?.data.length}-${organization.id}`}
            data={soldOutQuery?.data?.data}
            totalP={soldOutQuery?.data?.data.length}
            columns={
              organization.id === 1
                ? ['', 'Organization', 'Activity', 'Product', 'Description']
                : ['', 'Activity', 'Product', 'Description']
            }
            renderRow={(soldout, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: '80px' }} type={'primary'}>
                  <IconButton
                    onClick={() => {
                      setDeleteId(soldout.id);
                    }}
                    icon={<FwTrashIcon />}
                  />
                </TableCell>
                {organization.id === 1 ? (
                  <TableCell type={'primary'}>{soldout.ticket.show?.organization?.name}</TableCell>
                ) : null}
                <TableCell type={'primary'} className='ctm-text font-weight-bold '>
                  {soldout.ticket.show.name}
                </TableCell>
                <TableCell type={'primary'}>{soldout.ticket.name}</TableCell>
                <TableCell type={'primary'}>{soldout.description}</TableCell>
              </TableRow>
            )}
          />
        </>
      )}
      <SoldOutForm open={open} onClose={() => setOpen(false)} onSuccess={soldOutQuery.refetch} />
      <Modal
        idModal={'priceModal'}
        title={'Delete Sold Out'}
        open={!!deleteId}
        handleSubmit={() => {
          deleteQuery.mutate(deleteId);
        }}
        onClose={() => {
          setDeleteId('');
          deleteQuery.reset();
        }}
        textCancel={'Cancel'}
        textSubmit={'Delete'}
      >
        <div className='modal-background font-weight-bold text-center'>
          Are you sure you want to delete this sold out?
          {deleteQuery?.error ? (
            <ErrorAlert text={'Error while trying to delete sold out. Try again later.'} />
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SoldOut;
