import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Page } from '../components';

export default function AuthRoute() {
  const user = useSelector((state) => state.user);
  const authenticated = user.isAuthenticated;
  const isSuperAdmin = authenticated && user.user && user.roles.length > 0;

  if (!isSuperAdmin) {
    return <Navigate to={{ pathname: '/login' }} replace />;
  }
  return <Page><Outlet /></Page>;
}
