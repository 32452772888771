import React from 'react';

const Tabs = ({ options, onClick }) => {
  return (
    <ul className='nav nav-tabs nav-tabs-bottom border-table-bottom'>
      {options.map((o, i) => (
        <li
          key={i}
          className={`nav-link cursor-pointer ${o.isActive ? 'active' : ''}`}
          onClick={() => onClick(o)}
        >
          {o.title}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
