import React from 'react';

export default function CompanysIcon({
  color = 'currentColor',
  fontSize = '16px',
  isSelected = false,
}) {
  let stateColor = isSelected ? '#503ec4' : color;
  return (
    <i
      className={`fas fa-store `}
      style={{
        color: stateColor,
        fontSize: fontSize,
      }}
    ></i>
  );
}
