import React, { useContext, useState } from 'react';
import {
  Badge,
  BreadCrumbs,
  Card,
  CardRowDetails,
  CardRowInfo,
  Loading,
  Modal,
  Money,
  Subtitle,
  Dropdown,
} from '../../uiComponents/common';
import './operationDetail.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { WelletContext } from '../../context/wellet/welletContext';
import {
  fetchOperationById,
  fetchOperationByIdSuperAdmin,
  fetchPaymentById,
  refundOrder,
} from '../../services';
import { format } from 'date-fns';
import { useRedirectIfSuperAdmin } from '../../hooks/useRedirectIfSuperAdmin';
import { setSelectedUser } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import useUser from '../../hooks/useUser';
import { Pagination } from '../../components';
import usePagination from '../../hooks/usePagination';
import CheckinData from './checkinDetail';

const OperationDetail = () => {
  const [open, setOpen] = useState(false);

  // Constants
  const { isSuperAdmin } = useUser();
  const { id, orgId } = useParams();
  const organization = useSelector((state) => state.app.currentOrganization);
  const welletContext = useContext(WelletContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sellerId = queryParams.get('sellerId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFromOrders = location.pathname.split('/')[1] === 'orders';

  // Query Functions Api
  const bookingQuery = useQuery(['booking', id], () => fetchPaymentById(welletContext, id), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const operationQuery = useQuery(
    ['operation-super', id, orgId],
    () => fetchOperationByIdSuperAdmin(welletContext, orgId, id, sellerId),
    {
      onError: () => navigate('/wallets'),
      refetchInterval: false,
      enabled: isSuperAdmin,
      refetchOnWindowFocus: false,
    },
  );
  const operationAdminQuery = useQuery(
    ['operation', id, orgId],
    () => fetchOperationById(welletContext, id, sellerId),
    {
      onError: () => navigate('/wallets'),
      enabled: !isSuperAdmin,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );
  useRedirectIfSuperAdmin(!operationQuery?.isFetching, '/wallets');
  const refundQuery = useQuery(
    ['refund', id],
    () => refundOrder(welletContext, id, operation.organizationId),
    {
      enabled: false,
      onSuccess: () => (isSuperAdmin ? operationQuery.refetch() : operationAdminQuery.refetch()),
    },
  );
  const { currentPage, goToPage, totalPages } = usePagination(
    isSuperAdmin
      ? operationQuery.data?.data?.paymentDetails?.length ?? 1
      : operationAdminQuery.data?.data?.paymentDetails?.length,
    1,
  );

  // Loading Conditional
  if (operationQuery.isLoading || operationAdminQuery.isLoading || bookingQuery.isLoading) {
    return <Loading />;
  }

  // Query Constants
  const operation = isSuperAdmin ? operationQuery?.data?.data : operationAdminQuery.data?.data;
  const crumbs = sellerId
    ? [
        {
          name: organization.id === 83 ? 'Wallets' : 'Sellers',
          path: organization.id === 83 ? '/wallets' : '/sellers',
        },
        {
          name: operation?.walletDetails[0]?.name,
          path: `/sellers/sellersDetails/${orgId}/${sellerId}`,
        },
        { name: 'Operation' },
      ]
    : [
        {
          name: isFromOrders ? 'Orders' : 'Wallets',
          path: isFromOrders ? '/orders' : '/wallets',
        },
        { name: 'Operation', disabled: true },
      ];
  const hasTransport = operation.orderItems.some((p) => p.isTransportProduct);
  const currentPayment = operation.paymentDetails[currentPage - 1];
  const booking = bookingQuery.data.activities[0].tags;

  // Conditions Constants
  const isApproved = booking?.find((c) => c.type === 'Status').value === 'APPROVED' ?? null;
  const isNotAproved = booking?.find((c) => c.type === 'Status').value !== 'APPROVED';

  // Functions
  function calculateTotalAmount(array) {
    // Filtra el arreglo para excluir objetos con los operationType especificados
    const filteredArray = array.filter((item) => {
      const excludedOperationTypes = [
        'REFUND_COMMISSION',
        'REFUND_SALE',
        'REFUND_PROCESSING_FEE',
        'REFUND_IVA',
      ];
      return !excludedOperationTypes.includes(item.operationType);
    });

    // Inicializa una variable para el total
    let totalAmount = 0;

    // Recorre el nuevo arreglo filtrado y suma los amounts
    filteredArray.forEach((item) => {
      totalAmount += item.amount;
    });

    return totalAmount.toFixed(2);
  }
  const handleRefund = () => {
    refundQuery.refetch();
    setOpen(!open);
  };

  return (
    <>
      <BreadCrumbs crumbs={crumbs} />
      <Subtitle
        text={
          <div className='payment-title d-flex align-items-center'>
            <Money value={operation?.totalAmount} currencyCode={'MXN'} />
            <Badge status={operation.status} />
          </div>
        }
        endComponent={
          <Dropdown
            id={'dropdownOperation'}
            isHover={false}
            customDropdwon={''}
            btn={<button className='mt-1 btn-custom-white'>Actions</button>}
            list={
              <ul className='actionList'>
                {isFromOrders && isApproved ? (
                  <li className='dropdown-item justify-content-start' onClick={() => setOpen(true)}>
                    <i className={`fas fa-reply ctm-text-small`}></i>
                    <span className='ml-1'>Refund</span>
                  </li>
                ) : null}
                {isFromOrders && isNotAproved && (
                  <li className='dropdown-item justify-content-start'>No actions</li>
                )}
              </ul>
            }
          />
        }
      />
      <div className='row mt-1'>
        <div className='col-12 col-md-auto right-divider text-capitalize mb-1'>
          <CardRowInfo title={'Reference Code'} value={operation.referenceCode} />
        </div>
        <div className='col-12 col-md-auto right-divider text-capitalize mb-1'>
          <CardRowInfo
            title={'Transaction Date'}
            value={format(new Date(operation.transactionDateUTC), 'MMM dd, yyyy, h:mm a')}
          />
        </div>
        <div className='col-12 col-md-auto right-divider text-capitalize mb-1'>
          <CardRowInfo
            title={'Last update'}
            value={format(new Date(operation.lastUpdateUTC), 'MMM dd, yyyy, h:mm a')}
          />
        </div>
        <div className='col-12 col-md-auto right-divider mb-1'>
          <CardRowInfo title={'Customer'} value={operation?.customerUser?.email ?? '-'} />
        </div>
      </div>

      <Subtitle text={<h3>Items</h3>} />
      {operation.orderItems &&
        operation.orderItems.map((o, i) => (
          <React.Fragment key={i}>
            <CardRowDetails
              title={`${o.quantity}x ${o.product.name} ${
                o.isTransportProduct ? '(Transport)' : ''
              }`}
              value={
                <>
                  {o.priceWithoutDiscount !== o.price ? (
                    <div className='d-flex'>
                      <div className='text-strikethrough'>
                        <Money value={o.priceWithoutDiscount} />
                      </div>{' '}
                      <Money value={o.price} />
                    </div>
                  ) : (
                    <Money value={o.price} />
                  )}
                </>
              }
            />
          </React.Fragment>
        ))}

      <Subtitle text={<h3>Operation summary</h3>} />
      <CardRowDetails title={'Items'} value={operation.orderSummary.itemsQuantity} />
      <CardRowDetails title={'Paxs'} value={operation.orderSummary.totalPax} />
      <CardRowDetails
        title={'Subtotal'}
        value={<Money value={operation.orderSummary.subtotal ?? 0} />}
      />
      {operation.orderSummary.discount ? (
        <CardRowDetails
          title={'Discount'}
          value={<Money value={operation.orderSummary.discount ?? 0} />}
        />
      ) : (
        <></>
      )}
      {operation.orderSummary.additionalDiscount ? (
        <CardRowDetails
          title={'Additional Discount'}
          value={<Money value={operation.orderSummary.additionalDiscount} />}
        />
      ) : (
        <></>
      )}
      {operation.orderSummary.tip ? (
        <CardRowDetails title={'Tip'} value={<Money value={operation.orderSummary.tip ?? 0} />} />
      ) : (
        <></>
      )}
      {operation.orderSummary.serviceFee ? (
        <CardRowDetails
          title={'Service Fee'}
          value={<Money value={operation.orderSummary.serviceFee ?? 0} />}
        />
      ) : (
        <></>
      )}
      {hasTransport ? (
        <CardRowDetails
          title={'Transportation'}
          value={<Money value={operation.orderSummary.transportationPrice ?? 0} />}
        />
      ) : (
        <></>
      )}
      <CardRowDetails title={'Total Amount'} value={<Money value={operation.totalAmount} />} />

      <Subtitle text={<h3>Client</h3>} />
      <CardRowDetails title={'Name'} value={operation?.customerUser?.fullName ?? '-'} />
      <CardRowDetails title={'Email'} value={operation?.customerUser?.email ?? '-'} />
      <CardRowDetails title={'Phone'} value={operation?.customerUser?.phoneNumber ?? '-'} />
      <CardRowDetails title={'Country'} value={operation?.customerUser?.nationality ?? '-'} />
      {operation.sellerUser && !isFromOrders ? (
        <>
          <Subtitle text={<h3>Seller</h3>} />
          <CardRowDetails
            title={'Name'}
            value={
              <Link
                to={`/sellers/sellersDetails/${orgId}/${operation.sellerUser.id}`}
                onClick={() =>
                  dispatch(
                    setSelectedUser({
                      name: operation.sellerUser.fullName,
                      email: operation.sellerUser.email,
                      id: operation.sellerUser.id,
                    }),
                  )
                }
              >
                {operation.sellerUser.fullName}
              </Link>
            }
          />
          <CardRowDetails title={'Email'} value={operation.sellerUser.email} />

          {operation.sellerUser.salesCategory ? (
            <>
              <CardRowDetails
                title={'Category'}
                value={operation.sellerUser.salesCategory?.name ?? ''}
              />
              <CardRowDetails
                title={'Commission'}
                value={operation.sellerUser.salesCategory?.commissionPercentage + '%'}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      {!isFromOrders ? (
        <>
          <Subtitle text={<h3>{sellerId ? 'Seller Details' : 'Wallet Details'}</h3>} />
          {operation.walletDetails.find((w) => w.operationType === 'SALE') ? (
            <CardRowDetails
              title={'Sale'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'SALE')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'COMMISSION') ? (
            <CardRowDetails
              title={'Commission'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'COMMISSION')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'DISCOUNT') ? (
            <CardRowDetails
              title={'Discount'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'DISCOUNT')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'TIP') ? (
            <CardRowDetails
              title={'Tip'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'TIP')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'TIP_IVA') ? (
            <CardRowDetails
              title={'Tip Iva'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'TIP_IVA')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'TIP_PROCESSING_FEE') ? (
            <CardRowDetails
              title={'Tip Processing Fee'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'TIP_PROCESSING_FEE')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'IVA') ? (
            <CardRowDetails
              title={'Iva'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'IVA')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'PROCESSING_FEE') ? (
            <CardRowDetails
              title={'Processing Fee'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'PROCESSING_FEE')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          {operation.walletDetails.find((w) => w.operationType === 'PAYMENT_SHOWPRODUCER') ? (
            <CardRowDetails
              title={'Payment Show Producer'}
              value={
                <Money
                  value={operation.walletDetails
                    .filter((w) => w.operationType === 'PAYMENT_SHOWPRODUCER')
                    .reduce((total, w) => total + (w.amount || 0), 0)}
                />
              }
            />
          ) : (
            <></>
          )}
          <CardRowDetails
            title={'Total'}
            value={<Money value={calculateTotalAmount(operation.walletDetails)} />}
          />
        </>
      ) : (
        <></>
      )}

      {operation?.checkInsInfo?.length > 0 ? <CheckinData checkin={operation.checkInsInfo} /> : <></>}

      {operation.paymentDetails.length > 0 ? (
        <>
          <Subtitle text={<h3>Payment Method</h3>} />
          <CardRowDetails title={'ID'} value={currentPayment.chargeId} />
          <CardRowDetails
            title={'Date'}
            value={format(new Date(currentPayment.paymentDateUTC), 'MMM dd, yyyy, h:mm a')}
          />
          <CardRowDetails title={'Card Holder'} value={currentPayment?.ccName ?? ''} />
          <CardRowDetails
            title={'Brand'}
            value={
              <span className='card-brand'>
                {currentPayment.brand ? (
                  <img
                    style={{ height: '20px' }}
                    src={
                      'https://cdn1.wellet.fun/images/credit-card/' +
                      currentPayment.brand?.toLocaleLowerCase() +
                      '-logo.png'
                    }
                    className='mr-1'
                    alt='card'
                  />
                ) : (
                  <>-</>
                )}
              </span>
            }
          />
          {currentPayment.errorDescription ? (
            <CardRowDetails title={'Error'} value={currentPayment.errorDescription} />
          ) : (
            <></>
          )}
          {currentPayment.statusCode ? (
            <CardRowDetails title={'Status'} value={currentPayment.statusCode} />
          ) : (
            <></>
          )}
          <CardRowDetails title={'Currency'} value={currentPayment.currency} />
          <CardRowDetails title={'Country'} value={currentPayment.countryCode ?? '-'} />
          {operation.statis !== 'pending' && (currentPayment.chargeId || totalPages > 1) ? (
            <div className='mt-2 payment-pagination py-1'>
              <Pagination
                text='Payment'
                className='d-flex align-items-center'
                currentPage={currentPage}
                onPageChange={goToPage}
                totalPages={totalPages}
              />
            </div>
          ) : null}
        </>
      ) : (
        <></>
      )}

      {/* Modal Refund */}
      <Modal
        idModal={'refundModal'}
        title={'Refund Payment'}
        open={open}
        handleSubmit={handleRefund}
        onClose={() => setOpen(false)}
        textCancel={'Cancel'}
        textSubmit={'Refund'}
      >
        <div className='modal-background font-weight-bold'>
          Refunds take 5-10 days to appear on a customer's statement.
        </div>
        <div className='modal-background'>
          <div className='d-flex justify-content-center'>
            <span className='mr-2'>Refund</span>
            <Card>
              <div className='d-flex justify-content-between px-1'>
                <Money value={operation.totalAmount} />
                <span className='ml-3'>MXN</span>
              </div>
            </Card>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OperationDetail;
