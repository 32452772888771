import React, { useContext } from 'react';
import { Badge, Loading, Money, Subtitle, Tooltip } from '../../uiComponents/common';
import './wallets.css';
import { WelletContext } from '../../context/wellet/welletContext';
import { FilterCard, Table, TableCell, TableRow } from '../../components';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchWallets } from '../../services';
import { setSelectedOrg } from '../../actions';

const Wallets = () => {
  const welletContext = useContext(WelletContext);
  const organization = useSelector((state) => state.app.currentOrganization);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const walletsQuery = useQuery({
    queryKey: ['wellet-wallets'],
    queryFn: () => fetchWallets(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: organization.id === 83,
    onError: () => navigate('/'),
  });

  const handleSelectOrganization = (name, id) => {
    dispatch(
      setSelectedOrg({
        name,
        id,
      }),
    );
  };

  return (
    <>
      <Subtitle text={<div className='payment-title text-truncate'>Wallets</div>} />
      {walletsQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='my-3' style={{ width: '180px' }}>
            <FilterCard
              category={'All'}
              quantity={walletsQuery.data?.data?.length}
              isSelected={true}
            />
          </div>

          <Table
            tableKey={`wallets-${organization.id}`}
            data={walletsQuery.data.data}
            totalP={walletsQuery.data.data.length}
            columns={['Name', 'Amount', 'Active', 'Private', 'Processing Fee', '']}
            renderRow={(organization, index) => (
              <TableRow
                key={organization.organizationId}
                index={index}
                onClick={() =>
                  handleSelectOrganization(
                    organization.organizationName,
                    organization.organizationId,
                  )
                }
              >
                <TableCell>{organization.organizationName}</TableCell>
                <TableCell className='ctm-text font-weight-bold' type={'primary'}>
                  <Money value={organization.totalAmount} currencyCode={organization.currency} />
                </TableCell>
                <TableCell>
                  <Tooltip
                    text={`The organization is ${organization.isActive ? 'Active' : 'Inactive'}.`}
                  >
                    <Badge status={organization.isActive ? 'ACTIVE' : 'INACTIVE'} />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    text={`The organization is ${organization.isPrivate ? 'Private' : 'Public'}.`}
                  >
                    <Badge status={organization.isPrivate ? 'PRIVATE' : 'PUBLIC'} />
                  </Tooltip>
                </TableCell>
                <TableCell className='ctm-text font-weight-bold' type={'primary'}>
                  <Money value={organization.processingFee} />
                </TableCell>
                <TableCell className={'w-sm-50 w-25'}></TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Wallets;
