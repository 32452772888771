import React from 'react';
import SideBar from '../sideBar/sideBar';
import { SidebarContextProvider } from '../../context/sidebarContext';
import { Header, SignInHeader } from '../index';

import { Content } from '..';
function Page({ children }) {
  return (
    <SidebarContextProvider>
      <div className='page'>
        <SideBar />
        <div className='page-content'>
          <Header />
          <Content>{children}</Content>
        </div>
      </div>
    </SidebarContextProvider>
  );
}

export default Page;
