import React from 'react';
import styles from './button.module.css';
import { ChevronIcon, ClearFilterIcon, FwAddIcon } from '../../icons';

export default function Button({
  text,
  size,
  type = 'submit',
  icon = false,
  iconColor = '#fff',
  typeIcon = null,
  onClick,
  className,
  disabled = false,
  dataDismiss,
  ariaLabel,
  width = null,
  isClicked = false,
  isSelected = false,
  startIcon = null,
}) {
  const sizeConfig = {
    large: {
      widthConfig: '100%',
      height: '60px',
      fontSizeConfig: '18px',
    },
    medium: {
      widthConfig: `${width}`,
      height: '45px',
      fontSizeConfig: '16px',
    },
    small: {
      widthConfig: `${width}`,
      height: '35px',
      fontSizeConfig: '14px',
    },
    card: {
      widthConfig: `${width}`,
      height: '70px',
      fontSizeConfig: '10px',
    },
  };

  const { height, widthConfig, fontSizeConfig } = sizeConfig[size];

  const buttonStyles = {
    width: widthConfig,
    height,
    fontSize: fontSizeConfig,
  };

  return (
    <button
      style={buttonStyles}
      className={`btn-general ${styles.buttonWellet} ${className ? className : null}  ${
        isSelected ? styles.btnSelected : isClicked ? styles.btnClickeable : ''
      } ${disabled ? styles.disabledOpacity : ''}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-dismiss={dataDismiss}
      aria-label={ariaLabel}
    >
      {startIcon && icon ? <span className='mr-2'>{typeIcon}</span> : null}
      <div className={styles.text}>{text}</div>
      {!startIcon && icon ? <span className='ml-2'>{typeIcon}</span> : null}
    </button>
  );
}
