import { useState } from 'react';
import { useGetLanguagesQuery } from './queries/useGetLanguages';
import { useSelector } from 'react-redux';
import { getEnglishLangs } from '../helpers/languages';
const useLanguageSwitcher = (validateFields, methods) => {
  const user = useSelector((state) => state.user);
  const [languages, setLanguages] = useState([]);
  const [lngErrors, setLngErrors] = useState({});
  const currentLng = languages?.find((l) => l.isActive)?.shortForm;

  useGetLanguagesQuery(user.user !== null, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setLanguages(
        data.data.map((c, i) => ({ isActive: i === 0, title: getEnglishLangs(c.id), shortForm: c.id })),
      );
    },
  });


  const changeLanguage = (selectedLang) => {
    const errors = {};

    // Obtener los valores de los campos dinámicamente
    const fieldValues = {};
    Object.keys(validateFields).forEach((fieldName) => {
      fieldValues[fieldName] = methods.getValues(`${fieldName}_${currentLng}`);
    });
    // Validar los campos utilizando la información proporcionada en validateFields
    Object.keys(validateFields).forEach((fieldName) => {
      const { rule, errorMsg } = validateFields[fieldName];

      // Llamar a la función de regla y agregar el error si no pasa la validación
      const isValid = rule(fieldValues);
      if (!isValid) {
        errors['name'] = `To change the language, ${errorMsg}`;
      }
    });

    if (Object.keys(errors).length === 0) {
      const newLangs = languages.map((lang) => ({
        ...lang,
        isActive: lang.title === selectedLang.title,
      }));

      setLngErrors({});
      setLanguages(newLangs);
    } else {
      setLngErrors(errors);
    }
  };

  return { languages, lngErrors, currentLng, changeLanguage, setLanguages };
};

export default useLanguageSwitcher;
