import React, { useEffect, useState } from 'react';
import './buttonFilter.css';
import Button from '../button/button';
import Popup from 'reactjs-popup';
import { isScreenWidthGreaterThan } from '../../../utils/screenUtils';
import { FwFilterIcon, SearchIcon } from '../../icons';
import Input from '../input/input';

const ButtonFilter = ({ filterName, filterOptions = [], callback, isSearchable = false }) => {
  const [localFilterOptions, setLocalFilterOptions] = useState([...filterOptions]);
  const [isApplying, setIsApplying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const isDesktop = isScreenWidthGreaterThan(768);
  const wordsToShow = isDesktop ? 3 : 1;

  const handleCheckboxChange = (option) => {
    const updatedOptions = localFilterOptions.map((item) => {
      if (item.value === option) {
        return { ...item, isActive: !item.isActive };
      }
      return item;
    });
    setLocalFilterOptions(updatedOptions);
  };

  const filterOptionsBySearchTerm = () => {
    return localFilterOptions.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };

  const renderSelectedOptions = () => {
    const selectedLabels = localFilterOptions
      .filter((option) => option.isActive)
      .map((option) => option.label);

    if (selectedLabels.length <= wordsToShow) {
      return selectedLabels.join(', ');
    } else {
      const lastLabel = selectedLabels[selectedLabels.length - 1];
      return `${lastLabel}, and ${selectedLabels.length - 1} more`;
    }
  };

  const handleApplyClick = (close) => {
    setIsApplying(true);
    callback && callback(localFilterOptions);
    close();
  };

  const handleClear = () => {
    const newLocalState = localFilterOptions.map((item) => ({ ...item, isActive: false }));
    setLocalFilterOptions(newLocalState);
    callback && callback(newLocalState);
  };

  useEffect(() => {
    if (!isApplying && !isOpen) {
      setLocalFilterOptions([...filterOptions]);
    }
  }, [isApplying, isOpen]);

  return (
    <>
      <Popup
        keepTooltipInside={'.App'}
        trigger={
          <div className='btnFilter'>
            {' '}
            <FwFilterIcon customClass='mr-2' fontSize='12px' />
            <p className='p-0 m-0'>{filterName}</p>
            {localFilterOptions.some((option) => option.isActive) && (
              <span className='mx-1'>|</span>
            )}
            <p className='p-0 m-0 option-selected text-truncate'>{renderSelectedOptions()}</p>
            {localFilterOptions.some((option) => option.isActive) && (
              <i className='fas fa-angle-down ml-2'></i>
            )}
          </div>
        }
        nested
        arrow={false}
        onOpen={() => {
          setIsApplying(false);
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
          setSearchTerm('');
        }}
      >
        {(close) => (
          <div className={`filter-modal isOpen`} onClick={(e) => e.stopPropagation()}>
            {isSearchable && (
              <Input
                icon={<SearchIcon color='currentColor' size='16px' />}
                type={'search'}
                name={'search'}
                value={searchTerm}
                onChangeValue={(e) => setSearchTerm(e.target.value)}
                placeholder={'Search ...'}
              />
            )}

            <h4 className='ctm-text-medium'>Select options:</h4>
            <ul className='d-flex flex-column m-0 p-0 content-filter'>
              {filterOptionsBySearchTerm().map((option) => (
                <label key={option.value}>
                  <input
                    type='checkbox'
                    value={option.value}
                    onChange={() => handleCheckboxChange(option.value)}
                    checked={option.isActive}
                    className='mr-1'
                  />
                  {option.label}
                </label>
              ))}
            </ul>
            <div className='w-100 d-flex pt-2'>
              <Button
                size={'small'}
                onClick={() => handleApplyClick(close)}
                className='btn-custom-white'
                text={'Apply'}
              />
              <Button
                size={'small'}
                onClick={handleClear}
                className='btn-custom-red ml-2'
                text={'Clear'}
              />
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default ButtonFilter;
