import React from 'react';

export default function PaymentIcon({
  color = 'currentColor',
  fontSize = '16px',
  isSelected = false,
}) {
  return (
    <i
      className={`far fa-credit-card `}
      style={{
        color,
        fontSize: fontSize,
      }}
    ></i>
  );
}
