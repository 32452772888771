import React from 'react';

export default function SoldsOutIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 41.6 41.6'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path
            fill={color}
            d='m23.88,7.63l9.55,9.55-1.83,1.83c-.09-.01-.17-.01-.26-.01-6.82,0-12.34,5.52-12.34,12.34,0,.09,0,.17.01.26l-1.83,1.83-9.55-9.55L23.88,7.63Z'
          />
          <path
            fill={color}
            d='m40.33,18.04l-2.69,2.69c-1.25-.74-2.63-1.27-4.11-1.53l2.02-2.02-11.67-11.67L5.51,23.88l11.67,11.67,2.02-2.02c.26,1.48.79,2.86,1.53,4.11l-2.69,2.69c-.97.97-2.54.97-3.51,0l-5.05-5.06c1.02-1.02,1.02-2.67,0-3.69-1.02-1.02-2.68-1.02-3.69,0L.73,26.53c-.97-.97-.97-2.54,0-3.51L23.02.73c.97-.97,2.54-.97,3.51,0l5.05,5.06c-1.02,1.01-1.02,2.67,0,3.69s2.67,1.02,3.69,0l5.06,5.05c.97.97.97,2.54,0,3.51Z'
          />
          <path
            fill={color}
            d='m36.11,22.26c-1.34-.72-2.85-1.14-4.46-1.18-.1,0-.21,0-.31,0-.68,0-1.35.07-2,.2-4.07.8-7.27,4-8.07,8.07-.13.65-.2,1.32-.2,2,0,.1,0,.21.01.31.04,1.61.46,3.12,1.18,4.46,1.71,3.26,5.14,5.49,9.08,5.49,5.66,0,10.26-4.6,10.26-10.26,0-3.94-2.23-7.37-5.49-9.08Zm-1.01,10.71l1.41,1.41c.59.59.59,1.54,0,2.12s-1.54.59-2.12,0l-1.41-1.41-1.64-1.64-1.64,1.64-1.41,1.41c-.59.59-1.54.59-2.12,0-.59-.59-.59-1.54,0-2.12l1.41-1.41,1.64-1.64-1.64-1.64-1.41-1.41c-.59-.59-.59-1.54,0-2.12s1.54-.59,2.12,0l1.41,1.41,1.64,1.64,1.64-1.64,1.41-1.41c.59-.59,1.54-.59,2.12,0,.59.59.59,1.54,0,2.12l-1.41,1.41-1.64,1.64,1.64,1.64Z'
          />
        </g>
      </g>
    </svg>
  );
}

/*

*/
