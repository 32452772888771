import React, { useContext, useState } from 'react';
import {
  Badge,
  BreadCrumbs,
  Button,
  IconButton,
  Loading,
  Slider,
  Subtitle,
} from '../../uiComponents/common';
import { Table, TableCell, TableRow } from '../../components';
import FwEditIcon from '../../uiComponents/icons/font-awesome/fwEditOutline';
import { useQuery } from 'react-query';
import { getAllUsers } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useUser from '../../hooks/useUser';

const Users = () => {
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);
  const { isSuperAdmin } = useUser();
  const currentOrg = useSelector((state) => state.app.currentOrganization);
  const [showInactives, setShowInactives] = useState(false);
  const usersQuery = useQuery({
    queryKey: ['users-dragaret', showInactives, organization.id],
    queryFn: () => getAllUsers(welletContext, null, null, organization.id, showInactives),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {isSuperAdmin && currentOrg.id === 83 ? (
        <BreadCrumbs
          crumbs={[
            {
              name: 'Users',
              path: '/users',
            },
          ]}
        />
      ) : (
        <></>
      )}
      <Subtitle
        text={<h1 className='m-0 ctm-title font-weight-bold'>Users</h1>}
        endComponent={
          <Button
            size={'small'}
            className={'btn-custom-white'}
            text={'New User'}
            onClick={() => navigate('new-user')}
          />
        }
      />
      {usersQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <Slider
            className={'col-auto my-2'}
            label={'Only Actives'}
            onClick={() => setShowInactives(!showInactives)}
            defaultChecked={!showInactives}
          />
          <Table
            tableKey={`users-${showInactives}-${organization.id}`}
            data={usersQuery?.data?.data ?? []}
            totalP={usersQuery?.data?.data?.length}
            columns={
              organization.id === 1
                ? ['', 'Organization', 'Last name', 'First name', 'Email', 'Phone', 'Status', 'Kyc']
                : ['', 'Last name', 'First name', 'Email', 'Phone', 'Status', 'Kyc']
            }
            renderRow={(user, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: '60px' }} type={'primary'}>
                  <IconButton onClick={() => navigate(`edit/${user.id}`)} icon={<FwEditIcon />} />
                </TableCell>
                {organization.id === 1 ? (
                  <TableCell type={'primary'}>{user.organizationName}</TableCell>
                ) : null}
                <TableCell type={'primary'}>{user.lastName}</TableCell>
                <TableCell type={'primary'}>{user.firstName}</TableCell>
                <TableCell type={'primary'}>{user.email}</TableCell>
                <TableCell type={'primary'}>{user.phone}</TableCell>
                <TableCell>
                  <Badge status={user.isActive ? 'ACTIVE' : 'INACTIVE'} />
                </TableCell>
                <TableCell>
                  <Badge status={user.kycStatus} />
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Users;
