import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useUser from './useUser';

export function useRedirectIfSuperAdmin(condition, redirectUrl = '/') {
    const organization = useSelector((state)=> state.app.currentOrganization);
    const { isSuperAdmin } = useUser();
    const navigate = useNavigate();
    useEffect(() => {
    if (isSuperAdmin && condition) {
      navigate(redirectUrl);
    }
  }, [organization.id, isSuperAdmin]);
}
