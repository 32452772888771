import React from 'react';
import { InputBase } from '.';
import { useFormContext, Controller } from 'react-hook-form';
import { EyeHideIcon, EyeIcon } from '../../icons';
import { useState } from 'react';

const InputPassword = ({
  name,
  placeholder,
  className,
  icon,
  label,
  isRequired = false,
  autocomplete = null,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [ForceToShow, setForceToShow] = useState(false);
  return (
    <div className={`form-group ${className}`}>
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        rules={{
          required: 'This field is required.',
        }}
        render={({ field }) => (
          <div>
            <InputBase
              autocomplete={autocomplete}
              type={ForceToShow ? 'text' : 'password'}
              field={field}
              errors={errors}
              name={name}
              placeholder={placeholder}
              icon={icon}
              endComponent={
                <>
                  {!ForceToShow ? (
                    <span
                      onClick={() => setForceToShow(true)}
                      className='input-group-text px-2 input-icon end'
                    >
                      <EyeHideIcon color={'var(--color-subtitle)'} size={'16px'} />
                    </span>
                  ) : (
                    <span
                      onClick={() => setForceToShow(false)}
                      className='input-group-text px-2 input-icon end'
                    >
                      <EyeIcon color={'var(--color-subtitle)'} size={'16px'} />
                    </span>
                  )}
                </>
              }
              label={label}
            />
            {errors[name] && (
              <div className='d-flex align-items-center  error-content'>
                <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
                <p className='text-danger p-0 m-0'>{errors[name].message}</p>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default InputPassword;
