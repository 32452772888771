import React from 'react';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  text = 'Page',
  className = 'd-flex justify-content-between w-100',
  showText = true,
}) => {
  return (
    <div className={className}>
      <div className='pagination-text text-general text-truncate'>
        {showText ? (
          <>
            {text} {totalPages !== 0 ? currentPage : totalPages} of {totalPages}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className='d-flex'>
        <button
          type='button'
          className='btn-custom-white btn-general'
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          type='button'
          className='ml-2 btn-custom-white btn-general'
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages || totalPages === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
