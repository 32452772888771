import React from 'react';

export default function DoorsIcons({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M1920 5006 l0 -116 -440 0 -440 0 0 -2250 0 -2250 160 0 160 0 0
2090 0 2090 280 0 280 0 0 -2286 0 -2286 28 6 c15 4 491 89 1057 191 567 102
1040 187 1053 190 l22 5 0 2170 0 2170 -22 5 c-13 2 -489 88 -1058 191 -569
102 -1045 188 -1057 191 -23 5 -23 4 -23 -111z m469 -2310 c60 -54 77 -151 39
-226 -29 -55 -65 -82 -111 -82 -75 0 -127 69 -127 169 1 135 115 215 199 139z'
        />
      </g>
    </svg>
  );
}
