import React from 'react';

export default function NoPhotoIcon({ size = '60px', color = 'currentColor' }) {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      x='0px'
      y='0px'
      viewBox='0 0 80 80'
      fill={color}
      width={size}
      height={size}
    >
      <g>
        <path
          class='st0'
          d='M40,0C17.9,0,0,17.9,0,40c0,22.1,17.9,40,40,40s40-17.9,40-40C80,17.9,62.1,0,40,0z M64.8,64.9
		c-0.1,0.1-0.2,0.2-0.3,0.3c-3.3-2-7.1-4-10.3-5.6c-2.8-1.4-5.1-2.6-6.2-3.4c-0.4-0.3-0.8-0.7-0.9-1.1c-0.1-0.8,1.4-2.3,2.6-3.5
		c1.1-1.1,2.2-2.2,3-3.4c2.6-4.1,3.9-8.8,2.7-21c-0.7-6.9-4.9-10.1-8.3-11.6c-3.6-1.6-7-1.6-7.1-1.6c-0.1,0-3.5,0-7.1,1.6
		c-3.4,1.5-7.6,4.8-8.3,11.6c-1.2,12.2,0,17,2.7,21c0.8,1.2,1.9,2.4,2.9,3.4c1.2,1.2,2.7,2.7,2.6,3.5c0,0.4-0.5,0.8-0.9,1.1
		c-0.5,0.4-1.4,0.9-2.5,1.5c-1.1,0.6-2.4,1.2-3.7,1.9c-3.1,1.6-7,3.5-10.2,5.5l-0.2-0.2C8.6,58.3,4.9,49.4,4.9,40
		c0-9.4,3.7-18.3,10.3-24.9C21.8,8.4,30.6,4.8,40,4.8s18.2,3.7,24.8,10.3c6.6,6.7,10.3,15.5,10.3,24.9
		C75.1,49.4,71.4,58.3,64.8,64.9z'
        />
      </g>
    </svg>
  );
}
