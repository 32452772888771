import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { SignInHeader } from '../../components';
import logoWellet from '../../assets/images/welletIso.png';
import { Input, Button, Card, CardBody, Loading } from '../../uiComponents/common';
import WarningIcon from '../../uiComponents/icons/warningIcon';
import { WelletContext } from '../../context/wellet/welletContext';
import { useMutation } from 'react-query';
import { resetPassword, validateCode } from '../../services';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

const TENANT_ID = process.env.REACT_APP_TENANT_ID;

const ForgotPassword = () => {
  const [error, setError] = useState('');
  const [mode, setMode] = useState('');
  const welletContext = useContext(WelletContext);
  const auth = getAuth();
  auth.tenantId = TENANT_ID;
  const resetPasswordMutation = useMutation({
    mutationFn: () => resetPassword(welletContext, values.email, process.env.REACT_APP_TENANT_ID),
    onError: (error) => {
      setError(error.response.data);
    },
  });
  const validateCodeMutation = useMutation({
    mutationFn: () =>
      validateCode(welletContext, values.email, process.env.REACT_APP_TENANT_ID, values.code),
    onSuccess: (data) => {
      if (data.data.isValid) signInMutation.mutate(data.data.customToken);
    },
    onError: (error) => {
      setError(error.response.data);
    },
  });
  const signInMutation = useMutation({
    mutationFn: (data) => signInWithCustomToken(auth, data),
    onError: (error) => {
      setError(error.response.data);
    },
  });

  const sendForm = async () => {
    setError('');
    validateCodeMutation.reset();
    if (mode === '') {
      setSeconds(180);
      resetPasswordMutation.mutate();
    } else {
      validateCodeMutation.mutate();
    }
  };

  const validate = () => {
    setError('');
    let errors = {};

    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (mode === 'code') {
      if (!values.code) {
        errors.code = 'Code is required';
      }
    }

    return errors;
  };

  const defaultTime = resetPasswordMutation.data ? resetPasswordMutation.data?.data?.timeLeft : 0;
  const [seconds, setSeconds] = useState(180);

  useEffect(() => {
    let interval;
    if (defaultTime && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [defaultTime]);

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
  const isValid = validateCodeMutation?.data ? validateCodeMutation?.data?.data?.isValid : true;

  if (resetPasswordMutation.isLoading) {
    return <Loading />;
  }

  return (
    <div className='d-flex flex-column justify-content-beetween align-items-strech vh-100 w-100'>
      <SignInHeader />
      <div className='d-flex flex-column justify-content-center align-items-center h-100'>
        <form onSubmit={handleSubmit} className='login-form'>
          <Card>
            <div className='row py-3 justify-content-center'>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{ height: '70px', width: '70px' }}
              >
                <img src={logoWellet} alt='Logo' style={{ height: '100%', width: '100%' }} />
              </div>
            </div>
            <div className='row'>
              <h5 className='col-12 text-center' style={{ color: 'var(--color-subtitle)'}}>Password recovery</h5>
              <p className='col text-center text-muted'>We'll send you instructions in email</p>
            </div>
            <CardBody>
              {!resetPasswordMutation.isSuccess ? (
                <Input
                  placeholder={'Email'}
                  name={'email'}
                  value={values.email}
                  type={'email'}
                  error={errors.email}
                  onChangeValue={handleChange}
                />
              ) : (
                <Input
                  placeholder={'Code'}
                  name={'code'}
                  value={values.code}
                  type={'text'}
                  error={errors.code}
                  onChangeValue={handleChange}
                />
              )}
              {resetPasswordMutation.isSuccess ? (
                <div className='mt-2 d-flex align-items-center'>
                  <div>Check your email, the code was successfully sent.</div>
                </div>
              ) : (
                <></>
              )}

              {error || !isValid ? (
                <div className='mt-2 d-flex align-items-center'>
                  <div className='mr-1'>
                    <WarningIcon color={'#CD3D64'} />
                  </div>
                  <div className='error-text'>{!isValid ? 'Invalid Code' : error}</div>
                </div>
              ) : (
                <></>
              )}
              <Button
                type='submit'
                size={'small'}
                className={'mt-2'}
                width={'100%'}
                onClick={() => {
                  if (resetPasswordMutation.isSuccess) setMode('code');
                  else setMode('');
                }}
                text={resetPasswordMutation.isSuccess ? 'Validate Code' : 'Reset password'}
              />
              {resetPasswordMutation.isSuccess ? (
                <Button
                  type='submit'
                  size={'small'}
                  disabled={seconds > 0 && resetPasswordMutation.isSuccess}
                  className={'mt-2 btn-outline'}
                  width={'100%'}
                  onClick={() => setMode('')}
                  text={`Resend Code ${
                    seconds > 0 && resetPasswordMutation.isSuccess ? `(${seconds})` : ''
                  }`}
                />
              ) : (
                <></>
              )}
              <div className='py-2 mt-2 content-divider text-muted'>
                <span className='px-3'></span>
              </div>
              <div className='row'>
                <div className='col'>
                  <Link to={'/'}>Back to login</Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
