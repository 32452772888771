import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '../../uiComponents/common';

const AdminAlert = ({ children, text, extraValidation = true }) => {
  const organization = useSelector((state) => state.app.selectedOrganization);

  if (organization.id === 1 && extraValidation) {
    return (
      <Alert
        title='Hello Administrator'
        text={text}
        icon={<i className='fas fa-exclamation-triangle text-danger mr-2'></i>}
        type='warning'
      />
    );
  }
  return <>{children}</>;
};

export default AdminAlert;
