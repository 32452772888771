import React from 'react';
import './tooltip.css';

function Tooltip({ text, children, marginT = '-35px' }) {
  return (
    <div className='custom-tooltip'>
      {children}
      <span style={{ marginTop: marginT }} className='tooltiptext'>{text}</span>
    </div>
  );
}

export default Tooltip;
