import React from 'react';

export default function WebsiteIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 17.59 17.59'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <path
          fill={color}
          d='m8.8,0C3.95,0,0,3.95,0,8.8s3.95,8.79,8.8,8.79,8.8-3.95,8.8-8.79S13.65,0,8.8,0Zm-.89,1.05c-.36.7-.87,1.82-1.29,3.22-.56-.04-1.13-.11-1.72-.2.86-1.53,1.84-2.57,2.14-2.87.29-.07.58-.12.87-.15Zm4.33,2.93c-.41.07-.85.14-1.3.19-.41-1.36-.91-2.44-1.26-3.12.13.01.25.04.38.06h0s1.17,1.11,2.18,2.87Zm-.56,4.31c-.06-1.13-.24-2.19-.47-3.15.53-.07,1.04-.15,1.51-.24.47.99.84,2.14.96,3.39h-2Zm-4.52,3.56c-.17-.88-.25-1.73-.26-2.56h3.81c0,.84-.09,1.71-.27,2.6-1.02-.11-2.11-.14-3.27-.05Zm3.03,1.03c-.3,1.07-.75,2.16-1.39,3.26-.65-1.11-1.1-2.22-1.4-3.3.98-.07,1.91-.04,2.79.04Zm-3.28-4.58c.06-1.08.23-2.08.46-2.98.29.01.58.03.87.03.69,0,1.34-.03,1.98-.09.23.92.41,1.94.47,3.04h-3.77Zm.73-3.97c.38-1.23.83-2.2,1.15-2.81.32.61.76,1.56,1.14,2.76-.73.06-1.49.08-2.29.05Zm-1.29.92c-.22.93-.39,1.95-.45,3.04h-2.4c.12-1.21.47-2.33.92-3.3.66.12,1.3.2,1.93.26Zm-3.85,3.04h-1.48c.09-1.39.54-2.69,1.27-3.79.37.11.75.21,1.12.3-.45,1.03-.79,2.21-.9,3.49Zm-.03,1c.02,1.15.23,2.27.65,3.37-.3.1-.6.21-.89.32-.69-1.08-1.12-2.34-1.21-3.69h1.45Zm1,0h2.42c0,.86.09,1.75.26,2.66-.67.09-1.36.23-2.07.41-.39-1.01-.6-2.03-.61-3.08Zm2.91,3.64c.31,1.18.79,2.38,1.48,3.59-.42-.05-.83-.13-1.23-.25-.91-.97-1.61-1.96-2.13-2.98.64-.16,1.26-.28,1.88-.36Zm4.81.06c.53.08,1.03.18,1.5.29-.54,1.06-1.27,2.09-2.23,3.09l.02.02c-.25.05-.5.1-.76.13.68-1.19,1.15-2.37,1.46-3.53Zm.23-.98c.19-.94.27-1.85.28-2.73h2.02c-.02,1.04-.22,2.07-.61,3.07-.53-.13-1.09-.25-1.69-.34Zm3.3-2.73h1.85c-.09,1.38-.53,2.66-1.25,3.76-.37-.14-.79-.28-1.24-.42.41-1.09.62-2.2.64-3.34Zm-.03-1c-.12-1.33-.48-2.55-.95-3.6.53-.12,1-.25,1.43-.38.81,1.14,1.31,2.51,1.4,3.99h-1.88Zm-.21-4.83c-.36.1-.76.2-1.19.3-.47-.86-.97-1.58-1.41-2.14.99.43,1.88,1.06,2.61,1.84ZM5.09,1.94c-.4.53-.83,1.18-1.23,1.94-.3-.06-.61-.14-.91-.22.61-.69,1.33-1.28,2.15-1.72Zm-2.25,11.88c.23-.08.46-.17.68-.24.29.59.64,1.18,1.05,1.76-.65-.42-1.23-.93-1.73-1.52Zm9.47,1.92c.55-.71,1-1.44,1.36-2.18.36.11.7.22,1.01.33-.66.76-1.46,1.39-2.37,1.85Z'
        />
      </g>
    </svg>
  );
}
