import { useContext } from 'react';
import SidebarContext from '../../../context/sidebarContext';
import { DoubleArrows, CloseIcon, CompanysIcon } from '../../icons';
import { IconButton } from '../../common';
import logoWellet from '../../../assets/images/welletIso.png';
import { CompanySelector } from '../../../components';
import './sideBarHeader.css';

const SideBarHeader = () => {
  const { toggleIsSidebarOpen, handleToggleSidebarResized, isMouseOver, isSidebarResized } =
    useContext(SidebarContext);

  const isMobile = window.innerWidth < 992;

  return (
    <div className='sidebar-section'>
      <div className='sidebar-user-material'>
        <div
          className={`sidebar-section-body d-flex justify-content-${
            isMouseOver || !isSidebarResized ? 'between' : 'center'
          } align-items-center`}
        >
          <div className='w-auto'>
            <div className='' style={{ height: '25px', width: '25px' }}>
              <img
                src={logoWellet}
                alt='Logo'
                style={{ height: '100%', width: '100%', margin: 0 }}
              />
            </div>
          </div>

          <div
            className={`flex-1 text-right ${isMouseOver || !isSidebarResized ? '' : 'd-none'} w-25`}
          >
            {isMobile ? (
              <IconButton
                icon={<CloseIcon color='#3f51b5' size='18px' />}
                onClick={toggleIsSidebarOpen}
              />
            ) : (
              <IconButton
                icon={<DoubleArrows color='#3f51b5' size='18px' />}
                onClick={handleToggleSidebarResized}
              />
            )}
          </div>
        </div>
      </div>
      <CompanySelector
        icon={<CompanysIcon size='16px' color='#a3acba' />}
        callback={toggleIsSidebarOpen}
      />
    </div>
  );
};

export default SideBarHeader;
