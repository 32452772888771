import React from 'react';

const ErrorAlert = ({ text }) => {
  return (
    <div className='d-flex align-items-center justify-content-center error-content'>
      <i className='fas fa-exclamation-triangle text-danger mr-2'></i>{' '}
      <p className='text-danger p-0 m-0'>{text}</p>
    </div>
  );
};

export default ErrorAlert;
