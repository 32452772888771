import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  ErrorAlert,
  InputEmail,
  InputNumber,
  InputPassword,
  InputPhone,
  InputText,
  Select,
  Subtitle,
  TextAlert,
  Toast,
  ToggleToken,
} from '../../../uiComponents/common';

import { WelletContext } from '../../../context/wellet/welletContext';
import { useMutation } from 'react-query';

import {
  ChannelIcon,
  FwChannelIcon,
  FwCreditCard,
  FwLockIcon,
  FwMailIcon,
  FwPersonIcon,
  FwQrIcon,
  FwUserIcon,
} from '../../../uiComponents/icons';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import { postUser, putUser } from '../../../services';
import { useNavigate } from 'react-router-dom';

const UsersForm = ({
  id = null,
  roles = null,
  edit = null,
  userData = null,
  bossData = null,
  channelsData = null,
  organizationId = null,
}) => {
  const navigate = useNavigate();
  const welletContext = useContext(WelletContext);
  const methods = useForm();

  useEffect(() => {
    if (id) {
      const data = userData;
      methods.reset({
        lastName: data.lastName,
        firstName: data.firstName,
        userEmail: data.email,
        isActive: data.isActive,
        phone: data.phone,
        roles: data.role.map((r) => roles.find((v) => v.value === r)),
        userPassword: '',
        channel: channel?.find((c) => c.value === data.associateDetails?.channelId) ?? '',
        credit: data.associateDetails?.creditLimit ?? '',
        boss: boss?.find((b) => b.value === data.associateDetails?.leader) ?? '',
        commision: commisions?.find((c) => c.value === data.associateDetails?.commisionLevel) ?? '',
        payment:
          paymentMethod?.find((c) => c.value === data.associateDetails?.commissionPaymentMethod) ??
          '',
        mercadopago: data.associateDetails?.mercadoPago ?? '',
      });
    }
  }, []);

  const userMutation = useMutation({
    mutationFn: (body) => {
      if (id) return putUser(welletContext, body);
      else return postUser(welletContext, body);
    },
    onError: (error) => {
      console.log('Desde on error', error);
    },
  });
  const channel = channelsData?.data?.data.map((r) => ({ label: r.name, value: r.id }));
  const boss = bossData?.data?.data.map((r) => ({
    label: `${r.firstName} ${r.lastName}`,
    value: r.id,
    channelId: r.channelId,
  }));
  const commisions = Array.from({ length: 20 }, (_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }));
  const paymentMethod = [
    {
      label: 'Cash + Mercado Pago',
      value: 'Cash',
    },
    {
      label: 'Mercado Pago',
      value: 'MercadoPago',
    },
  ];

  const activeRoles = methods.watch('roles');

  const onSubmit = (data) => {
    const body = {
      id: id ? id : null,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      isEnabled: id ? data.isActive : true,
      email: data.userEmail,
      companyId: organizationId,
      administration: {},
      management: {},
      revenue: {},
      salesVenue: null,
      customerService: {},
      reporting: {},
      role: data.roles.map((r) => r.value),
      associateDetails: null,
    };
    if (data.roles.some((r) => r.value === 'salesteamlead')) {
      body.associateDetails = {
        channelId: Number(data?.channel?.value),
        channel: data.channel.label,
        creditLimit: Number(data.credit),
        commisionLevel: data.commision.value,
        commissionPaymentMethod: data.payment.value,
        mercadoPago: data.mercadopago,
        leader: data?.boss?.value ?? null,
      };
    }

    if (!!id !== true) {
      body.password = data.userPassword;
    }
    userMutation.mutate(body);
  };
  const clearForm = () => {
    navigate('/users');
    userMutation.reset();
    methods.reset();
  };
  const handleToastCallback = () => {
    clearForm();
    userMutation.reset();
    navigate('/users');
  };

  const renderForm = () => {
    return (
      <div className='col-12 px-0 py-2'>
        <Subtitle text={<h3>Main Data</h3>} />
        {id && (
          <div className=''>
            <ToggleToken
              label={'Status'}
              activeText={'Active'}
              inactiveText={'Inactive'}
              name={'isActive'}
              onClick={() => null}
            />
          </div>
        )}
        <div className='row mt-2'>
          <InputText
            className={'col-12 col-sm-6 col-xl-4'}
            name={'firstName'}
            icon={<FwPersonIcon />}
            label={'First name'}
            placeholder={'User first name'}
          />
          <InputText
            className={'col-12 col-sm-6 col-xl-4'}
            name={'lastName'}
            icon={<FwPersonIcon />}
            label={'Last name'}
            placeholder={'User last name'}
          />
        </div>
        <div className='mt-2 row'>
          <InputEmail
            className={'col-12 col-sm-6 col-xl-4'}
            name={'userEmail'}
            icon={<FwMailIcon />}
            label={'Email'}
            isRequired={true}
            placeholder={'User email'}
            isDisabled={id ? true : false}
            autocomplete={'off'}
          />
          <InputPhone
            className={'col-12 col-sm-6 col-xl-4'}
            placeholder={'User phone'}
            label={'Mobile phone'}
            name={'phone'}
            isRequired={false}
            isDisabled={false}
          />
        </div>
        <div className='row mt-2 '>
          <Select
            options={roles}
            label={'User Roles'}
            className={'col-12 col-xl-8'}
            icon={<FwUserIcon />}
            name={'roles'}
            components={{
              Option: InputOption,
            }}
            isMulti={true}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            hideSelectedOptions={false}
            isRequired={true}
          />
        </div>
        <div className='row'>
          {id ? (
            <></>
          ) : (
            <InputPassword
              className={'col-12 col-sm-6 col-xl-4'}
              name={'userPassword'}
              icon={<FwLockIcon />}
              label={'Password'}
              placeholder={'Enter password'}
              autocomplete={'new-password'}
            />
          )}
        </div>
        {activeRoles && activeRoles.some((r) => r.value === 'salesteamlead') ? (
          <>
            <Subtitle text={<h3>General Data</h3>} />
            <div className='row mt-2 '>
              <Select
                options={channel}
                label={'Channel'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<ChannelIcon />}
                name={'channel'}
                isRequired={true}
              />
              <InputNumber
                label={'Limit credit amount'}
                icon={<FwCreditCard />}
                name={'credit'}
                customValidation={{}}
                placeholder={'0'}
                className={'col-12 col-sm-6 col-xl-4'}
              />
            </div>
            <div className='row mt-2 '>
              <Select
                options={boss}
                label={'Boss'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwChannelIcon />}
                name={'boss'}
                placeholder={'Select a boss'}
              />
              <Select
                options={commisions}
                label={'Commission level'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwChannelIcon />}
                name={'commision'}
                isRequired={true}
              />
              {methods.watch('boss') &&
              methods.watch('channel') &&
              methods.watch('boss').channelId !== methods.watch('channel').value ? (
                <div className='col-12 m-0'>
                  <TextAlert
                    type={'warning'}
                    text={'Warning: Boss channel differs from selected channel'}
                  />{' '}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className='row mt-2 '>
              <Select
                options={paymentMethod}
                label={'Payment Method'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<FwCreditCard />}
                name={'payment'}
                isRequired={true}
              />
              <InputText
                className={'col-12 col-sm-6 col-xl-4'}
                name={'mercadopago'}
                icon={<FwQrIcon />}
                label={'Mercado Pago'}
                placeholder={'Mercado Pago'}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {userMutation?.error && (
          <div className='col-8'>
            <Alert
              title={'Oops, something went wrong'}
              text={userMutation?.error?.response?.data}
              type={'error'}
              icon={<i className='fas fa-exclamation-triangle text-danger mr-2'></i>}
            />
          </div>
        )}
        <div className='d-flex mt-3 justify-content-center justify-content-sm-start align-items-centerc w-100'>
          <Button
            size={'small'}
            className={' ml-2'}
            type='submit'
            text={`${userMutation.isLoading ? 'Sending...' : 'Send'}`}
          />
          <Button
            size={'small'}
            type='button'
            className={'btn-custom-white  ml-2'}
            text={`Cancel`}
            onClick={clearForm}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='col-12 px-0'>
      {userMutation.isSuccess && (
        <Toast
          message={`User ${id ? 'updated' : 'created'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}

      <>
        {id ? (
          <>
            <FormProvider {...methods}>
              <form key={1} onSubmit={methods.handleSubmit(onSubmit)} autoComplete='off'>
                {edit ? renderForm() : <></>}
              </form>{' '}
            </FormProvider>
          </>
        ) : (
          <></>
        )}

        {!!!id ? (
          <FormProvider {...methods}>
            <form key={2} onSubmit={methods.handleSubmit(onSubmit)} autoComplete='off'>
              {renderForm()}
            </form>
          </FormProvider>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default UsersForm;
