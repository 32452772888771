import React, { useContext, useState } from 'react';
import { Table, TableRow, TableCell, CustomCard, DateFilter } from '../../components';
import { useQuery } from 'react-query';
import { fetchCheckIns, fetchChecksInfo } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { Badge, ButtonFilter, Loading, Subtitle } from '../../uiComponents/common';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { calculatePercentage } from '../../helpers/format';
import { usePagination } from '../../context/paginationContext';

const CheckInHistory = () => {
  const organization = useSelector((state) => state.app.currentOrganization);
  const selectedDates = useSelector((state) => state.app.selectedDates);
  const welletContext = useContext(WelletContext);
  const { paginationState } = usePagination();
  const [filter, setFilter] = useState([
    { label: 'Pending', value: 'Pending', isActive: false },
    { label: 'CheckIn', value: 'CheckIn', isActive: false },
  ]);

  const selectedFormattedDates = {
    from: selectedDates.from ? new Date(selectedDates.from).toISOString() : null,
    to: selectedDates.to ? new Date(selectedDates.to).toISOString() : null,
  };

  const filterActive = filter.find((f) => f.isActive === true) ? filter.filter((f) => f.isActive === true).map((f) => f.value)  : null

  const tableKey = `checkins-${organization.id}-${JSON.stringify(selectedFormattedDates)}-${JSON.stringify(filter)}`

  let limit = 10;
  let skip =
    10 * paginationState[tableKey]?.currentPage - 10
      ? 10 * paginationState[tableKey]?.currentPage - 10
      : 0;

  
  const checkInQuery = useQuery({
    queryKey: ['checkins', organization.id, selectedFormattedDates, filter, limit, skip],
    queryFn: () => fetchCheckIns(welletContext, organization.id, selectedFormattedDates, filterActive, limit, skip),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const checkinInfoQuery = useQuery({
    queryKey: ['checkins-info', organization.id, selectedFormattedDates, filter],
    queryFn: () => fetchChecksInfo(welletContext, organization.id, selectedFormattedDates, filterActive),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
  const headerAdmin = [
    'Activity Name',
    'ReferenceCode',
    'Status',
    'Device',
    'Checker Name',
    'CheckIn Date',
  ];
  const headerSuperAdmin = [
    'Organization Name',
    'Activity Name',
    'ReferenceCode',
    'Status',
    'Device',
    'Gate',
    'Checker Name',
    'CheckIn Date',
  ];

  return (
    <>
      <Subtitle
        text={
          <div className='w-100 mt-1'>
            <div className='payment-title text-truncate mr-2'>Check In</div>
          </div>
        }
        endComponent={
          <div className='mt-1'>
            <DateFilter filterName={'Activity Date'} />
          </div>
        }
      />

      {checkinInfoQuery.isLoading || checkInQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='d-flex justify-content-start aling-items-center mt-3 mb-3 w-100 flex-wrap'>
            <CustomCard
              title={`${checkinInfoQuery?.data?.data?.checkInRegistered} of ${checkinInfoQuery?.data?.data?.totalCheckIn}`}
              subtitle={'CHECK-INS'}
              text={`${calculatePercentage(
                checkinInfoQuery?.data?.data?.totalCheckIn,
                checkinInfoQuery?.data?.data?.checkInRegistered,
              )} % `}
            />
          </div>
          <div className='w-100 d-flex align-items-center justify-content-start my-3'>
            <ButtonFilter
              type={'checkbox'}
              filterName={'Type'}
              filterOptions={filter}
              callback={setFilter}
            />
          </div>
          <Table
            data={checkInQuery?.data?.data?.items || []}
            totalP={checkInQuery?.data?.data?.totalRegisters}
            hasPagination={false}
            tableKey={tableKey}
            columns={organization.id === 83 ? headerSuperAdmin : headerAdmin}
            renderRow={(checks, index) => (
              <TableRow key={checks.id} index={index}>
                {organization.id === 83 && (
                  <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                    {checks.organizationName || '-'}
                  </TableCell>
                )}
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.activityName || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.referenceCode || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  <Badge status={checks.status} />
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.registerDevice || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {checks.registerUserName || '-'}
                </TableCell>
                <TableCell className='ctm-text' style={{ width: '120px' }} type={'primary'}>
                  {(checks.status !== 'Pending' &&
                    format(new Date(checks.registerDate), 'MMM dd, yyyy, h:mm a')) ||
                    '-'}
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default CheckInHistory;
