import React from 'react';

export default function ClearFilterIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 15.8 16.3'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <path
          fill={color}
          d='M2.3,2.5H1C0.5,2.5,0.3,3,0.6,3.3l5.6,5.4C6.4,8.8,6.4,8.9,6.4,9v5.6c0,0.2,0.1,0.3,0.2,0.4l2.1,1.2c0.3,0.2,0.7,0,0.7-0.4
		V9.3L9,8.9L2.3,2.5z'
        />
        <path
          fill={color}
          d='M14.9,2.5H6.9L4.6,0.2C4.6,0.2,4.5,0.1,4.4,0.1C4.3,0,4.2,0,4.1,0h0C3.7,0,3.4,0.2,3.3,0.5c-0.1,0.3,0,0.6,0.2,0.8l8.6,8.4
		l0.3,0.3l0.4,0.4c0.3,0.2,0.6,0.3,0.9,0.1c0.2-0.1,0.4-0.3,0.4-0.5c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.4-0.2-0.5l-0.5-0.5L13,8.4
		l-1.6-1.6l3.8-3.6C15.5,3,15.3,2.5,14.9,2.5z'
        />
      </g>
    </svg>
  );
}
