import React, { useContext, useEffect, useState } from 'react';
import { PageHeader, Table, FilterCard, TableRow, TableCell } from '../../components';
import { useQuery, useQueryClient } from 'react-query';
import { fetchPayments } from '../../services/liveFeedServices';
import { WelletContext } from '../../context/wellet/welletContext';
import { Badge, Loading, Money, Tooltip } from '../../uiComponents/common';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { getCurrentFilter, getStatusMessage } from '../../helpers/format';
import MercadoPago from '../../uiComponents/icons/mercadoPago';
import Fiserv from '../../uiComponents/icons/fiserv';
const Payments = () => {
  /*---------- Important ----------*/
  const organization = useSelector((state) => state.app.currentOrganization);
  const [filter, setFilter] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [showButtons, setShowButtons] = useState(false);
  const queryClient = useQueryClient();
  const welletContext = useContext(WelletContext);
  const navigate = useNavigate();

  // To Do: Se repite unicamente una vez al cambiar de organizacion y tiene un filtro diferente al por defecto.
  // En caso de tener por defecto y cambia, no se repite.
  useEffect(() => {
    if (filter === null || !!(filter[0] ?? '')) {
      setFilter([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id]);

  const paymentsQuery = useQuery({
    queryKey: ['payments', filter ? filter[0] ?? '' : '', organization.id],
    queryFn: () => fetchPayments(welletContext, filter, organization.id),
    refetchInterval: 10000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: filter !== null,
  });
  const lastsPaymentsQuery = useQuery({
    queryKey: ['last-payments', filter ? filter[0] ?? '' : '', organization.id],
    queryFn: () =>
      fetchPayments(
        welletContext,
        filter,
        [],
        organization.id,
        { from: null, to: null },

        getCurrentFilter(
          filter ? filter[0] ?? '' : '',
          queryClient.getQueryState(['last-payments', '', organization.id])?.data,
        ),
        0,
      ),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled:
      paymentsQuery.isFetched &&
      queryClient.getQueryState(['payments', '', organization.id]).data?.activities?.length === 0,
  });

  const handleClick = (filter) => {
    setFilter(filter);
  };

  const payments =
    queryClient.getQueryState(['payments', '', organization.id])?.data?.activities?.length === 0
      ? lastsPaymentsQuery
      : paymentsQuery;
  const quantities =
    queryClient.getQueryState(['payments', '', organization.id])?.data?.activities?.length === 0
      ? queryClient.getQueryState(['last-payments', '', organization.id])
      : queryClient.getQueryState(['payments', '', organization.id]);

  const paymentFormatted = payments.data?.activities?.map((payment) => {
    // Tags que necesito para mis th y mis td
    const allowedKeys = [
      'GatewayName',
      'Price',
      'Currency',
      'Company',
      'BookingCode',
      'CustomerEmail',
      'PurchaseDate',
      'Channel',
      'Status',
    ];
    // Filtrar las tags para incluir solo las claves que necesitas
    const tagsFiltered = payment?.tags
      .filter((tag) => allowedKeys.includes(tag.type))
      .reduce((obj, tag) => {
        // Convierte la primera letra de la clave a minúscula
        const key = tag.type.charAt(0).toLowerCase() + tag.type.slice(1);
        // Asigna el valor al objeto con la clave en minúscula
        obj[key] = tag.value;
        return obj;
      }, {});

    return {
      id: payment.id,
      tags: tagsFiltered,
    };
  });

  const uniquePayments = paymentFormatted?.filter(
    (payment, index, self) =>
      index === self.findIndex((p) => p.tags.bookingCode === payment.tags.bookingCode),
  );

  const navigateToPaymentDetail = (id) => {
    navigate(`/paymentDetails/${id}`);
  };
  const headerAdmin = ['Amount', '', '', 'Description', 'Customer', 'Date', 'Channel', 'Gateway'];
  const headerSuperAdmin = ['Amount', '', 'Description', 'Customer', 'Date', 'Channel', 'Gateway'];
  return (
    <>
      <PageHeader title='Payments' />
      {!paymentsQuery.isFetched || lastsPaymentsQuery.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='row my-3'>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'All'}
                quantity={quantities.data?.allQuantity}
                onClick={() => handleClick([])}
                isSelected={filter && filter.length === 0}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Approved'}
                quantity={quantities.data?.approvedQuantity}
                onClick={() => handleClick(['approved'])}
                isSelected={filter && filter[0] === 'approved'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Refunded'}
                quantity={quantities.data?.refundedQuantity}
                onClick={() => handleClick(['refunded'])}
                isSelected={filter && filter[0] === 'refunded'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Pending'}
                quantity={quantities.data?.pendingQuantity}
                onClick={() => handleClick(['pending'])}
                isSelected={filter && filter[0] === 'pending'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Expired'}
                quantity={quantities.data?.expiredQuantity}
                onClick={() => handleClick(['expired'])}
                isSelected={filter && filter[0] === 'expired'}
              />
            </div>
            <div className='col-6 pl-0 col-sm'>
              <FilterCard
                category={'Error'}
                quantity={quantities.data?.errorQuantity}
                onClick={() => handleClick(['error'])}
                isSelected={filter && filter[0] === 'error'}
              />
            </div>
          </div>

          <Table
            data={uniquePayments || []}
            totalP={uniquePayments?.length}
            tableKey={`payments-${organization.id}-${filter ? filter[0] ?? '' : ''}`}
            showText={false}
            columns={organization.id === 83 ? headerAdmin : headerSuperAdmin}
            renderRow={(payment, index) => (
              <TableRow
                key={payment.id}
                index={index}
                onMouseEnter={() => setShowButtons(index)}
                onMouseLeave={() => setShowButtons(null)}
                onClick={() => navigateToPaymentDetail(payment.id)}
              >
                <TableCell
                  className='ctm-text font-weight-bold'
                  style={{ width: '120px' }}
                  type={'primary'}
                >
                  <Money value={payment.tags.price} currencyCode={payment.tags.currency} />
                </TableCell>
                <TableCell style={{ width: '120px' }}>
                  <Tooltip text={getStatusMessage(payment.tags.status)}>
                    <Badge status={payment.tags.status} />
                  </Tooltip>
                </TableCell>
                {organization.id === 83 ? <TableCell>{payment.tags.company}</TableCell> : null}
                <TableCell>{payment.tags.bookingCode}</TableCell>
                <TableCell>{payment.tags.customerEmail}</TableCell>
                <TableCell style={{ width: '180px' }}>
                  {format(new Date(payment.tags.purchaseDate), 'MMM dd, yyyy, h:mm a')}
                </TableCell>
                <TableCell>{payment.tags.channel}</TableCell>
                <TableCell>
                  {payment.tags.gatewayName === 'mercadopago' ? (
                    <div className='img-table d-flex align-items-center rounded justify-content-center overflow-hidden'>
                      <MercadoPago size={'30px'} />
                    </div>
                  ) : payment.tags.gatewayName === 'fiserv' ? (
                    <div className='img-table d-flex align-items-center rounded justify-content-center overflow-hidden'>
                      <Fiserv size={'30px'} />
                    </div>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </>
  );
};

export default Payments;
