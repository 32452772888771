export const fetchPayments = async (
  welletContext,
  filter,
  id,
  dates = {
    from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString(),
    to: null,
  },
  limit = null,
  skip = null,
  organizationFilter = [],
  url = 'api/Dashboard/ApprovalActivityFeed',
) => {
  let data;
  const company = id !== 83 ? [id] : null;
  const filterFormatted = (filter) => {
    if (filter.length === 0) {
      return [];
    } else {
      return filter.filter((item) => item.isActive).map((item) => item.value);
    }
  };
  const organizations = filterFormatted(organizationFilter);

  const body = {
    companiesIds: company ? company : organizations,
    companyIds: company ? company : organizations,
    showIds: null,
    venuesIds: null,
    venueIds: null,
    term: '',
    channels: [],
    tag: 2,
    filter: {
      states: filter,
      bookingStatus: [],
      channels: [],
      from: dates.from,
      to: dates?.to ? dates.to : null,
      limit,
      skip,
    },
  };
  try {
    const response = await welletContext.apis.manager.post(url, body);
    if (response.status === 200) {
      data = response.data;
    } else {
      data = response.error;
    }
  } catch (error) {
    data = error;
  }

  return data;
};

export const fetchSellers = async (welletContext, id = null) => {
  try {
    const response = await welletContext.apis.admin.get(`api/Wallet/sellers/${id}`);
    return response;
  } catch (e) {
    console.error(e);
  }
};
