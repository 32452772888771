import React from 'react';
import './performanceWeekdays.css';

const PerformanceWeekdays = ({ performance }) => {
  const daysOfWeek = [
    { name: 'Sun', shortName: 'S' },
    { name: 'Mon', shortName: 'M' },
    { name: 'Tue', shortName: 'T' },
    { name: 'Wed', shortName: 'W' },
    { name: 'Thu', shortName: 'T' },
    { name: 'Fri', shortName: 'F' },
    { name: 'Sat', shortName: 'S' },
  ];
  return (
    <table className='table table-bordered small-table'>
      <thead>
        <tr>
          {daysOfWeek.map((day) => (
            <th
              key={day.name}
              className={`small-cell ${performance.includes(day.name) ? 'badge-success' : ''}`}
              scope='col'
            >
              {day.shortName}
            </th>
          ))}
        </tr>
      </thead>
    </table>
  );
};

export default PerformanceWeekdays;
