import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { SignInHeader } from '../../components';
import logoWellet from '../../assets/images/welletIso.png';
import { Input, Button, Card, CardBody, Loading, Toast } from '../../uiComponents/common';
import WarningIcon from '../../uiComponents/icons/warningIcon';
import { WelletContext } from '../../context/wellet/welletContext';
import useUser from '../../hooks/useUser';
import { useMutation } from 'react-query';
import { changePassword } from '../../services';

const ChangePassword = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate()
  const welletContext = useContext(WelletContext);
  const { jwt } = useUser();

  const changePasswordMutation = useMutation({
    mutationFn: () => changePassword(welletContext, values),
    onError: (error) => {
      setError(error.response.data);
    },
  });

  const sendForm = async () => {
    setError('');

    if (jwt) {
      changePasswordMutation.mutate();
    } else {
      setError('Please log-in to change your password.');
    }
  };

  const validate = () => {
    setError('');
    let errors = {};

    if (!values.new_password) {
      errors.new_password = 'New password is required.';
    }
    if (!values.confirm_password) {
      errors.confirm_password = 'Confirm password is required.';
    }

    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  if (changePasswordMutation.isLoading) {
    return <Loading />;
  }

  return (
    <div className='d-flex flex-column justify-content-beetween align-items-strech vh-100 w-100'>
      <SignInHeader />
      {changePasswordMutation.isSuccess && (
        <Toast
          message='Contraseña cambiada con exito'
          type='SUCCESS'
          onCallback={() => navigate(`/`, { replace: true })}
        />
      )}
      <div className='d-flex flex-column justify-content-center align-items-center h-100'>
        <form onSubmit={handleSubmit} className='login-form'>
          <Card>
            <div className='row py-3 justify-content-center'>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{ height: '70px', width: '70px' }}
              >
                <img src={logoWellet} alt='Logo' style={{ height: '100%', width: '100%' }} />
              </div>
            </div>
            <div className='row'>
              <h5 className='col-12 text-center' style={{ color: 'var(--color-subtitle)'}}>Change your Password</h5>
            </div>
            <CardBody>
              <Input
                placeholder={'New Password'}
                name={'new_password'}
                type={'password'}
                value={values.new_password}
                error={errors.new_password}
                onChangeValue={handleChange}
              />
              <Input
                placeholder={'Confirm New Password'}
                name={'confirm_password'}
                type={'password'}
                value={values.confirm_password}
                error={errors.confirm_password}
                onChangeValue={handleChange}
              />
              {error ? (
                <div className='mt-2 d-flex align-items-center'>
                  <div className='mr-1'>
                    <WarningIcon color={'#CD3D64'} />
                  </div>
                  <div className='error-text'>{error}</div>
                </div>
              ) : (
                <></>
              )}
              <Button
                type='submit'
                size={'small'}
                className={'mt-2'}
                width={'100%'}
                text={'Sign in'}
              />
              <div className='py-2 mt-2 content-divider text-muted'>
                <span className='px-3'></span>
              </div>
              <div className='row'>
                <div className='col'>
                  <Link to={'/'}>Back to main</Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
