import React from 'react';
import './inputs.css';

const InputBase = ({
  icon,
  label,
  value,
  onChange,
  type = 'text',
  field,
  errors,
  name,
  placeholder,
  onClick,
  endComponent,
  isDisabled,
  isAutocomplete = true,
  ...others
}) => {
  return (
    <div className=''>
      {label ? <label style={{ color: 'var(--color-text)' }}>{label}</label> : <></>}
      <div className='d-flex'>
        {icon ? (
          <span
            className='input-group-text px-2 input-icon'
            style={{ color: 'var(--color-subtitle)' }}
          >
            {icon}
          </span>
        ) : (
          <></>
        )}
        <input
          isAutocomplete={isAutocomplete}
          disabled={isDisabled}
          type={type}
          value={value}
          name={name}
          className={`input-style ${endComponent ? 'input-end' : ''} ${
            errors[name] ? 'input-error' : ''
          }  ${isDisabled ? 'input-disabled' : ''}`}
          placeholder={placeholder}
          onChange={onChange}
          onClick={onClick}
          {...others}
          {...field}
        />
        {endComponent}
      </div>
    </div>
  );
};

export default InputBase;
