import { useContext } from 'react';
import { getAllLanguages } from '../../services';
import { WelletContext } from '../../context/wellet/welletContext';
import { useQuery } from 'react-query';

export const useGetLanguagesQuery = (user, options) => {
  const welletContext = useContext(WelletContext);
  const queryOptions = {
    staleTime: 300,
    enabled: !!user,
    ...options,
  };

  return useQuery(['languages', user], () => getAllLanguages(welletContext), queryOptions);
};
