import React from 'react';

export default function NumberTaskIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 23.54 14.23'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g id='Capa_1-2' data-name='Capa 1'>
        <g>
          <path
            fill={color}
            d='m19.05,14.23H1.7c-.94,0-1.7-.76-1.7-1.7V1.7C0,.76.76,0,1.7,0h17.35c.94,0,1.7.76,1.7,1.7v1.65h-1v-1.65c0-.39-.31-.7-.7-.7H1.7c-.39,0-.7.31-.7.7v10.83c0,.39.31.7.7.7h17.35c.39,0,.7-.31.7-.7v-1.69h1v1.69c0,.94-.76,1.7-1.7,1.7Z'
          />
          <g>
            <path
              fill={color}
              d='m6.79,3.06l-4.1,1.63v1.26h.99v3.16h-.41v.66h7.03v-.66h-.41v-3.16h.99v-1.26l-4.1-1.63Zm-2.27,2.9h1.95v3.16h-1.95v-3.16Zm4.53,3.16h-1.74v-3.16h1.74v3.16Z'
            />
            <rect fill={color} x='3.02' y='10.51' width='7.53' height='.66' />
          </g>
          <g>
            <path
              fill={color}
              d='m12.96,5.48h.2c.73,0,1.03-.29,1.08-.61.03-.23.23-.23.23-.23h.37c.18,0,.22.08.22.23v4.34c0,.42-.95.39-.95-.02v-3.05h-1.15c-.25,0-.28-.66,0-.66Z'
            />
            <path
              fill={color}
              d='m16.5,6.34c0-1.57,1.15-1.7,1.57-1.7.92,0,1.65.54,1.65,1.52,0,.83-.54,1.25-1,1.57-.64.45-.97.66-1.08.89h2c.23,0,.21.84,0,.84h-2.86c-.11,0-.2-.05-.26-.14-.04-.06-.05-.13-.05-.21,0-.6.26-.96,1.09-1.56.87-.63,1.21-.88,1.21-1.42,0-.32-.2-.68-.66-.68-.66,0-.68.55-.69.9,0,0,0,.24-.51.24-.43,0-.41-.24-.41-.24Z'
            />
            <path
              fill={color}
              d='m21.82,6.67c.28,0,.62-.03.62-.65,0-.18-.08-.62-.6-.62-.65,0-.6.63-.65.79-.14.39-.88.31-.88-.08,0-.95.51-1.47,1.59-1.47.64,0,1.49.33,1.49,1.34,0,.49-.31.79-.58.92h0c.17.06.73.31.73,1.13,0,.91-.67,1.55-1.64,1.55-.45,0-1.67.1-1.67-1.51,0-.44.79-.53.93-.09.06.18-.01.84.69.84.38,0,.71-.26.71-.79,0-.88-.87-.37-1.18-.68-.12-.12-.16-.68.44-.68Z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
