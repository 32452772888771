import React from 'react';

export default function ChangePasswordIcon({ size = '16px', color = '#fff', rotation = '0' }) {
  return (
    <svg
      id='Capa_2'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 129.54 145.83'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path
            fill={color}
            d='m120.23,39.5c-.74-1.23-1.92-2.09-3.31-2.43-1.38-.34-2.81-.11-4.03.63-1.22.74-2.08,1.91-2.42,3.3-.34,1.39-.12,2.82.62,4.04,5.07,8.38,7.76,18.02,7.76,27.88,0,29.82-24.26,54.07-54.08,54.07-4.65,0-9.22-.58-13.64-1.73l3.6-1.73c2.66-1.28,3.78-4.48,2.5-7.13-1.28-2.65-4.48-3.77-7.13-2.5l-15.08,7.25c-1.33.64-2.35,1.82-2.79,3.24-.44,1.42-.27,2.96.47,4.25l8.34,14.52c.95,1.66,2.72,2.68,4.64,2.68.93,0,1.84-.25,2.65-.71,1.24-.71,2.12-1.86,2.5-3.24.37-1.38.19-2.82-.53-4.06l-1.2-2.09c5.17,1.3,10.42,1.96,15.66,1.96,35.71,0,64.77-29.05,64.77-64.77,0-11.83-3.22-23.38-9.31-33.42Z'
          />
          <path
            fill={color}
            d='m64.77,18.83c4.64,0,9.22.58,13.64,1.73l-3.6,1.73c-2.66,1.28-3.78,4.48-2.5,7.13.89,1.84,2.78,3.03,4.83,3.03.8,0,1.58-.18,2.31-.53l15.08-7.25c1.33-.64,2.35-1.82,2.79-3.24.44-1.42.27-2.97-.47-4.24l-8.34-14.52c-.71-1.24-1.86-2.13-3.24-2.5-1.38-.37-2.82-.18-4.06.53-1.24.71-2.12,1.86-2.5,3.24-.37,1.38-.19,2.82.53,4.06l1.2,2.09c-5.17-1.3-10.42-1.96-15.66-1.96C29.05,8.14,0,37.2,0,72.91c0,11.82,3.22,23.38,9.3,33.42.98,1.61,2.69,2.58,4.58,2.58.97,0,1.93-.27,2.77-.78,2.52-1.53,3.33-4.82,1.8-7.34-5.07-8.38-7.76-18.01-7.76-27.88,0-29.82,24.26-54.08,54.08-54.08Z'
          />
          <path
            fill={color}
            d='m64.77,40.37c-9.32,0-16.91,7.58-16.91,16.91v9.47c-3.38,1.18-5.68,4.35-5.68,7.98v17.91c0,4.68,3.8,8.48,8.48,8.48h28.22c4.68,0,8.48-3.8,8.48-8.48v-17.92c0-3.63-2.31-6.81-5.69-7.98v-9.47c0-9.32-7.58-16.91-16.91-16.91Zm6.21,16.91v8.97h-12.43v-8.97c0-3.43,2.79-6.21,6.21-6.21s6.21,2.79,6.21,6.21Z'
          />
        </g>
      </g>
    </svg>
  );
}
