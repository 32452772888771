import React from 'react';

export default function FwTrashIcon({
  color = 'currentColor',
  fontSize = '16px',
  classNameIcon = '',
}) {
  return (
    <>
      <i className={`fas fa-trash ${classNameIcon}`} style={{ color, fontSize: fontSize }}></i>
    </>
  );
}
