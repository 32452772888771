import React from 'react';
import '../selects/selects.css';
import { useFormContext, Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

const InputEmails = ({
  options,
  label,
  icon,
  name,
  className,
  selectAll = false,
  isRequired = false,
  ...others
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = React.useState('');

  const createOption = (label) => ({
    label,
    value: label,
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validationRules = {};

  if (isRequired) {
    validationRules.required = 'This field is required';
  }

  const SelectComponent = CreatableSelect;

  return (
    <div className={`form-group ${className}`}>
      <label style={{ color: 'var(--color-text)' }}>{label}</label>
      <div className='d-flex'>
        {icon ? (
          <span
            className='input-group-text px-2 input-icon'
            style={{ color: 'var(--color-subtitle)' }}
          >
            {icon}
          </span>
        ) : (
          <></>
        )}
        <Controller
          name={name}
          control={control}
          rules={validationRules}
          render={({ field }) => (
            <>
              <SelectComponent
                isClearable
                isMulti
                menuIsOpen={false}
                inputValue={inputValue}
                onChange={(newValue) => {
                  setError('');
                  field.onChange(newValue);
                }}
                onInputChange={(newValue) => {
                  setError('');
                  setInputValue(newValue);
                }}
                onKeyDown={(event) => {
                  switch (event.key) {
                    case 'Enter':
                    case 'Tab':
                      event.preventDefault();
                      if (isValidEmail(inputValue)) {
                        const newOption = createOption(inputValue);
                        if (Array.isArray(field.value)) {
                          field.value.map((i) => i.value).includes(inputValue)
                            ? setError('Mail already included.')
                            : field.onChange([...field?.value, newOption]);
                        } else {
                          field.onChange([newOption]);
                        }
                        setInputValue('');
                      } else {
                        setError('Email is not valid.');
                      }
                      break;
                    default:
                      break;
                  }
                }}
                placeholder={others.placeholder}
                value={field.value}
                components={{
                  ...others.components,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: null,
                }}
                styles={{
                  control: (baseStyles, props) => ({
                    ...baseStyles,
                    border: 'none',
                    borderRadius: 'none',
                    outline: 0,
                    boxShadow: 'none',
                    padding: 0,
                    minHeight: '34px',
                    background: props.isDisabled ? '#e1e1e1' : 'none',
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: '0 8px',
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    padding: 0,
                    margin: 0,
                    color: 'var(--text-color)',
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: 0,
                    backgroundColor: 'var(--color-background-app)',
                    borderRadius: '5px',
                    zIndex: 99999,
                  }),
                  menuList: (baseStyles) => ({
                    ...baseStyles,
                    padding: 0,
                    borderRadius: '5px',
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: 'var(--text-color)',
                  }),
                  multiValueRemove: (baseStyles) => ({
                    ...baseStyles,
                    ':hover': {
                      color: 'var(--color-title)',
                      backgroundColor: '#cacaca',
                    },
                  }),
                  menuPortal: (baseStyles) => ({
                    ...baseStyles,
                    zIndex: 9999,
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    color: 'var(--text-color)',
                    backgroundColor: state.isSelected
                      ? 'rgba(0,0,0,.10)'
                      : 'var(--color-background-app)',
                    ':hover': {
                      backgroundColor: state.isSelected ? 'rgba(0,0,0,.10)' : 'rgba(0,0,0,.03)',
                    },
                  }),
                }}
                className={`select-style ${errors[name] ? 'input-error' : ''}`}
              />
            </>
          )}
        />
      </div>
      {errors[name] || error ? (
        <div className='d-flex align-items-center error-content'>
          <i className='fas fa-exclamation-triangle text-danger mr-2'></i>
          <p className='text-danger p-0 m-0'>{errors[name]?.message || error}</p>
        </div>
      ) : null}
    </div>
  );
};

export default InputEmails;
