import './sidebar.css';

import { useContext } from 'react';
import SidebarContext from '../../context/sidebarContext';
import { SideBarSection, SideBarHeader } from '../../uiComponents/sideBarCommon/';
import { useTheme } from '../../context/ThemeContext';

// To do: agergar transiciones
export default function SideBar() {
  const { isSidebarOpen, isSidebarResized, isMouseOver, setIsMouseOver } =
    useContext(SidebarContext);
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`${isSidebarOpen ? 'sidebar-mobile-expanded' : ' '} ${
        isSidebarResized ? 'sidebar-main-resized' : ''
      } sidebar sidebar-main  sidebar-expand-lg ${
        isMouseOver && isSidebarResized ? 'sidebar-main-unfold' : ''
      } ${isDarkMode ? 'dark-mode' : ''}`}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div className='sidebar-content'>
        <SideBarHeader />
        <SideBarSection />
      </div>
    </div>
  );
}
