import React, { useContext, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  fetchActivities,
  getAllChannels,
  getAllTickets,
  getPromotionById,
  postPromotion,
  putPromotion,
} from '../../../services';
import { WelletContext } from '../../../context/wellet/welletContext';
import { useSelector } from 'react-redux';
import {
  Alert,
  BreadCrumbs,
  Button,
  InputClock,
  InputDate,
  InputNumber,
  InputText,
  Loading,
  ModernSwitcher,
  Select,
  Subtitle,
  Tabs,
  TextAlert,
  Toast,
  ToggleToken,
} from '../../../uiComponents/common';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ChannelIcon,
  DiscountIcon,
  FwCalendarIcon,
  FwClockIcon,
  FwMasksIcon,
  SaleIcon,
  VoucherIcon,
} from '../../../uiComponents/icons';
import { InputOption } from '../../../uiComponents/common/selects/checkOptions';
import useLanguageSwitcher from '../../../hooks/useLanguageSwitcher';
import Weekdays from '../../../data/weekdays.json';
import { removeObjectsWithEmptyFields } from '../../../helpers/forms';
import { formatTimeToMinutes } from '../../../helpers/format';
import Table from '../../table/table';
import TableRow from '../../table/tableRow';
import TableCell from '../../table/tableCell';
import { format } from 'date-fns';

const PromotionsForm = () => {
  const { id } = useParams();
  const promotionQuery = useQuery({
    queryKey: ['promotion', id],
    queryFn: () => getPromotionById(welletContext, id),
    enabled: !!id,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => methods.reset(formatData(data.data)),
  });
  const [isRedirect, setIsRedirect] = useState(null);
  const [voucherTable, setVoucherTable] = useState([]);

  const lastClickedButton = useRef(null);
  const welletContext = useContext(WelletContext);
  const location = useLocation();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.app.selectedOrganization);

  const methods = useForm();

  const activitiesQuery = useQuery({
    queryKey: ['activities', organization.id],
    queryFn: () => fetchActivities(welletContext, organization.id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const activities = activitiesQuery?.data?.map((v) => ({ label: v.name, value: v.id }));
  const currentActivity = methods.watch('activity');

  const channelsQuery = useQuery({
    queryKey: ['channels'],
    queryFn: () => getAllChannels(welletContext),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const channel = channelsQuery?.data?.data.map((r) => ({ label: r.name, value: r.id }));

  const ticketsQuery = useQuery({
    queryKey: ['tickets', currentActivity?.value, organization.id],
    queryFn: () =>
      getAllTickets(
        welletContext,
        [1, 2, 3, 4, 5, 6],
        null,
        null,
        false,
        currentActivity?.value,
        organization.id,
      ),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!currentActivity?.value,
  });

  const tickets = ticketsQuery?.data?.data?.map((v) => ({ label: v.name, value: v.id }));

  const promotionMutation = useMutation({
    mutationFn: (body) => {
      if (id) {
        return putPromotion(welletContext, body);
      }
      return postPromotion(welletContext, body);
    },
  });

  const onSubmit = (data) => {
    const initialLangPromotions = languages.map((lng) => {
      return {
        language: lng.shortForm,
        name: data[`name_${lng.shortForm}`],
      };
    });
    const removeEmptysLangPromotions = removeObjectsWithEmptyFields(initialLangPromotions, [
      'name',
    ]);
    const body = {
      id: isEditing ? data.id : 0,
      name: removeEmptysLangPromotions[0].name,
      langPromotions: removeEmptysLangPromotions,
      fromPurchaseDate: data.fromDate,
      toPurchaseDate: data.toDate,
      fromTime: data.fromTime
        ? {
            hour: Number(data.fromTime.split(':')[0]),
            minutes: Number(data.fromTime.split(':')[1]),
          }
        : null,
      toTime: data.toTime
        ? {
            hour: Number(data.toTime.split(':')[0]),
            minutes: Number(data.toTime.split(':')[1]),
          }
        : null,
      purchaseWeekdays: data.weekdays?.map((d) => d.value),
      discountType: data.discountType?.value,
      discountValue: data.discountValue,
      isVoucherPromo: !!data.voucher,
      totalQuantity: data.quantity,
      minimunTotalOrderAmount: data.minimumAmount,
      promotionChannel: data.channel?.map((c) => ({ ChannelId: c.value })),
      productPromotions: data.tickets?.map((t) => ({ ProductId: t.value })),
      voucher:
        data.voucher && !isEditing
          ? {
              maxRedemptions: data?.voucherQuantity ? data?.voucherQuantity : null,
              validFromUTC: data?.voucherStartDate ? data?.voucherStartDate : null,
              validToUTC: data?.voucherEndDate ? data?.voucherEndDate : null,
              NumberOfVouchers: data.voucherGenerator,
              voucherCodeLength: data?.voucherLength ? data?.voucherLength : null,
              VoucherCodePrefix: data?.voucherPrefix,
              VoucherCodePostfix: data?.voucherPostfix,
            }
          : null,
      isActive: !isEditing ? true : data.isActive,
    };

    promotionMutation.mutate(body);
  };
  const formatData = (data) => {
    const formattedData = {
      id: data.id,
      discountType: { label: data.discountType, value: data.discountType },
      discountValue: data.discountValue,
      fromDate: data.fromPurchaseDate?.split('T')[0],
      toDate: data.toPurchaseDate?.split('T')[0],
      fromTime: data.fromTime,
      toTime: data.toTime,
      activity: { label: data?.activity?.name, value: data?.activity?.id },
      channel: data.promotionChannel.map((c) => ({ label: c.channel.name, value: c.channel.id })),
      tickets: data.productPromotions.map((t) => ({
        label: t.ticketProduct.name,
        value: t.ticketProduct.id,
      })),
      quantity: data.totalQuantity,
      minimumAmount: data.minimunTotalOrderAmount,
      weekdays: data?.purchaseWeekdays?.map((r) => Weekdays.find((v) => v.value === r)),
      isActive: data.isActive,
    };
    setVoucherTable(data.vouchers);
    data.langPromotions.forEach((langPromo) => {
      const lang = langPromo.language;
      formattedData[`name_${lang}`] = langPromo.name;
    });

    return formattedData;
  };

  const clearForm = () => {
    if (id) {
      navigate('/promotions');
    } else {
      promotionMutation.reset();
      methods.reset();
      navigate('/promotions');
    }
  };

  const handleRedirect = (bool, string) => {
    setIsRedirect(bool);
    lastClickedButton.current = string;
  };

  const handleToastCallback = () => {
    if (isRedirect) {
      clearForm();
      navigate('/promotions');
    } else {
      if (isEditing) {
        promotionQuery.refetch();
      }
      promotionMutation.reset();
    }
  };

  const isEditing = location.pathname.split('/')[2] === 'edit-promotion';
  const { changeLanguage, currentLng, lngErrors, languages } = useLanguageSwitcher({}, methods);

  const dateFrom = methods.watch('fromDate');
  const dateTo = methods.watch('toDate');
  const dateVoucherFrom = methods.watch('voucherStartDate');
  const dateVoucherTo = methods.watch('voucherEndDate');

  return (
    <div>
      <BreadCrumbs
        crumbs={[
          {
            name: 'Promotions',
            path: '/promotions',
          },
          {
            name: isEditing ? 'Edit Promotion' : 'New Promotion',
            path: isEditing ? '/edit-promotion' : '/new-promotion',
          },
        ]}
      />
      {promotionQuery.isLoading || activitiesQuery.isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form key={1} onSubmit={methods.handleSubmit(onSubmit)}>
            <Subtitle text={<h3>{isEditing ? `Edit Promotion` : 'New Promotion'}</h3>} />

            <div className='row mt-2'>
              <Select
                options={activities}
                label={'Activity'}
                className={'col-12 col-sm-6 col-xl-4 m-0 mb-2'}
                icon={<FwMasksIcon />}
                name={'activity'}
                isRequired={true}
                onManualChange={() => methods.setValue('tickets', null)}
              />
              <Select
                options={tickets}
                label={'Tickets'}
                className={'col-12 col-sm-6 col-xl-4 m-0 mb-2'}
                icon={<SaleIcon />}
                name={'tickets'}
                type={'all'}
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
                isRequired={true}
                isFetching={ticketsQuery.isFetching}
              />
            </div>
            <div className='row'>
              <Select
                options={channel}
                label={'Channels'}
                className={'col-12 col-sm-6 col-xl-4'}
                icon={<ChannelIcon />}
                name={'channel'}
                type='all'
                components={{
                  Option: InputOption,
                }}
                isRequired={true}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
              />
            </div>
            <Tabs onClick={(l) => changeLanguage(l)} options={languages} />
            <div className='row mt-1'>
              {Object.keys(lngErrors).length !== 0 && (
                <TextAlert text={lngErrors.name} type={'danger'} />
              )}
            </div>
            <div className='row mt-2'>
              <InputText
                name={'name_' + currentLng}
                label={'Name'}
                className={'col-12 col-sm-6 col-xl-4'}
                placeholder={'Enter promotion name'}
                icon={<VoucherIcon />}
                isRequired={true}
              />
              {isEditing ? (
                <div className='col-12 col-sm-6 col-xl-4'>
                  <ToggleToken
                    label={'Status'}
                    activeText={'Active'}
                    inactiveText={'Inactive'}
                    name={'isActive'}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <Subtitle text={<h3>Discount Data</h3>} />
            <div className='row mt-2'>
              <Select
                options={[
                  { label: 'Percent', value: 'Percent' },
                  { label: 'Amount', value: 'Amount' },
                ]}
                label={'Discount type'}
                className={'col-12 col-sm-6 col-xl-4 m-0 mb-2'}
                icon={<DiscountIcon />}
                name={'discountType'}
                isRequired={true}
              />
              <InputNumber
                placeholder={'Enter discount value'}
                label={'Discount Value'}
                icon={<DiscountIcon />}
                className={'col-12 col-sm-6 col-xl-4 mb-2'}
                customValidation={{
                  validate: (value) => {
                    if (value && value < 0) {
                      return 'Value must be positive.';
                    }
                  },
                }}
                name={'discountValue'}
                isRequired={true}
              />
            </div>
            <Subtitle text={<h3>Dates Data</h3>} />
            <div className='row mt-2'>
              <InputDate
                placeholder={'Select Date'}
                label={'From Date'}
                className={'col-12 col-sm-6 col-xl-4 mb-1'}
                icon={<FwCalendarIcon />}
                name={'fromDate'}
                fromDate={new Date('01/01/2021')}
                toDate={dateTo ? new Date(dateTo + 'T00:00:00') : new Date('01/01/2030')}
                isRequired={true}
              />
              <InputDate
                placeholder={'Select Date'}
                label={'To Date'}
                className={'col-12 col-sm-6 col-xl-4 mb-1'}
                icon={<FwCalendarIcon />}
                name={'toDate'}
                fromDate={dateFrom ? new Date(dateFrom + 'T00:00:00') : new Date('01/01/2021')}
                toDate={new Date('01/01/2030')}
                isRequired={true}
              />
            </div>
            <div className='row mt-2'>
              <InputClock
                label={'From Time'}
                icon={<FwClockIcon />}
                className={'col-12 col-sm-6 col-xl-4 mb-2'}
                required={false}
                name={`fromTime`}
              />
              <InputClock
                label={'To Time'}
                icon={<FwClockIcon />}
                className={'col-12 col-sm-6 col-xl-4 mb-2'}
                name={`toTime`}
                required={false}
                validateFunction={(value) => {
                  const fromTime = methods.watch('fromTime');
                  const toTimeMinutes = formatTimeToMinutes(value ?? '');
                  const fromTimeMinutes = formatTimeToMinutes(fromTime ?? '');
                  if (dateFrom === dateTo && toTimeMinutes < fromTimeMinutes) {
                    return 'It must be greater than or equal to the from time.';
                  }
                  return true;
                }}
              />
            </div>
            <div className='row'>
              <Select
                options={Weekdays}
                label={'Weekdays'}
                className={'col-12 col-xl-8 mb-1'}
                icon={<FwClockIcon />}
                name={'weekdays'}
                components={{
                  Option: InputOption,
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                hideSelectedOptions={false}
                isRequired={true}
              />
            </div>

            <Subtitle text={<h3>Quantities Data</h3>} />
            <div className='row mt-2'>
              <InputNumber
                placeholder={'Enter total quantity'}
                label={'Total quantity'}
                className={'col-12 col-sm-6 col-xl-4 mb-2'}
                customValidation={{
                  validate: (value) => {
                    if (value && value < 0) {
                      return 'Value must be positive.';
                    }
                  },
                }}
                name={'quantity'}
                isRequired={true}
              />
              <InputNumber
                placeholder={'Enter minimum total order amount'}
                label={'Minimum Order Amount'}
                className={'col-12 col-sm-6 col-xl-4 mb-2'}
                customValidation={{
                  validate: (value) => {
                    if (value && value < 0) {
                      return 'Value must be positive.';
                    }
                  },
                }}
                name={'minimumAmount'}
                isRequired={true}
              />
            </div>
            <Subtitle text={<h3>Card Voucher Data</h3>} />
            {isEditing ? (
              <Table
                columns={['Code', 'Max Redemptions', 'Valid From', 'Valid To', 'Creation Date']}
                data={voucherTable}
                tableKey={`voucherTables-${voucherTable.length}`}
                renderRow={(voucher) => (
                  <TableRow key={voucher.code}>
                    <TableCell>{voucher.code}</TableCell>
                    <TableCell>{voucher.maxRedemptions ?? '-'}</TableCell>
                    <TableCell>
                      {voucher.validFromUTC
                        ? format(new Date(voucher.validFromUTC), 'MMM dd, yyyy, h:mm a')
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {voucher.validToUTC
                        ? format(new Date(voucher.validToUTC), 'MMM dd, yyyy, h:mm a')
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {voucher.creationDateUTC
                        ? format(new Date(voucher.creationDateUTC), 'MMM dd, yyyy, h:mm a')
                        : '-'}
                    </TableCell>
                  </TableRow>
                )}
              />
            ) : (
              <>
                <div className='row mt-2'>
                  <div className='col-auto mt-2'>
                    <ModernSwitcher
                      onClick={() => {
                        methods.clearErrors([
                          'voucherCode',
                          'voucherQuantity',
                          'voucherStartDate',
                          'voucherEndDate',
                          'voucherGenerator',
                          'voucherLength',
                          'voucherPrefix',
                          'voucherPostfix',
                        ]);
                      }}
                      name={'voucher'}
                      text={'Allow Voucher'}
                    />
                  </div>
                </div>
                <div className='row mt-2'>
                  <InputNumber
                    placeholder={'Enter number of vouchers'}
                    label={'Number of Vouchers'}
                    icon={<VoucherIcon />}
                    className={'col-12 col-sm-6 col-xl-4 mb-2'}
                    isDisabled={!methods.watch('voucher')}
                    customValidation={{
                      validate: (value) => {
                        if (value && value < 0) {
                          return 'Value must be positive.';
                        }
                      },
                      required: !!methods.watch('voucher'),
                    }}
                    name={'voucherGenerator'}
                    isRequired={!!methods.watch('voucher')}
                  />

                  <InputNumber
                    placeholder={'Enter voucher code length'}
                    label={'Voucher Code Length'}
                    icon={<VoucherIcon />}
                    className={'col-12 col-sm-6 col-xl-4 mb-2'}
                    isDisabled={!methods.watch('voucher')}
                    customValidation={{
                      validate: (value) => {
                        if (value && value < 0) {
                          return 'Value must be positive.';
                        } else if (value > 50) {
                          return 'Value must be between 0 and 50.';
                        }
                      },
                    }}
                    name={'voucherLength'}
                  />
                </div>
                <div className='row'>
                  <InputText
                    name={'voucherPrefix'}
                    isDisabled={!methods.watch('voucher')}
                    label={'Voucher Prefix'}
                    className={'col-12 col-sm-6 col-xl-4 mb-2'}
                    placeholder={'Enter voucher prefix'}
                    icon={<VoucherIcon />}
                    minLength={1}
                  />
                  <InputText
                    name={'voucherPostfix'}
                    isDisabled={!methods.watch('voucher')}
                    label={'Voucher PostFix'}
                    className={'col-12 col-sm-6 col-xl-4 mb-2'}
                    placeholder={'Enter voucher postfix'}
                    icon={<VoucherIcon />}
                    minLength={1}
                  />
                </div>
                <div className='row'>
                  <InputDate
                    placeholder={'Select Date'}
                    label={'Voucher Start Date'}
                    className={'col-12 col-sm-6 col-xl-4 mb-2'}
                    icon={<FwCalendarIcon />}
                    name={'voucherStartDate'}
                    isDisabled={!methods.watch('voucher')}
                    fromDate={new Date('01/01/2021')}
                    toDate={
                      dateVoucherTo ? new Date(dateVoucherTo + 'T00:00:00') : new Date('01/01/2030')
                    }
                  />
                  <InputDate
                    placeholder={'Select Date'}
                    label={'Voucher End Date'}
                    className={'col-12 col-sm-6 col-xl-4 mb-2'}
                    icon={<FwCalendarIcon />}
                    fromDate={
                      dateVoucherFrom
                        ? new Date(dateVoucherFrom + 'T00:00:00')
                        : new Date('01/01/2021')
                    }
                    toDate={new Date('01/01/2030')}
                    isDisabled={!methods.watch('voucher')}
                    name={'voucherEndDate'}
                  />
                </div>
                <div className='row'>
                  <InputNumber
                    placeholder={'Enter voucher max redemptions'}
                    label={'Max Redemptions'}
                    icon={<VoucherIcon />}
                    className={'col-12 col-sm-6 col-xl-4'}
                    isDisabled={!methods.watch('voucher')}
                    customValidation={{
                      validate: (value) => {
                        if (value && value < 0) {
                          return 'Value must be positive.';
                        }
                      },
                    }}
                    name={'voucherQuantity'}
                  />
                </div>
              </>
            )}
            <div className='row align-items-center justify-content-center justify-content-sm-start px-2 py-3'>
              <Button
                size={'small'}
                type='submit'
                text={`${
                  promotionMutation.isLoading && lastClickedButton.current === 'send'
                    ? 'Sending...'
                    : 'Send'
                }`}
                onClick={() => {
                  handleRedirect(false, 'send');
                }}
              />
              <Button
                size={'small'}
                type='button'
                className={'btn-custom-white  ml-2'}
                text={`Cancel`}
                onClick={clearForm}
              />
              <Button
                size={'small'}
                className={' ml-2'}
                type='submit'
                text={`${
                  promotionMutation.isLoading && lastClickedButton.current === 'sendAndClose'
                    ? 'Sending...'
                    : 'Send & Close'
                }`}
                onClick={() => {
                  handleRedirect(true, 'sendAndClose');
                }}
              />
            </div>
          </form>
        </FormProvider>
      )}
      {promotionMutation.isSuccess && (
        <Toast
          message={`Promotion ${id ? 'updated' : 'created'} successfully.`}
          type='SUCCESS'
          onCallback={handleToastCallback}
        />
      )}
      {promotionMutation?.error &&
        (promotionMutation?.error?.response?.data?.message ? (
          <Alert
            title={'Oops, something went wrong'}
            text={promotionMutation?.error?.response?.data?.message}
            type={'error'}
          />
        ) : (
          <></>
        ))}
    </div>
  );
};

export default PromotionsForm;
