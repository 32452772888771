import React from 'react';

export default function CityIcon({ size = '16px', color = 'currentColor', rotation = '0' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19.52 18.82'
      style={{
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.5s ease-in-out',
      }}
      width={size}
      height={size}
    >
      <g>
        <g>
          <path
            fill={color}
            d='m15.44,8.03h-5.25c-.21,0-.38.17-.38.38v7.48c0,.21.17.38.38.38h5.25c.21,0,.38-.17.38-.38v-7.48c0-.21-.17-.38-.38-.38Zm-1.88,5.81c0,.13-.1.23-.23.23h-1.04c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h1.04c.13,0,.23.1.23.23v1.03Zm0-3.24c0,.13-.1.23-.23.23h-1.04c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h1.04c.13,0,.23.1.23.23v1.03Z'
          />
          <path
            fill={color}
            d='m11.54,6.97V.38c0-.21-.17-.38-.38-.38h-5.25c-.21,0-.38.17-.38.38v15.51c0,.21.17.38.38.38h2.69c.21,0,.38-.17.38-.38V7.85c0-.27.23-.5.5-.5h1.68c.21,0,.38-.17.38-.38Zm-4.93-3.88c0-.13.1-.23.23-.23h.6c.13,0,.23.1.23.23v1.03c0,.13-.1.23-.23.23h-.6c-.13,0-.23-.1-.23-.23v-1.03Zm1.07,10.75c0,.13-.1.23-.23.23h-.6c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h.6c.13,0,.23.1.23.23v1.03Zm0-3.24c0,.13-.1.23-.23.23h-.6c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h.6c.13,0,.23.1.23.23v1.03Zm0-3.24c0,.13-.1.23-.23.23h-.6c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h.6c.13,0,.23.1.23.23v1.03Zm2.62-3.24c0,.13-.1.23-.23.23h-.6c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h.6c.13,0,.23.1.23.23v1.03Z'
          />
          <path
            fill={color}
            d='m4.38,3.49H.38c-.21,0-.38.17-.38.38v12.02c0,.21.17.38.38.38h4c.21,0,.38-.17.38-.38V3.87c0-.21-.17-.38-.38-.38Zm-1.25,10.35c0,.13-.1.23-.23.23h-1.04c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h1.04c.13,0,.23.1.23.23v1.03Zm0-3.24c0,.13-.1.23-.23.23h-1.04c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h1.04c.13,0,.23.1.23.23v1.03Zm0-3.24c0,.13-.1.23-.23.23h-1.04c-.13,0-.23-.1-.23-.23v-1.03c0-.13.1-.23.23-.23h1.04c.13,0,.23.1.23.23v1.03Z'
          />
        </g>
      </g>
    </svg>
  );
}
