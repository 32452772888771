import React from 'react';

const UsersIcons = ({ size = '16px', color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 103 68'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M51.6187 37.6202C61.9662 37.6202 70.3545 29.2318 70.3545 18.8843C70.3545 8.53677 61.9662 0.148438 51.6187 0.148438C41.2711 0.148438 32.8828 8.53677 32.8828 18.8843C32.8828 29.2318 41.2711 37.6202 51.6187 37.6202Z'
        fill={color}
      />
      <path
        d='M80.1054 61.5092C80.1054 61.5092 71.0237 67.2861 51.6113 67.2861C50.2191 67.2861 48.892 67.26 47.6039 67.195C33.0706 66.5835 25.264 62.7192 23.5075 61.7304C23.2473 61.5872 23.1172 61.5092 23.1172 61.5092C23.1172 48.368 31.3662 39.5466 34.4498 36.6972C34.8531 36.3198 35.1784 36.0466 35.3736 35.8775C35.4646 35.8124 35.5297 35.7474 35.5687 35.7213C35.5947 35.7213 35.6078 35.6953 35.6078 35.6953C37.6115 37.8161 40.0445 39.5336 42.7508 40.7176C45.4701 41.9146 48.4626 42.5651 51.6113 42.5651C57.9086 42.5651 63.5944 39.9369 67.6148 35.6953C67.6148 35.6953 70.7375 38.1024 73.8601 42.5391C76.9828 46.9629 80.1054 53.4163 80.1054 61.5092Z'
        fill={color}
      />
      <path
        d='M96.5342 25.0252C96.5342 31.8559 90.9915 37.4116 84.1607 37.4116C79.0994 37.4116 74.7407 34.3671 72.8281 30.0084C74.5716 26.6906 75.5604 22.9044 75.5604 18.884C75.5604 17.9992 75.5084 17.1275 75.4043 16.2687C77.6422 14.0178 80.7388 12.6387 84.1607 12.6387C90.9915 12.6387 96.5342 18.1814 96.5342 25.0252Z'
        fill={color}
      />
      <path
        d='M30.4092 30.0084C28.4966 34.3671 24.1379 37.4116 19.0766 37.4116C12.2458 37.4116 6.70312 31.8559 6.70312 25.0252C6.70312 18.1944 12.2458 12.6387 19.0766 12.6387C22.4985 12.6387 25.5951 14.0178 27.833 16.2687C27.7289 17.1275 27.6769 17.9992 27.6769 18.884C27.6769 22.9044 28.6657 26.6906 30.4092 30.0084Z'
        fill={color}
      />
      <path
        d='M24.3074 40.7302C21.575 44.8937 19.0249 50.3583 18.2052 56.994C5.98786 56.8378 0.25 53.1817 0.25 53.1817C0.25 44.6855 5.46742 38.9087 7.60122 36.918C10.2555 39.9886 14.1848 41.9272 18.5565 41.9272C20.5992 41.9272 22.5509 41.4978 24.3074 40.7302Z'
        fill={color}
      />
      <path
        d='M78.9297 40.7302C81.662 44.8937 84.2122 50.3583 85.0319 56.994C97.2492 56.8378 102.987 53.1817 102.987 53.1817C102.987 44.6855 97.7696 38.9087 95.6358 36.918C92.9816 39.9886 89.0523 41.9272 84.6806 41.9272C82.6378 41.9272 80.6862 41.4978 78.9297 40.7302Z'
        fill={color}
      />
    </svg>
  );
};

export default UsersIcons;
