import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { SignInHeader } from '../../components';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';

import './login.css';
import logoWellet from '../../assets/images/welletIso.png';

import { Input, Button, Card, CardBody, CheckBox, Loading } from '../../uiComponents/common';
import { exchangeToken, setLoginLoading } from '../../actions';
import { useDispatch } from 'react-redux';
import WarningIcon from '../../uiComponents/icons/warningIcon';

const TENANT_ID = process.env.REACT_APP_TENANT_ID;

const Login = () => {
  const [errorLogin, setErrorLogin] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  auth.tenantId = TENANT_ID;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendForm = async () => {
    try {
      setLoading(true);
      dispatch(setLoginLoading(true))
      const res = await signInWithEmailAndPassword(auth, values.email, values.password);
      await onLoginSuccess(res.user);
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          setErrorLogin('Revisa tu clave');
          break;
        case 'auth/user-disabled':
          setErrorLogin('Esta cuenta ha sido dada de baja');
          break;
        default:
          setErrorLogin('Se produjo un error');
          break;
      }
    } finally {
      dispatch(setLoginLoading(false))
      setLoading(false);
    }
  };

  const onLoginSuccess = async (user) => {
    const res = await exchangeToken(dispatch, user, () => navigate('/'));
    return res;
  };

  const validate = () => {
    let errors = {};
    if (!values.email?.trim()) {
      errors.email = 'Email requerido';
    }
    if (!values.password?.trim()) {
      errors.password = 'Contraseña requerida';
    }
    return errors;
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='d-flex flex-column justify-content-beetween align-items-strech vh-100 w-100'>
      <SignInHeader />
      <div className='d-flex flex-column justify-content-center align-items-center h-100'>
        <form onSubmit={handleSubmit} className='login-form'>
          <Card>
            <div className='row py-3 justify-content-center'>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{ height: '70px', width: '70px' }}
              >
                <img src={logoWellet} alt='Logo' style={{ height: '100%', width: '100%' }} />
              </div>
            </div>
            <div className='row'>
              <h5 className='col-12 text-center' style={{ color: 'var(--color-subtitle)'}}>Login to your account</h5>
              <p className='col text-center text-muted'>Your credentials</p>
            </div>
            <CardBody>
              <Input
                placeholder={'Email'}
                name={'email'}
                value={values.email}
                type={'email'}
                error={errors.email}
                onChangeValue={handleChange}
              />
              <Input
                placeholder={'Password'}
                name={'password'}
                type={'password'}
                value={values.password}
                error={errors.password}
                onChangeValue={handleChange}
              />
              {errorLogin ? (
                <div className='mt-2 d-flex align-items-center'>
                  <div className='mr-1'>
                    <WarningIcon color={'#CD3D64'} />
                  </div>
                  <div className='error-text'>{errorLogin}</div>
                </div>
              ) : (
                <></>
              )}
              <div className='row py-2'>
                <div className='col pr-0'>
                  <CheckBox label={'Remember'} />
                </div>
                <div className='col text-right pl-0'>
                  <Link to={'/forgot-password'}>Forgot Password?</Link>
                </div>
              </div>
              <Button
                type='submit'
                size={'small'}
                className={'mt-2'}
                width={'100%'}
                text={'Sign in'}
              />
              <div className='py-2 mt-2 content-divider text-muted'>
                <span className='px-3'></span>
              </div>
              <div className='text-muted text-center'>
                <span>
                  By continuing, you&apos;re confirming that you&apos;ve read our{' '}
                  <Link href={''}>Terms & Conditions</Link> and <Link href={''}>Cookie Policy</Link>
                </span>
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default Login;
